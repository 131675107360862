import { createAsyncThunk } from "@reduxjs/toolkit";
import { videoService } from "../../../services";
import { VideoAction } from "./videoType";

export const fetchVideo = createAsyncThunk(
  VideoAction.FETCHVIDEOS,
  async () => {
    const response = await videoService.getAll();
    return response.data;
  }
);

export const fetchCourseVideo = createAsyncThunk(
  VideoAction.FETCHCOURSEVIDEOS,
  async (body: any) => {
    const response = await videoService.getCourseVideo(body);
    return response.data;
  }
);

export const publishVideo = createAsyncThunk(
  VideoAction.PUBLISHVIDEO,
  async (note: any) => {
    const response = await videoService.publish(note);
    return response.data;
  }
);

export const unPublish = createAsyncThunk(
  VideoAction.UNPUBLISH,
  async (note: any) => {
    const response = await videoService.unPublish(note);
    return response.data;
  }
);

export const deleteVideo = createAsyncThunk(
  VideoAction.DELETEVIDEO,
  async (id: number) => {
    const response = await videoService.deleteVideo(id);
    return response.data;
  }
);
