import React from "react";
import { Icon, makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";
import lecturerImage from "../../../assets/images/lecturer.jpeg";
import studentImage from "../../../assets/images/student.jpeg";
import { NavLink } from "react-router-dom";
import {
  Books,
  Calendar,
  ChevronDown,
  Result,
  Transcript,
} from "../../../assets/svg";
import { useAppSelector } from "../../../store";
import { selectRole, selectUser } from "../../../store/features/auth/authSlice";
import { Role } from "../../../shared";
import { Close } from "@material-ui/icons";
import dashboard from "../../../assets/images/home-icon.svg";
import notebook from "../../../assets/images/notebook.svg";
import marker from "../../../assets/images/markers.svg";
import video from "../../../assets/images/video.svg";
import liveclasses from "../../../assets/images/video-camera.svg";
import records from "../../../assets/images/records.svg";
import users from "../../../assets/images/users.svg";
import roles from "../../../assets/images/roles.svg";
import events from "../../../assets/images/events.svg";
import schoolSystem from "../../../assets/images/schoolSystem.svg";
import application from "../../../assets/images/application.svg";
import cbt from "../../../assets/images/cbt.svg";
import help from "../../../assets/images/help.svg";
import setup from "../../../assets/images/setup.svg";

import jquery from "jquery";

// this helps TypeScript to understand jQuery best !!!  otherwise It will confused .
const $: JQueryStatic = jquery;
interface Props {}

const SideBar = (props: Props) => {
  const style = useStyles();
  //Opening assignments menu
  const [menuOpen, setMenuOpen] = React.useState(false);
  const menuOpenHandler = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };

  const user = useAppSelector(selectUser);
  const userRole = useAppSelector(selectRole);

  const activeStyle = ({ isActive }: any) => {
    return {
      //color: isActive && colors.black,
      color: isActive && "#152E88",
      borderLeft: isActive && "3px solid " + colors.blue,
      marginBottom: "20px",
      minHeight: "16px",
      paddingLeft: "1rem",
    };
  };

  return (
    <div className={style.container + " sideBar"}>
      <div className={style.header}>
        <div className="close">
          <Close className="closeIcon" />
        </div>
        {userRole === Role.STUDENT || userRole === Role.LECTURER ? (
          <img
            src={
              `${user.image}` || require("../../../assets/img/placeholder.jpg")
            }
            alt="picture"
            style={{ height: "8.5rem", width: "8.5rem", borderRadius: 0 }}
          />
        ) : (
          <img
            src={require("../../../assets/img/placeholder.jpg")}
            alt="picture"
            style={{ height: "8.5rem", width: "8.5rem", borderRadius: 0 }}
          />
        )}

        <span>
          {userRole !== Role.ADMIN ? user.rank + " " + user.name : "Admin"}
        </span>
      </div>
      <div className={style.menu}>
        {/* <h6>MENU</h6> */}
        <ul className={style.menuItems}>
          {userRole !== Role.ADMIN && (
            <>
              <NavLink
                className={style.link}
                style={activeStyle}
                to="/dashboard"
              >
                <img
                  src={dashboard}
                  alt="dashboard-image"
                  width={"25px"}
                  height={"25px"}
                />{" "}
                Home
              </NavLink>

              {userRole === Role.STUDENT && (
                <NavLink
                  className={style.link}
                  style={activeStyle}
                  to="/dashboard/cbt-assignment"
                >
                  <img
                    src={cbt}
                    alt="CBT"
                    width={"25px"}
                    height={"25px"}
                    style={{ marginRight: ".3rem" }}
                  />
                  Exam
                </NavLink>
              )}

              <NavLink
                className={style.link}
                style={activeStyle}
                to="/dashboard/calendar"
              >
                <Calendar /> Calendar
              </NavLink>
            </>
          )}
          {userRole === Role.ADMIN && (
            <NavLink className={style.link} style={activeStyle} to="/dashboard">
              <img
                src={users}
                alt="dashboard-image"
                width={"25px"}
                height={"25px"}
              />
              Users
            </NavLink>
          )}
          {userRole === Role.ADMIN && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/applicants"
            >
              <img
                src={application}
                alt="dashboard-image"
                width={"25px"}
                height={"25px"}
              />
              Applications
            </NavLink>
          )}
          {userRole === Role.ADMIN && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/notice"
            >
              <img
                src={roles}
                alt="dashboard-image"
                width={"25px"}
                height={"25px"}
              />
              Notice
            </NavLink>
          )}
          {userRole === Role.ADMIN && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/events"
            >
              <img
                src={events}
                alt="dashboard"
                width={"25px"}
                height={"25px"}
              />{" "}
              Events
            </NavLink>
          )}
          {userRole === Role.ADMIN && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/schoolsystem"
            >
              <img
                src={setup}
                alt="dashboard"
                width={"25px"}
                height={"25px"}
              />
              Setup
            </NavLink>
          )}
          {userRole === Role.ADMIN && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/manage-website"
            >
              <img
                src={schoolSystem}
                alt="dashboard"
                width={"25px"}
                height={"25px"}
              />
              Website
            </NavLink>
          )}
          {/* {userRole === Role.ADMIN && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/payments"
            >
              Payments
            </NavLink>
          )} */}

          {userRole !== Role.ADMIN && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/courses"
            >
              <Books /> Courses
            </NavLink>
          )}
          
          {/* hidden pending implementation */}

          {/* {userRole === Role.LECTURER && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/records"
            >
              <img
                src={records}
                alt="notebook-image"
                width={"25px"}
                height={"25px"}
              />{" "}
              Records
            </NavLink>
          )} */}

          {/* {userRole === Role.STUDENT && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/transcript"
            >
              <Transcript /> Transcript
            </NavLink>
          )} */}

          {/* hidden pending implementation */}
          {(userRole === Role.STUDENT || userRole == Role.LECTURER) && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/results"
            >
              <Result /> Results
            </NavLink>
          )}

          {userRole !== Role.ADMIN && (
            <NavLink
              className={style.link}
              style={activeStyle}
              to="/dashboard/essays"
            >
              <img
                  src={notebook}
                  alt="notebook"
                  width={"25px"}
                  height={"25px"}
                  style={{ marginRight: ".3rem" }}
                /> Essays
            </NavLink>
          )}
          
          <NavLink
            className={style.link}
            style={activeStyle}
            to="/dashboard/help"
          >
            <img
                src={help}
                alt="help"
                width={"25px"}
                height={"25px"}
                style={{ marginRight: ".3rem" }}
              /> Help
          </NavLink>
          
        </ul>
        {userRole !== Role.ADMIN && (
          <>
            <h6>E-Learning</h6>
            <ul className={style.menuItems}>
              {/* <NavLink
                className={style.link}
                style={activeStyle}
                to="/dashboard/assignments"
              >
                <img
                  src={notebook}
                  alt="notebook"
                  width={"25px"}
                  height={"25px"}
                  style={{ marginRight: ".3rem" }}
                />
                Assignments
              </NavLink> */}

              {userRole === Role.LECTURER && (
                <NavLink
                  className={style.link}
                  style={activeStyle}
                  to="/dashboard/cbt-assignment"
                >
                  <img
                    src={cbt}
                    alt="CBT"
                    width={"25px"}
                    height={"25px"}
                    style={{ marginRight: ".3rem" }}
                  />
                  CBT
                </NavLink>
              )}

              {userRole === Role.STUDENT && (
                <NavLink
                  className={style.link}
                  style={activeStyle}
                  to="/dashboard/cbt-assignment"
                >
                  <img
                    src={cbt}
                    alt="CBT"
                    width={"25px"}
                    height={"25px"}
                    style={{ marginRight: ".3rem" }}
                  />
                  CBT
                </NavLink>
              )}

              <NavLink
                className={style.link}
                style={activeStyle}
                to="/dashboard/notes"
              >
                <img src={marker} alt="marker" width={"25px"} height={"25px"} />{" "}
                Notes
              </NavLink>
              <NavLink
                className={style.link}
                style={activeStyle}
                to="/dashboard/videos"
              >
                <img src={video} alt="video" width={"25px"} height={"25px"} />
                Videos
              </NavLink>
              <NavLink
                className={style.link}
                style={activeStyle}
                to="/dashboard/liveClasses"
              >
                <img
                  src={liveclasses}
                  alt="liveclasses"
                  width={"25px"}
                  height={"25px"}
                />
                Live Classes
              </NavLink>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    //close btn for sidebarmobile
    "& .close": {
      display: "none",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      width: "100%",
      "& .closeIcon": {
        position: "absolute",
        right: ".5rem",
        color: colors.blue,
        cursor: "pointer",
      },
    },
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    position: "sticky",
    top: 0, zIndex: 1,

    background: colors.white,
    boxShadow: "6px 0px 4px rgba(0, 0, 0, 0.25)",
    padding: "1.5rem 0.1rem",
    [theme.breakpoints.up("sm")]: { overflowY: "auto", overFlowX: "hidden" },

    // width: "33%",
    // zIndex: 2022,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      height: "100vh -webkit-fill-available",
      display: "none",
      width: "70%",

      boxShadow: "2px 0px 2px rgba(0, 0, 0, 0.25)",
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    "& img": {
      height: "80px",
      width: "80px",
      objectFit: "cover",
      border: "2px solid #FFFFFF",
      boxShadow: "0px 5px 12px rgba(0, 0, 0, 0.125)",
      borderRadius: "50%",
    },
    "& span": {
      marginTop: "1rem",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: "17px",
      //color: colors.black,
      [theme.breakpoints.down("sm")]: {
        marginTop: ".8rem",
        fontSize: "1rem",
        lineHeight: "15px",
      },
      color: "#152E88",
    },

    backgroundColor: "#F1EDEC",
    width: "100%",
    // height: '100%',
    marginTop: "-1.35rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  menu: {
    marginTop: "2.5625rem",
    [theme.breakpoints.down("sm")]: { marginTop: "2rem" },
    "& h6": {
      marginLeft: "10px",
      marginBottom: "10px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "20px",

      color: colors.blue,
      [theme.breakpoints.down("sm")]: { fontSize: ".8rem", lineHeight: "18px" },
    },
  },
  menuItems: {
    marginLeft: "-1.4rem",
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    "& li ": {
      marginBottom: "20px",

      minHeight: "16px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "18px",
        minHeight: "14px",
      },

      "& a": {
        textDecoration: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "0.9375rem",
        lineHeight: "24px",
        /* identical to box height */

        color: "rgba(0, 0, 0, 0.7)",
        marginLeft: "20px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.9rem",
          lineHeight: "22px",
          marginLeft: "18px",
        },
      },
    },
    "& li.active": {
      borderLeft: "3px solid " + colors.blue,
      "& a": { color: colors.black },
    },
    "& li:hover": {
      transition: ".5s",
      borderLeft: "3px solid " + colors.blue,
      "& a": { color: colors.black },
    },
  },
  dropdownMenu: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "& .NavLinks": {
      display: "flex",
      flexDirection: "column",
      marginLeft: "56px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "50px",
      },

      "& a": {
        margin: ".5rem 0",
        [theme.breakpoints.down("sm")]: {
          margin: ".3rem 0",
        },
      },
      "& a:hover": {
        color: colors.blue,
      },
    },
  },
  dropdown: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& .dropdown-icon": {
      position: "absolute",
      right: "55px",
      [theme.breakpoints.down("sm")]: {
        right: "50px",
      },
    },
  },
  dropItems: {
    display: "flex",
    flexDirection: "column",
  },
  link: {
    textDecoration: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.9375rem",
    lineHeight: "24px",
    /* identical to box height */

    color: "rgba(0, 0, 0, 0.7)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      lineHeight: "22px",
    },
    "&:hover": {
      transition: ".5s",
      borderLeft: "3px solid #152E88",
      color: colors.black,
    },
  },
}));

$(document).on("click", ".closeIcon", function () {
  $(".sideBar").removeClass("activateSidebar");
});

SideBar.propTypes = {};

export default SideBar;
