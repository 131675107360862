import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; 

import { useStyles } from "../cbt.style";
import { Container, Grid, Button, Typography } from "@material-ui/core";

import studentImage from "../../../assets/images/student.jpeg";
import { SvgResult } from "../../../assets/svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { selectUser } from "../../store/features/auth/authSlice";
import { useAppSelector } from "../../store";
import LoadingSpinner from "../../../components/common/Loader";

interface Props {
  state: {
    score: number,
    obtainable_score: number
  }  
}

const UtmeResult = () => {
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const navigate = useNavigate(); 
  const style = useStyles();
  const user = useAppSelector(selectUser); 
  const [testLoaded, isTestLoaded] = useState(false);

  const { state }:any = useLocation(); 
  useEffect(() => {     
    setTimeout(()=>isTestLoaded(true),1500);
  }, []);
 
  const returnHome = () => {
    try {
      navigate(`/dashboard`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="xl" className={style.profile}>
      <div className="cont">
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link to="/dashboard/cbt-assignment">
            <img
              width="55px"
              height="50px"
              src={require("../../../assets/icons/NAFLogo.png")}
              alt=""
            />
          </Link>

          <Typography
            variant="h5"
            style={{ color: "#152e88", fontWeight: "800" }}
          >
            CBT Centre
          </Typography>
        </div>
        <Grid container alignItems="center" >
          <Grid item xs={12} sm={12} md={12} className="welcome" style={{borderRight: 'none'}}>
            <h1 style={{textAlign: "center",color: "#152e88"}} >              
            <SvgResult style={{height: '2.5rem'}}/>
            Result</h1>
            <div style={{ borderBottom:"1px solid #152e88", margin: "0 40rem", marginTop: '-1rem'}}></div>
            {testLoaded ?(<> 
            <Grid container style={{textAlign: "center"}}>
              <Grid item xs={12} sm={12}>
                <h4>Your score is 
                  {/* <span  style={{fontSize: '1.75rem',color: "#152e88",fontWeight: "500"}}> { state?.score | 0}{state.score ? ` out of ${state?.obtainable_score}` :""}. </span> */}
                  <span  style={{fontSize: '1.75rem',color: "#152e88",fontWeight: "500"}}> { state?.score} </span>
                  out of 
                  <span  style={{fontSize: '1.75rem',color: "#152e88",fontWeight: "500"}}>{` ${state?.obtainable_score}`}</span>
                </h4>
                <br/><br/>               
                <Button style={{textAlign: "center",color: "#fff", backgroundColor: "#152e88", borderBottom:"1px solid #152e88"}} 
                color="primary" onClick={()=>returnHome()} title="Click to return home">Return to home</Button>
              </Grid>               
            </Grid>            
            </>) : <><h3>Loading Score...</h3><LoadingSpinner/> </>}
          </Grid>    
        </Grid>
      </div>
    </Container>
  );
};

UtmeResult.propTypes = {};

export default UtmeResult;
