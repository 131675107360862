import {
  ArrowBack,
  ArrowForward,
  StayPrimaryLandscapeRounded,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Books } from "../../../assets/svg";
import Layout from "../../../components/Layout";

import { useStyles } from "./essays.style";
import LoadingSpinner from "../../../components/common/Loader";

interface Props {}

interface CustomizedState {
  id: any;
  name: any;
  code: any;
}

const EssaysStudentsList = () => {
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const [essays, setEssays] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    getCourseEssay();
  }, [state.id]);

  const getCourseEssay = async () => {
    try {
      const request = await fetch(`${BASE_URL}/essay/course/${state.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      console.log("response", response);
      setIsLoading(false);
      if (response.length) {
        setEssays(response);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <div className={style.container}>
        <div className={style.dash}>
          <div
            className={style.header}
            style={{
              backgroundColor: "#F1EDEC",
              padding: "1rem",
              display: "flex",
              justifyContent: "flex-start",
              gap: "30px",
            }}
          >
            <ArrowBack
              style={{ color: "#152E88", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <h5
              style={{
                fontWeight: 100,
                color: "#152E88",
                fontSize: "1.5rem",
              }}
            >
              {state.code} Essays
            </h5>
            <span style={{ float: "right" }}></span>
          </div>

          <div className={style.main}>
            <h5>Students</h5>
            <div className="lists">
              {isLoading && <LoadingSpinner />}
              {essays?.map((essay: any) => (
                <div className="list" key={essay.id}>
                  <div
                    className="content"
                    key={`-${essay.id}`}
                    style={{ display: "flex", gap: "30px" }}
                    onClick={() =>
                      navigate("/dashboard/essays/read-essay", {
                        state: {
                          title: essay.title,
                          content: essay.content,
                          date: essay.created_at,
                          course: essay.course,
                          student: essay.student,
                        },
                      })
                    }
                  >
                    <h6>{essay.student.rank}</h6>
                    <h6>{essay.student.name}</h6>
                    <h6>{essay.student.service_number}</h6>
                  </div>

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/dashboard/essays/read-essay", {
                        state: {
                          title: essay.title,
                          content: essay.content,
                          date: essay.created_at,
                          course: essay.course,
                          student: essay.student,
                        },
                      })
                    }
                  >
                    <ArrowForward />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

EssaysStudentsList.propTypes = {};

export default EssaysStudentsList;
