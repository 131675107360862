import { combineReducers } from "redux";
import { assignmentSlice } from "./features/assignment/assignmentSlice";
import { authSlice } from "./features/auth/authSlice";
import { courseSlice } from "./features/course/courseSlice";
import { errortSlice } from "./features/error/errorSlice";
import { eventeSlice } from "./features/event/eventSlice";
import { noteSlice } from "./features/note/noteSlice";
import { noticeSlice } from "./features/notice/noticeSlice";
import { paymentSlice } from "./features/payments/paymentSlice";
import { recordtSlice } from "./features/record/recordSlice";
import { videoSlice } from "./features/video/videoSlice";
import { livestreamSlice } from "./features/livestream/livestreamSlice";
import { websiteSlice } from "../pages/store/features/website/websiteSlice";

export const reducers = {
  auth: authSlice.reducer,
  assignments: assignmentSlice.reducer,
  notes: noteSlice.reducer,
  videos: videoSlice.reducer,
  courses: courseSlice.reducer,
  payments: paymentSlice.reducer,
  notice: noticeSlice.reducer,
  events: eventeSlice.reducer,
  records: recordtSlice.reducer,
  error: errortSlice.reducer,
  livestream: livestreamSlice.reducer,
  website: websiteSlice.reducer,
};

const rootReducer = combineReducers({ ...reducers });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
