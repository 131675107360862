import React from "react";
import { Link, useNavigate } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components

function SecondNavbar() {
  const navigate = useNavigate();
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  });
  return (
    <>
      {bodyClick ? (
        <div 
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}

      <Navbar
        className="fixed-top"
        expand="lg"
        id="navbar-main"  style={
          {
            // backgroundColor: "black",
            backgroundImage:
              "url(" + require("../../../assets/img/abujaview.jpg") + ")",
          }
        } 
      >
        {/* <Container> */}
        <div className="navbar-translate">
          <NavbarBrand id="navbar-brand" to="/" tag={Link}  style={{color:"white"}}>
            <img
              src={require("../../../assets/img/_logo.png")}
              width={"50px"}
              height={"50px"}
              alt="Site Logo"
            />
            {/* Air Mindedness */}
            <span style={{color:"orange"}}>Air Warfare Centre</span>
          </NavbarBrand>
          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            Site Name 
          </UncontrolledTooltip>
          <button
            className="navbar-toggler"
            id="navigation"
            type="button"
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setBodyClick(true);
              setCollapseOpen(true);
            }}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse 
          navbar 
          isOpen={collapseOpen}
          style={{ justifyContent: "flex-end"}}
        >
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Button
                id="about-us-btn"
                className="btn-neutral"
                color="link"
                onClick={(e)=>navigate("/about-us")}
                style={{
                  textDecoration: "none",
                  color: "#d3d1d1",
                }}
              >
                About Us
              </Button>
              <UncontrolledTooltip placement="bottom" target="about-us-btn">
                About Air Warfare Centre
              </UncontrolledTooltip>
            </NavItem>

            <NavItem>
              <Button
                id="updates-btn"
                className="btn-neutral"
                color="link"        
                onClick={(e)=>navigate("/updates")}
                style={{ textDecoration: "none", color: "#d3d1d1" }}
              >
                Updates
              </Button>
              <UncontrolledTooltip placement="bottom" target="updates-btn">
                Posted Updates of AWC
              </UncontrolledTooltip>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                className="mr-2"
                color="link" style={{color:"white"}}
                caret
                nav
                id="classes-access-btn"
              >
                Training
              </DropdownToggle>               
              <DropdownMenu className="dropdown-danger" right>
                <DropdownItem to="/instructors/login" tag={Link}>
                  Resource Persons
                </DropdownItem>

                <DropdownItem to="/participants/login" tag={Link}>
                  Students
                </DropdownItem>
                <DropdownItem to="/application" tag={Link}>
                  Apply
                </DropdownItem>

                <DropdownItem
                  href="https://airforce.mil.ng/doctrines"
                  target="_blank"
                >
                  NAF Publications
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <NavItem>
              <Button
                id="naf-site-btn"
                className="btn-neutral"
                color="link"
                href="https://airforce.mil.ng"
                target="_blank"
                style={{ color: "#66615b" }}
              >
                <i
                  className="fa fa-paper-plane-o"
                  style={{ color: "#d3d1d1" }}
                />
              </Button>
              <UncontrolledTooltip placement="bottom" target="naf-site-btn">
                NAF main website
              </UncontrolledTooltip>
            </NavItem>
            <NavItem>
              <Button
                className="btn-neutral"
                id="microsoft-teams-btn"
                style={{ backgroundColor: "purple" }}
                color="link"
                href="https://www.microsoft.com/en-ww/microsoft-teams/log-in"
                target="_blank"
              >
                <i className="fa fa-video-camera" />
              </Button>
              <UncontrolledTooltip
                placement="bottom"
                target="microsoft-teams-btn"
              >
                Microsoft Teams for Air Warfare Centre
              </UncontrolledTooltip>
            </NavItem>
          </Nav>
        </Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  );
}

export default SecondNavbar;
