import { API } from "../configure-api";

//Student endpoints
const getAll = (user?: string) => API.get(`/note/student/all`);
const getCourseNote = (id: number) => API.get(`/note/student/course/${id}`);

//Lecturer endpoints
const getLecAll = () => API.get("/note/lecturer/notes");
const getLecNote = (course_id: number, note_id: number) =>
  API.get(`/note/course/${course_id}/${note_id}`);
const createNote = (body: any) => API.post("/note/create", body);
const publishNote = (body: any) => API.patch("/note/publish", body);
const unPublish = (body: any) => API.patch("/note/unpublish", body);
const shareNote = (body: any) => API.patch("/note/send-to-students", body);
const deleteNote = (id: number) => API.delete(`/note/${id}`);
const getCouseNote = (id: number) => API.get(`/note/course/${id}/notes`);

export const notesService = {
  getAll,
  getCourseNote,
  getLecAll,
  getLecNote,
  createNote,
  publishNote,
  unPublish,
  deleteNote,
  shareNote,
  getCouseNote,
};
