import { createAsyncThunk } from "@reduxjs/toolkit";
import { eventService } from "../../../services";
import { EventAction } from "./eventType";

export const fetchEvents = createAsyncThunk(
  EventAction.FETCHEVENT,
  async (user: string) => {
    const response = await eventService.getAll(user);
    return response.data;
  }
);

export const fetchRecentEvents = createAsyncThunk(
  EventAction.FETCHRECENTEVENT,
  async (user: string) => {
    const response = await eventService.getRecentEvents(user);
    return response.data;
  }
);
