import React from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./transcript.style";
import Layout from "../../../components/Layout";
import { Grid, TextField, Button, Box } from "@material-ui/core";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Exclamation } from "../../../assets/svg";
import {SvgTranscript} from "../../../assets/svg";

interface IFormInputs {
  email: string;
  session: string;
  password: string;
}
const schema = yup.object().shape({
  email: yup.string().email().required(),
  session: yup.string().required(),
  password: yup.string().required(),
});

interface Props {}

const Transcript = (props: Props) => {
  const style = useStyles();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    
  };
  const sessions = [
    { value: "0", label: "Select session" },
    { value: "1", label: "2019/2020" },
    { value: "2", label: "2021/2022" },
  ];
  const [isSubmited, setIsSubmited] = React.useState(false);
  const handleIsSubmit = () => {
    isSubmited ? setIsSubmited(false) : setIsSubmited(true);
  };

  return (
    <Layout>
      <div className={style.container}>
        {/* //Request transcript */}
        {isSubmited ? (
          <div className={style.modalContainer}>
            <div className="modal">
              <Link to="" className="cancelBox" onClick={handleIsSubmit}>
                x
              </Link>
              <div className="content">
                <Exclamation />
                <h6>Enter your password!</h6>
                <div>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        placeholder="Password"
                        fullWidth={true}
                        size="small"
                      />
                    )}
                  />
                </div>
                <div>
                  <Button color="primary" variant="contained" type="submit">
                    Submit
                  </Button>
                </div>
                <form></form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className={style.dash}>
        <h5 style={{ color: '#152E88', fontSize: '1.5rem', fontWeight: 100, backgroundColor: '#F1EDEC', padding: '1rem' }}>
            Transcript <span style={{ float: 'right' }}><SvgTranscript /></span>
          </h5>
          <div className={style.main}>
            <div className="form">
              <span className="info">Leave blank to receive in your email</span>
              <form onSubmit={handleSubmit(formSubmitHandler)}>
                <div className="form-control">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        placeholder="Reciever’s Email Address (optional)"
                        fullWidth={true}
                        size="medium"
                      />
                    )}
                  />
                </div>
                <div className="form-control row">
                  <div>
                    <Controller
                      name="session"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.session}
                          select
                          // label="Level"
                          SelectProps={{
                            native: true,
                          }}
                          variant="filled"
                          placeholder="Session"
                          size="medium"
                          helperText={
                            errors.session ? (
                              <span>session is required</span>
                            ) : (
                              ""
                            )
                          }
                          InputProps={{ disableUnderline: true }}
                        >
                          {sessions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </div>
                  <div>
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      size="medium"
                      onClick={handleIsSubmit}
                    >
                      Request
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Transcript.propTypes = {};

export default Transcript;
