import React from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";

import { useStyles } from "./records.style";
import Layout from "../../../components/Layout";
import { Grid, TextField, Button, Box } from "@material-ui/core";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { Drowpdown } from "../../../assets/svg";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCourses } from "../../../store/features/course/courseSlice";
import { fetchCourses } from "../../../store/features/course/courseThunk";
import { selectRole } from "../../../store/features/auth/authSlice";
import { recordService } from "../../../services";

import records from "../../../assets/images/records.svg";
import { toast } from "react-toastify";

interface IFormInputs {
  student_id: number;
  course_id: number;
  name: string;
  obtainable: number;
  obtained: number;
}
interface StudentRecordInputs {
  student_id: number;
  course_id: number;
}
const schema = yup.object().shape({
  course_id: yup.number().required(),
  student_id: yup.number().required(),
  obtainable: yup.number().required(),
  name: yup.string().required(),
  obtained: yup.number().required(),
});

interface Props {}

const Records = (props: Props) => {
  const style = useStyles();
  const dispatch = useAppDispatch();
  const coursess = useAppSelector(selectCourses);
  const userRole = useAppSelector(selectRole);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setIsLoading(true);
    

    const response = await recordService.addStudentCouseRecord(data);
    console.log("RESPONSE", response);
    if (response.status === 200) {
      toast.success("Record added successfully");
      setIsLoading(false);
    } else {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };
  // const generateStudentRecord: SubmitHandler<StudentRecordInputs> = async (
  //   data: StudentRecordInputs
  // ) => {
  //   setIsLoading(true);
  //   

  //   // const response = await recordService.generateStudentCourseResult(data);
  //   // console.log("RESPONSE", response);
  //   // if (response.status === 200) {
  //   //   toast.success("Record generated successfully");
  //   //   setIsLoading(false);
  //   // } else {
  //   //   toast.error("Something went wrong");
  //   //   setIsLoading(false);
  //   // }
  // };
  const generateStudentRecord = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    let course_id = watch("course_id");
    let student_id = watch("student_id");
    console.log("Form data", course_id, student_id);
    const data = { course_id: course_id, student_id: student_id };

    const response = await recordService.generateStudentCourseResult(data);
    console.log("RESPONSE", response);
    if (response.status === 200) {
      toast.success("Record generated successfully");
      setIsLoading(false);
    } else {
      toast.error("Could not generate result");
      setIsLoading(false);
    }
  };
  const generateSemesterRecord = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    let course_id = e.target.course_id.value;

    console.log("Form data", course_id);
    const data = { course_id: course_id };

    const response = await recordService.generateStudentSemesterResult(data);
    console.log("RESPONSE", response);
    if (response.status === 200) {
      toast.success("Record generated successfully");
      setIsLoading(false);
    } else {
      toast.error("Could not generate result");
      setIsLoading(false);
    }
  };

  const courses = [{ value: "0", label: "Course", students: [] }];

  coursess.map((course: any) =>
    courses.push({
      value: course.id,
      label: course.name,
      students: course.students,
    })
  );

  const assessmentType = [
    { value: "0", label: "Assessment Type" },
    { value: "Attendence", label: "Attendence" },
    { value: "test", label: "test" },
  ];
  const [openMenu, setIsOpenMenu] = React.useState(false);
  const handleMenu = () => {
    setIsOpenMenu(!openMenu);
  };
  const [generateStudent, setGenerateStudent] = React.useState(false);
  const [generateSession, setGenerateSession] = React.useState(false);
  const handleGenerateStudent = () => {
    setGenerateStudent(!generateStudent);
    setGenerateSession(false);
    setIsOpenMenu(false);
  };
  const handleGenerateSemester = () => {
    setGenerateSession(!generateSession);
    setGenerateStudent(false);
    setIsOpenMenu(false);
  };

  const getCourses = async () => {
    await dispatch(fetchCourses(userRole));
  };
  React.useEffect(() => {
    getCourses();
  }, []);
  // const [courseId, setCourseId] = React.useState("");
  const [courseID, setCourseID] = React.useState("");
  const [students, setStudents] = React.useState([
    { id: "0", name: "Select", service_number: "" },
  ]);
  React.useEffect(() => {
    setStudents([{ id: "0", name: "Select", service_number: "" }]);
    var stuOptions: any = [{ id: "0", name: "Select", service_number: "" }];

    coursess
      .filter((course: any) => course.id === parseInt(courseID))
      .map((course: any) =>
        course.students.map((student: any) =>
          stuOptions.push({
            id: student.id,
            name: student.name + " - " + student.service_number,
            service_number: student.service_number,
          })
        )
      );

    setStudents(stuOptions);
  }, [courseID]);

  return (
    <Layout>
      <div className={style.container}>
        {/* //Generate Student Modal */}
        {generateStudent && (
          <div className={style.modalContainer}>
            <div className="modals">
              <Link to="" className="cancelBox" onClick={handleGenerateStudent}>
                x
              </Link>
              <div className="content">
                <form onSubmit={generateStudentRecord}>
                  <div className="input">
                    <Controller
                      name="course_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.course_id}
                          select
                          // label="Level"
                          SelectProps={{
                            native: true,
                          }}
                          variant="filled"
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            value: courseID,
                          }}
                          fullWidth={true}
                          hiddenLabel
                          onChange={(e) => {
                            setCourseID(e.target.value);
                            setValue("course_id", parseInt(e.target.value));
                          }}
                        >
                          {courses.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </div>

                  <div className="input">
                    <Controller
                      name="student_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.course_id}
                          select
                          // label="Level"
                          SelectProps={{
                            native: true,
                            name: "student_id",
                          }}
                          variant="filled"
                          size="small"
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          hiddenLabel
                        >
                          {students.map((student, i: any) => (
                            <option key={i} value={student.id}>
                              {student.name}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </div>
                  <button type="submit">
                    {isLoading ? "Generating..." : "Generate"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
        {generateSession && (
          <div className={style.modalContainer}>
            <div className="modals">
              <Link
                to=""
                className="cancelBox"
                onClick={handleGenerateSemester}
              >
                x
              </Link>
              <div className="content">
                <form onSubmit={generateSemesterRecord}>
                  <div className="input">
                    <Controller
                      name="course_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.course_id}
                          select
                          // label="Level"
                          SelectProps={{
                            native: true,
                          }}
                          variant="filled"
                          size="small"
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          hiddenLabel
                        >
                          {courses.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </div>

                  <Button type="submit">Generate</Button>
                </form>
              </div>
            </div>
          </div>
        )}
        <div className={style.dash}>
          <div className="header">
            <h5
              style={{
                color: "#152E88",
                fontSize: "1.5rem",
                fontWeight: 100,
                backgroundColor: "#F1EDEC",
                padding: "1rem",
              }}
            >
              Records{" "}
              <span style={{ float: "right" }}>
                <img
                  src={records}
                  alt="notebook-image"
                  width={"25px"}
                  height={"25px"}
                />
              </span>
            </h5>
            <div className="btn">
              <button onClick={handleMenu}>
                Generate report <Drowpdown />
              </button>
              {openMenu && (
                <div className="dropItems">
                  <Link to="" onClick={handleGenerateStudent}>
                    Student
                  </Link>
                  <Link to="" onClick={handleGenerateSemester}>
                    Course
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className={style.main}>
            <div className={style.form}>
              <form onSubmit={handleSubmit(formSubmitHandler)}>
                <div className="form-row row-1">
                  <div>
                    <Controller
                      name="course_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.course_id}
                          select
                          // label="Level"
                          SelectProps={{
                            native: true,
                          }}
                          variant="filled"
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            value: courseID,
                          }}
                          fullWidth={true}
                          hiddenLabel
                          onChange={(e) => {
                            setCourseID(e.target.value);
                            setValue("course_id", parseInt(e.target.value));
                          }}
                        >
                          {courses.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="student_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.course_id}
                          select
                          // label="Level"
                          SelectProps={{
                            native: true,
                            name: "student_id",
                          }}
                          variant="filled"
                          size="small"
                          InputProps={{ disableUnderline: true }}
                          fullWidth={true}
                          hiddenLabel
                        >
                          {students.map((student, i: any) => (
                            <option key={i} value={student.id}>
                              {student.name}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </div>
                </div>
                <div className="form-row row-2">
                  <div>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.name}
                          variant="outlined"
                          placeholder="Assessment name"
                          fullWidth={true}
                          size="small"
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Controller
                      name="obtainable"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.obtainable}
                          variant="outlined"
                          placeholder="Obtainable Score"
                          fullWidth={true}
                          size="small"
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Controller
                      name="obtained"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.obtained}
                          variant="outlined"
                          placeholder="Score"
                          size="small"
                          fullWidth={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <Button color="primary" variant="contained" type="submit">
                    {isLoading ? "Adding..." : "Submit"}
                  </Button>
                </div>
              </form>
            </div>
            <div className={style.lists}>
              {coursess.map((course: any, i: number) => (
                <div className="list" key={i}>
                  <div>
                    <h6 className="title">{course.code}</h6>
                    <h6 className="desc">{course.name}</h6>
                  </div>
                  <div className="right"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Records.propTypes = {};

export default Records;
