import { createAsyncThunk } from "@reduxjs/toolkit";
import { websiteService } from "../../../services/website/websiteService";
import { WebsiteAction } from "./websiteTypes";

export const fetchWebsite = createAsyncThunk(
  WebsiteAction.FETCHWEBSITE,
  async () => {
    const response = await websiteService.getWebsite();
    return response.data;
  }
);
