import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./CBTAssignments.style";
import Layout from "../../../components/Layout";
import { Grid } from "@material-ui/core";
import box from "../../../assets/images/box.png";
import { Link, useNavigate } from "react-router-dom";
import { Plus, Elipses } from "../../../assets/svg/";
import { useAppDispatch, useAppSelector } from "../../../store";

import { selectRole, selectUser } from "../../../store/features/auth/authSlice";
import { formatDate, Role } from "../../../shared";
import { toast, ToastContainer } from "react-toastify";

interface Props {
  quizes: any;
}

const CBTAssignmentsList = (props: Props) => {
  const style = useStyles();
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectRole);
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);

  console.log(user);

  return (
    <div className="lists">
      {props.quizes?.length === 0
        ? (<h6 style={{marginLeft:'1rem'}}>No Test(s) found</h6>)
        : props.quizes
            ?.map((obj: any) => ({
              ...obj,
              date: new Date(obj.updated_at),
            }))
            .sort((a: any, b: any) => b.date.getTime() - a.date.getTime())
            .map((ass: any, i: number) => (
              ass.is_published && new Date(ass.due_date) >= new Date()  &&( 
              <div className="list" key={i}>
                <div
                  className="content"
                  onClick={() => navigate(`/cbt/profile/${ass.id}`)}
                >
                  <h5>{ass.name}</h5>
                  <h6>{ass.course.name}</h6>
                  <span>{formatDate(ass.updated_at)}</span>
                </div>
                <div></div>
              </div>
              )
            ))}
    </div>
  );
};

CBTAssignmentsList.propTypes = {};

export default CBTAssignmentsList;
