import React from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { colors } from "../../../styles/color";
import { Settings, ChevronDown, Logout } from "../../../assets/svg/";
import { useAppDispatch, useAppSelector } from "../../../store";
import { logout } from "../../../store/features/auth/authThunk";
import { toast } from "react-toastify";
import { clearState, selectRole } from "../../../store/features/auth/authSlice";
import { Role } from "../../../shared";

import {
  ExitToAppOutlined,
  MenuOutlined,
  SettingsApplications,
} from "@material-ui/icons";
import jquery from "jquery";

// this helps TypeScript to understand jQuery best !!!  otherwise It will confused .
const $: JQueryStatic = jquery;
interface Props {}

const TopBarMobile = (props: Props) => {
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  const role = useAppSelector(selectRole);

  const style = useStyles();

  const loginOut = async () => {
    await dispatch(logout());
    dispatch(clearState());
    // toast.error("Successfully logout");
    if (role === Role.LECTURER) Navigate("/instructors/login");
    if (role === Role.STUDENT) Navigate("/participants/login");
  };

  //Activating mobile sidebar
  const [isActiveSidebar, setIsActiveSidebar] = React.useState(false);

  const activateSidebar = () => {
    setIsActiveSidebar(!isActiveSidebar);
  };

  return (
    <div className={style.container} id="mobileHeader">
      
      <div className="toggleSidebar">
        <MenuOutlined />
      </div>

      <Typography variant="h5" style={{ color: 'gold' }} >
        Training Centre
      </Typography>
      <div className="right" onClick={loginOut}>
        Logout <ExitToAppOutlined />
      </div>

    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    //background: colors.white,
    height: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "1rem",
    //color: colors.blue,
    fontWeight: 600,
    padding: "0 1rem",
    //boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    "& .right": {
      display: "flex",
      alignItems: "center",
    },

    backgroundColor: '#152E88',
    boxShadow: '2px 3px 12px 0 #888888', color: '#fff'
  }, 

}));
$(document).on("click", ".toggleSidebar", function () {
  if ($(".sideBar").hasClass("activateSidebar")) {
    $(".sideBar").removeClass("activateSidebar");
  } else {
    $(".sideBar").addClass("activateSidebar");
  }
});

TopBarMobile.propTypes = {};

export default TopBarMobile;
