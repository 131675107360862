import { API } from "../configure-api";

//Students endpoints
const getAll = () => API.get("/assignment/student/all");
const getCourseAss = (id: number) =>
  API.get(`/assignment/student/course/${id}`);
const createSubmission = (body: any) =>
  API.post(`/assignment/create-submission`, body);
const updateSubmission = (body: any) =>
  API.post(`/assignment/update-submission`, body);
const submitAssignemt = (body: any) => API.patch(`/assignment/submit`, body);

//Lecturer endpoints
const getLecCourseAss = (id: number) =>
  API.get(`/assignment/course/${id}/assignments`);
const getLectAss = () => API.get(`/assignment/all`);
const getSubmissions = (body: any) =>
  API.get(`/assignment/course/${body.course_id}/${body.ass_id}`);
const publishAss = (body: any) => API.patch(`/assignment/publish`, body);
const unPublishAss = (body: any) => API.patch(`/assignment/unpublish`, body);
const markAss = (body: any) => API.patch(`/assignment/mark`, body);
const createAss = (body: any) => API.post(`/assignment/create`, body);
const updateAss = (body: any) => API.put(`/assignment/update`, body);

export const assignmentService = {
  getAll,
  getCourseAss,
  createSubmission,
  updateSubmission,
  submitAssignemt,
  getLecCourseAss,
  publishAss,
  unPublishAss,
  markAss,
  createAss,
  updateAss,
  getLectAss,
  getSubmissions,
};
