import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchPayments } from './paymentThunk'

const initialState = {
    payments: [] as any[],
    error: '' as string,
    status: '' as string
}

export const paymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        clearState: (state) => {
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPayments.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetchPayments.fulfilled, (state, action) => {
                return { ...state, status: "fullfilled" }
            })
            .addCase(fetchPayments.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })
    }
})


// export const select = (state: any) => state.notes.notes
