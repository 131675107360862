import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./assignments.style";
import Layout from "../../../components/Layout";
import { Grid } from "@material-ui/core";
import box from "../../../assets/images/box.png";
import { Link } from "react-router-dom";
import { Plus, Elipses } from "../../../assets/svg/";

interface Props {}

const AssignmentsHistory = (props: Props) => {
  const style = useStyles();

  return (
    <Layout>
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.header}>
            <h5>History</h5>

            <Link to="/dashboard/assignments">Back</Link>
          </div>
          <div className={style.main}>
            <div className="lists student">
              <div className="list">
                <div className="content">
                  <span>Jon Doe</span>
                  <h5>General Studies</h5>
                </div>
                <div className="detail">
                  <span>GSC 112</span>
                  <span>20, Sep 2021</span>
                </div>
              </div>
              <div className="list">
                <div className="content">
                  <span>Sophie Musa</span>
                  <h5>C++ Programming</h5>
                </div>
                <div className="detail">
                  <span>CSC 102</span>
                  <span>18, Sep 2021</span>
                </div>
              </div>
              <div className="list">
                <div className="content">
                  <span>John Snow</span>
                  <h5>Microsoft Word</h5>
                </div>
                <div className="detail">
                  <span>CSC 104</span>
                  <span>15, Sep 2021</span>
                </div>
              </div>
              <div className="list">
                <div className="content">
                  <span>John Snow</span>
                  <h5>Software Development</h5>
                </div>
                <div className="detail">
                  <span>CSC 101</span>
                  <span>12, Sep 2021</span>
                </div>
              </div>
              <div className="list">
                <div className="content">
                  <span>Jamie Bones</span>
                  <h5>Data-Base</h5>
                </div>
                <div className="detail">
                  <span>CSC 112</span>
                  <span>10, Sep 2021</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

AssignmentsHistory.propTypes = {};

export default AssignmentsHistory;
