import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./viewResults.style";
import Layout from "../../../components/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Chev } from "../../../assets/svg/";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/common/Loader";
import { Badge, Button, Table } from "reactstrap";
import { Role } from "../../shared";
import { useAppSelector } from "../../store";
import { selectRole, selectUser } from "../../store/features/auth/authSlice";
import { DownloadTableExcel } from "react-export-table-to-excel";
 
import { useReactToPrint } from "react-to-print";
import { Grid } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

interface Props {}

interface CustomizedState {
  course_id: number;
  course_code: string;
  course_name: string;
  test_score: number,
  exam_score: number,
  attendance_score: number
}

const ViewResults = (props: Props) => {
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const dispatch = useDispatch<any>();
  const [isLoading, setIsLoading] = useState(false);

  const userRole = useAppSelector(selectRole);
  const user = useAppSelector(selectUser);

  const [result, setResult] = useState<{ [key: string]: any }>({});
  const [score, setScore] = useState(0);
  const [totalTestScore, setTotalTestScore] = useState(0);
  //const [grade, setGrade] = useState("");

  const [questionIndex, setQuestionIndex] = useState(0);
  
  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();
  // console.log(state.course_id, state.assignment_id);
  let parser = new DOMParser();

  const alphabets = 'abcdefghijklmnopqrstuvwxyz'.split('');

  const tableRef = React.useRef(null);
  const printRef = React.useRef(null);
  const handlePrintList = useReactToPrint({
    content: () => printRef.current
  });

  const [viewModal, setViewModal] = useState(false);
  const [focusedTest, setFocusedTest] = useState<{
    [key: string]: any;
  }>();
  

  /*const calculateAvg = (result: any)=>{
    let totalScore = 0; ; let obtainableScore = 0; let finalScore = "0";
    if(result.results.length > 0){
      result.results.map((assessment: any)=>{
        console.log(`IN ${assessment.score} - ${assessment.obtainable_score}`)
        totalScore += assessment?.score;
        obtainableScore += assessment?.obtainable_score;
     });

     finalScore = ((totalScore/obtainableScore) * 100).toFixed(1);
    }
    return parseFloat(finalScore);   
  }*/

  const calculateGrade = (score: any)=>{
    if(score < 40)return "F";
    if(score > 40 && score < 49.9)return "C-";
    if(score >= 50 && score < 54.9)return "LC";
    if(score >= 60 && score < 64.9)return "HC";
    if(score >= 65 && score < 69.9)return "C+";
    if(score >= 70 && score < 74.9)return "B";
    if(score >= 75 && score < 79.9)return "B+";
    if(score >= 80)return "A";
  }

  const handlePageReload = (waitingMinutes: number) => {
    setTimeout(() => {
      window.location.reload();
    }, waitingMinutes * 60 * 1000);
  };

  const fetchResult = async () => {
    setIsLoading(true);
    try {
      const request = await fetch(
        `${BASE_URL}/result/course/${state?.course_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      console.log("Response Data: ",response);
      if (request.ok) {  
        
        if (!response.wait) {
          setIsLoading(false);
          response?.students?.map((student: any, key:number) => {
            student.test_total_scored = student.tests.map((a: { score: any })=>a.score).reduce((a:any, b:any)=> parseInt(a) + parseInt(b), 0);
            student.test_total = student.tests.map((a: { obtainable_score: any })=>a.obtainable_score).reduce((a:any, b:any)=> parseInt(a) + parseInt(b), 0);
          });
          setResult(response);
        } else {
          toast.info(response.message);
          handlePageReload(response.wait);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  
  const handleRegenerate = async () => {
    setIsLoading(true);
    try {
      const request = await fetch(
        `${BASE_URL}/result/course/${state?.course_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "DELETE"
        }
      );
      const response = await request.json();
      console.log("Response Data: ",response);
      if (request.ok) {  
          toast.info(response.message);
          handlePageReload(response.wait);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchResult();
  }, []);

  //console.log(result);
  
  return (
    <Layout>
      <ToastContainer />
      {isLoading && <LoadingSpinner />}
      {/* //Generate Student Modal */}
      {/*viewModal && (
        <div className={style.modalContainer}>
          <div className="modals">
            <div
              style={{
                boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
                minHeight: "calc(100vh - 100px)",
                height: "calc(100% - 50px)",
              }}
            >
              <ToastContainer />
              <div
                className={style.header}
                style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
              >
                <h5
                  style={{
                    fontWeight: 100,
                    color: "#152E88",
                    fontSize: "1.5rem",
                  }}
                >
                  <Chev className="icon" onClick={() => setViewModal(false)} />
                  {focusedTest?.name}
                </h5>
              </div>

              <div className={style.main}>
                <div className="testBadgesContainer">
                  <Badge className="testBadge" pill>
                    Number of questions: {focusedTest?.correct_answers + focusedTest?.incorrect_answers}
                  </Badge>
                  <Badge className="testBadge"  color="primary" pill>
                    Attempted:{" "}
                    {
                      focusedTest?.questions_answered
                    }
                  </Badge>
                  <Badge className="testBadge"  color="success" pill>
                    Correct:{" "}
                    {
                      focusedTest?.correct_answers
                    }
                  </Badge>
                  <Badge className="testBadge" color="danger" pill>
                    Incorrect:{" "}
                    {
                      focusedTest?.incorrect_answers
                    }
                  </Badge>
                  <Badge className="testBadge"  color="info" pill>
                    Score:{" "}
                    {
                      focusedTest?.obtainable_score 
                      != 0 
                      ?`${focusedTest?.score.toFixed(1)}/${focusedTest?.obtainable_score}`
                      :'-'                                             
                    }
                  </Badge>
                </div>
                
                <Grid container spacing={3}>
                
                <React.Fragment>
                  {                  
                  result && result?.
                  quizzes.find(
                    (quiz: any) => quiz.id === focusedTest?.quiz_id
                  )?.answers
                  .filter(()=>{ return true})
                  .map((answer: any, index: any) => (
                    answer.student.id == user.id &&
                    <Grid item xs={12} sm={6} key={answer.id}>
                      <div
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                          borderRadius: "8px",
                          margin: "10px 20px",
                          padding: "8px 16px",
                          fontWeight: 400,
                          display: "flex",
                        }}
                      >
                        
                        <div
                          dangerouslySetInnerHTML={{
                            __html: answer.question.text,
                          }}
                        />
                      </div>
                      <ul style={{ listStyleType: "none" }}>
                        {answer.question.options.map((option: any) => (
                          <React.Fragment key={option.id}>
                            <li
                              style={{
                                background: "#E9E9E9",
                                borderRadius: "8px",
                                margin: "10px 20px",
                                padding: "8px 16px",
                                fontWeight: 400,
                              }}
                            >
                              {option.text}
                              {answer.option.id === option.id &&
                                (answer.is_correct ? (
                                  <CheckIcon style={{ color: "green" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                ))}
                              
                              {answer.option.id !== option.id && option.is_correct && 
                                (
                                  <CheckIcon style={{ color: "green" }} />
                                )}
                            </li>
                          </React.Fragment>
                        ))}
                      </ul>
                    </Grid>
                  ))}
                  </React.Fragment>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      )*/}

      <div className={style.container}>
        <div className={style.dash}>
          <div
            className={style.header}
            style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
          >
            <h5
              style={{
                fontWeight: 100,
                color: "#152E88",
                fontSize: "1.5rem",
              }}
            >
              <Link className="icon" to="/dashboard/results">
                <Chev />
              </Link>
              {state.course_code} : {state.course_name}
            </h5>
          </div>

          <div className={style.main}>
            <h5
              style={{
                fontWeight: 400,
                color: "#152E88",
                fontSize: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              {/* Result: {grade}<span style={{float:"right"}}>{score}%</span> */}
              {userRole === Role.LECTURER && 
              <span style={{float:"right", marginTop: "-5rem"}}>
                <Button 
                  title="Regenerate"
                  style={{color:"#152E88", background:"#fff",borderColor:"#152E88",fontSize:".85rem", marginRight: ".5rem"}}  
                  onClick={()=>handleRegenerate()}>
                  <i className="fa fa-refresh"></i>
                </Button> 
                
                <Button 
                  style={{color:"#152E88", background:"#fff",borderColor:"#152E88",fontSize:".85rem"}}  
                  onClick={()=>handlePrintList()}>
                  <i className="fa fa-print"></i>
                </Button> 

                 <DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                >
                    {/* <button> Export excel </button> */}
                    <Button 
                      style={{marginLeft: ".5rem", background:"#fff", borderColor: "#29C688",fontSize:".85rem", color:"#29C688"}}>
                      <i className="fa fa-file-excel-o" style={{}}></i>
                    </Button> 
                </DownloadTableExcel>
              </span> 
              }  
            </h5>

            

            {/**Students Result view */}
            {userRole === Role.STUDENT && (

            <Table responsive striped size="sm">
              <thead>
                <tr>
                  <th>
                    <p className="scoreTableHeading">
                      Assessment
                    </p>
                  </th>

                  <th>
                    <p className="scoreTableHeading">
                      Attempted
                    </p>
                  </th>
                  <th>
                    <p className="scoreTableHeading">
                      Correct
                    </p>
                  </th>
                  <th>
                    <p  className="scoreTableHeading">
                      Incorrect
                    </p>
                  </th>
                  <th>
                    <p className="scoreTableHeading">
                      Score
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>                
                {result?.results?.map((item: any, key:number) => (
                  <tr key={key} 
                  style={{ cursor: "pointer" }}
                        onClick={() => {
                          setViewModal(true);
                          setFocusedTest(item);
                        }}>
                    <td>
                      <p className="scoreTableData">
                        {item.name}
                      </p>
                    </td>

                    <td>
                      <p className="scoreTableData scoreTableDataNumber">
                        {item.questions_answered}
                      </p>
                    </td>
                    <td>
                      <p className="scoreTableData scoreTableDataNumber">
                        {item.correct_answers}
                      </p>
                    </td>
                    <td>
                      <p className="scoreTableData scoreTableDataNumber">
                        {item.incorrect_answers}
                      </p>
                    </td>
                    <td>
                      <p className="scoreTableData scoreTableDataNumber">
                        {item.obtainable_score != 0 ?`${Math.round(parseFloat(item.score))}/${item.obtainable_score}`:'-'}
                      </p>
                    </td>
                  </tr>
                ))}

                {result?.results?.length === 0 && (<tr><td colSpan={5}><p style={{fontSize: "16px",color: "#333",fontWeight: 400,}}>No submissions recorded.</p></td></tr>)}
              </tbody>
            </Table>
            )}

            {/**Instructor Result view */}
            {userRole === Role.LECTURER && result && (

              <>
               
              <div ref={printRef} className="printTableDiv">  
              <h5 style={{
                fontWeight: 100,
                color: "#152E88",
                fontSize: "1.5rem",
                textAlign: "center"
              }}>{state.course_code} - {state.course_name} Report Sheet
              </h5> 
              <table   ref={tableRef} className="printTable responsive table table-striped sm">        
              {/* <Table responsive striped size="sm" ref={tableRef} className="printTable"> */}
                {/* <thead>    */}                  
                  <tr>
                    <td className="scoreTableHeading">
                        Serial 
                    </td>
                    <td className="scoreTableHeading">
                        Service Number 
                    </td>
                    <td className="scoreTableHeading">
                        Participant                    
                    </td>            
                    <td rowSpan={2}>   <table>
                      <tr> <th colSpan={result?.quizzes?.length} style={{textAlign:"center"}}>Daily Tests</th> </tr>  
                      <tr>            
                    {result?.quizzes?.map((quiz: any, key:number) => (
                      <td className="scoreTableHeading" style={{textAlign: "center", width: "2rem", wordWrap:"break-word"}}>
                        Test {key+1/* {quiz.name} */}
                      </td>
                    ))}
                    </tr>
                    <tr>
                    {result?.quizzes?.map((alpha: any, key:number) => (
                      <td className="scoreTableHeading" style={{textAlign: "center"}}>
                         ({alphabets[key+3]})
                      </td>
                    ))}
                    </tr> </table>
                    </td>    

                    <td className="scoreTableHeading" style={{textAlign:"center"}}>
                      Total Daily Tests <br/> {state.test_score?state.test_score:'0'}%
                    {/* { result?.students[0]?.attendance?.obtainable_score != undefined && result?.students[0]?.exam?.obtainable_score != undefined &&
                    (100 - (result?.students[0]?.attendance?.obtainable_score + result?.students[0]?.exam?.obtainable_score)).toString() + "%"}   */}
                    </td>
                    <td className="scoreTableHeading" style={{textAlign:"center"}}>
                      Attendance <br/> {state.attendance_score?state.attendance_score:'0'}%  
                      {/* {result?.students[0]?.attendance?.obtainable_score != undefined && result?.students[0]?.attendance?.obtainable_score.toString() + "%"}                */}
                    </td>
                    <td className="scoreTableHeading" style={{textAlign:"center"}}>
                      Final Exam <br/> {state.exam_score? state.exam_score: '0'}% 
                      {/* {result?.students[0]?.exam?.obtainable_score != undefined && result?.students[0]?.exam?.obtainable_score.toString() + "%"}                  */}
                    </td>
                    <td className="scoreTableHeading" style={{textAlign:"center"}}>
                      Total Score  <br/> 100%
                      {/* {'100%'}                      */}
                    </td>
                    <td className="scoreTableHeading">
                        Remarks                 
                    </td>
                  </tr>
                    
                  <tr>
                    <td className="scoreTableHeading" style={{textAlign: "center"}}>
                        ({alphabets[0]})
                    </td>
                    <td className="scoreTableHeading" style={{textAlign: "center"}}>
                    ({alphabets[1]})
                    </td>
                    <td className="scoreTableHeading" style={{textAlign: "center"}}>
                    ({alphabets[2]})                    
                    </td>          
                        
                    <td className="scoreTableHeading" style={{textAlign: "center"}}>
                    ({alphabets[result?.quizzes?.length+3]})
                    </td>
                    <td className="scoreTableHeading" style={{textAlign: "center"}}>
                    ({alphabets[result?.quizzes?.length+4]})
                    </td>
                    <td className="scoreTableHeading" style={{textAlign: "center"}}>
                    ({alphabets[result?.quizzes?.length+5]})
                    </td>
                    <td className="scoreTableHeading" style={{textAlign: "center"}}>
                    ({alphabets[result?.quizzes?.length+6]})
                    </td>
                    <td className="scoreTableHeading" style={{textAlign: "center"}}>
                    ({alphabets[result?.quizzes?.length+7]})                 
                    </td>
                    <td className="scoreTableHeading" style={{textAlign: "center"}}>
                                     
                    </td>
                  </tr>
                {/* </thead> */}
                <tbody>                
                  {result?.students?.map((item: any, key:number) => (
                    <tr key={key}>
                      <td>
                        <p className="scoreTableData" style={{textAlign:"center"}}>
                          {key+1}
                        </p>
                      </td>

                      <td>
                        <p className="scoreTableData">
                          {item.student.service_number}
                        </p>
                      </td>

                      <td>
                        <p className="scoreTableData">
                          {item.student.rank} {item.student.name}
                        </p>
                      </td> 
                        
                      <td><table>
                        {/* <tr>             */}
                       
                      {item.tests.map((test: any, key:number) => (
                        <td className="scoreTableData"  style={{textAlign: "center", width: "2rem", wordWrap:"break-word"}}>
                          { parseInt(test.score) !== 0 && !Number.isNaN(state.test_score) && state.test_score !== undefined  ?  
                          Math.round(parseFloat(`${(test.score/item.test_total)*(state.test_score)}`))                           
                          : "-"}  
                          {/* parseFloat(`${(test.score/test.obtainable_score)*(state.test_score/item.tests.length)}`).toFixed(1) : "-"}   */}
                        </td>
                      ))}
                      {/* </tr>  */}</table> 
                      </td>   
                      
                      <td className="scoreTableData"  style={{textAlign:"center"}}>                                                   
                        {!Number.isNaN(state.test_score) && state.test_score !== undefined  
                         ?Math.round(parseFloat(`${(item.test_total_scored/item.test_total)*(state.test_score)}`)) || "-" : "-"
                        //?parseFloat(`${item.test_summary.percentage*state.test_score}`).toFixed(1) || "-" : "-"
                        }                         
                      </td>
                      
                      <td className="scoreTableData" style={{textAlign:"center"}}>
                          {!Number.isNaN(item.attendance.obtained_score) && item.attendance.obtained_score !== undefined 
                          && !Number.isNaN(state.attendance_score) && state.attendance_score != undefined 
                          ? Math.round(parseFloat(`${(item.attendance.obtained_score/item.attendance.obtainable_score)*state.attendance_score}`)) : "-"}                        
                      </td>                                        

                      <td>
                        <p className="scoreTableData" style={{textAlign:"center"}}> 
                          {!Number.isNaN(item.exam.score) && item.exam.score !== undefined 
                          && !Number.isNaN(state.exam_score) && state.exam_score != undefined 
                          ? Math.round(parseFloat(`${item.exam.score/item.exam.obtainable_score*state.exam_score}`)) : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="scoreTableData" style={{textAlign:"center"}}>
                          {!Number.isNaN(item.test_summary.percentage) && item.test_summary.percentage !== undefined && 
                          !Number.isNaN(item.attendance.obtained_score) && item.attendance.obtained_score !== undefined &&  

                          !Number.isNaN(item.exam.score) && item.exam.score !== undefined 
                          && !Number.isNaN(state.test_score) && state.test_score != undefined 
                          && !Number.isNaN(state.attendance_score) && state.attendance_score != undefined 
                          && !Number.isNaN(state.exam_score) && state.exam_score != undefined 

                          ? (
                          Math.round((item.test_summary.percentage * state.test_score || 0) +
                          ((item.attendance.obtained_score/item.attendance.obtainable_score) * state.attendance_score || 0) +
                          ((item.exam.score/item.exam.obtainable_score) * state.exam_score || 0)
                          )) : "-"}
                        </p>
                      </td>
                      <td>
                        <p className="scoreTableData">                        
                        </p>
                      </td>
                      
                    </tr>
                  ))}                 
                  
                  {result?.results?.length === 0 && (<tr><td colSpan={5}><p style={{fontSize: "16px",color: "#333",fontWeight: 400,}}>No submissions recorded.</p></td></tr>)}
                </tbody>
              {/* </Table> */}
              </table>
              </div>  
            </>
            )}      


          </div>
        </div>
      </div>
    </Layout>
  );
};

ViewResults.propTypes = {};

export default ViewResults;
