import { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Button,
  Col,
  Row,
  Container,
} from "reactstrap";
import { useStyles } from "../landing.style";

// const items = [
//   {
//     src:
//       "url(" + require("../../../assets/img/ac/AgustaWestland_AW139.jpg") + ")",
//     altText: "Slide 1",
//     caption: "Slide 1",
//     key: 1,
//     content: (
//       <Container>
//         <Row>
//           <Col className="text-left" md="6">
//             <h1 className="title">Mission</h1>
//             <h5 style={{ fontWeight: "500" }}>
//               The mission of Air Warfare Centre is{" "}
//               <q style={{ color: "orange", fontWeight: "600" }}>
//                 to educate and support the airman for air warfare.
//               </q>
//             </h5>
//             <br />
//             <div className="buttons">
//               <Button
//                 className="btn-round"
//                 color="danger"
//                 href="/#teams"
//                 // onClick={(e) => e.preventDefault()}
//                 size="lg"
//               >
//                 Read More
//               </Button>
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 href="/#teams"
//                 // onClick={(e) => e.preventDefault()}
//               >
//                 <i className="fa fa-paper-plane-o" />
//               </Button>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     ),
//   },
//   {
//     src: "url(" + require("../../../assets/img/ac/Mi-35P.JPG") + ")",
//     altText: "Slide 2",
//     caption: "Slide 2",
//     key: 2,
//     content: (
//       <Container>
//         <Row>
//           <Col className="ml-auto mr-auto text-center" md="8">
//             <h1 className="title">About the Centre</h1>
//             <h5>
//               To bridge training deficiency for officers between post Staff
//               Course and NDC. The Centre was also to develop operational and
//               tactical doctrines as well as provide integrated mission support
//               for the Nigerian Air Force.
//             </h5>
//             <br />
//             <h6>Connect with us:</h6>
//             <div className="buttons">
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 target="_blank"
//                 href="https://twitter.com/nigairforce"
//               >
//                 <i className="fa fa-twitter" />
//               </Button>
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 target="_blank"
//                 href="https://web.facebook.com/hqnigerianairforce/?_rdc=1&_rdr"
//               >
//                 <i className="fa fa-facebook-square" />
//               </Button>
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 target="_blank"
//                 href="https://www.instagram.com/nigerianairforce/?hl=en"
//               >
//                 <i className="fa fa-instagram" />
//               </Button>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     ),
//   },
//   {
//     src: "url(" + require("../../../assets/img/ac/Mi-35M.jpg") + ")",
//     altText: "Slide 3",
//     caption: "Slide 3",
//     key: 3,
//     content: (
//       <Container>
//         <Row>
//           <Col className="ml-auto text-right" md="7">
//             <h2 className="title">Air Warfare Centre</h2>
//             <h5>
//               The Air Warfare Centre was established in Kainji to bridge
//               training deficiency for officers between post Staff Course and
//               NDC. The Centre was also to develop operational and tactical
//               doctrines as well as provide integrated mission support for the
//               NAF.
//             </h5>
//             <br />
//             <div className="buttons">
//               <Button
//                 className="btn-round"
//                 color="success"
//                 href="/#teams"
//                 // onClick={(e) => e.preventDefault()}
//                 size="lg"
//               >
//                 Read More
//               </Button>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     ),
//   },
// ];

function Header({ slideItems }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const style = useStyles();

  console.log("items", slideItems);

  const items = slideItems?.map((slide, index) => ({
    src: "url(" + slide.image + ")",
    altText: `Slide ${index + 1}`,
    caption: `Slide ${index + 1}`,
    key: index + 1,
    content: (
      <Container>
        <Row>
          <Col className="text-left" md="6">
            <h1 className="title">{slide.title}</h1>
            <h5
              style={{ fontWeight: "500" }}
              className={style.carouselTextContainer}
              dangerouslySetInnerHTML={{ __html: slide.description }}
            ></h5>
            <br />
            {/* <div className="buttons">
              <Button
                className="btn-round"
                color="danger"
                href="/#teams"
                // onClick={(e) => e.preventDefault()}
                size="lg"
              >
                Read More
              </Button>
              <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="/#teams"
                // onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-paper-plane-o" />
              </Button>
            </div> */}
          </Col>
        </Row>
      </Container>
    ),
  }));

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >
        <div className="page-header" style={{ backgroundImage: item.src }}>
          <div className="filter" />
          <div className="content-center">{item.content}</div>
        </div>
        {/* <img src={item.src} alt={item.altText} /> */}
      </CarouselItem>
    );
  });

  return (
    <div
      className="section section-header cd-section"
      id="headers"
      style={{ marginTop: "-5rem" }}
    >
      {/* ********* HEADER 3 ********* */}
      <div className="header-3">
        <div className="page-carousel">
          <div className="filter" />
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {slides}
            {/* <CarouselControl   
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
              className="fa fa-angle-left "
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
              // className="fa fa-angle-right "
            /> */}

            <a
              style={{ textDecoration: "none" }}
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="fa fa-angle-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              style={{ textDecoration: "none" }}
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="fa fa-angle-right" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Header;
