import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./layout.style";
import SideBar from "../Menu/SideBar";
import RightBar from "../Menu/RightBar";
import { Grid, Box } from "@material-ui/core";
import TopBar from "../Menu/TopBar";
import TopBarMobile from "../Menu/TopBar/TopBarMobile";
import { selectRole } from "../../store/features/auth/authSlice";
import { useAppSelector } from "../../store";
import { Role } from "../../shared";
import { Link, useLocation } from "react-router-dom";

const Layout = ({ children }: any) => {
  const style = useStyles();

  const userRole = useAppSelector(selectRole);
  let location = useLocation();

  return (
    <React.Fragment>
      <Grid container className={style.containerDesktop}>
        <Grid item md={3} sm={12}>
          <SideBar />
        </Grid>

        <Grid item md={9} sm={12}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <TopBar />
            </Grid>

            <Grid item md={userRole !== Role.ADMIN ? 8 : 12} sm={12}>
              {children}
            </Grid>

            {userRole !== Role.ADMIN && (
              <Grid item md={4} sm={12}>
                {(location.pathname !== '/dashboard/notes/read' && !location.pathname.includes(`/dashboard/view-results/`) 
                // && 
                //   location.pathname !== '/dashboard/create-cbt' &&  
                //   location.pathname !== '/dashboard/update-cbt'
                ) && <RightBar />} 
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* //Table */}
      <Grid container className={style.containerTablet}>
        <Grid item md={12} sm={12}>
          <TopBarMobile />
        </Grid>

        <Grid item md={12} sm={12} className="sidebarDesk">
          <SideBar />
        </Grid>
        <Grid item sm={12}>
          <Grid container>
            <Grid item md={userRole !== Role.ADMIN ? 8 : 12} sm={userRole !== Role.ADMIN ? 8 : 12}>
              {children}
            </Grid>
            {userRole !== Role.ADMIN && (
              <Grid item sm={4}>
                {(location.pathname !== '/dashboard/notes/read' && !location.pathname.includes(`/dashboard/view-results/`) 
                // && 
                  // location.pathname !== '/dashboard/create-cbt' &&  
                  // location.pathname !== '/dashboard/update-cbt'
                ) && <RightBar />}  
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* //Mobile */}
      <Grid container className={style.containerMobile}>
        <Grid item md={12} sm={12} xs={12}>
          <TopBarMobile />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <SideBar />
          {children}
          {userRole !== Role.ADMIN && location.pathname != '/dashboard/notes/read' && <RightBar />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Layout.propTypes = {};

export default Layout;
