import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../Landing/landing.style";

import Truncate from 'react-truncate-html';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function TeamSection({ page }) {
  const navigate = useNavigate();
  const style = useStyles();
  return (
    <>
      <div className="section section-team cd-section" id="teams">
        {/* ********* TEAM 2 ********* */}
        <div
          className="team-2 section-image"
          style={{
            // backgroundImage:
            //   "url(" + require("../../assets/img/abujaview.jpg") + ")",
            backgroundColor: "#273746",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="9" sm="9">
                <h2 className="title" style={{ textAlign: "left" }}>
                  {page?.about?.title}
                </h2>
                <h5
                  className="description text-white"
                  style={{ textAlign: "justify" }}
                >
                  <Truncate dangerouslySetInnerHTML={{ __html: page?.about?.description,}}/>
                  <span style={{ fontSize: "16px", fontWeight: 600 }}>
                    . . .
                  </span>
                  <br /> <br />
                  <Button
                    className="btn-round"
                    color="danger"
                    href="/about-us"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/about-us", {
                        state: {
                          page,
                        },
                      });
                    }}
                    size="lg"
                  >
                    Read More
                  </Button>{" "}
                </h5>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="2" sm="3">
                <img
                  style={{
                    height: "15rem", width: "15rem",
                    boxShadow: "2px 3px 12px 0 #888888",
                  }}
                  alt="..."
                  className="img"
                  src={require("../../assets/images/AWC_Door.png")}
                  // src={require("../../assets/images/Mascot.jpg")}
                />
              </Col>
            </Row>
            <Row>
              <br />
            </Row>
          </Container>
        </div>
        {/* ********* END TEAM 2 ********* */}
      </div>
    </>
  );
}

export default TeamSection;
