import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useStyles } from "../cbt.style";
import { Container, Grid, Button, Typography } from "@material-ui/core";

import studentImage from "../../../assets/images/student.jpeg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { selectUser } from "../../store/features/auth/authSlice";
import { useAppSelector } from "../../store";
import LoadingSpinner from "../../../components/common/Loader";

interface Props {}

const UtmeProfile = (props: Props) => {
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { quizId } = useParams();
  const style = useStyles();
  const user = useAppSelector(selectUser);
  const [test, setTest] = useState<{ [key: string]: any }>({});
  const [testDetails, isTestDetails] = useState(false);

  useEffect(() => {
    let status = localStorage.getItem(`started_status_${quizId}`);
    if(status !== undefined && status === "started")return navigate(`/cbt/exam/${quizId}`)

    fetchQuiz();
  }, [quizId]);

  const fetchQuiz = async () => {
    try {
      const request = await fetch(`${BASE_URL}/cbt/quiz/${quizId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      if (request.ok) {
        setTest(response);
        isTestDetails(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStartQuiz = async () => {
    try {
      // const req = await fetch(`${BASE_URL}/cbt/quiz/${test.id}`, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      // const res = await req.json();
      // console.log(res);
      // if (req.ok) {
      //   navigate(`/cbt/utme/exam/${res.id}`);
      // }

      navigate(`/cbt/exam/${test.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="xl" className={style.profile}>
      <div className="cont">
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/dashboard/cbt-assignment">
            <img
              width="55px"
              height="50px"
              src={require("../../../assets/icons/NAFLogo.png")}
              alt=""
            />
          </Link>

          <Typography
            variant="h5"
            style={{ color: "#152e88", fontWeight: "800" }}
          >
            CBT Centre
          </Typography>
        </div>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={12} md={7} className="welcome">
            <h1>Welcome to AWC CBT Centre</h1>
            {testDetails ?(<> 
            <Grid container>
              <Grid item xs={6} sm={3}>
                <h6>course </h6>
              </Grid>
              <Grid item xs={6} sm={9}>
                <h5>
                  <span className="dot">: </span>
                  {test?.course?.name}
                </h5>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} sm={3}>
                <h6>Total score </h6>
              </Grid>
              <Grid item xs={6} sm={9}>
                <h5>
                  <span className="dot">: </span>
                  {test?.obtainable_score != 0 ?test?.obtainable_score:'-' }
                </h5>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} sm={3}>
                <h6>Time </h6>
              </Grid>
              <Grid item xs={6} sm={9}>
                <h5>
                  <span className="dot">: </span>
                  {test?.time_allowed != 0 ?test?.time_allowed +' minutes' :'-'}
                </h5>
              </Grid>
            </Grid>
            <p>Please read the instructions below before starting the Exams.</p>
            <ul className="">
              <li>Your result will appear in your dashboard</li>
              <li>Read the questions carefully and select your answer.</li>               
            </ul>
            <br/>
            <div dangerouslySetInnerHTML={{ __html: test?.instruction }} className="reading"></div>
            </>) : <><h3>Loading Test...</h3><LoadingSpinner/> </>}
          </Grid>
          
          <Grid item xs={12} sm={12} md={5} className="userDetails">
            <img src={user?.image} alt="profile pic" style={{borderRadius: 0}}/>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h6>Service Number</h6>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h5>
                  <span className="dot">:</span>
                  {user?.service_number}
                </h5>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h6>Rank</h6>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h5>
                  <span className="dot">:</span>
                  {user?.rank}
                </h5>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h6>Full Name</h6>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h5>
                  <span className="dot">:</span>
                  {user?.name}
                </h5>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h6>Specialty</h6>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h5>
                  <span className="dot">:</span>
                  {user?.specialty}
                </h5>
              </Grid>
            </Grid>
            
            <Button
              color="primary"
              variant="contained"
              type="button"
              size="medium"
              onClick={handleStartQuiz}
              disabled={testDetails?false:true}
              style={!testDetails?{opacity: .2, cursor:"no-drop"}:{}}
            >
              Start
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

UtmeProfile.propTypes = {};

export default UtmeProfile;
