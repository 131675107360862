import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useStyles } from "./website.style";
import { Badge, Button } from "reactstrap";
import LoadingSpinner from "../../../../components/common/Loader";
import { toast, ToastContainer } from "react-toastify";
import Confirm from "../../../../components/common/Confirm";
import {
  DeleteRounded,
  EditRounded,
  GetApp,
  LabelImportantOutlined,
  Publish,
} from "@material-ui/icons";
import { Calendar2 } from "../../../../assets/svg";
import { format } from "date-fns";
import PostsCreateModal from "./PostsCreateModal";
import axios from "axios";

interface Props {}

const Posts = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
  const [openPublishWarning, setOpenPublishWarning] = useState(false);
  const [openCreatePost, setOpenCreatePost] = useState(false);
  const [allPosts, setAllPosts]:any = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [title, setTitle] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const [featuredImageUrl, setFeaturedImageUrl] = useState("");
  const [content, setContent] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusedItem, setFocusedItem] = useState<any>(null);
  const [fileURL, setFileURL] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  useEffect(() => {
    setFocusedItem(null);
    fetchPosts();
    fetchTags();
  }, []);

  useEffect(() => {
    setTitle(focusedItem?.title);
    setContent(focusedItem?.body);
    setSelectedTags(
      focusedItem?.tags?.map((tag: any) => ({
        label: tag.name,
        value: tag.name,
      }))
    );
  }, [focusedItem]);

  const readMorePost = ()=>{
    setIsLoading(true);

    if(page < totalPages){
      setPage(page+1);
    }
    else{
      setPage(1);
    }        

    console.log(`Page: ${page}`);
    fetchPosts();
  }

  const fetchPosts = async () => {
    
    try {
      const request = await fetch(`${BASE_URL}/blog/post/all?page=${page}&per_page=3`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();    
      setTotalPages(Number(response.pages));   //console.log(`Total Pages: ${totalPages}`);     

      if (response.posts.length > 0) {
        let posts:any = response.posts;      
        setAllPosts(Object.keys(posts).map(function(key)   
        {  
          return Number(key), posts[key];
        })); 
      }

      setIsLoading(false);
      //console.log(`Posts: ${allPosts}`);

    } catch (error) {
      toast.error("Error fetching posts");
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      const request = await fetch(`${BASE_URL}/blog/tags`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      if (response.length) {
        setAllTags(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createPost = async (data: any) => {
    try {
      setIsSubmitting(true);
      const request = await fetch(`${BASE_URL}/blog/post`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const response = await request.json();
      setIsSubmitting(false);
      if (request.ok) {
        fetchPosts();
        setOpenCreatePost(false);
        toast.success("Post created successfully");
        console.log(response);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  const updatePost = async (data: any) => {
    try {
      setIsSubmitting(true);
      const request = await fetch(`${BASE_URL}/blog/post/${focusedItem?.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const response = await request.json();
      setIsSubmitting(false);
      if (request.ok) {
        fetchPosts();
        setOpenCreatePost(false);
        toast.success("Post updated successfully");
        console.log(response);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  const togglePublishPost = async () => {
    try {
      const body = {
        is_published: focusedItem?.is_published ? false : true,
      };
      setIsSubmitting(true);
      const request = await fetch(`${BASE_URL}/blog/post/${focusedItem?.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const response = await request.json();
      setIsSubmitting(false);
      if (request.ok) {
        fetchPosts();
        toast.success(
          `Post ${
            focusedItem?.is_published ? "unpublished" : "published"
          } successfully`
        );
        setFocusedItem(null);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  const deletePost = async () => {
    try {
      setIsSubmitting(true);
      const request = await fetch(`${BASE_URL}/blog/post/${focusedItem?.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsSubmitting(false);
      if (request.ok) {
        toast.success("Post deleted successfully");
        fetchPosts();
        setFocusedItem(null);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  const handleMultiSelect = (values: any) => {
    setSelectedTags(values);
  };

  const handleImageChange = async (event: any) => {
    setIsUploading(true);
    try {
      event.preventDefault();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Brearer " + localStorage.getItem("token"),
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}/upload/add`,
          { file: event.target.files[0] },
          config
        )
        .then((res) => {
          setIsUploading(false);
          if (res.status === 200) {
            setFeaturedImage(URL.createObjectURL(event.target.files[0]));
            setFeaturedImageUrl(res.data.url);
          }
        })
        .catch((err) => {
          setIsUploading(false);
          console.log(err);
        });
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };
  
  const handleFileChange = async (event: any) => {
    setIsUploading(true);
    try {
      event.preventDefault();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Brearer " + localStorage.getItem("token"),
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}/upload/add`,
          { file: event.target.files[0] },
          config
        )
        .then((res) => {
          setIsUploading(false);
          if (res.status === 200) {
            setFileURL(res.data.url);
          }
        })
        .catch((err) => {
          setIsUploading(false);
          console.log(err);
        });
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };

  const handleSubmit = () => {
    const body = {
      title,
      image: featuredImageUrl,
      body: content,
      tags: selectedTags.map((tag: any) => tag.value),
    };
    console.log(body);

    if (focusedItem) {
      updatePost(body);
    } else {
      createPost(body);
    }
  };

  const handleClose = () => {
    setFocusedItem(null);
    setOpenCreatePost(false);
    setFileURL("");
    setFeaturedImageUrl("");
    setFeaturedImage("");
  };

  return (
    <div>
      <ToastContainer />
      <Confirm
        open={openDeleteWarning}
        setOpen={setOpenDeleteWarning}
        title="warning"
        message="Are u sure you want to delete this post?"
        action={deletePost}
      />
      <Confirm
        open={openPublishWarning}
        setOpen={setOpenPublishWarning}
        title="warning"
        message={`Are u sure you want to ${
          focusedItem?.is_published ? "unpublish" : "publish"
        } this post?`}
        action={togglePublishPost}
      />

      <PostsCreateModal
        handleClose={handleClose}
        openCreatePost={openCreatePost}
        focusedItem={focusedItem}
        title={title}
        content={content}
        selectedTags={selectedTags}
        setTitle={setTitle}
        setContent={setContent}
        image={featuredImage}
        handleSubmit={handleSubmit}
        fileURL={fileURL}
        handleFileChange={handleFileChange}
        isSubmitting={isSubmitting}
        isUploading={isUploading}
        allTags={allTags}
        handleMultiSelect={handleMultiSelect}
        handleImageChange={handleImageChange}
      />
              
      {
          isLoading ? <h5 style={{color: "#152E88", fontWeight: 400, padding: "1rem"}}>Loading centres' posts info...</h5> :
          (
          <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button onClick={() => setOpenCreatePost(true)}>Create Post</Button>
        </div>
        {allPosts?.map((post: any) => (
          <div
            className="event"
            style={{ display: "flex", alignItems: "baseLine", gap: 2 }}
            key={post.id}
          >
            <div className="content" style={{ flexGrow: 1 }}>
              <h4>
                {post.title}
                {post.is_published && (
                  <span
                    style={{
                      fontWeight: 300,
                      fontSize: "12px",
                      border: "1px solid green",
                      color: "green",
                      padding: 2,
                    }}
                  >
                    published
                  </span>
                )}
              </h4>

              {post.image && (
                <img src={post.image} height={200} width={"100%"} alt="post" />
              )}

              <div dangerouslySetInnerHTML={{ __html: post?.body }} />
              <div className="details">
                <div className="det">
                  <LabelImportantOutlined style={{ color: "#152E88" }} />
                  <p>Tags:</p>
                  {post.tags?.map((tag: any) => (
                    <Badge key={tag.id} pill>
                      {tag.name}
                    </Badge>
                  ))}
                </div>
                <div className="det">
                  <Calendar2 />
                  <p>Updated:</p>
                  <p>{format(new Date(post.created_at), "EEEE, MMM d, YYY")}</p>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              {post.is_published ? (
                <GetApp
                  onClick={() => {
                    setFocusedItem(post);
                    setOpenPublishWarning(true);
                  }} 
                  titleAccess="Unpublish this post" 
                  fontSize="small"
                  style={{ color: "orange" }}
                />
              ) : (
                <Publish
                  onClick={() => {
                    setFocusedItem(post);
                    setOpenPublishWarning(true);
                  }}
                  titleAccess="Publish this post" 
                  fontSize="small"
                  style={{ color: "green" }}
                />
              )}
              <EditRounded
                onClick={() => {
                  setFocusedItem(post);
                  setOpenCreatePost(true);
                }}
                titleAccess="Edit this post"  
                fontSize="small"
                color="primary"
              />
              <DeleteRounded
                onClick={() => {
                  setFocusedItem(post);
                  setOpenDeleteWarning(true);
                }} 
                titleAccess="Delete this post" 
                fontSize="small"
                color="error"
              />
            </div>
          </div>
        ))}

        <Button onClick={() => readMorePost()}> Read More... </Button>
        
      </div>

      </>
      )
      }
      
    </div>
  );
};

Posts.propTypes = {};

export default Posts;
