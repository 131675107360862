import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCourses, fetchAddCourses, fetchCourseStudents } from "./courseThunk";

const initialState = {
  courses: [] as any[],
  courseListAdd: [] as any[],
  error: "" as string,
  status: "" as string,
  message: "" as string,
};

export const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    clearState: (state) => {
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.pending, (state, action) => {
        return { ...state, status: "loading" };
      })
      .addCase(fetchCourseStudents.pending, (state, action) => {
        return { ...state, status: "loading" };
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        return { ...state, courses: action.payload, status: "fullfilled" };
      })
      .addCase(fetchCourseStudents.fulfilled, (state, action) => {
        return { ...state, students: action.payload, status: "fullfilled" };
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message as any;
      })
      .addCase(fetchCourseStudents.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message as any;
      })
      .addCase(fetchAddCourses.pending, (state, action) => {
        return { ...state, status: "loading" };
      })
      .addCase(fetchAddCourses.fulfilled, (state, action) => {
        return {
          ...state,
          courseListAdd: action.payload,
          status: "fullfilled",
        };
      })
      .addCase(fetchAddCourses.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message as any;
      });
  },
});

//Get of list of courses for student to add

export const selectCourses = (state: any) => state.courses.courses;
export const selectCourseListAdd = (state: any) => state.courses.courseListAdd;
export const selectCourseStudents = (state: any) => state.courses.students;
