export enum AssignemtAction {
  FETCHASSIGNMENT = "[assignment/fetchassignment]",
  FETCHCOURSEASSIGNMENT = "[assignment/fetchcourseassignment]",
  ADDTSUBMISSION = "[assignment/addsubmission]",
  CREATESUBMISSION = "[assignment/createSubmission]",

  FETCHLECCOURSEASS = "[assignment/fetchLecCourseAss]",
  PUBLISH = "[assignment/publish]",
  UNPUBLISH = "[assignment/unpublish]",
  MARKASSIGNMENT = "[assignment/markAssignment]",
  DELETEASSIGNMENT = "[assignment/deleteAssignment]",
  CREATEASSIGNMENT = "[assignment/createAssignment]",
}
