import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  sharedNote: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "19px 23px",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
    },
    "& .title": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
      "& h5": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: "24px",
        color: colors.black,
        display: "flex",
        alignItems: "center",
        gap: "6px",
      },

      "& .icon": {
        // padding: "9.25px 12.99px",
        background: "#E9E9E9",
        borderRadius: "5px",
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .status": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "21px",
        color: colors.black,
        "& span": {
          fontStyle: "italic !important",
          fontWeight: 300 + " !important",
        },
      },
    },
    "& .content": {
      background: "#F6F4F4",
      borderRadius: "6px",
      padding: "9px 13px",
      marginTop: "17px",
      position: "relative",
      minHeight: "calc(90vh - 120px)",
      // [theme.]

      "& .header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "32px",
        "& .title": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "22px",
          lineHeight: "33px",
          color: colors.black,
        },
        "& .date": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: colors.black,
        },
      },
    },
    "& .body": {
      "& p": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "24px",
        color: colors.black,
      },
    },

    "& .pagination": {
      display: "flex",
      listStyleType: "none",
      gap: "6px",
      alignItems: "center",
      marginTop: "81px",
      position: "absolute",
      bottom: "7px",
      right: "20px",
      [theme.breakpoints.down("sm")]: {
        position: "static",
        // marginTop: "120px",
        justifyContent: "end",
      },
      "& li": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& a": {
          textDecoration: "none",
          color: colors.white,
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 640,
          fontSize: "1rem",
          lineHeight: "24px",
        },
      },

      "& .icon": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        padding: "4.33px 4.92px",
        cursor: "pointer",
      },
      "& .active": {
        background: " #E5E5E5 !important",
        color: "#fff !important",
      },
    },
  },
}));
