import React, { FormEvent } from "react";
import PropTypes from "prop-types";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useStyles } from "./admission.style";

import { Logo, ChevronDown2, Search2, NavPointer } from "../../../assets/svg/";

import { colors } from "../../../styles/color";

import { MenuOutlined } from "@material-ui/icons";

import Header from "../../../components/Header";

interface Props {}
const AdmissionCheck = (props: Props) => {
  const style = useStyles();
  const [jambNumber, setJambNumber] = React.useState("");
  const handleForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(jambNumber);
  };

  const navigate = useNavigate();

  return (
    <div className={style.container}>
      <Header />
      <div className="headerTitle">
        <h2>Admission Status</h2>
      </div>
      <div className="wrapper">
        <div className="form">
          <form onSubmit={handleForm} autoComplete="off" method="post">
            <div className="form-control">
              <input
                type="text"
                name="jambNumber"
                value={jambNumber}
                onChange={(e) => setJambNumber(e.target.value)}
                placeholder="Enter JAMB number"
              />
            </div>
            <div className="form-control">
              <button
                type="submit"
                onClick={() => navigate("/admissionStatus")}
              >
                Check
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

AdmissionCheck.propTypes = {};

export default AdmissionCheck;
