import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import PropTypes from "prop-types";

import { useStyles } from "../login.style";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
} from "@material-ui/core";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo.png";
import Footer from "../../../components/Footer";
import { useAppDispatch } from "../../../store";
import { clearState } from "../../../store/features/auth/authSlice";
import { login, resetPassword } from "../../../store/features/auth/authThunk";
import { IFormInputs } from "../../../shared";
import { Spinner } from "../../../components/common/Spinner";
import { toast, ToastContainer } from "react-toastify";
import useNetworkStatus from "../../shared/utils/network";

interface Props { }

const schema = yup.object().shape({
  password: yup.string().required()
});

const ResetPassword = (props: Props) => {
  const style = useStyles();
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useAppDispatch();

  const { isOnline } = useNetworkStatus();
  !isOnline && toast.warning("Check your network connectivity to be sure you're online.");

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setIsloading(true);
    await dispatch(resetPassword(data)).then((res: any) => {
      setIsloading(false);
      if (res.payload?.data.message){
        toast.success(res.payload.data.message);
        dispatch(clearState());
        localStorage.removeItem('token');
        window.location.href = "/success-password-reset";
      }else{
        toast.error('Something went wrong');
      }
    });
  };

  useEffect(() => {
    dispatch(clearState());
    // set localStorage token to to url param token
    localStorage.setItem("token", window.location.search.split("=")[1]);
  }, []);
  return (
    <>
    <ToastContainer/>
    <Container maxWidth="xl" className={style.container}>

      <Grid container className={style.form}>

        <Link to="/">
          <img
            src={require("../../../assets/icons/NAFLogo.png")}
            className={style.logoIMG} alt="Site Logo"
          />
        </Link>

        <Typography variant="h3" className={style.appTitle}>
          Air Warfare Centre
        </Typography>

        <Card variant="outlined" className={style.card} >
          <Container>
            <h3 className={style.headerTitle}>Reset Password              
            </h3>

            <form onSubmit={handleSubmit(formSubmitHandler)}>
              <Grid item sm={12} className={style.formGroup}>                
                <p className={style.labels}>Password</p>
                <div>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.password}
                        variant="outlined"
                        placeholder="Password"
                        type="password"
                        helperText={
                          errors.password ? <span>Password is required</span> : ""
                        }
                      />
                    )}
                  />
                </div>

                <p className={style.labels}>Confirm Password</p>
                <div>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.password}
                        variant="outlined"
                        placeholder="Password"
                        type="text"
                        helperText={
                          errors.password ? <span>Password is required</span> : ""
                        }
                      />
                    )}
                  />
                </div>
                 
                <div>
                  {/* <Grid container justify="center"> */}
                  <Button color="primary" variant="contained" type="submit" className={style.loginBtn} 
                    style={{ width: '100%', textTransform: 'capitalize' }}>
                    {isLoading ? <Spinner size="2" /> : "Submit"}
                  </Button>
                  {/* </Grid> */}
                </div>
              </Grid>
            </form>
          </Container>
        </Card>
      </Grid>
      {/* <Footer /> */}
    </Container>
    </>
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;
