import { API } from "../configure-api";

//Student endpoints
const getAll = (user: string) => API.get(`/${user}/courses`);

//Course Students endpoints
const getAllStudents = (courseId: number) => API.get(`/lecturer/courses/${courseId}/students`);

const getAddCourses = (level_id: number) =>
  API.get(`/course/get-by-level/${level_id}`);

const addCourses = (body: { course_ids: Array<{}> }) =>
  API.post("/student/add-course/multiple", body);

const dropCourse = (body: { course_id: number }) =>
  API.delete("/student/drop-course", body);

//Lecturer endpoints
const getLecAll = () => API.get("/lecturer/courses");
const getCourse = (id: number) => API.get(`/lecturer/courses/${id}`);

export const coursesService = {
  getAll,
  getAllStudents,
  getAddCourses,
  addCourses,
  dropCourse,
  getLecAll,
  getCourse,
};
