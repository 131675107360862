import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},
  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",

    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },

    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
  },
  main: {
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 150px)",
      overflowY: "auto",
      overflowX: "hidden",
    },
  },
  detailsBox: {
    marginTop: "1.875rem",
    padding: "1rem",
    background: "#F7F4F4",
    "& p": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      marginBottom: "0.70rem",
      color: "#000000",
    },
  },
  divider: {
    borderBottom: "2px solid rgba(0, 0, 0, 0.3)",
    margin: "3rem 0.125rem",
  },
  cardBox: {
    background: colors.gray,
    padding: "0.6875rem 0.8125rem",
    // borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    minHeight: "130px",

    justifyContent: "space-between",
    cursor: "pointer",
    "& div": { marginTop: "1.375rem" },
    "& h6": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
      color: colors.black,
    },
    "& span": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "12px",
      color: colors.black,
    },

    boxShadow: '2px 3px 12px 0 #888888', borderRadius: '.4rem'
  },

  dashboardName: {
    backgroundColor:'#F1EDEC', 
    padding: '1rem', 
    // color: 'white', 
    // boxShadow: '2px 3px 12px 0 #888888'
    color: '#152E88'
  },

  navLink: {
    textDecoration: 'none'
  }
}));
