import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { selectRole } from '../../store/features/auth/authSlice';
import { Role } from '../constant/roles';

const useTokenStatus = () => {
  const Navigate = useNavigate();
  const role = useAppSelector(selectRole);
  const [isValid, setisValid] = useState(true);
  const BASE_URL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    const interval = setInterval(() => {
      fetch(`${BASE_URL}/user/profile`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },        
      })
        .then(res=>res.json())
        .then((res) => {     
          if(res?.message === "Invalid Token") {
            localStorage.removeItem("token");
            setisValid(false);            
            if (role === Role.LECTURER) Navigate("/instructors/login");
            if (role === Role.ADMIN) Navigate("/instructors/login");
            if (role === Role.STUDENT) Navigate("/participants/login");
            Navigate("/");
            window.location.href = "/";
            return;
          } 
            setisValid(true);
         })
        .catch((err) => console.error(err));
    }, 600 * 1000);//10 mins

    return () => clearInterval(interval);
  }, [isValid]);

  return { isValid };
};

export default useTokenStatus;