import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { WhiteElipses } from "../../assets/svg";
import { colors } from "../../styles/color";
import { dropCourse } from "../../store/features/course/courseThunk";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import LoadingSpinner from "./Loader";

interface Props {
  options?: any;
  id: number;
  className?: string;
}

export const CourseOptionMenu = (props: Props) => {
  const { options, id, className } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isLoading, setIsLoading] = React.useState(false);

  const style = {
    textDecoration: "none",
    color: colors.black,
    backgroundColor: "transparent",
    outline: "none",
    border: "none",
  };
  const dispatch = useDispatch<any>();
  const handleDropCourse = async () => {
    setIsLoading(true);
    await dispatch(dropCourse({ course_id: id }))
      .then((res: any) => {
        setIsLoading(false);

        toast.success(res.payload.message);

        console.log(res.payload.message);
        // Close the modal

        handleClose();
        // window.location.reload();
      })
      .catch((err: any) => {
        setIsLoading(false);

        console.log(err);
      });
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={className}
      >
        <WhiteElipses />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option: any) => (
          <MenuItem
            key={id}
            selected={option === "share"}
            onClick={handleClose}
          >
            <button style={style} onClick={handleDropCourse}>
              {option.name}
            </button>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
