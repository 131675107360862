import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchCourseNotes, fetchNotes, fetcLecthNotes } from './noteThunk'

const initialState = {
    notes: [] as any[],
    courseNote: [] as any[],
    error: '' as string,
    status: '' as string
}

export const noteSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        clearState: (state) => {
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotes.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetchNotes.fulfilled, (state, action) => {
                return { ...state, notes: action.payload, status: "fullfilled" }
            })
            .addCase(fetchNotes.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })
            .addCase(fetchCourseNotes.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetchCourseNotes.fulfilled, (state, action) => {
                return { ...state, courseNote: action.payload, status: "fullfilled" }
            })
            .addCase(fetchCourseNotes.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })

            .addCase(fetcLecthNotes.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetcLecthNotes.fulfilled, (state, action) => {
                return { ...state, notes: action.payload, status: "fullfilled" }
            })
            .addCase(fetcLecthNotes.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })
    }
})


export const selectNotes = (state: any) => state.notes.notes
export const selectCourseNotes = (state: any) => state.notes.courseNote
