import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchUser, login, logout } from './authThunk'

const initialState = {
    user: {},
    isAuthenticated: false,
    status: "idle",
    isLoading: true,
    isLoggedIn: false,
    userRole: null,
    token: localStorage.getItem("token") || null
}

export const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearState: (state) => {
            localStorage.removeItem("token")
            state.user = {};
            state.isAuthenticated = false;
            state.isLoading = false;
            state.status = "idle";
            state.token = null;
            state.isLoggedIn = false;

            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state, action) => {
                return { ...state, status: "loading", isLoading: true }
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                return {
                    ...state,
                    user: action.payload,
                    isAuthenticated: true,
                    status: "fullfilled",
                    isLoading: false,
                    isLoggedIn: false,
                    userRole: action.payload.user && action.payload.user.role
                }
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = "rejected"
                state.isLoading = false
                state.isLoggedIn = false
            })
            .addCase(login.fulfilled, (state, action) => {
                return {
                    ...state,
                    user: action.payload.user,
                    token: action.payload.token,
                    isAuthenticated: true,
                    status: "fullfilled",
                    isLoggedIn: true
                }
            })
            .addCase(login.rejected, (state, action) => {
                state.status = "rejected"
                state.isLoggedIn = false
                state.isLoading = false
            })
    }
})

export const { clearState } = authSlice.actions;

export const selectUser = (state: any) => state.auth.user
export const selectAuth = (state: any) => state.auth.isAuthenticated
export const selectIsLoggedIn = (state: any) => state.auth.isLoggedIn
export const selectStatus = (state: any) => state.auth.status
export const selectIsLoading = (state: any) => state.auth.isLoading
export const selectError = (state: any) => state.auth.error
export const selectIsError = (state: any) => state.auth.isError
export const selectRole = (state: any) => state.auth.userRole
export default authSlice.reducer
