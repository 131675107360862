import { Routes, Route } from "react-router-dom";
import Board from "./dashboard/dashboard";
import Calendar from "./calendar/calendar";
import Courses from "./courses/courses";
// import CourseStudents from "./courseStudents/courseStudents";
import Records from "./records/records";
import Assignments from "./assignments/assignments";
import MarkAssignments from "./markAssignments/markAssignments";
import Notes from "./notes/notes";
import ReadNotes from "./notes/readNotes";
import Videos from "./videos/videos";
import PlayVideo from "./videos/playVideo";
import SendAssignments from "./sendAssigments/sendAssignments";
import PayFees from "./payFess/payFees";
import Transcript from "./transcript/transcript";
import Result from "./result/result";
import AssignmentsHistory from "./assignments/history";
import AttemptAssignments from "./assignments/attemptAssignment";
import SubmittedAssignments from "./assignments/submittedAssignment";
import { withAuth } from "../../hoc/withAuth";
import PageLoader from "../../components/common/PageLoader";
import { PrivateRoute } from "../../components/Routes";
import { Role } from "../../shared";
import Admin from "./admin";
import { selectRole } from "../../store/features/auth/authSlice";
import { useAppSelector } from "../../store";
import Users from "./admin/users";
import ViewAssignment from "./viewAssignment/viewAssignment";
import SharedNote from "./sharedNote/sharedNote";
import RecordAssignment from "./recordAssignment/recordAssignment";
import RecordedAssignment from "./recordedAssignment/recordedAssignment";
import EditAssigment from "./editAssignment/editAssignment";
import LiveClasses from "./liveClasses/liveClasses";
import AssignmentSubmissions from "./assignmentSubmissions/assignmentSubmissions";
import CourseStudents from "./courses/listStudents";
import ViewAssignmentLecturer from "./viewAssignment/viewAssignmenInstructor";
import CBTAssignments from "./CBT-Assignment/CBTAssignments";
import CreateCBT from "./CBT-Assignment/CreateCBT";
import LecturerViewCBT from "./CBT-Assignment/LecturerViewCBT";
import ViewResults from "./result/ViewResults";
import UpdateCBT from "./CBT-Assignment/UpdateCBT";
import Essays from "./essays/essays";
import EssaysStudentsList from "./essays/essaysStudentsList";
import ReadEssay from "./essays/readEssay";
import Help from "./help/help";
// import UpdateAssignments from "./updateAssigments /updateAssignments";

const Dashboard = () => {
  const userRole = useAppSelector(selectRole);

  return (
    <PageLoader>
      <Admin />
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT, Role.ADMIN]}
              component={userRole !== Role.ADMIN ? Board : Users}
            />
          }
        />
        {/* <Route path="/dashboard" element={<PrivateRoute roles={[Role.LECTURER, Role.STUDENT, Role.ADMIN]} component={Board} />} /> */}
        <Route
          path="/dashboard/calendar"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT]}
              component={Calendar}
            />
          }
        />
        <Route
          path="/dashboard/courses"
          element={
            <PrivateRoute
              roles={[Role.STUDENT, Role.LECTURER]}
              component={Courses}
            />
          }
        />
        <Route
          path="/dashboard/courses/students"
          element={
            <PrivateRoute roles={[Role.LECTURER]} component={CourseStudents} />
          }
        />
        <Route
          path="/dashboard/records"
          element={<PrivateRoute roles={[Role.LECTURER]} component={Records} />}
        />
        <Route
          path="/dashboard/essays"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT]}
              component={Essays}
            />
          }
        />
        <Route
          path="/dashboard/essays/students-list"
          element={
            <PrivateRoute
              roles={[Role.LECTURER]}
              component={EssaysStudentsList}
            />
          }
        />
        <Route
          path="/dashboard/essays/read-essay"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT]}
              component={ReadEssay}
            />
          }
        />
        <Route
          path="/dashboard/assignments"
          element={
            <PrivateRoute
              roles={[Role.STUDENT, Role.LECTURER]}
              component={Assignments}
            />
          }
        />

        <Route
          path="/dashboard/cbt-assignment"
          element={
            <PrivateRoute
              roles={[Role.STUDENT, Role.LECTURER]}
              component={CBTAssignments}
            />
          }
        />
        <Route
          path="/dashboard/create-cbt"
          element={
            <PrivateRoute roles={[Role.LECTURER]} component={CreateCBT} />
          }
        />
        <Route
          path="/dashboard/update-cbt"
          element={
            <PrivateRoute roles={[Role.LECTURER]} component={UpdateCBT} />
          }
        />
        <Route
          path="/dashboard/view-cbt"
          element={
            <PrivateRoute roles={[Role.LECTURER]} component={LecturerViewCBT} />
          }
        />
        <Route
          path="/dashboard/markAssignments"
          element={
            <PrivateRoute roles={[Role.LECTURER]} component={MarkAssignments} />
          }
        />
        <Route
          path="/dashboard/assignments/view"
          element={
            <PrivateRoute roles={[Role.LECTURER]} component={ViewAssignment} />
          }
        />
        {/* <Route
          path="/dashboard/markAssignments/submissions"
          element={
            <PrivateRoute
              roles={[Role.LECTURER]}
              component={AssignmentSubmissions}
            />
          }
        /> */}
        <Route
          path="/dashboard/assignments/submissions"
          element={
            <PrivateRoute
              roles={[Role.LECTURER]}
              component={ViewAssignmentLecturer}
            />
          }
        />

        <Route
          path="/dashboard/assignments/recorded"
          element={
            <PrivateRoute
              roles={[Role.LECTURER]}
              component={RecordedAssignment}
            />
          }
        />
        <Route
          path="/dashboard/assignments"
          element={
            <PrivateRoute
              roles={[Role.STUDENT, Role.LECTURER]}
              component={Assignments}
            />
          }
        />
        <Route
          path="/dashboard/assignments/record"
          element={
            <PrivateRoute
              roles={[Role.LECTURER]}
              component={RecordAssignment}
            />
          }
        />
        <Route
          path="/dashboard/assignments/edit"
          element={
            <PrivateRoute roles={[Role.LECTURER]} component={EditAssigment} />
          }
        />
        <Route
          path="/dashboard/notes"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT]}
              component={Notes}
            />
          }
        />
        <Route
          path="/dashboard/notes/shared"
          element={
            <PrivateRoute roles={[Role.LECTURER]} component={SharedNote} />
          }
        />
        <Route
          path="/dashboard/notes/read/"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT]}
              component={ReadNotes}
            />
          }
        />
        <Route
          path="/dashboard/videos"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT]}
              component={Videos}
            />
          }
        />
        {/* <Route
          path="/dashboard/videos/play/"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT]}
              component={PlayVideo}
            />
          }
        /> */}
        <Route
          path="/dashboard/liveClasses"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT]}
              component={LiveClasses}
            />
          }
        />

        <Route
          path="/dashboard/payfees"
          element={<PrivateRoute roles={[Role.STUDENT]} component={PayFees} />}
        />
        <Route
          path="/dashboard/transcript"
          element={
            <PrivateRoute roles={[Role.STUDENT]} component={Transcript} />
          }
        />
        <Route
          path="/dashboard/results"
          element={<PrivateRoute roles={[Role.STUDENT, Role.LECTURER]} component={Result} />}
        />
        <Route
          path="/dashboard/view-results/:courseId"
          element={
            <PrivateRoute roles={[Role.STUDENT, Role.LECTURER]} component={ViewResults} />
          }
        />
        <Route
          path="/dashboard/assignments/history"
          element={
            <PrivateRoute
              roles={[Role.LECTURER, Role.STUDENT]}
              component={AssignmentsHistory}
            />
          }
        />
        <Route
          path="/dashboard/sendAssignments"
          element={
            <PrivateRoute roles={[Role.LECTURER]} component={SendAssignments} />
          }
        />
        <Route
          path="/dashboard/assignments/attempt"
          element={
            <PrivateRoute
              roles={[Role.STUDENT]}
              component={AttemptAssignments}
            />
          }
        />
        <Route
          path="/dashboard/assignments/view"
          element={
            <PrivateRoute
              roles={[Role.LECTURER]}
              compontents={ViewAssignment}
            />
          }
        />
        {/* <Route
          path="/dashboard/assignments/update"
          element={
            <PrivateRoute
              roles={[Role.LECTURER]}
              compontents={UpdateAssignments}
            />
          }
        /> */}

        <Route
          path="/dashboard/assignments/submitted"
          element={
            <PrivateRoute
              roles={[Role.STUDENT]}
              component={SubmittedAssignments}
            />
          }
        />
        <Route
          path="/dashboard/help"
          element={
            <PrivateRoute
              roles={[Role.STUDENT, Role.ADMIN, Role.LECTURER]}
              component={Help}
            />
          }
        />
      </Routes>
    </PageLoader>
  );
};

export default withAuth(Dashboard);
