import { API } from "../configure-api";

//Student endpoints

const getStudentLivestream = (user?: string) =>
  API.get(`/livestream/get/student`);

//Lecturer endpoints
const getLecturerLivestream = () => API.get(`/livestream/get/host`);
const createLivestream = (body: any) => API.post("/livestream/create", body);
const deleteLivestream = (body: any) =>
  API.delete(`/livestream/delete/${body.id}`);

export const livestreamService = {
  getStudentLivestream,
  getLecturerLivestream,
  createLivestream,
  deleteLivestream,
};
