export enum AssignemtAction {
  FETCHASSIGNMENT = "[assignment/fetchassignment]",
  FETCHSUBMISSIONS = "[assignment/fetchsubmissions]",
  FETCHCOURSEASSIGNMENT = "[assignment/fetchcourseassignment]",
  ADDTSUBMISSION = "[assignment/addsubmission]",
  CREATESUBMISSION = "[assignment/createsubmission]",
  UPDATESUBMISSION = "[assignment/updatesubmission]",

  FETCHLECCOURSEASS = "[assignment/fetchLecCourseAss]",
  PUBLISH = "[assignment/publish]",
  UNPUBLISH = "[assignment/unpublish]",
  MARKASSIGNMENT = "[assignment/markAssignment]",
  CREATEASSIGNMENT = "[assignment/createAssignment]",
  UPDATEASSIGNMENT = "[assignment/updateAssignment]",
}
