import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useStyles } from "./events.style";
import {
  Bullet,
  Calendar2,
  Clock2,
  Compass,
  Next,
  Prev,
  X,
} from "../../../../assets/svg";
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  subMonths,
  addMonths,
} from "date-fns";
import { Modal as ReactStrapModal, ModalBody } from "reactstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingSpinner from "../../../../components/common/Loader";
import { toast, ToastContainer } from "react-toastify";
import { DeleteRounded, EditRounded } from "@material-ui/icons";
import Confirm from "../../../../components/common/Confirm";

interface CreateEventInputs {
  title: string;
  description: string;
  starts_at: string;
  ends_at: string;
  recipients: any;
}

interface Props {}

const createEventSchema = yup.object().shape({
  title: yup.string().required("Required"),
  description: yup.string().required("Required"),
  starts_at: yup.string().required("Required"),
  ends_at: yup.string().required("Required"),
  recipients: yup.array().min(1, "pick atleast one recipient"),
});

const Events = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");

  // Add Event
  const [isDate, setIsDate] = React.useState("06/4/2022");
  const [isEventTab, setIsEventTab] = React.useState(true);
  const [isCalendarTab, setIsCalendarTab] = React.useState(false);
  const [addEventModal, setAddEventModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [allEvents, setAllEvents] = React.useState([]);
  const [focusedRow, setFocusedRow] = React.useState<{ [key: string]: any }>(
    {}
  );
  const [openWarning, setOpenWarning] = React.useState(false);
  const [isInitLoading, setIsInitLoading] = React.useState(true);

  useEffect(() => {
    fetchAllEvents();
  }, []);
  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateEventInputs>({
    resolver: yupResolver(createEventSchema),
  });
  const onSubmit = (data: CreateEventInputs) => {
    const body = {
      title: data.title,
      description: data.description,
      starts_at: data.starts_at,
      ends_at: data.ends_at,
      recipients: data.recipients,
    };
    createEvent(body);
  };

  const fetchAllEvents = async () => {
    try {
      const request = await fetch(`${BASE_URL}/admin/event/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      console.log(response);
      if (response.length) {
        setAllEvents(response);
      }
      setIsInitLoading(false);
    } catch (error) {
      toast.error("Error fetching instructors");
      console.log(error);
      setIsInitLoading(false);
    }
  };

  const deleteEvent = async (body: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/admin/event/${focusedRow?.id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const res = await req.json();
      console.log(res);
      if (res.message) {
        toast.success("event deleted", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        fetchAllEvents();
        reset();
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const createEvent = async (data: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/admin/create-event`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await req.json();
      if (res.id) {
        toast.success("Event created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        fetchAllEvents();
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleIsAddNew = () => {
    if (isEventTab || isCalendarTab) {
      setAddEventModal(!addEventModal);
    }
  };

  const handleEventTab = () => {
    setIsEventTab(true);
    setAddEventModal(false);
    setIsCalendarTab(false);
  };
  const handleCalendarTab = () => {
    setIsCalendarTab(true);
    setAddEventModal(false);
    setIsEventTab(false);
  };

  const handleClose = () => {
    setAddEventModal(false);
  };
  //Calendar
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [activeDate, setActiveDate] = React.useState(new Date());

  const getHeader = () => {
    return (
      <div className="header">
        <div className="todayDate">
          {format(new Date(), "EEEE, MMM d, YYY")}
        </div>
        <div className="currentMonth">
          <Prev
            onClick={() => setActiveDate(subMonths(activeDate, 1))}
            className="icon"
          />
          <h2>{format(activeDate, "MMMM")}</h2>
          <Next
            onClick={() => setActiveDate(addMonths(activeDate, 1))}
            className="icon"
          />
        </div>
        <div className="dayFull">
          <div>{format(new Date(), "YYY")}</div>

          <div>{format(new Date(), "MMMM")}</div>

          <div>{format(new Date(), "d")}</div>
        </div>
      </div>
    );
  };

  const getWeekDaysNames = () => {
    const weekStartDate = startOfWeek(activeDate);
    const weekDays = [];
    for (let day = 0; day < 7; day++) {
      weekDays.push(
        <div className="weekNames">
          {format(addDays(weekStartDate, day), "E")}
        </div>
      );
    }
    return <div className="weekContainer weeks">{weekDays}</div>;
  };

  const generateDatesForCurrentWeek = (
    date: any,
    selectedDate: any,
    activeDate: any
  ) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;
      week.push(
        <div
          className={`day ${
            isSameMonth(currentDate, activeDate) ? "" : "inactiveDay"
          }
          ${isSameDay(currentDate, new Date()) ? "today" : ""}`}
          onClick={() => {
            setSelectedDate(cloneDate);
          }}
        >
          {format(currentDate, "d")}
        </div>
      );
      currentDate = addDays(currentDate, 1);
    }
    return <>{week}</>;
  };

  const getDates = () => {
    const startOfTheSelectedMonth = startOfMonth(activeDate);
    const endOfTheSelectedMonth = endOfMonth(activeDate);
    const startDate = startOfWeek(startOfTheSelectedMonth);
    const endDate = endOfWeek(endOfTheSelectedMonth);

    let currentDate = startDate;

    const allWeeks = [];

    while (currentDate <= endDate) {
      allWeeks.push(
        generateDatesForCurrentWeek(currentDate, selectedDate, activeDate)
      );
      currentDate = addDays(currentDate, 7);
    }

    return <div className="weekContainer days">{allWeeks}</div>;
  };

  return (
    <Layout>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this event?"
        action={deleteEvent}
      />
      <ReactStrapModal
        isOpen={addEventModal}
        toggle={() => setAddEventModal(!addEventModal)}
        centered={true}
        scrollable={true}
      >
        <ModalBody>
          <div className={style.addEventModal}>
            <div className="header">
              <X className="icon" onClick={handleClose} />
            </div>

            <div className="bodyMod">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formGroup">
                  <div className="inputGroup">
                    <input
                      className="text"
                      {...register("title")}
                      placeholder="Event title"
                    />
                    <p>{errors.title?.message}</p>

                    <input
                      className="text"
                      {...register("description")}
                      placeholder="Description"
                    />
                    <p>{errors.description?.message}</p>
                  </div>
                  <div className="bottomInputs">
                    <div className="formRow">
                      <Calendar2 />
                      <span>Start</span>
                      <input
                        className="date"
                        type="date"
                        {...register("starts_at")}
                        // defaultValue={format(
                        //   new Date(isDate),
                        //   "EEEE, d MMMM YYY"
                        // )}
                        // onFocus={(e) => (e.target.type = "date")}
                        // onChange={(e) => setIsDate(e.target.value)}
                        // onBlur={(e) => {
                        //   e.target.type = "text";
                        //   e.target.value = format(
                        //     new Date(isDate),
                        //     "EEEE, d MMMM YYY"
                        //   );
                        //   console.log(isDate);
                        // }}
                      />
                    </div>
                    <p>{errors.starts_at?.message}</p>
                    <div className="formRow">
                      <Calendar2 />
                      <span>End</span>
                      <input
                        className="date"
                        type="date"
                        {...register("ends_at")}
                        defaultValue={format(
                          new Date(isDate),
                          "EEEE, d MMMM YYY"
                        )}
                      />
                    </div>
                    <p>{errors.starts_at?.message}</p>
                    {/* <div className="formRow">
                      <Clock2 />
                      <div className="inputs">
                        <input type="text" defaultValue="08:00" />
                        <input type="text" defaultValue="09:00" />
                      </div>
                    </div> */}
                    <p>Recipients</p>
                    <div className="formRow">
                      <input
                        {...register("recipients")}
                        type="checkbox"
                        value="lecturers"
                      />
                      <p>Instructors</p>
                      <input
                        {...register("recipients")}
                        type="checkbox"
                        value="students"
                      />
                      <p>Participants</p>
                    </div>
                    <p>{errors.recipients?.message && "pick atleast one"}</p>
                  </div>
                  <button className="btn" type="submit">
                    Add Event
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </ReactStrapModal>
      <div className={style.container}>
        <div className={style.header}>
          <div className="search">
            <input
              className="searchInput"
              placeholder="Search"
              //   value={filter}
              //   onChange={(e) =>
              //     globalFilter === ""
              //       ? setFilter(e.target.value)
              //       : setGlobalFilter("")
              //   }
            />
            <button className="btn" type="button">
              Search
            </button>
          </div>
          <h4 className="title">Events</h4>
        </div>
        <div className={style.tabs}>
          <div className="tabLinks">
            <div className={isEventTab ? "active link" : "link"}>
              <Link to="" onClick={handleEventTab}>
                Events
              </Link>
            </div>
            <div className={!isEventTab ? "active link" : "link"}>
              <Link to="" onClick={handleCalendarTab}>
                Calendar
              </Link>
            </div>
          </div>

          {!isLoading ? (
            <button className="btn" onClick={handleIsAddNew}>
              Add new
            </button>
          ) : (
            <LoadingSpinner />
          )}
        </div>

        <div className={style.body}>
          {isEventTab ? (
            <div className="events">

              {
              isInitLoading ? <h5 style={{color: "#152E88", fontWeight: 400, padding: "1rem"}}>Loading events...</h5> :
              (
                <>
                  {allEvents?.reverse().map((item: any) => (
                    <div
                      className="event"
                      style={{ display: "flex", alignItems: "baseLine", gap: 2 }}
                      key={item.id}
                    >
                      <div className="content" style={{ flexGrow: 1 }}>
                        <h4>{item.title} </h4>
                        <p>{item.description}</p>
                        <div className="details">
                          <div className="det">
                            <Calendar2 />
                            <p>Starts:</p>
                            <p>
                              {format(new Date(item.starts_at), "EEEE, MMM d, YYY")}
                            </p>
                          </div>

                          <div className="det">
                            <Calendar2 /> <p>Ends:</p>
                            <p>
                              {format(new Date(item.ends_at), "EEEE, MMM d, YYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", gap: 20 }}>
                        {/* <EditRounded
                          fontSize="small"
                          color="primary"
                          onClick={() => {
                            setFocusedRow(item);
                            setOpenEdit(true);
                            setOpenAddNotice(true);
                          }}
                        /> */}
                        <DeleteRounded
                          fontSize="small"
                          color="error"
                          onClick={() => {
                            setFocusedRow(item);
                            setOpenWarning(true);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )
              }

              {allEvents?.length === 0 && !isInitLoading && 
              <h5 style={{color: "#152E88", fontWeight: 400, padding: "1rem"}}>Empty event(s) list.</h5> }
            </div>
          ) : (
            <div className="calendar">
              {getHeader()}
              {getWeekDaysNames()}
              {getDates()}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

Events.propTypes = {};

export default Events;
