import { makeStyles } from "@material-ui/core";
import { colors } from "../../styles/color";

export const useStyles = makeStyles((theme) => ({
  unlock: {
    marginTop: "2.625rem",
    height: "100%",
    overflow: "hidden",
    "& .content": {
      position: "fixed",
      top: "50%",
      left: "50%",
      "-webkit-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
      "& .form": {
        // width: "70%",

        "& span.info": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "1.625rem",
          lineHeight: "39px",
          color: colors.black,
          marginBottom: "1.25rem",
          textAlign: "center",
          display: "block",
        },
      },

      "& .form-control.row": {
        display: "flex",
        // gridTemplateColumns: "4fr 1fr",
        // flexDirection:
        width: "100%",
        alignItems: "center",
        "& div:first-child": {
          width: "100%",
        },
        "& .MuiInputBase-input ": {
          borderRight: "0",
          borderTopRightRadius: "0",
          borderBottomRightRadius: "0",
        },
        "& .MuiButton-root": {
          borderLeft: "0",
          borderTopLeftRadius: "0",
          borderBottomLeftRadius: "0",
        },
      },
      "& .form-control": {
        marginBottom: "15px",

        "& .MuiInputBase-input ": {
          background: colors.white,
          border: "1px solid rgba(0, 0, 0, 0.7)",
          borderRadius: "5px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "22px",
          lineHeight: "33px",
          color: "rgba(0, 0, 0, 0.7)",
          padding: ".6rem",
          width: "100%",
        },
        "& .MuiButton-root": {
          float: "right",
          background: colors.blue,
          border: "1px solid rgba(0, 0, 0, 0.7)",
          borderRadius: "8px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "22px",

          lineHeight: "33px",
          /* identical to box height */

          color: colors.white,
        },
        "& .MuiFilledInput-underline": {
          border: "0",
        },
      },
    },
  },
  utme: {
    marginTop: "2.625rem",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    "& .welcome": {
      borderRight: "1px solid rgba(0, 0, 0, 0.25);",
      paddingRight: "10px",
      paddingTop: "4rem",
      paddingBottom: "2rem",
      [theme.breakpoints.down("sm")]: {
        borderRight: "0",
        paddingRight: "0",
        borderBottom: "1px solid rgba(0, 0, 0, 0.25);",
        paddingBottom: "10px",
        marginBottom: "10px",
        paddingTop: "auto",
      },

      "& h1": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "33px",
        lineHeight: "50px",
        color: colors.black,
        marginBottom: "1.375rem",
      },
      "& p": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.125rem",
        lineHeight: "27px",
        color: colors.black,
      },
      "& ul": { marginBottom: "30px", listStylePosition: "inside" },
      "& ul li": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "1.125rem",
        lineHeight: "27px",
        color: colors.black,
      },
    },
    "& .form": {
      // display: "flex",
      width: "70%",
      margin: "0 auto",
      "& span.info": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "1.625rem",
        lineHeight: "39px",
        color: colors.black,
        marginBottom: "1.25rem",
        textAlign: "center",
        display: "block",
      },
    },

    "& .form-control.row": {
      display: "flex",
      // gridTemplateColumns: "4fr 1fr",
      // flexDirection:
      width: "100%",
      alignItems: "center",
      "& div:first-child": {
        // width: "100%",
      },
      "& .MuiInputBase-input ": {
        borderRight: "0",
        borderRadius: "0",
      },
      "& .MuiButton-root": {
        borderLeft: "0",
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
      },
    },
    "& .form-control": {
      marginBottom: "15px",

      "& .MuiInputBase-input ": {
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "27px",
        color: "rgba(0, 0, 0, 0.7)",
        padding: ".51rem",
        // width: "100%",
      },
      "& .MuiButton-root": {
        float: "right",
        background: colors.blue,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",

        lineHeight: "27px",
        /* identical to box height */

        color: colors.white,
      },
      "& .MuiFilledInput-underline": {
        border: "0",
      },
    },
  },
  profile: {
    marginTop: "2.625rem",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    "& .welcome": {
      borderRight: "1px solid rgba(0, 0, 0, 0.25);",
      paddingRight: "10px",
      paddingTop: "4rem",
      paddingBottom: "2rem",
      [theme.breakpoints.down("sm")]: {
        borderRight: "0",
        paddingRight: "0",
        borderBottom: "1px solid rgba(0, 0, 0, 0.25);",
        paddingBottom: "10px",
        marginBottom: "10px",
        paddingTop: "auto",
      },
      "& h1": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "33px",
        lineHeight: "50px",
        color: colors.black,
        marginBottom: "1.375rem",
      },
      "& p": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.125rem",
        lineHeight: "27px",
        color: colors.black,
      },
      "& ul": { marginBottom: "30px", listStylePosition: "inside" },
      "& ul li": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "1.125rem",
        lineHeight: "27px",
        color: colors.black,
      },
    },
    "& .userDetails": {
      padding: "0 1rem",

      "& img": {
        display: "block",
        margin: "auto auto 3rem auto",
        height: "100px",
        width: "100px",
        borderRadius: "50%",
        objectFit: "cover",
      },
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "22px",
        lineHeight: "33px",
        color: colors.black,
        marginBottom: "15px",
        [theme.breakpoints.down("sm")]: { fontWeight: 600 },
      },
      "& h5": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "22px",
        lineHeight: "33px",
        color: colors.black,
        marginBottom: "15px",
      },
      "& span.dot": {
        marginRight: "1rem",
        [theme.breakpoints.down("sm")]: { display: "none" },
      },
      "& .MuiButton-root": {
        display: "block",
        background: colors.blue,
        border: "2px solid rgba(0, 0, 0, 0.3);",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "22px",

        lineHeight: "33px",
        /* identical to box height */
        margin: "2rem auto auto auto",
        color: colors.white,
        [theme.breakpoints.down("sm")]: { margin: "2rem auto 2rem auto" },
      },
    },
  },
  exam: {
    height: "100%",

    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    "& .cont": { padding: "0 1rem" },
    "& .header": {
      background: "#F7F4F4",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "5px 10px",
      marginBottom: "4rem",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        marginBottom: "2em",
      },
      "& img.logo": { height: "56px" },
      "& div.time": {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: { order: "3" },
        "& h6": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "22px",
          lineHeight: "33px",
          color: colors.black,
          "& span": { color: "red" },
        },
      },
      "& div.details": {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: { flexDirection: "column" },
        "& h6": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "27px",
          color: colors.black,
          "&.reg": { fontStyle: "italic" },
        },
        "& img": {
          height: "95px",
          width: "95px",
          objectFit: "cover",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          marginLeft: "10px",
        },
      },
    },
    "& .body": {
      "& .subjects": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "3rem 0",
        "& .subject:not(:last-child)": { marginBottom: "2rem" },
        "& .subject": {
          textDecoration: "none",
          width: "213px",
          height: "60px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // padding: "12px auto",

          background: "rgba(21, 46, 136, 0.7);",
          borderRadius: "8px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "24px",
          lineHeight: "36px",
          color: colors.white,
          [theme.breakpoints.down("sm")]: { width: "100%", padding: "0" },
        },
        "& .subject.done": { background: "rgba(75, 211, 123, 0.85)" },
        "& .subject.active": { background: "#D9D9D9;", color: colors.black },
        "& .subject:hover": { background: "#D9D9D9;", color: colors.black },
      },
      "& .question": {
        position: "relative",
        padding: "0 20px",

        borderLeft: "1px solid rgba(0, 0, 0, 0.25)",
        [theme.breakpoints.down("sm")]: {
          padding: "20px 0",
          borderLeft: "0",
          borderTop: "1px solid rgba(0, 0, 0, 0.25)",
          marginTop: "2rem",
        },

        "& h6": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "18px",
          color: colors.black,
          marginBottom: "2.5rem",
          "& span": { fontWeight: 600 },
        },
        "& p": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "26px",
          lineHeight: "39px",
          color: colors.black,
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            lineHeight: "20px",
            marginBottom: "1rem",
          },
        },
        "& div.buttons": {
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          bottom: "0",

          paddingLeft: "inherit",
          paddingRight: "inherit",
          left: 0,
          right: 0,
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            position: "static",
            flexDirection: "column",
          },
          "& .MuiButton-root": {
            background: colors.blue,
            border: "1px solid #152E88;",
            borderRadius: "5px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",

            lineHeight: "30px",
            color: colors.white,
            padding: "auto 3rem",
            [theme.breakpoints.down("sm")]: {
              fontSize: "1rem",

              lineHeight: "27px",
              marginBottom: "1rem",
            },
          },
          "& .MuiButton-root.prev": {
            background: colors.white,
            border: "1px solid #152E88;",

            color: colors.blue,
            [theme.breakpoints.down("sm")]: { order: "2" },
          },
        },
      },
      "& .options": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "end",
        [theme.breakpoints.down("sm")]: { alignItems: "center" },
        "& .option:not(:last-child)": { marginBottom: "1rem" },
        "& .option": {
          textDecoration: "none",
          width: "100%",
          height: "auto",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px 15px",

          background: "rgba(196, 196, 196, 0.37);",
          borderRadius: "8px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "27px",
          color: colors.black,
          [theme.breakpoints.down("sm")]: { width: "100%" },
        },

        "& .option:hover": { background: colors.blue, color: colors.yellow },
      },

      
    },
    "& .MuiButton-root.submitExams": {
      float: "right",
      background: "rgba(75, 211, 123, 0.85);",
      border: "0",
      borderRadius: "8px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "22px",

      lineHeight: "33px",
      color: colors.white,
      marginTop: "3rem",
      bottom: 0,
      [theme.breakpoints.down("sm")]: { width: "100%", marginBottom: "5rem" },
    },
    "& .successModal": {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 20291,
      background: "rgba(0,0,0,0.4)",
      display: "flex",
      overflow: "auto",

      "& .modal": {
        margin: "auto",
        // minWidth: "31.25rem",

        maxWidth: "35.125rem",
        background: colors.white,
        position: "relative",

        minHeight: "50%",
        transition: ".5s",
      },
      "& .cancelBox": {
        textDecoration: "none",
        position: "absolute",
        padding: "1.4375rem",
        right: 0,
        top: 0,
        color: colors.white,
        background: colors.blue,
      },
      "& .content": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "3.75rem 1.75rem",
        "& .icon": { marginBottom: "2rem" },
        "& p": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "20px",
          lineHeight: "30px",
          color: colors.black,
          textAlign: "center",
        },
        "& div": {
          marginBottom: "1.9375rem",
        },
      },
    },
    "& .confirmModal": {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 20291,
      background: "rgba(0,0,0,0.4)",
      display: "flex",
      overflow: "auto",

      "& .cancelBox": {
        textDecoration: "none",
        position: "absolute",
        padding: "1.4375rem",
        color: colors.white,
        background: colors.blue,
        alignSelf: "flex-end",
      },

      "& .content": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "5.5625rem",
        position: "relative",

        "& .icon": { marginBottom: "2rem" },
        "& h6": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
          color: colors.black,
          textAlign: "center",
          marginBottom: ".5rem",
        },
        "& p": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "21px",
          color: colors.black,
          textAlign: "center",
        },
        "& div": {
          marginBottom: "1.9375rem",
        },
        "& div.buttons": {
          display: "flex",
          justifyContent: "space-between",

          width: "100%",
          [theme.breakpoints.down("sm")]: {
            position: "static",
            flexDirection: "column",
          },
          "& .MuiButton-root": {
            background: colors.blue,
            border: "1px solid #152E88;",
            borderRadius: "5px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",

            lineHeight: "27px",
            color: colors.white,
            padding: "auto 3rem",
            [theme.breakpoints.down("sm")]: {
              fontSize: "14px",

              lineHeight: "20px",
              marginBottom: "1rem",
            },
          },
          "& .MuiButton-root.cancel": {
            background: colors.white,
            border: "1px solid #152E88;",

            color: colors.blue,
            [theme.breakpoints.down("sm")]: { order: "2" },
          },
        },
      },
    },
  },
}));
