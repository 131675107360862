import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    background: colors.white,
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",

    margin: "13px 35px 13px 40px",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    // position: "fixed",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },

    "& .btn": {
      minWidth: "100px",
      padding: "6px 9px",
      height: "35px",
      border: "none",
      background: colors.blue,
      borderRadius: "8px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "22px",
      /* identical to box height */
      color: colors.white,

      "&.secondary": {
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "21px",
        height: "26px",
        width: "72px",
        background: colors.white,
        color: colors.blue,
        border: "1px solid #E0E0E0",
        borderRadius: "6px",

        "&.disabled": {
          border: "1px solid #E0E0E0",
          color: " #D9D9D9",
        },
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      margin: "1rem",
    },
  },
  header: {
    padding: "38px 177px 0px 38px",
    [theme.breakpoints.down("xs")]: {
      padding: "15px",
    },

    "& .search": {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      gap: "14px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
      "& .searchInput": {
        width: "399px",
        height: "48px",
        padding: "18px 20px",
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "22px",
        lineHeight: "33px",
        /* identical to box height */
        color: colors.black,

        "&:placeholder": {
          color: "#C4C4C4",
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          height: "30px",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "21px",
        },
      },
    },
    "& h4.title": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "45px",
      /* identical to box height */
      color: colors.blue,
    },
  },
  tabs: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #EBEEF2;",
    margin: "0x -35px 0px -40px",
    padding: "0px 177px 3px 88px",
    width: "100%",

    [theme.breakpoints.down("xs")]: {
      gap: "20px",
      padding: "15px",
      flexDirection: "column",
      alignItems: "start",
    },
    "& .tabLinks": {
      display: "flex",
      gap: "35px",

      "& .link": {
        display: "flex",
        justifyContent: "center",
        width: "112px",

        "&.active": { borderBottom: "3px solid " + colors.blue },
        [theme.breakpoints.down("xs")]: {
          width: "100px",
        },

        "& a": {
          textDecoration: "none",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "18px",

          lineHeight: "25px",
          /* identical to box height */
          color: colors.blue,
        },
      },
    },
  },

  body: {
    marginTop: "42px",
    paddingLeft: "88px",
    paddingRight: "177px",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    position: "relative",
    height: "calc(100vh - 320px)",
    gap: "30px",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      padding: "15px",
    },
    "& .event": {
      display: "flex",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
      },
      "& .content": {
        display: "flex",
        flexDirection: "column",
        gap: "9px",
        paddingLeft: "30px",
        paddingBottom: "15px",
        borderBottom: "1px solid #EBEEF2",
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "5px",
        },

        "& h4": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "14px",

          lineHeight: "21px",
          /* identical to box height */
          color: colors.lightBlack,
        },
        "& p": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",

          lineHeight: "21px",
          /* identical to box height */
          color: colors.lightBlack,
        },
        "& >.details": {
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignContent: "center",
          justifyContent: "center",
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            gap: "10px",
            alignItems: "start",
          },

          "& .det": {
            display: "flex",
            alignItems: "center",
            gap: "12px",
          },
        },
      },
    },
    "& .calendar": {
      marginTop: "27px",
      paddingLeft: "88px",
      paddingRight: "88px",
      overflowY: "scroll",
      position: "relative",
      height: "calc(100vh - 320px)",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        padding: "15px",
      },
      "& .header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          gap: "10px",
        },

        "& .todayDate": {
          padding: "6px",
          border: "1px solid #A3A3A3",
          borderRadius: "10px",
          fontFamily: "'Open Sans', sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "130%",
          /* identical to box height */
          color: colors.blue,
        },
        "& .currentMonth": {
          display: "flex",
          alignItems: "center",
          gap: "5px",
          "& .icon": {
            cursor: "pointer",
          },
          "& h2": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "130%",
            /* identical to box height */
            color: colors.blue,
          },
        },
        "& .dayFull": {
          display: "flex",
          alignItems: "center",
          gap: "15px",

          "&>div": {
            padding: "6px",
            border: "1px solid #054A8C",
            width: "66.97px",
            borderRadius: "10px",
            textAlign: "center",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "130%",
            /* identical to box height */
            color: colors.blue,
          },
        },
      },
      "& .weekContainer": {
        display: "grid",

        gridTemplateColumns: "repeat(7, 1fr)",

        "&.weeks": {
          textAlign: "center",
          marginTop: "1.5rem",
          paddingBottom: "13px",

          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "130%",
          /* identical to box height */
          color: "#6E88A0;",
        },
        "&.days": {
          // border: "0.5px solid #6E88A0",
          // borderRadius: "8px",
          "& .day:first-child": { borderTopLeftRadius: "8px" },
          "& .day:nth-child(7)": { borderTopRightRadius: "8px" },
          "& .day:nth-child(36)": { borderBottomLeftRadius: "8px" },
          "& .day:last-child": { borderBottomRightRadius: "8px" },
        },
        "&.days >.day": {
          background: colors.white,
          border: "0.5px solid #6E88A0",
          paddingTop: "3px",
          paddingLeft: "3px",
          width: "100%",
          height: "58px",
          cursor: "pointer",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "130%",
          /* identical to box height */
          color: "#666666",
          "&.today": {
            background: " rgba(107, 199, 251, 0.15) !important",
            border: "1px solid #6BC7FB !important",
          },
          "&.selectedDay": {
            background: "#F5F5F5 !important",
            border: "0.5px solid #6E88A0 !important",
          },
        },
      },
    },
  },
  addEventModal: {
    // position: "fixed",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // zIndex: 20290,
    // background: "rgba(0,0,0,0.4)",
    // display: "flex",
    // overflow: "auto",
    // "& .modal": {
    //    border: "1px solid #DADADA",
    //   borderRadius: "8px",
    //   margin: "auto",
    //   width: "90%",
    //   maxWidth: "310px",
    //   background: colors.white,
    //   position: "relative",
    //   height: "90%",
    //   maxHeight: "434px",
    //   transition: ".5s",
    "& .header": {
      display: "flex",
      alignItems: "end",
      justifyContent: "end",
      margin: "33px 35px 0px 41px",
      "& .icon": {
        cursor: "pointer",
      },
    },

    "& .btn.secondary": {
      width: "56px",
      height: "25px",
      background: colors.white,
      border: "1px solid #054A8C",
      borderRadius: "15px",
    },
    "& .btn": {
      width: "100%",
      height: " 38.6px",
      background: colors.blue,
      borderRadius: "6px",
      marginTop: "35px",
      border: "none",
      cursor: "pointer",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",

      lineHeight: "130%",
      /* identical to box height */
      color: colors.white,
    },
    "& .bodyMod": {
      padding: "17px 35px 35px 35px",
      "& svg path": {
        stroke: "#ACB4BF",
      },

      "& .formGroup": {
        display: "flex",
        flexDirection: "column",
        gap: "9px",

        "& .inputGroup": {
          display: "flex",
          flexDirection: "column",
          gap: "9px",
          marginBottom: "29px",

          "& input": {
            height: "40px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "130%",
            /* identical to box height */
            textAlign: "center",
            color: colors.lightBlack,
            padding: "9px",
            "&:placeholder": {
              color: "#B8B8B8",
            },
          },
          // },
        },
        "& .bottomInputs": {
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          "& .formRow": {
            display: "flex",
            alignItems: "center",
            gap: "15px",
            "& span": {
              color: "#333333",
              fontSize: "12px",
            },
            "& input.date": {
              border: "none",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",

              lineHeight: "130%",
              /* identical to box height */
              color: "#333333;",
              padding: "7px",
              width: "100%",
              "&:placeholder": {
                color: colors.grayShade,
              },
            },
            "& input.venue": {
              border: "none",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",

              lineHeight: "130%",
              /* identical to box height */
              color: "#333333;",
              padding: "7px",
              width: "100%",
              "&:placeholder": {
                color: colors.grayShade,
              },
            },
            "& .inputs": {
              display: "flex",
              gap: "18px",
              "& input": {
                width: "79px",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",

                lineHeight: "130%",
                /* identical to box height */
                color: "#A3A3A3;",
                background: "#F5F5F5;",
                border: "none",
                borderRadius: "6px",
                textAlign: "center",
                padding: "7px",
              },
            },
          },
        },
      },
    },
  },
}));
