import React, { HTMLAttributes, HTMLProps, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "../dataTables.style";
import { ChevDown, X } from "../../../../assets/svg";
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";

import LoadingSpinner from "../../Loader";
import { toast, ToastContainer } from "react-toastify";
import { request } from "http";
import ViewApplicantModal from "./ViewApplicantModal";

interface Props {
  rows: any;
  fetchApplicants: any;
  isLoadingAdmitted: boolean;
  setFilter: string;
}
type Course = {
  [key: string]: any;
};

const AdmittedApplicantsDataTable = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [focusedRow, setFocusedRow] = React.useState<{ [key: string]: any }>(
    {}
  );
  const [openViewApplicant, setOpenViewApplicant] = React.useState(false);

  const handleRejectStudent = async (item: any) => {
    setOpenViewApplicant(false);
    const body = {
      service_number: item?.service_number,
    };
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/admission/applicant/reject`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const res = await req.json();
      console.log(res);
      if (req.ok) {
        toast.success(res.messsage || "success", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchApplicants();
      } else {
        throw res.message || "could not reject";
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const columns = React.useMemo<ColumnDef<Course>[]>(
    () => [
      {
        accessorKey: "service_number",
        header: "Service Number",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "rank",
        header: "Rank",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "name",
        header: "Name",
        cell: ({ row }) => (
          <div className="">
            <span
              onClick={() => {
                setFocusedRow(row.original);
                setOpenViewApplicant(true);
              }}
              style={{ color: "#152E88", fontWeight: 600 }}
            >
              {row.original.name}
            </span>
          </div>
        ),
      },
      {
        accessorKey: "gender",
        header: "Gender",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "email",
        header: "Email",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "phone",
        header: "Phone Number",
        footer: (props) => props.column.id,
      },

      {
        id: "action",
        header: "",
        cell: ({ row }) => (
          <div className="">
            <span
              onClick={() => {
                setFocusedRow(row.original);
                handleRejectStudent(row.original);
              }}
              style={{ color: "red", fontWeight: 600 }}
            >
              Reject
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const [data, setData] = React.useState(props.rows);

  useEffect(() => {
    setData(props.rows);
  }, [props.rows]);

  useEffect(() => {
    setGlobalFilter(props.setFilter);
  }, [props.setFilter]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <>
      <ToastContainer />
      {isLoading && <LoadingSpinner />}
      <ViewApplicantModal
        applicant={focusedRow}
        open={openViewApplicant}
        toggle={() => setOpenViewApplicant(!openViewApplicant)}
        actions={{ reject: handleRejectStudent }}
      />

      <table className={style.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} {...table.getRowModel()}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.isLoadingAdmitted && (<h4 style={{padding:".5rem 1.2rem"}}>Loading Admitted Students...</h4>)}
      <div className={style.tableMisc}>
        <div className="nextPrevious">
          <button
            className=""
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className=""
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className=""
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="pageCounter">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className=""
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div></div>
      </div>
    </>
  );
};

AdmittedApplicantsDataTable.propTypes = {};

export default AdmittedApplicantsDataTable;
