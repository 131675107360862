import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  makeStyles,
  Grid,
  Typography,
  InputBase,
  IconButton,
  Divider,
  Paper,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import { colors } from "../../styles/color";

interface Props {}

const Header = (props: Props) => {
  const style = useStyles();
  return (
    <div className={style.container}>
      <div className={style.topBar}>
        <ul>
          <li>
            <a href="https://airforce.mil.ng/">Air Force </a>{" "}
          </li>
          <li>
            <a href="https://nafrecruitment.airforce.mil.ng">Join NAF </a>
          </li>
          <li>
            <a href="/participants/login">Warfare Center </a>{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column",
  },
  topBar: {
    paddingTop: "1em",
    paddingBottom: "1em",
    color: "#fff",
    background: "#000",
    padding: "0 100px",
    flex: 1,
    "& ul": {
      display: "flex",
      listStyle: "none",
    },
    "& li": {
      marginRight: "20px",
      textTransform: "uppercase",
    },
    "& a": {
      color: "#fff",
      textDecoration: "none",
    },
    "& a:hover": {
      color: "green",
    },
  },
}));

Header.propTypes = {};

export default Header;
