import React, { useEffect } from 'react'
import { makeStyles } from "@material-ui/core";

import { selectIsLoading, selectIsLoggedIn, selectStatus } from '../../store/features/auth/authSlice'
import { Spinner } from './Spinner';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchUser } from '../../store/features/auth/authThunk';
import { toast } from 'react-toastify';

const PageLoader = ({ children }: any) => {
    const style = useStyles()
    const [loading, setLoading] = React.useState(false);
    const dispatch = useAppDispatch();

    const status = useAppSelector(selectStatus)
    const isLoading = useAppSelector(selectIsLoading)
    const isLoggedIn = useAppSelector(selectIsLoggedIn)


    const LoadUser = async () => {
        setLoading(true)
        await dispatch(fetchUser())
        setLoading(false)
    }

    useEffect(() => {
        if (isLoggedIn) {
            // console.log("PAGELOADER LOADER USER")
            // toast.success('Successfully login')
            LoadUser()
        }
    }, [isLoggedIn]);

    if (isLoading || status === "loading") {
        return <div className={style.loading}>
            <div className={style.wrapper}>
            <div className={style.text}>Loading....</div>
            <Spinner size='3' color='#152E88' />
            </div>
        </div>
    }

    return <>{children}</>

}

export const useStyles = makeStyles({
    loading: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#E5E5E5',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem'
    },
    text: {
        wordSpacing: 5,
        fontSize: '2rem',
    }
})


export default PageLoader
