import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createLivestream,
  fetchLecturerLiveStream,
  fetchStudentLiveStream,
  deleteLiveStream,
} from "./livestreamThunks";

const initialState = {
  stundentLivestreams: [] as any[],
  lecturerLivestreams: [] as any[],
  response: [] as any[],
  error: "" as string,
  status: "" as string,
};

export const livestreamSlice = createSlice({
  name: "livestream",
  initialState,
  reducers: {
    clearState: (state) => {
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLecturerLiveStream.pending, (state, action) => {
        return { ...state, status: "loading" };
      })
      .addCase(fetchLecturerLiveStream.fulfilled, (state, action) => {
        return {
          ...state,
          stundentLivestreams: action.payload,
          status: "fullfilled",
        };
      })
      .addCase(fetchLecturerLiveStream.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message as any;
      });
    // .addCase(fetchStudentLiveStream.pending, (state, action) => {
    //   return { ...state, status: "loading" };
    // })
    // .addCase(fetchStudentLiveStream.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     lecturerLivestreams: action.payload,
    //     status: "fullfilled",
    //   };
    // })
    // .addCase(fetchStudentLiveStream.rejected, (state, action) => {
    //   state.status = "rejected";
    //   state.error = action.error.message as any;
    // })
    // .addCase(createLivestream.pending, (state, action) => {
    //   return { ...state, status: "loading" };
    // })
    // .addCase(createLivestream.fulfilled, (state, action) => {
    //   return { ...state, response: action.payload, status: "fullfilled" };
    // })
    // .addCase(createLivestream.rejected, (state, action) => {
    //   state.status = "rejected";
    //   state.error = action.error.message as any;
    // })
    // .addCase(deleteLiveStream.pending, (state, action) => {
    //   return { ...state, status: "loading" };
    // })
    // .addCase(deleteLiveStream.fulfilled, (state, action) => {
    //   return { ...state, response: action.payload, status: "fullfilled" };
    // })
    // .addCase(deleteLiveStream.rejected, (state, action) => {
    //   state.status = "rejected";
    //   state.error = action.error.message as any;
    // });
  },
});

export const selectLiveStream = (state: any) => state.livestream.livestream;
