import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./liveVideos.style";
import { toast, ToastContainer } from "react-toastify";
import Layout from "../../../components/Layout";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListSubheader,
  Typography,
  TextField,
} from "@material-ui/core";
import { useAppSelector } from "../../../store";
import { selectRole, selectUser } from "../../../store/features/auth/authSlice";
import { getCourseDueDate, Role } from "../../../shared";
import { formatDate } from "../../shared";
import livemarker from "../../../assets/images/video-camera.svg";
import { Input, Toast } from "reactstrap";
import Confirm from "../../../components/common/Confirm";
import { sessionCheck } from "../../../shared/utils/sessionCheck";

function Live() {
  const style = useStyles();
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const liveURL = `${process.env.REACT_APP_ZOOM_URL}`;
  const [setupLive, setSetupLive] = useState(false);
  const [courses, setCourses] = useState([]);
  const [allLiveClasses, setAllLiveClasses] = useState([]);
  const [activeLiveClasses, setActiveLiveClasses] = useState([]);
  const [pendingLiveClassses, setPendingLiveClasses] = useState([]);
  const [focusedItem, setFocusedItem] = useState({});
  const [openWarning, setOpenWarning] = useState(false);
  const [doFetch, setDoFetch] = useState(false);

  const userRole = useAppSelector(selectRole);
  const user = useAppSelector(selectUser);

  //create couse form
  const [inputCourse, setInputCourse] = useState("");
  const [inputDate, setInputDate] = useState("");
  const [inputTopic, setInputTopic] = useState("");
  const [inputDuration, setInputDuration] = useState("");
  const [isCreatingLive, setIsCreatingLive] = useState(false);

  //loading states
  const [isDeletingStream, setIsDeletingStream] = useState(false);
   
  const handleSubmit = async () => {
    try {
      if (!inputCourse || !inputDate || !inputTopic) {
        //return alert("please fill all the fields");
        toast.error("All fields are compulsory except duration", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      //check if datetime is in the past
      if (
        new Date(inputDate).getFullYear() < new Date().getFullYear() ||
        (new Date(inputDate).getDate() < new Date().getDate() &&
          new Date(inputDate).getMonth() < new Date().getMonth() &&
          new Date(inputDate).getFullYear() < new Date().getFullYear()) ||
        (new Date(inputDate).getDate() == new Date().getDate() &&
          new Date(inputDate).getMonth() == new Date().getMonth() &&
          new Date(inputDate).getFullYear() == new Date().getFullYear() &&
          new Date(inputDate).getHours() < new Date().getHours()) ||
        (new Date(inputDate).getDate() == new Date().getDate() &&
          new Date(inputDate).getMonth() == new Date().getMonth() &&
          new Date(inputDate).getFullYear() == new Date().getFullYear() &&
          new Date(inputDate).getHours() <= new Date().getHours() &&
          new Date(inputDate).getMinutes() <= new Date().getMinutes())
      ) {
        toast.error("Schedule date cannot be in the past", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      setIsCreatingLive(true);

      let body = {
        topic: inputTopic,
        duration: parseInt(inputDuration) || 30,
        course_id: inputCourse,
        starts_at: inputDate,
        role: userRole, user_id: user?.user_id
      };

      // // create livestream
      const response = await fetch(`${BASE_URL}/livestream/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        toast.error("Error creating class", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        throw "error creating meeting";
      } else {
        setDoFetch(!doFetch);

        //add to event //mail participants

        //display success message regardless
        toast.success("Class successfully created", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setIsCreatingLive(false);
      setSetupLive(!setupLive);
    } catch (error) {
      console.log(error);
      setIsCreatingLive(false);
    }
  };

  const handleJoinMeeting = (classItem) => {
    let meetingNumber = classItem.meeting_id;
    let userName = user?.name;
    let userEmail = user?.email;
    let passWord = classItem?.password;
    let role = userRole == Role.LECTURER || classItem.user_id == user?.user_id ? 1 : 0;

    let urlParameter = `?meetingNumber=${meetingNumber}&role=${role}&userName=${userName}&userEmail=${userEmail}&passWord=${passWord}`;
    window.location.replace(liveURL + urlParameter);
  };
  
  const handleShareMeeting = (classItem) => {
    let meetingNumber = classItem.meeting_id;
    let passWord = classItem?.password;
    let role = 0;

    let urlParameter = `?meetingNumber=${meetingNumber}&role=${role}&passWord=${passWord}`;
    navigator.clipboard.writeText(
      liveURL + urlParameter
      );
  };

  const handleDeleteMeeting = async () => {
    console.log("id:", focusedItem.id);
    try {
      setIsDeletingStream(true);
      const req = await fetch(
        `${BASE_URL}/livestream/delete/${focusedItem.id}`,
        {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!req.ok) {
        toast.error("Error in deleting class", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        throw "something went wrong";
      }
      const res = await req.json();
      toast.success(
        //res.messsage ||
        "class deleted successfully",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      setIsDeletingStream(false);
      setDoFetch(!doFetch);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsDeletingStream(false);
      console.log(error);
    }
  };    

  const fetchAllClasses = async () => {
    try {
      const url =
        userRole == Role.LECTURER
          ? `${BASE_URL}/livestream/get/host`
          : `${BASE_URL}/livestream/get/student`;
      const request = await fetch(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      console.log(response);
      sessionCheck(response, userRole);

      if (response.length) {
        setAllLiveClasses(response);
      }
    } catch (error) {
      toast.error("Error fetching classes");
      console.log(error);
    }
  };

  const fetchActiveLiveClasses = async () => {
    try {
      const url =
        userRole == Role.LECTURER
          ? `${BASE_URL}/livestream/get/active/host`
          : `${BASE_URL}/livestream/get/active/student`;
      const request = await fetch(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      console.log(response);

      /*if(response.message === "Expired or Invalid Token"){
          userRole == Role.STUDENT ?window.location.replace("/participants/login"):window.location.replace("/instructors/login")
      }*/
      sessionCheck(response, userRole);

      if (response.length) {
        setActiveLiveClasses(response);
      }
    } catch (error) {
      toast.error("Error fetching classes");
      console.log(error);
    }
  };

  const fetchPendingLiveClasses = async () => {
    try {
      const url =
        userRole == Role.LECTURER
          ? `${BASE_URL}/livestream/get/pending/host`
          : `${BASE_URL}/livestream/get/pending/student`;
      const request = await fetch(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      sessionCheck(response, userRole);
      console.log(response);
      if (response.length) {
        setPendingLiveClasses(response);
      }
    } catch (error) {
      toast.error("Error fetching classes");
      console.log(error);
    }
  };

  const fetchCourses = async () => {
    //to be used for creating live class
    try {
      const url =
        userRole == Role.LECTURER
          ? `${BASE_URL}/lecturer/courses`
          : `${BASE_URL}/student/courses`;

      const request = await fetch(url, {//const request = await fetch(`${BASE_URL}/lecturer/courses`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      sessionCheck(response, userRole);
      console.log("response", response);
      if (response.length) {
        setCourses(response);
      }
    } catch (error) {
      toast.error("Error fetching courses", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCourses();
    fetchActiveLiveClasses();
    fetchPendingLiveClasses();
  }, [doFetch]);

  React.useMemo(() => {
    fetchCourses();
    fetchActiveLiveClasses();
    fetchPendingLiveClasses();
  }, [doFetch]);

  let getScheduleMonth = (monthNumber) => {
    switch (monthNumber) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
    }
  };

  let getScheduleDateTime = (classDate) => {
    if (
      new Date(classDate).getHours() < 10 &&
      new Date(classDate).getMinutes() < 10
    ) {
      return `${new Date(classDate).getDate()} ${getScheduleMonth(
        new Date(classDate).getMonth()
      )} ${new Date(classDate).getFullYear()} 0${new Date(
        classDate
      ).getHours()}0${new Date(classDate).getMinutes()}hours`;
    }

    if (
      new Date(classDate).getHours() < 10 &&
      new Date(classDate).getMinutes() >= 10
    ) {
      return `${new Date(classDate).getDate()} ${getScheduleMonth(
        new Date(classDate).getMonth()
      )} ${new Date(classDate).getFullYear()} 0${new Date(
        classDate
      ).getHours()}${new Date(classDate).getMinutes()}hours`;
    }

    if (
      new Date(classDate).getHours() >= 10 &&
      new Date(classDate).getMinutes() < 10
    ) {
      return `${new Date(classDate).getDate()} ${getScheduleMonth(
        new Date(classDate).getMonth()
      )} ${new Date(classDate).getFullYear()} ${new Date(
        classDate
      ).getHours()}0${new Date(classDate).getMinutes()}hours`;
    }

    if (
      new Date(classDate).getHours() >= 10 &&
      new Date(classDate).getMinutes() >= 10
    ) {
      return `${new Date(classDate).getDate()} ${getScheduleMonth(
        new Date(classDate).getMonth()
      )} ${new Date(classDate).getFullYear()} ${new Date(
        classDate
      ).getHours()}${new Date(classDate).getMinutes()}hours`;
    }
  };

  return (
    <Layout>
      <ToastContainer />
      {/* Warning modal */}
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        message="Are you sure you want to remove this class?"
        title="Warning!!!"
        action={handleDeleteMeeting}
      />

      <div className={style.container}>
        <>
          <div
            className={style.dash}
            style={{ overflow: "auto", height: "25rem" }}
          >
            <div
              className={style.header}
              style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
            >
              <h5
                style={{
                  fontSize: "1.5rem",
                  fontWeight: 100,
                  color: "#152E88",
                }}
              >
                Live Classes
              </h5>
              {/*userRole == Role.LECTURER && */(
                <span
                  title="Create a live class"
                  style={{ float: "right", cursor: "grab" }}
                  onClick={() => setSetupLive(!setupLive)}
                >
                  <img src={livemarker} width={"25px"} height={"25px"} />{" "}
                </span>
              )}
              {/*userRole != Role.LECTURER && (
                <span style={{ float: "right", cursor: "pointer" }}>
                  <img src={livemarker} width={"25px"} height={"25px"} />{" "}
                </span>
              )*/}
            </div>
            <div className={style.main}>
              {
                <>
                  {setupLive && //userRole == Role.LECTURER && 
                  (
                    //Create live class form
                    <div
                      style={{
                        width: "100%",
                        background: "rgb(2,1,24)",
                        background:
                          "linear-gradient(360deg, rgba(2,1,24,1) 47%, rgba(7,5,72,1) 96%, rgba(1,0,51,1) 100%)",
                      }}
                    >
                      <div
                        style={{
                          padding: "30px 60px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={require("../../../assets/img/_logo.png")}
                          width={"100px"}
                          height={"100px"}
                          alt="Site Logo"
                        />
                        <p style={{ color: "white", fontWeight: 600 }}>
                          AWC Live Classroom
                        </p>
                        <Input
                          value={inputCourse}
                          onChange={(e) => setInputCourse(e.target.value)}
                          className="mb-4 mt-4"
                          type="select"
                          style={{ borderRadius: "20px" }}
                        >
                          <option value="">Select a course</option>
                          {courses?.map((item) =>  
                            getCourseDueDate(item?.start_date, item?.duration_in_days) > new Date() && (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                            )
                             
                          )}
                        </Input>
                        <Input
                          value={inputDate}
                          onChange={(e) => setInputDate(e.target.value)}
                          className="mb-4"
                          type="datetime-local"
                          style={{ borderRadius: "20px" }}
                        />
                        <Input
                          value={inputDuration}
                          onChange={(e) => setInputDuration(e.target.value)}
                          className="mb-4"
                          type="number"
                          placeholder="Enter duration in minutes"
                          style={{ borderRadius: "20px" }}
                        />
                        <Input
                          value={inputTopic}
                          onChange={(e) => setInputTopic(e.target.value)}
                          placeholder="Enter lecture topic"
                          className="mb-3"
                          style={{
                            borderRadius: "20px",
                          }}
                        />

                        <Button
                          onClick={handleSubmit}
                          color="primary"
                          style={
                            !isCreatingLive
                              ? {
                                  width: "100%",
                                  background: "#152E88",
                                  borderRadius: "20px",
                                  color: "white",
                                  cursor: "pointer",
                                }
                              : {
                                  width: "100%",
                                  background: "#152E88",
                                  borderRadius: "20px",
                                  color: "white",
                                  opacity: 0.4,
                                  cursor: "no-drop",
                                }
                          }
                          disabled={isCreatingLive}
                        >
                          {!isCreatingLive
                            ? "Proceed"
                            : "Creating Class..Please wait"}
                        </Button>
                      </div>
                    </div>
                  )}

                  <List
                    style={{ marginTop: "10px" }}
                    subheader={
                      <ListSubheader
                        component="div"
                        style={{ color: "#152E88", fontSize: "1.3rem" }}
                      >
                        Active Class(es)
                      </ListSubheader>
                    }
                    dense={true}
                  >
                    
                    {activeLiveClasses?.map((classItem, i) => (
                      // new Date(classItem.starts_at) > new Date()  && (
                      <ListItem
                        key={i}
                        style={{
                          backgroundColor: "#F1EDEC",
                          padding: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <ListItemText
                          primary={
                            "LECTURE: " +
                            classItem?.topic?.toString().toUpperCase()
                          }
                          secondary={
                            <p style={{ fontWeight: "500" }}>
                              <span style={{ color: "#152E88" }}>
                                {classItem.course.code} ({classItem.course.name}
                                )
                              </span>
                              <br />
                              <span style={{ fontStyle: "italic" }}>
                                {getScheduleDateTime(classItem.starts_at)}
                              </span>
                            </p>
                          } //course code - course name classTime
                        />
                        <ListItemSecondaryAction>
                          <button
                            onClick={() => handleJoinMeeting(classItem)}                            
                            style={
                              new Date(classItem.starts_at).getDate() ==
                                new Date().getDate() &&
                              new Date(classItem.starts_at).getMonth() ==
                                new Date().getMonth() &&
                              new Date(classItem.starts_at).getFullYear() ==
                                new Date().getFullYear() &&
                              new Date().getHours() >=
                                new Date(classItem.starts_at).getHours()
                                ? {
                                    cursor: "pointer",
                                    backgroundColor: "#fff",
                                    border: "1px solid #152E88",
                                    padding: "1rem",
                                    color: "#152E88",
                                    textDecoration: "none",
                                    fontWeight: 500,
                                    marginRight: "4px",
                                  }
                                : {
                                    cursor: "no-drop",
                                    backgroundColor: "#fff",
                                    opacity: 0.2,
                                    border: "1px solid #152E88",
                                    padding: "1rem",
                                    color: "#152E88",
                                    textDecoration: "none",
                                    fontWeight: 500,
                                    marginRight: "4px",
                                  }
                            }
                            disabled={
                              new Date(classItem.starts_at).getDate() ==
                                new Date().getDate() &&
                              new Date(classItem.starts_at).getMonth() ==
                                new Date().getMonth() &&
                              new Date(classItem.starts_at).getFullYear() ==
                                new Date().getFullYear() &&
                              new Date().getHours() >=
                                new Date(classItem.starts_at).getHours()
                                ? false
                                : true
                            }
                            title="Join this class"
                          >
                            Join
                          </button>
                          {//userRole === Role.LECTURER && 
                          (
                            <React.Fragment>
                              { classItem?.role === userRole  && classItem?.role === Role.LECTURER &&  
                              <button
                                onClick={() => {
                                  handleShareMeeting(classItem);
                                  toast.success(
                                    "Invite link copied to clipboard"
                                  );
                                }}
                                style={{
                                  backgroundColor: "#fff",
                                  border: "1px solid blue",
                                  padding: "1rem",
                                  color: "blue",
                                  textDecoration: "none",
                                  fontWeight: 500,
                                  marginRight: "4px",
                                }}
                                title="Copy class link"
                              >
                                Share
                              </button>
                              }

                              { classItem?.role === userRole && 
                              <button
                                onClick={() => {
                                  setOpenWarning(true);
                                  setFocusedItem(classItem);
                                }}
                                style={{
                                  backgroundColor: "#fff",
                                  border: "1px solid red",
                                  padding: "1rem",
                                  color: "red",
                                  textDecoration: "none",
                                  fontWeight: 500,
                                }}
                                title="Delete this class"
                              >
                                X
                              </button>
                              }
                              
                            </React.Fragment>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>

                      // )
                    ))}

                    {activeLiveClasses.length === 0 ? (
                      <h6 style={{ paddingLeft: "1rem" }}>Empty schedule.</h6>
                    ) : (
                      ""
                    )}
                  </List>

                  <List
                    style={{ marginTop: "10px" }}
                    subheader={
                      <ListSubheader
                        component="div"
                        style={{ color: "#152E88", fontSize: "1.3rem" }}
                      >
                        Pending Class(es)
                      </ListSubheader>
                    }
                    dense={true}
                  >
                    
                    {pendingLiveClassses?.map((classItem, i) => (
                      // new Date(classItem.starts_at) > new Date()  && (
                      <ListItem
                        key={i}
                        style={{
                          backgroundColor: "#F1EDEC",
                          padding: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <ListItemText
                          primary={
                            "LECTURE: " +
                            classItem?.topic?.toString().toUpperCase()
                          }
                          secondary={
                            <p style={{ fontWeight: "500" }}>
                              <span style={{ color: "#152E88" }}>
                                {classItem.course.code} ({classItem.course.name}
                                )
                              </span>
                              <br />
                              <span style={{ fontStyle: "italic" }}>
                                {getScheduleDateTime(classItem.starts_at)}
                              </span>
                            </p>
                          } //course code - course name classTime
                        />
                        <ListItemSecondaryAction>
                          <button
                            onClick={() => handleJoinMeeting(classItem)}                            
                            style={
                              new Date(classItem.starts_at).getDate() ==
                                new Date().getDate() &&
                              new Date(classItem.starts_at).getMonth() ==
                                new Date().getMonth() &&
                              new Date(classItem.starts_at).getFullYear() ==
                                new Date().getFullYear() &&
                              new Date().getHours() >=
                                new Date(classItem.starts_at).getHours()
                                ? {
                                    cursor: "pointer",
                                    backgroundColor: "#fff",
                                    border: "1px solid #152E88",
                                    padding: "1rem",
                                    color: "#152E88",
                                    textDecoration: "none",
                                    fontWeight: 500,
                                    marginRight: "4px",
                                  }
                                : {
                                    cursor: "no-drop",
                                    backgroundColor: "#fff",
                                    opacity: 0.2,
                                    border: "1px solid #152E88",
                                    padding: "1rem",
                                    color: "#152E88",
                                    textDecoration: "none",
                                    fontWeight: 500,
                                    marginRight: "4px",
                                  }
                            }
                            disabled={
                              new Date(classItem.starts_at).getDate() ==
                                new Date().getDate() &&
                              new Date(classItem.starts_at).getMonth() ==
                                new Date().getMonth() &&
                              new Date(classItem.starts_at).getFullYear() ==
                                new Date().getFullYear() &&
                              new Date().getHours() >=
                                new Date(classItem.starts_at).getHours()
                                ? false
                                : true
                            }
                            title="Join this class"
                          >
                            Join
                          </button>
                          {//userRole == Role.LECTURER && 
                          (
                            <>
                              { classItem?.role === userRole && classItem?.role === Role.LECTURER && 
                              <button
                                onClick={() => {
                                  handleShareMeeting(classItem);
                                  toast.success(
                                    "Invite link copied to clipboard"
                                  );
                                }}
                                style={{
                                  backgroundColor: "#fff",
                                  border: "1px solid blue",
                                  padding: "1rem",
                                  color: "blue",
                                  textDecoration: "none",
                                  fontWeight: 500,
                                  marginRight: "4px",
                                }}
                                title="Copy class link"
                              >
                                Share
                              </button>
                              }

                              { classItem?.role === userRole && 
                              <button
                                onClick={() => {
                                  setOpenWarning(true);
                                  setFocusedItem(classItem);
                                }}
                                style={{
                                  backgroundColor: "#fff",
                                  border: "1px solid red",
                                  padding: "1rem",
                                  color: "red",
                                  textDecoration: "none",
                                  fontWeight: 500,
                                }}
                                title="Delete this class"
                              >
                                X
                              </button>
                              }
                              
                            </>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>

                      // )
                    ))}

                    {pendingLiveClassses.length === 0 ? (
                      <h6 style={{ paddingLeft: "1rem" }}>Empty schedule.</h6>
                    ) : (
                      ""
                    )}
                  </List>
                </>
              }
            </div>
          </div>
        </>
      </div>
    </Layout>
  );
}

export default Live;
