import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import PropTypes from "prop-types";

import { useStyles } from "../login.style";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
} from "@material-ui/core";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo.png";
import Footer from "../../../components/Footer";
import { useAppDispatch } from "../../../store";
import { login } from "../../../store/features/auth/authThunk";
import { Spinner } from "../../../components/common/Spinner";
import { clearState } from "../../../store/features/auth/authSlice";
import { IFormInputs } from "../../../shared";
import { toast, ToastContainer } from "react-toastify";
import useNetworkStatus from "../../shared/utils/network";

interface Props {}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const InstructorLogin = (props: Props) => {
  const style = useStyles();
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useAppDispatch();

  const { isOnline } = useNetworkStatus();
  !isOnline &&
    toast.warning("Check your network connectivity to be sure you're online.");

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setIsloading(true);
    let res = await dispatch(login(data)); //console.log(Object.keys(res)); console.log(res.type);
    if (res.type === "[auth/login]/rejected") toast.error("Invalid login");
    setIsloading(false);
  };

  useEffect(() => {
    dispatch(clearState());
  }, []);

  return (
    <>
      <Container maxWidth="xl" className={style.container}>
        <ToastContainer />
        {/* <div className={style.header}>
        <div className={style.logoTitleContainer}>
          <Link to="/">
            <img
              src={require("../../../assets/icons/NAFLogo.png")}
              className={style.logoIMG} width={"50px"} height={"50px"} alt="Site Logo"
            />
          </Link>
          <Typography variant="h3" className={style.appTitle}>
            Air Warfare Centre
          </Typography>
        </div>
         
      </div> */}

        <Grid container className={style.form}>
          <Link to="/">
            <img
              src={require("../../../assets/icons/NAFLogo.png")}
              className={style.logoIMG}
              alt="Site Logo"
            />
          </Link>

          <Typography variant="h3" className={style.appTitle}>
            Air Warfare Centre
          </Typography>

          <Card variant="outlined" className={style.card}>
            <Container>
              <h3 className={style.headerTitle}>
                Instructor
                <span>
                  <a className={style.registerLink} href="#">
                    Not Registered? Contact Admin.
                  </a>
                </span>
              </h3>
              <form onSubmit={handleSubmit(formSubmitHandler)}>
                <Grid item sm={12} className={style.formGroup}>
                  <p className={style.labels}>Email Address</p>
                  <div>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.email}
                          variant="outlined"
                          placeholder="abc@airforce.mil.ng"
                          helperText={
                            errors.email ? (
                              <span>Email Address is required</span>
                            ) : (
                              ""
                            )
                          }
                        />
                      )}
                    />
                  </div>

                  <p className={style.labels}>Password</p>
                  <div>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.password}
                          variant="outlined"
                          placeholder="Password"
                          type="password"
                          helperText={
                            errors.password ? (
                              <span>Password is required</span>
                            ) : (
                              ""
                            )
                          }
                        />
                      )}
                    />
                  </div>
                  <a className={style.registerLink} href="/forgot-password">
                    Forgot Password?
                  </a>
                  <div>
                    {/* <Grid container justify="center" > */}
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={style.loginBtn}
                      style={{ width: "100%", textTransform: "capitalize" }}
                    >
                      {isLoading ? <Spinner size="2" /> : "Login"}
                    </Button>
                    {/* </Grid> */}
                  </div>
                </Grid>
              </form>
            </Container>
          </Card>
        </Grid>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

InstructorLogin.propTypes = {};

export default InstructorLogin;
