import { API } from "../configure-api";

function getInfo() {
  const requestOptions = {
    headers: { "Content-Type": "application/json" },
  };

  return fetch(`${process.env.REACT_APP_BASEURL}/logo/info`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getWebsite() {
  return API.get("/logo/info", "");
}

function handleResponse(response: any) {
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export const websiteService = {
  getWebsite,
  getInfo,
};
