import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./calendar.style";
import Layout from "../../../components/Layout";
import { Grid } from "@material-ui/core";
import box from "../../../assets/images/box.png";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  isDate,
  isEqual,
} from "date-fns";
import { Calendar, Left, Right } from "../../../assets/svg";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectEvents } from "../../../store/features/event/eventSlice";
import { fetchEvents } from "../../../store/features/event/eventThunk";
import { selectRole } from "../../../store/features/auth/authSlice";
interface Props {}

const CalendarPage = (props: Props) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dispatch = useAppDispatch();

  const userRole = useAppSelector(selectRole);
  const events = useAppSelector(selectEvents);
  const getEvents = async () => {
    await dispatch(fetchEvents(userRole));
  };
  React.useEffect(() => {
    getEvents();
  }, []);
  // const events = [
  //   {
  //     id: 1,
  //     title: "Departmental test",
  //     start: "2022-07-04",
  //     end: "2021-06-14T12:00:00",
  //   },
  //   {
  //     id: 2,
  //     title: "Lab test",
  //     start: "2022-07-05",
  //     end: "2021-06-16T18:00:00",
  //   },
  //   {
  //     id: 3,
  //     title: "SUG elections",
  //     start: "2022-07-08",
  //     end: "2021-06-20",
  //   },
  //   {
  //     id: 4,
  //     title: "Matriculation",
  //     start: "2022-07-09",
  //     end: "2021-06-16T18:00:00",
  //   },
  //   {
  //     id: 5,
  //     title: "Swearing In",
  //     start: "2022-07-10",
  //     end: "2021-06-16T18:00:00",
  //   },
  //   {
  //     id: 6,
  //     title: "Added new",
  //     start: "2022-07-10",
  //     end: "2021-06-16T18:00:00",
  //   },
  // ];

  const changeMonthHandle = (btnType: any) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };
  const changeWeekHandle = (btnType: any) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };
  const onDateClickHandle = (day: any, dayStr: any) => {
    setSelectedDate(day);
    // showDetailsHandle(dayStr);
  };
  // Days + Events
  const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
  const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
  const dateFormat = "d";
  const dateFormat2 = "EEE";
  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = "";
  const renderDays = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const dateFormat2 = "EEE";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const date = format(cloneDay, "yyyy-MM-dd");
        console.log(date);
        const renderEventName = (events: any) => {
          let todayEvent = events.filter(
            (event: any) =>
              format(new Date(event.starts_at), "yyyy-MM-dd") === date
          );
          if (todayEvent.length !== 0) {
            // console.log();
            for (let i = 0; i < todayEvent.length; i++) {
              return (
                <li key={i} className="event" 
                style={{ minWidth: "100%", color: "#152E88", textDecoration: "none", overflowX: "hidden", wordWrap:"break-word"}}>
                   {todayEvent[i].title}  
                </li>
              );
            }
          }
        };
        days.push(
          <div 
            className={`day ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            // key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "ccc dd MMM yy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            {/* //Displaying Calendar Details */}
            <span className="dayName">
              {format(addDays(startDate, i), dateFormat2)}
            </span>
            <span className="number" style={{ textAlign: "center", }}>
              {formattedDate}
            </span>
            {renderEventName(events)}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div 
          className="row"
          // key={day}
        >
          {days}
        </div>
      );
      days = [];
    }
    return <div className={style.week}>{rows}</div>;
  };

  const style = useStyles();
  return (
    <Layout>
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.main}>
            <div className="header">
              <h5
                style={{
                  color: "#152E88",
                  fontSize: "1.5rem",
                  fontWeight: 100, width: "20%", 
                }}
              >
                Calendar
              </h5>
              <div className="weeks">
                <Left
                  onClick={() => changeWeekHandle("prev")}
                  className="icon"
                />{" "}
                <h5 className="weekTitle">WEEK {currentWeek}</h5>
                <Right
                  onClick={() => changeWeekHandle("next")}
                  className="icon"
                />
              </div>
              <Calendar />
            </div>
            {renderDays()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

CalendarPage.propTypes = {};

export default CalendarPage;
