import React from "react";
import { useNavigate } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Grid } from "@material-ui/core";

import Truncate from 'react-truncate-html';

// core components

function UpdatesSection() {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const [itemNegativeFourFull , setItemNegativeFourFull] = React.useState(false);
  const [itemNegativeThreeFull , setItemNegativeThreeFull] = React.useState(false);
  const [itemNegativeTwoFull , setItemNegativeTwoFull] = React.useState(false);
  const [itemNegativeOneFull , setItemNegativeOneFull] = React.useState(false);
  const [itemZeroFull , setItemZeroFull] = React.useState(false);
  const [itemOneFull , setItemOneFull] = React.useState(false);
  const [itemTwoFull , setItemTwoFull] = React.useState(false);
  const [itemThreeFull , setItemThreeFull] = React.useState(false);
  const [posts, setPosts] = React.useState([]);
  const [loadingPost, setLoadingPost] = React.useState(true);

  React.useEffect(() => {
    fetch(`${BASE_URL}/blog/post/published?page=1&per_page=3`)
      .then((response) => response.json())
      .then((json) => setPosts(json.posts));
    
    setLoadingPost(false);
  }, []);

  return (
    <>
      <div
        className="section secion-blog cd-section"
        id="blogs"
        style={{
          marginTop: "-5rem",
          width: "100vw",
          height: "auto", 
        }}
      >
        {/* ********* BLOGS 3 ********* */}
        <div className="blog-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Updates</h2>
                <br />
                {loadingPost && (<h4>Loading Post(s)...</h4>)}
                {posts?.map((post, index) => (
                <Card className="card-plain card-blog" key={post.id}>
                  <Row>
                    <Col md="8">
                      <CardBody>
                        <h6 className={post.tags[0].name === "Course" ?"card-category text-danger": "card-category text-info"}>
                          {index === 0 && <i className="fa fa-free-code-camp mr-1" /> }
                          {post.tags.length? " "+post.tags[0].name:""}
                        </h6>
                        <CardTitle tag="h3">
                          <a href="#author" onClick={(e) => e.preventDefault()}>
                            {/* Commencement of activities in the Centre */}
                            {post.title}
                          </a>
                        </CardTitle>
                        <p className="card-description" style={{textAlign: "justify"}}>
                          {!post.readmore && (<><span><Truncate lines={6} dangerouslySetInnerHTML={{ __html: post.body}}/> </span> {" "}
                          <a href="#" onClick={(e) => {e.preventDefault(); post.readmore = true; setPosts([...posts])}}>Read More</a></>)}
                          {
                            post.readmore && (<><span><div dangerouslySetInnerHTML={{ __html: post.body }}></div> </span>
                          <a href="#" onClick={(e) => {e.preventDefault(); post.readmore = false; setPosts([...posts])}}>Read Less</a></>)
                          } 
                        </p>

                        <p className="author">
                          {/* by{" "}
                          <a href="#" onClick={(e) => e.preventDefault()}>
                            <b>Admin Officer</b>
                          </a>{" "} */}                          
                          {`Updated on ${new Date(post.updated_at).getDate()}/${new Date(post.updated_at).getMonth()+1}/${new Date(post.updated_at).getFullYear()}`}
                        </p>
                      </CardBody>
                    </Col>
                    <Col md="4">
                      <div className="card-image">
                        <a href="#author" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            // src={require("../../assets/img/ac/Mi-35M.jpg")}
                            src={post.image? post.image:require("../../assets/images/new_students.jpg")}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Card>
                ))}
    
                {posts?.length > 2 && <Button className="btn-round"
                    color="danger" 
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/updates");
                    }}
                    size="lg">Read More</Button>}

                {posts?.length === 0 && <h4>No post(s) found.</h4>}

              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END BLOGS 3 ********* */}
      </div>
    </>
  );
}

export default UpdatesSection;
