import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { selectAuth, selectUser } from "../store/features/auth/authSlice";
import { fetchUser } from "../store/features/auth/authThunk";

export function withAuth(Component: any) {
  return (props: any) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const isAUth = useAppSelector(selectAuth);

    const authUser = async () => {
      await dispatch(fetchUser());
    };

    useEffect(() => {
      authUser();
    }, []);

    return <Component {...props} auth={user} isAuth={isAUth} />;
  };
}
