import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    /*minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",*/
    height: "90vh",
    autoflow: "hidden",
    overflowY: "auto",
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
  },
  main: {
    marginTop: "4.9375rem",
    "& div.course-det": {
      position: "relative",
      background: colors.blue,
      padding: ".5rem",
      minHeight: "100px",
      boxShadow: "2px 2px 18px rgba(0, 0, 0, 0.25)",
      borderRadius: "8px",
      cursor: "pointer",
      "& .icon": {
        position: "absolute",
        right: ".5rem",
      },
      "& .optionsCourse": {
        background: colors.white,
        borderRadius: "8px",
        width: "70%",
        position: "absolute",
        right: "4px",
        "& .option": {
          background: colors.white,
          outline: "none",
          border: "none",
          width: "100%",
          textAlign: "center",
          padding: "0.2rem",
          fontWeight: 400,
          fontSize: "1em",
          lineHeight: "28px",
          /* identical to box height */
          color: colors.blue,
          borderRadius: "8px",
          cursor: "pointer",
          "&:hover": {
            background: "rgba(0, 0, 0, 0.1)",
          },
        },
      },
      "& .details": {
        marginTop: "1rem",
        "& h4": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "1.25rem",
          lineHeight: "33px",
          /* identical to box height */
          color: colors.white,
        },
        "& h5": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "0.625rem",
          lineHeight: "12px",
          /* identical to box height */
          color: colors.white,
        },
      },
    },
    "& div.course-add": {
      position: "relative",
      background: colors.white,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: ".5rem",
      minHeight: "100px",
      boxShadow: "2px 2px 18px rgba(0, 0, 0, 0.25)",
      border: "1px solid " + colors.blue,
      borderRadius: "8px",
      cursor: "pointer",
      "& h6": {
        marginTop: ".5rem",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "0.625rem",
        lineHeight: "12px",
        /* identical to box height */
        color: colors.blue,
      },
    },
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modals": {
      margin: "auto",
      width: "90%",
      maxWidth: "31.25rem",
      background: colors.white,
      position: "relative",
      minHeight: "90%",
      transition: ".5s",
    },
    "& .cancelBox": {
      textDecoration: "none",
      position: "absolute",
      padding: "1.4375rem",
      right: 0,
      top: 0,
      color: colors.white,
      background: colors.blue,
    },
    "& .content": { margin: "3.75rem 0.625rem" },
    "& .courseLists ": {
      margin: "7.5625rem 0 4rem 0",
      "& div.course ": {
        margin: "0 -0.625rem .5rem -0.625rem",
        padding: "0.875rem",
        background: colors.gray,
      },
      "& .MuiSvgIcon-root": {
        fill: colors.black,
        enableBackground: colors.white,
        marginRight: "1.5rem",
      },
      "& .MuiTypography-root": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1.5rem",
        lineHeight: "36px",
        /* identical to box height */
        color: colors.black,
      },
      "& .MuiButton-root": {
        width: "50%",
        background: colors.blue,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "0.9rem",

        lineHeight: "22px",
        /* identical to box height */

        color: colors.white,
        marginTop: "3rem",
      },
    },
  },
});
