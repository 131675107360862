import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    autoflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    "& a": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2px",
      borderRadius: "5px",
      textDecoration: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      /* identical to box height */

      color: colors.blue,
      background: colors.gray,
      "& svg": { marginRight: ".2rem" },
    },

    backgroundColor: "#F1EDEC",
    padding: "1rem",
  },
  main: {
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 200px)",
      overflowY: "auto",
    },
    marginTop: "2.5625rem",
    "& .lists.student .list": { position: "relative" },
    "& .lists.student": {
      "& span": { display: "block" },

      "& .dot": { position: "absolute", top: "10px" },
    },
    "& .lists": {
      overflowY: "auto",
      overflowX: "hidden",
      margin: "0 -1rem",

      "& div.list:not(:last-child)": {
        marginBottom: "0.5rem",
      },

      "& div.list": {
        position: "relative",
        "& svg path": { fill: colors.blue },
        background: colors.gray,
        padding: "0.2rem 1.875rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        "& h5": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "1rem",
          lineHeight: "24px",
          /* identical to box height */

          color: colors.black,
        },
        "& h6": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "21px",
          /* identical to box height */

          color: colors.black,
        },
        "& span": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "15px",
          /* identical to box height */

          color: colors.black,
        },
      },
    },
    "& .drafts": {
      margin: "1rem 0",
      fontSize: "1.5rem",
      fontWeight: 100,
      color: "#152E88",
    },
    "& .divider": {
      borderBottom: "2px solid #F7F4F4",
      margin: "1.5rem 1rem ",
    },
    // handling menu
    "& .dropItems": {
      width: "81px",
      background: colors.white,
      boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      gap: "6px",
      right: "35px",
      marginTop: "90px",
      zIndex: 1,
      "& >a": {
        textDecoration: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
        /* identical to box height */
        color: colors.black,
        textAlign: "center",
        padding: "4px 0",
      },
      "& a:not(:last-child)": {
        borderBottom: "1px solid #F6F4F4",
      },
    },
  },
  modalContainer: {
    position: "fixed",

    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modals": {
      margin: "auto",
      width: "60%",
      // maxWidth: "90%",

      background: colors.white,
      position: "relative",
      minHeight: "80%",
      transition: ".5s",
      [theme.breakpoints.down("sm")]: { width: "90%", minHeight: "90%" },
    },
    "& .cancelBox": {
      textDecoration: "none",
      position: "absolute",
      padding: "1.4375rem",
      right: 0,
      top: 0,
      color: colors.white,
      background: colors.blue,
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      margin: "3.75rem 0.625rem",
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "30px",
        lineHeight: "45px",
        color: colors.black,
        marginTop: "1.5rem",
        marginBottom: "2.5rem",
      },
      "& div": {
        marginBottom: ".5rem",
      },
      "& .MuiInputBase-input ": {
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        // lineHeight: "33px",
        color: "#C4C4C4",
        padding: ".5rem",
      },
      "& .MuiButton-root": {
        background: colors.blue,
        border: "none",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "22px",
        padding: "6px 22px",
        lineHeight: "33px",
        /* identical to box height */

        color: colors.white,
        float: "right",
      },
    },
  },
  form: {
    // display: "flex",
    width: "70%",
    [theme.breakpoints.down("sm")]: { width: "90%" },
    "& div": { width: "100%" },

    // justifyContent: "center",
    "& div:not(.form-row):not(.Mui-error)": { marginBottom: ".5rem" },

    "& .form-row": {
      display: "flex",
      alignItems: "start",
      [theme.breakpoints.down("sm")]: { flexDirection: "column" },

      "& div:not(:last-child)": { marginRight: "1.5rem" },

      "& .MuiInputBase-input": {},
    },
    "& .MuiFormHelperText-root": {
      margin: "0",
    },

    "& textarea": {
      minHeight: "60px",
      padding: "10.5px",
      width: "100%",
      border: "1px solid rgba(0, 0, 0, 0.3)",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "33px",
      color: "#C4C4C4",
    },
    "& .form-row2": {
      display: "flex",
      marginTop: "2.25rem",
      [theme.breakpoints.down("sm")]: { flexDirection: "column" },
    },
  },
  readNote: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "19px 23px",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
      width: "145%",
    },
    "& .title": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
      "& h5": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: "24px",
        color: colors.black,
        display: "flex",
        alignItems: "center",
        gap: "6px",
      },

      "& .icon": {
        // padding: "9.25px 12.99px",
        background: "#E9E9E9",
        borderRadius: "5px",
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      //handling menu options
      "& .dropItems": {
        width: "81px",
        background: colors.white,
        boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        gap: "6px",
        right: "8px",
        marginTop: "100px",
        zIndex: 1,
        "& >button": {
          textDecoration: "none",
          background: "transparent",
          border: "none",
          outline: "none",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "21px",
          /* identical to box height */
          color: colors.black,
          textAlign: "center",
          padding: "4px 0",
        },
        "& a:not(:last-child)": {
          borderBottom: "1px solid #F6F4F4",
        },
      },
    },
    "& .content": {
      background: "#F6F4F4",
      borderRadius: "6px",
      padding: "9px 13px",
      // marginTop: "17px",
      position: "relative",
      minHeight: "calc(90vh - 120px)",
      marginTop: "-1rem",
      marginLeft: 0,
      width: "100%",
      // [theme.]

      "& .reading": {
        overflowY: "auto",
        height: "27rem",
        marginTop: "-2.5rem",
        backgroundColor: "#fff",
        // borderRadius: "5px",
        border: "1px solid grey",
        padding: "1.5rem",
        textAlign: "justify",
      },

      "& .attachment": {
        color: "#152E88",
        textDecoration: "none",
        fontSize: "1rem",
        fontWeight: "500",
      },

      "& .header": {
        boxShadow: "2px 3px 12px 0 #888888",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "16px",
        marginTop: "16px",

        border: "1px solid black",
        padding: ".2rem 1rem",
        backgroundColor: "#FCFEFE",

        "& .title": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "22px",
          lineHeight: "33px",
          // color: colors.black,
          color: "#152E88",
          marginTop: ".5rem",
        },
        "& .date": {
          fontFamily: "Poppins",
          fontStyle: "italic",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: colors.black,
          marginTop: "-.5rem",
        },
      },
    },
    "& .body": {
      "& p": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "24px",
        color: colors.black,
      },
    },

    "& .pagination": {
      display: "flex",
      listStyleType: "none",
      gap: "6px",
      alignItems: "center",
      marginTop: "81px",
      position: "absolute",
      bottom: "7px",
      right: "20px",
      [theme.breakpoints.down("sm")]: {
        position: "static",
        // marginTop: "120px",
        justifyContent: "end",
      },
      "& li": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& a": {
          textDecoration: "none",
          color: colors.white,
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 640,
          fontSize: "1rem",
          lineHeight: "24px",
        },
      },

      "& .icon": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        padding: "4.33px 4.92px",
        cursor: "pointer",
      },
      "& .active": {
        background: " #E5E5E5 !important",
        color: "#fff !important",
      },
    },
  },
}));
