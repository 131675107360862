import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";
// select dropdown
import dropdown from "../../../assets/icons/selectDropdown2.svg";
import calendar from "../../../assets/icons/fa_calendar.svg";
export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    // marginTop: "-4rem",
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    // minHeight: "calc(100vh - 100px)",
    // height: "calc(100% - 50px)",
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: "rgba(0, 0, 0, 0.6)",
    },
    "& .divider": {
      borderBottom: " 1px solid #F6F4F4",
      margin: "1rem auto 2rem auto",
      width: "90%",
      alignSelf: "center",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%", width: "90%"
    },
    // [theme.breakpoints.up("sm")]: {
    //   overflowY: "auto", 
    //   width: "145%", 
    // },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    "& a": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2px",
      borderRadius: "5px",
      textDecoration: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "0.7rem",
      lineHeight: "20px",
      /* identical to box height */

      color: colors.blue,
      background: colors.gray,
      "& svg": { marginRight: ".2rem" },
    },
  },
  main: {
    // border: "1px solid green",
    // marginTop: "2.5625rem",
    overflowX:"hidden", paddingRight: "1rem",
    marginTop: ".5rem",
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
      height: "80vh",
    },
    "& .fileUpload": {
      display: "flex",
      flexDirection: "column",
      gap: "4px",

      "& button": {
        outline: "none",
        border: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        color: colors.blue,
      },
    },
    "& .fileUpload ": {
      marginBottom: ".5rem",
    },
    "& .fileUpload span": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1rem",
      // lineHeight: "33px",
      color: colors.black,
      padding: ".5rem",
      marginBottom: ".5rem",
    },
    "& .file ": {
      background: colors.white,
      border: "1px solid rgba(0, 0, 0, 0.3)",
      borderRadius: "5px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1rem",
      // lineHeight: "33px",
      color: "#C4C4C4",
      padding: ".5rem",
    },
  },

  form: {
    "& .form-group": {
      marginBottom: "16px",
    },
    "& input, select": {
      width: "100%",
      //   height: "45px",
      background: colors.white,
      //   border: " 1px solid rgba(0, 0, 0, 0.3)",
      borderRadius: "5px",
      padding: "10px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
      "&::placeholder": {
        color: "#C4C4C4;",
      },
    },
    "& select, input[type='file']": {
      appearance: "none",
      backgroundImage: `url(${dropdown}) !important`,
      backgroundSize: "24px !important",
      backgroundRepeat: "no-repeat !important",
      backgroundPosition: " calc(100% - 12px) !important",
    },
    "& .form-row": {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "13px",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
    },
    "& .date-picker": {
      backgroundImage: `url(${calendar}) !important`,
      backgroundSize: "24px !important",
      backgroundRepeat: "no-repeat !important",
      backgroundPosition: " calc(100% - 12px) !important",

      "&::-webkit-inner-spin-button,&::-webkit-calendar-picker-indicator": {
        opacity: "0",
        "-webkit-appearance": "none",
        // display: "none",
      },
    },
    // add icon to input type file

    "& .questions": {
      "& p": {
        textDecoration: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80px",
        padding: "0.5rem .3rem",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "0.7rem",
        lineHeight: "18px",
        background: colors.blue,
        color: colors.white,
        borderRadius: "5px",
        marginBottom: "18px",
        "& svg path": { fill: colors.white },
      },
    },
    "& .questions h5": {
      margin: "1rem 0 1rem 0",
    },
    "& .questions textarea": {
      minHeight: "60px",
      padding: "4px 9px",
      width: "100%",
      border: "1px solid rgba(0, 0, 0, 0.3)",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "20px",
      color: "#C4C4C4",
    },
    "& div.upload": {
      marginTop: "18px",
      display: "flex",
      justifyContent: "end",
      "& button": {
        background: colors.blue,
        color: colors.white,
        borderRadius: "6px",
        padding: "4px 15px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "33px",
        textTransform: "uppercase",
        border: "none",
        cursor: "pointer",
      },
    },
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modals": {
      margin: "auto",
      width: "50%",
      // maxWidth: "100%",
      background: colors.white,
      position: "relative",
      minHeight: "10%",
      transition: ".5s",
      [theme.breakpoints.down("sm")]: { width: "50%" },
    },
    "& .cancelBox": {
      textDecoration: "none",
      position: "absolute",
      padding: "1.4375rem",
      right: 0,
      top: 0,
      color: colors.white,
      background: colors.blue,
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "3.75rem auto",
      // margin: "0 auto",
      width: "70%",
      [theme.breakpoints.down("sm")]: { width: "90%" },
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "30px",
        lineHeight: "45px",
        color: colors.black,
        marginTop: "1.5rem",
        marginBottom: "2.5rem",
      },
      "& form": { width: "100%" },
      "& div": {
        width: "100%",

        marginBottom: ".5rem",
      },
      "& .MuiInputBase-input ": {
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        // lineHeight: "33px",
        color: "#C4C4C4",
        padding: ".5rem",
      },
      "& .MuiButton-root": {
        background: colors.blue,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "0.9rem",

        lineHeight: "22px",
        /* identical to box height */

        color: colors.white,
      },
    },
  },
}));
