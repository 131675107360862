import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./attemptAssigments.style";
import Layout from "../../../components/Layout";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Chev, NextPage, Previous, Info } from "../../../assets/svg/";
import { formatDate } from "../../../shared";
import { formatDateDifference } from "../../shared/utils/dateDifference";
import { useAppSelector } from "../../../store";
import { selectUser } from "../../store/features/auth/authSlice";
import { useDispatch } from "react-redux";
import {
  createSubmission,
  submitAssignment,
  updateSubmission,
} from "../../../store/features/assignment/assignmentThunk";
import { toast } from "react-toastify";
import { Spinner } from "../../../components/common/Spinner";
import LoadingSpinner from "../../../components/common/Loader";
import TextEditor from "../../../components/common/TextEditor";

interface Props {}

interface CustomizedState {
  myState: any;
  id: any;
  title: string;
  questions: any;
  time_used: any;
  course: string;
  createdDate: any;
  date: any;
  submission: any;
}
const AttemptAssignments = (props: Props) => {
  const style = useStyles();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();

  //Getting user details
  const user = useAppSelector(selectUser);

  const questions =
    Object.keys(state.submission).length === 0
      ? state.questions
      : state.submission.questions;
  const totalQuestions = questions.length || 0;

  const [currentPage, setCurrentPage] = React.useState(1);
  const [questionsPerPage] = React.useState(4);
  // Get current note
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = questions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  // Change page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalQuestions / questionsPerPage); i++) {
    pageNumbers.push(i);
  }
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch<any>();

  console.log("submission", state.submission);
  const handleSubmission = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const attemptedQuestions: any = [];
    const options = document.querySelector("[class=options]");

    Array.from(e.target.elements).map((el: any) => {
      if (el.tagName === "TEXTAREA") {
        console.log("el", el.tagName);

        attemptedQuestions.push({
          question_id: el.name,
          text: el.value,
        });
      }
    });
    const data = {
      assignment_id: state.id,
      student_id: user.id,
      answers: attemptedQuestions,
    };

    // {
    // "assignment_id":2,
    // "student_id":1,
    // "answers":[
    //   {
    //     "text":"Shema",
    //     "attachment":"",
    //     "question_id":5
    //   },
    // }

    await dispatch(createSubmission(data))
      .then((res: any) => {
        setIsLoading(false);
        console.log(res);
        if (res?.error?.code === "ERR_BAD_REQUEST") {
          toast.error("Submission already exists");
        } else {
          toast.success("Assignment has been saved to drafts");
          navigate("/dashboard/assignments");
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error("Something went wrong please try again");
        console.log("error", err);
      });

    console.log(data);
  };

  // console.log("submission", state.submission);
  const handleResubmission = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const attemptedQuestions: any = [];

    Array.from(e.target.elements).map((el: any) => {
      if (el.tagName === "TEXTAREA") {
        console.log("el", el.tagName);

        attemptedQuestions.push({
          question_id: el.name,
          text: el.value,
        });
      }
    });

    const data = {
      submission_id: state.submission.id,
      assignment_id: state.id,
      student_id: user.id,
      answers: attemptedQuestions,
    };

    //    {
    //     "submission_id":4,
    //     "assignment_id":2,
    //     "student_id":1,
    //    "time_used": 5
    //     "answers": [
    //         {
    //           "id": 5,
    //           "text": "Tukur Kagarko"
    //         },
    //         {
    //           "id": 6,
    //           "text": "35"
    //         }
    //       ]
    // }

    await dispatch(updateSubmission(data))
      .then((res: any) => {
        setIsLoading(false);
        console.log(res);
        toast.success("Assignment has been updated to drafts");
        navigate("/dashboard/assignments");
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error("Something went wrong please try again");
        console.log("error", err);
      });
  };
  const finalSubmit = async () => {
    setIsLoading(true);

    const data = {
      submission_id: state.submission.id,
    };

    //    {
    //     "submission_id":4,
    //     "assignment_id":2,
    //     "student_id":1,
    // "time_used": 5,
    //     "answers": [
    //         {
    //           "id": 5,
    //           "text": "Tukur Kagarko"
    //  "question_id":5
    //         },
    //         {
    //           "id": 6,
    //           "text": "35"
    //         }
    //       ]
    // }

    await dispatch(submitAssignment(data))
      .then((res: any) => {
        setIsLoading(false);
        console.log(res);
        toast.success("Assignment has been successfully submitted");
        navigate("/dashboard/assignments");
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error("Something went wrong please try again");
        console.log("error", err);
      });

    console.log(data);
  };
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const handleIsSubmit = () => {
    setIsSubmitted(!isSubmitted);
    setIsLoading(false);
  };
  return (
    <Layout>
      {isLoading && <LoadingSpinner />}
      {isSubmitted && (
        <div className={style.modalContainer}>
          <div className="modals">
            <i className="cancelBox" onClick={handleIsSubmit}>
              x
            </i>
            <div className="content">
              <Info />
              <h6>Submit Assignment?</h6>
              <p>Are you sure you want to submit your assignment?</p>
              <p>You cannot undo this action.</p>

              <div className="buttons">
                <button className="primary" onClick={finalSubmit}>
                  Submit
                </button>
                <button onClick={handleIsSubmit}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.header}>
            <h5>
              <Link className="icon" to="/dashboard/assignments">
                <Chev />{" "}
              </Link>{" "}
              {state.course} - {state.title}
            </h5>
            {!state.submission.is_submitted ? (
              <div className="date">
                <p>Due date : {formatDate(state.date)}</p>
                <p className="remaining">
                  Time remaining :{" "}
                  <span>{formatDateDifference(state.date)}</span>
                </p>
              </div>
            ) : (
              <div className="date">
                <p>
                  <strong>Score : {state.submission.score}</strong>
                </p>
                <p className="remaining">
                  Date Updated:{" "}
                  <span>{formatDate(state.submission.updated_at)}</span>
                </p>
              </div>
            )}
          </div>
          <div className={style.main}>
            {/* If already attempted display the questions with the answers */}
            {Object.keys(state.submission).length !== 0 ? (
              <form onSubmit={handleResubmission}>
                <div className="questionsContainer">
                  {currentQuestions.map((question: any, i: any) => (
                    <div className="questions" key={i}>
                      <p className="question">
                        <strong>
                          Q{i + 1}.{" "}
                          <p
                            dangerouslySetInnerHTML={{ __html: question.text }}
                          />
                        </strong>
                      </p> 
                      <div className="options">
                        <TextEditor
                          name={question.id}
                          disabled={state.submission.is_submitted}
                          initialValue={state.submission.answers[i]?.text}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {!state.submission.is_submitted && (
                  <div className="footBottons">
                    <button type="submit">
                      {isLoading ? <Spinner size="2" /> : "Save Draft"}
                    </button>
                    <button type="button" onClick={handleIsSubmit}>
                      {isLoading && isSubmitted ? (
                        <Spinner size="2" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                )}
              </form>
            ) : (
              <form onSubmit={handleSubmission}>
                <div className="questionsContainer">
                  {currentQuestions.map((question: any, i: any) => (
                    <div className="questions" key={i}>
                      <p className="question">
                        Q{i + 1}.{" "}
                        <p
                          dangerouslySetInnerHTML={{ __html: question.text }}
                        />
                      </p>
                      <div className="options" id={question.id}>
                        {/* <textarea
                          placeholder="Answer here ......"
                          rows={5}
                          // id="editor"
                          name={question.id}
                         
                        ></textarea> */}
                        <TextEditor name={question.id} />
                      </div>
                    </div>
                  ))}
                </div>
                <button type="submit">
                  {isLoading ? <Spinner size="2" /> : "Save Draft"}
                </button>
              </form>
            )}

            {/* //Pagination */}
            {/* <ul className="pagination">
              <Previous
                className={currentPage === 1 ? "icon active" : "icon "}
                onClick={() => paginate(1)}
              />
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={number === currentPage ? "active" : ""}
                >
                  <a
                    onClick={() => paginate(number)}
                    // href="#"
                    // className="page-link"
                  >
                    {number}
                  </a>
                </li>
              ))}
              <NextPage
                className={
                  currentPage == pageNumbers.length ? "icon active" : "icon "
                }
                onClick={() => paginate(pageNumbers.length)}
              />
            </ul> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

AttemptAssignments.propTypes = {};

export default AttemptAssignments;
