import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchCourseVideo, fetchVideo } from './videoThunk'

const initialState = {
    videos: [] as any[],
    Coursevideos: [] as any[],
    error: '' as string,
    status: '' as string
}

export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        clearState: (state) => {
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVideo.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetchVideo.fulfilled, (state, action) => {
                return { ...state, videos: action.payload, status: "fullfilled" }
            })
            .addCase(fetchVideo.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })
            .addCase(fetchCourseVideo.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetchCourseVideo.fulfilled, (state, action) => {
                return { ...state, Coursevideos: action.payload, status: "fullfilled" }
            })
            .addCase(fetchCourseVideo.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })
    }
})


export const selectVideo = (state: any) => state.videos.videos
