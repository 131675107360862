import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Close } from "@material-ui/icons";
import { Avatar, Grid } from "@material-ui/core";

interface Props {
  applicant: any;
  open: any;
  toggle: any;
  actions: any;
}

const styles = {
  group: { display: "flex", gap: "8px" },
  label: { fontWeight: 600 },
  value: { fontWeight: 400 },
  image: {
    height: "200px",
    width: "200px",
  },
};

const ViewApplicantModal = ({ applicant, toggle, open, actions }: Props) => {
  return (
    <Modal isOpen={open} toggle={toggle} size="xl" scrollable={true}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px",
          borderBottom: "1px solid #c7cad6",
        }}
      >
        <h6>Applicant information</h6>
        <Close onClick={toggle} style={{ cursor: "pointer" }} />
      </div>
      <ModalBody>
        <div>
          <Grid container spacing={2}>
            <Grid item container xs={12} md={8}>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Name:</p>
                  <p style={styles.value}> {applicant.name}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Rank:</p>
                  <p style={styles.value}> {applicant.rank}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Service No:</p>
                  <p style={styles.value}> {applicant.service_number}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Course Intake:</p>
                  <p style={styles.value}> {applicant.course_intake}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Service Years:</p>
                  <p style={styles.value}>{applicant.service_years}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Specialty:</p>
                  <p style={styles.value}> {applicant.specialty}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Email:</p>
                  <p style={styles.value}> {applicant.email}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Age:</p>
                  <p style={styles.value}> {applicant.age}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Phone:</p>
                  <p style={styles.value}>{applicant.phone}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Gender:</p>
                  <p style={styles.value}>{applicant.gender}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Marital Status:</p>
                  <p style={styles.value}> {applicant.marital_status}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Highest Qualification:</p>
                  <p style={styles.value}>{applicant.highest_qualification}</p>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={styles.group}>
                  <p style={styles.label}>Essay:</p>
                  <p style={styles.value}>{applicant.essay}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Emergency Contact Name:</p>
                  <p style={styles.value}>{applicant.emergency_contact_name}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Emergency Contact Relationship:</p>
                  <p style={styles.value}>
                    {applicant.emergency_contact_relationship}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Emergency Contact address:</p>
                  <p style={styles.value}>
                    {applicant.emergency_contact_address}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={styles.group}>
                  <p style={styles.label}>Emergency Contact Phone:</p>
                  <p style={styles.value}>
                    {applicant.emergency_contact_phone}
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={styles.group}>
                {applicant.image ? (
                  <img
                    src={applicant.image}
                    alt="applicant"
                    style={styles.image}
                  />
                ) : (
                  <Avatar style={styles.image} />
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        {/* {applicant.medical_status}
        {applicant.other_military_course}
        {applicant.professional_military_qualification}
        {applicant.professional_qualification}
        {applicant.projected_pme_year}
        {applicant.projected_promotion_year}
        {applicant.projected_upgrade_year}
        {applicant.skill_level} */}
      </ModalBody>
      <ModalFooter>
        {actions.admit && (
          <Button color="success" onClick={() => actions.admit()}>
            Admit
          </Button>
        )}
        {actions.reject && (
          <Button color="danger" onClick={() => actions.reject()}>
            Reject
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

ViewApplicantModal.propTypes = {};

export default ViewApplicantModal;
