import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import PropTypes from "prop-types";

import { useStyles } from "../login.style";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  MenuItem,
  Select,
} from "@material-ui/core";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";

import { Link, Navigate, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import { useAppDispatch } from "../../../store";
import { Spinner } from "../../../components/common/Spinner";
import { clearState } from "../../../store/features/auth/authSlice";
import { IFormInputs } from "../../../shared/interfaces/user";
import { getYears } from "../../../shared/utils/date";
import { toast, ToastContainer } from "react-toastify";
import useNetworkStatus from "../../shared/utils/network";
import { useAppSelector } from "../../store";
import { selectWebsite } from "../../store/features/website/websiteSlice";

interface Props {}

const schema = yup.object().shape({
  service_number: yup.string().required("Service number is required"),
  email: yup.string().email().required("Email is required"),
});

const VerifyAccount = (props: Props) => {
  const style = useStyles();
  const [isLoadingUserData, setIsLoadingUserData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [openVerificationForm, setOpenVerificationForm] = useState(false);
  const [applicantEmail, setApplicantEmail] = useState("");
  const [applicantToken, setApplicantToken] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const BASE_URL = process.env.REACT_APP_BASEURL;
  const NAF_URL = process.env.REACT_APP_NAFURL;

  const website = useAppSelector(selectWebsite);

  const { isOnline } = useNetworkStatus();
  !isOnline &&
    toast.warning("Check your network connectivity to be sure you're online.");

  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    const body = {
      email: applicantEmail,
    };

    try {
      setIsSubmitting(true);
      const response = await fetch(`${BASE_URL}/admission/applicant/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const result = await response.json();
      console.log("result", result);
      if (result.message) {
        setOpenVerificationForm(true);
        toast.success(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setIsSubmitting(false);
      // window.location.href = "/success-registration";
    } catch (error) {
      console.log(error);
      toast.error("Check the details and try again", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsSubmitting(false);
    }
  };

  const getUserData = async () => {
    const service_number = getValues("service_number");
    const url = `${NAF_URL}/${service_number}`;
    try {
      setIsLoadingUserData(true);
      const request = await fetch(url);
      const response = await request.json();
      if (response) {
        setIsLoadingUserData(false);
        setValue("email", response[0]?.email || "", { shouldValidate: true });
        setApplicantEmail(response[0]?.email);
      } else {
        toast.error("unable to get user data", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setIsLoadingUserData(false);
    } catch (error) {
      console.log("err", error);
      toast.error("unable to get user data", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoadingUserData(false);
    }
  };

  const handleVerification = async (e: any) => {
    e.preventDefault();
    const url = `${BASE_URL}/admission/applicant/verify?email=${applicantEmail}&token=${applicantToken}`;
    try {
      setIsVerifying(true);
      const request = await fetch(url);
      const response = await request.json();
      setIsVerifying(false);
      if (!request.ok) {
        return toast.error(response.message || "unable to verify user", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      navigate("/application", {
        state: { serviceNo: getValues("service_number") },
      });
    } catch (error) {
      console.log("err", error);
      toast.error("unable to verify user", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsVerifying(false);
    }
  };

  useEffect(() => {
    dispatch(clearState());
  }, []);

  return (
    <Container maxWidth="lg" className={style.container}>
      <ToastContainer />
      <div className={style.header}>
        <div className={style.logoTitleContainer}>
          <Link to="/">
            <img src={website?.logo} alt="" className={style.logoIMG} />
          </Link>
          <Typography variant="h1" className={style.appTitle}>
            Air Warfare Centre
          </Typography>
        </div>
        <h1 className={style.title}>Verification Form</h1>
      </div>
      <Paper elevation={3} className={style.regFormContainer}>
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box style={{ display: "flex", gap: 5 }}>
                <Typography>Send verification token</Typography>
                {isLoadingUserData && <Spinner size="1" />}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="service_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onBlur={getUserData}
                    error={!!errors.service_number}
                    variant="outlined"
                    placeholder="Service Number"
                    helperText={errors.service_number?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.email}
                      variant="outlined"
                      placeholder="email"
                      disabled={true}
                      helperText={errors.email?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end">
              {isSubmitting ? (
                <Spinner size="1" />
              ) : (
                <Button variant="contained" type="submit">
                  Send Email
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
      {openVerificationForm && (
        <Paper elevation={3} className={style.regFormContainer}>
          <form onSubmit={handleVerification}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box style={{ display: "flex", gap: 5 }}>
                  <h6>
                    A token has been sent to your email {applicantEmail}, please
                    paste it here to proceed
                  </h6>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  value={applicantToken}
                  onChange={(e: any) => setApplicantToken(e.target.value)}
                  error={!applicantToken}
                  variant="outlined"
                  placeholder="Token"
                  helperText={"Enter the token from your email"}
                  fullWidth
                />
              </Grid>

              <Grid container item xs={12} justifyContent="flex-end">
                {isVerifying ? (
                  <Spinner size="1" />
                ) : (
                  <Button variant="contained" type="submit">
                    Verify
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
      <br />
      <br />

      {/* <Footer /> */}
    </Container>
  );
};

VerifyAccount.propTypes = {};

export default VerifyAccount;
