import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import TextEditor from "../../../../components/common/TextEditor";
import CreatableSelect from "react-select/creatable";
import { Spinner } from "../../../../components/common/Spinner";

function PostsCreateModal({
  handleClose,
  focusedItem,
  openCreatePost,
  title,
  content,
  selectedTags,
  setTitle,
  image,
  setContent,
  handleSubmit,
  handleImageChange,
  fileURL,
  handleFileChange,
  isSubmitting,
  isUploading,
  allTags,
  handleMultiSelect,
}) {
  return (
    <Modal isOpen={openCreatePost} size="lg" toggle={handleClose}>
      <ModalHeader>{focusedItem ? "Update post" : "Create Post"}</ModalHeader>
      <ModalBody>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <Input
            type="text"
            name="title"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <p>Featured Image</p>
          <Input onChange={handleImageChange} type="file" name="file" />
          { image && <img src={image} alt="logo" height="100" width="100" /> }
          { isUploading && <Spinner size="2" /> }
          <p>File Upload</p>
          <Input onChange={handleFileChange} type="file" name="file" />          
          <p>Copy Upload File Link: <span style={{padding: ".2rem", cursor: "copy", backgroundColor: "#E0EEF0", color: "black"}}>{fileURL || "-"}</span></p>
          
          <TextEditor
            type="textarea"
            name="content"
            placeholder="enter slider content"
            id="content"
            onChange={(content) => setContent(content)}
            value={content}
            toolbarOptions="quickimage"
          />

          <CreatableSelect
            isMulti
            onChange={handleMultiSelect}
            value={selectedTags}
            options={allTags?.map((tag) => ({
              label: tag.name,
              value: tag.name,
            }))}
          />

          <Button
            style={{ background: "#152E88" }}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "saving..." : focusedItem ? "Update" : "Submit"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default PostsCreateModal;
