import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    overflowY: "auto",
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    "& a": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2px",
      borderRadius: "5px",
      textDecoration: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      /* identical to box height */

      color: colors.blue,
      background: colors.gray,
      "& svg": { marginRight: ".2rem" },
    },
  },
  main: {
    marginTop: "1rem",
  },
}));
