import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    autoflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "start",

      gap: "8px",
    },

    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "24px",
      color: colors.black,
      display: "flex",
      alignItems: "center",
      gap: "6px",

      "& .icon": {
        // padding: "9.25px 12.99px",
        background: "#E9E9E9",
        borderRadius: "5px",
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& p": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21px",
      color: colors.black,
      "& span": {
        fontStyle: "italic !important",
        fontWeight: 300 + " !important",
      },
    },
    "& button": {
      border: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "27px",
      color: colors.white,
      cursor: "pointer",
      width: "120px",
      height: "40px",
      background: colors.blue,
      borderRadius: "6px",
    },
  },
  main: {
    marginTop: "1rem",
    position: "relative",

    "& .listContainer": {
      display: "flex",
      gap: "74px",
      height: "70vh",
      overflowY: "auto",
      "& .table": {
        width: "100%",
        borderCollapse: "collapse",
        borderSpacing: "0",
        "& tr:not(:last-child), th": {
          borderBottom: "8px solid #fff",
        },
        "& tr": {
          background: "#F6F4F4;",
          borderRadius: "4px",
        },

        "& th": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: colors.black,
          textAlign: "left",
          padding: "10px",
        },
        "& td": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: colors.black,
          padding: "23px 10px",

          "&:not(:first-child)": {
            textAlign: "center",
          },
        },
      },
    },
    "& .pagination": {
      display: "flex",
      listStyleType: "none",
      gap: "6px",
      alignItems: "center",
      paddingTop: "10px",
      //   marginTop: "81px",
      //   position: "absolute",
      //   bottom: "7px",
      //   right: "20px",
      justifyContent: "end",
      "& li": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& a": {
          textDecoration: "none",
          color: colors.white,
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 640,
          fontSize: "1rem",
          lineHeight: "24px",
        },
      },

      "& .icon": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        padding: "4.33px 4.92px",
        cursor: "pointer",
      },
      "& .active": {
        background: " #E5E5E5 !important",
        color: "#fff !important",
      },
    },
  },
}));
