import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useStyles } from "./users.style";

import { ChevDown } from "../../../../assets/svg/";
import {
  AdminDataTable,
  StaffDataTable,
  StudentDataTable,
} from "../../../../components/common/DataTables/Users";
import { toast, ToastContainer } from "react-toastify";

interface Props {}

const Users = (props: Props) => {
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const [filter, setFilter] = React.useState("");
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [addStudent, setIsAddStudent] = React.useState(false);
  const [addStaff, setIsAddStaff] = React.useState(false);
  const [addAdmin, setIsAddAdmin] = React.useState(false);
  const [isStudentTab, setIsStudentTab] = React.useState(true);
  const [isStaffTab, setIsStafftTab] = React.useState(false);
  const [isAdminTab, setIsAdminTab] = React.useState(false);
  const [students, setStudents] = React.useState([]);
  const [staff, setStaff] = React.useState([]);
  const [adminStaff, setAdminStaff] = React.useState([]);

  const [isLoadingStudents, setIsLoadingStudents] = React.useState(true);
  const [isLoadingStaff, setIsLoadingStaff] = React.useState(true);
  const [isLoadingAdmins, setIsLoadingAdmins] = React.useState(true);

  const handleIsAddNew = () => {
    if (isStudentTab) {
      setIsAddStudent(!addStudent);
    } else if (isStaffTab) {
      setIsAddStaff(!addStaff);
    } else if (isAdminTab) {
      setIsAddAdmin(!addAdmin);
    }
  };

  const handleStudentsTab = () => {
    setIsStudentTab(true);
    setIsAddStudent(false);
    setIsStafftTab(false);
    setIsAdminTab(false);
  };
  const handleStaffTab = () => {
    setIsStafftTab(true);
    setIsAddStaff(false);
    setIsStudentTab(false);
    setIsAdminTab(false);
  };
  const handleAdminTab = () => {
    setIsAdminTab(true);
    setIsAddStaff(false);
    setIsStudentTab(false);
    setIsAddStudent(false);
    setIsStafftTab(false);
  };

  const handleCloseAll = () => {
    setIsAddAdmin(false);
    setIsAddStudent(false);
    setIsAddStaff(false);
  };

  const fetchStudents = async () => {
    try {
      setIsLoadingStudents(true);
      const request = await fetch(`${BASE_URL}/admin/student/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      
      setIsLoadingStudents(false);
      if (response.length) {
        setStudents(response);
      }
    } catch (error) {
      setIsLoadingStudents(false);
      toast.error("Error fetching students", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };
  const fetchStaff = async () => {
    try {
      setIsLoadingStaff(true);
      const request = await fetch(`${BASE_URL}/admin/lecturer/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsLoadingStaff(false);
      if (response.length) {
        setStaff(response);
      }
    } catch (error) {
      setIsLoadingStaff(false);
      toast.error("Error fetching instructors");
      console.log(error);
    }
  };
  const fetchAdminStaff = async () => {
    try {
      setIsLoadingAdmins(true);
      const request = await fetch(`${BASE_URL}/admin/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsLoadingAdmins(false);
      if (response.length) {
        setAdminStaff(response);
      }
    } catch (error) {
      setIsLoadingAdmins(false);
      toast.error("Error fetching admin", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStudents();
    fetchStaff();
    fetchAdminStaff();
  }, []);

  return (
    <Layout>
      <ToastContainer />
      <div className={style.container}>
        <div className={style.header}>
          <div className="search">
            <input
              className="searchInput"
              placeholder="Search"
              value={filter}
              onChange={(e) =>
                globalFilter === ""
                  ? setFilter(e.target.value)
                  : setGlobalFilter("")
              }
            />
            <button
              className="btn"
              type="button"
              onClick={() => setGlobalFilter(filter)}
            >
              Search
            </button>
          </div>
          <h4 className="title">Users</h4>
          <div className="tabs">
            <div className="tabLinks">
              <div className={isStudentTab ? "active link" : "link"}>
                <Link to="" onClick={handleStudentsTab}>
                  Participants
                </Link>
              </div>
              <div className={isStaffTab ? "active link" : "link"}>
                <Link to="" onClick={handleStaffTab}>
                  Instructors
                </Link>
              </div>
              <div className={isAdminTab ? "active link" : "link"}>
                <Link to="" onClick={handleAdminTab}>
                  Admin
                </Link>
              </div>
            </div>
            <button className="btn" onClick={handleIsAddNew}>
              Add new
            </button>
          </div>
        </div>
        <div className={style.body}>
          <div className="table">
            {isStudentTab && (
              <StudentDataTable
                students={students}
                setFilter={globalFilter}
                isAddStudent={addStudent}
                handleCloseAll={handleCloseAll}
                fetchStudents={fetchStudents}
                isLoadingStudents={isLoadingStudents}
              />
            )}
            {isStaffTab && (
              <StaffDataTable
                staff={staff}
                setFilter={globalFilter}
                isAddStaff={addStaff}
                handleCloseAll={handleCloseAll}
                fetchStaff={fetchStaff} 
                isLoadingStaff={isLoadingStaff}
              />
            )}
            {isAdminTab && (
              <AdminDataTable
                staff={adminStaff}
                setFilter={globalFilter}
                isAddStaff={addAdmin}
                handleCloseAll={handleCloseAll}
                fetchAdminStaff={fetchAdminStaff}
                isLoadingAdmins={isLoadingAdmins}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

Users.propTypes = {};

export default Users;
