import { ArrowBack } from "@material-ui/icons";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Books } from "../../../assets/svg";
import Layout from "../../../components/Layout";
import { useAppSelector } from "../../store";
import { selectCourses } from "../../store/features/course/courseSlice";
import { useStyles } from "./essays.style";
import { Role } from "../../../shared";
import { selectRole } from "../../../store/features/auth/authSlice";
import CreateEssay from "./createEssay";
import LoadingSpinner from "../../../components/common/Loader";

interface Props {}

interface CustomizedState {
  title: any;
  content: any;
  date: any;
  course: any;
  student: any;
}

const ReadEssay = () => {
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const navigate = useNavigate();
  const courses = useAppSelector(selectCourses);
  const location = useLocation();
  const state = location.state as CustomizedState;
  const userRole = useAppSelector(selectRole);
  const [essays, setEssays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const courseEssay: any = essays?.find(
    (essay: any) =>
      essay.title === "Air Mindedness" || essay.course.id === state?.course?.id
  );

  console.log(courseEssay);

  const getStudentEssays = async () => {
    try {
      setIsLoading(true);
      const request = await fetch(`${BASE_URL}/essay/student`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      if (request.ok && response.length) {
        setEssays(response);
      }
      setIsLoading(false);
      console.log(response);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (userRole === Role.STUDENT) {
      getStudentEssays();
    }
  }, []);

  return (
    <Layout>
      {userRole === Role.STUDENT ? (
        <div className={style.container}>
          <div className={style.dash}>
            <div
              className={style.header}
              style={{
                backgroundColor: "#F1EDEC",
                padding: "1rem",
                display: "flex",
                justifyContent: "flex-start",
                gap: "30px",
              }}
            >
              <ArrowBack
                style={{ color: "#152E88", cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              <h5
                style={{
                  fontWeight: 100,
                  color: "#152E88",
                  fontSize: "1.5rem",
                }}
              >
                {state.course?.code} {state.course?.name}
              </h5>
              <span style={{ float: "right" }}></span>
            </div>

            <div className={style.main}>
              {isLoading ? (
                <LoadingSpinner />
              ) : courseEssay ? (
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h6>{courseEssay?.title}</h6>
                    {/* <span>
                      Submitted:
                      {format(new Date(courseEssay?.created_at), " d MMMM YYY")}
                    </span> */}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#F6F4F4",
                      minHeight: "100%",
                      padding: "8px",
                    }}
                  >
                    <p>{courseEssay?.content}</p>
                  </div>
                </div>
              ) : (
                <CreateEssay
                  course={state.course}
                  getStudentEssays={getStudentEssays}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={style.container}>
          <div className={style.dash}>
            <div
              className={style.header}
              style={{
                backgroundColor: "#F1EDEC",
                padding: "1rem",
                display: "flex",
                justifyContent: "flex-start",
                gap: "30px",
              }}
            >
              <ArrowBack
                style={{ color: "#152E88", cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              <h5
                style={{
                  fontWeight: 100,
                  color: "#152E88",
                  fontSize: "1.5rem",
                }}
              >
                {state.student?.rank} {state.student?.name}
              </h5>
              <span style={{ float: "right" }}></span>
            </div>

            <div className={style.main}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h6>{state.title}</h6>
                {/* <span>
                  Submitted:{format(new Date(state.date), " d MMMM YYY")}
                </span> */}
              </div>
              <div
                style={{
                  backgroundColor: "#F6F4F4",
                  minHeight: "100%",
                  padding: "8px",
                }}
              >
                <p>{state.content}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

ReadEssay.propTypes = {};

export default ReadEssay;
