import { createAsyncThunk } from "@reduxjs/toolkit";
import { livestreamService } from "../../../services/livestream/livestreamService";
import { LivestreamAction } from "./livestreamType";

export const createLivestream = createAsyncThunk(
  LivestreamAction.CREATELIVESTREAM,
  async (user?: string) => {
    const response = await livestreamService.createLivestream(user);
    return response.data;
  }
);

export const fetchStudentLiveStream = createAsyncThunk(
  LivestreamAction.FETCHLIVESTREAM,
  async () => {
    const response = await livestreamService.getStudentLivestream();
    return response.data;
  }
);
export const fetchLecturerLiveStream = createAsyncThunk(
  LivestreamAction.FETCHLIVESTREAM,
  async () => {
    const response = await livestreamService.getLecturerLivestream();
    return response.data;
  }
);

export const deleteLiveStream = createAsyncThunk(
  LivestreamAction.DELETELIVESTREAM,
  async (liveStreamId: number) => {
    const response = await livestreamService.deleteLivestream(liveStreamId);
    return response.data;
  }
);
