import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./website.style";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import Confirm from "../../../../components/common/Confirm";
import { Divider } from "@material-ui/core";
import { Spinner } from "../../../../components/common/Spinner";
import axios from "axios";
import Slider from "./Slider";

interface Props {}

const Header = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openUpdateWebsite, setOpenUpdateWebsite] = useState(false);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [websiteInfo, setWebsiteInfo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchWebsiteInfo = async () => {
    try {
      const request = await fetch(`${BASE_URL}/logo/info`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      console.log(response);
      if (request.ok) {
        setWebsiteInfo(response);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching info");
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateWebsiteInfo = async () => {
    try {
      setIsSubmitting(true);
      const body = { title: title, logo: imageUrl };
      const request = await fetch(`${BASE_URL}/logo/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const response = await request.json();
      setIsSubmitting(false);
      console.log(response);
      if (request.ok) {
        fetchWebsiteInfo();
        toast.success("Website updated successfully");
        setOpenUpdateWebsite(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      toast.error("Error fetching info");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWebsiteInfo();
  }, []);

  useEffect(() => {
    setImageUrl(websiteInfo?.logo || "");
    setImage(websiteInfo?.logo || "");
    setTitle(websiteInfo?.title || "");
  }, [websiteInfo]);

  const handleImageChange = async (event: any) => {
    setIsUploading(true);
    try {
      event.preventDefault();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Brearer " + localStorage.getItem("token"),
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}/upload/add`,
          { file: event.target.files[0] },
          config
        )
        .then((res) => {
          setIsUploading(false);
          if (res.status === 200) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setImageUrl(res.data.url);
          }
        })
        .catch((err) => {
          setIsUploading(false);
          console.log(err);
        });
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this event?"
        action=""
      />
      <Modal
        isOpen={openUpdateWebsite}
        size="lg"
        toggle={() => setOpenUpdateWebsite(false)}
      >
        <ModalHeader>
          <h6>Update Website</h6>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Input
              onChange={(e: any) => setTitle(e.target.value)}
              value={title}
              type="text"
              name="name"
              placeholder="Name"
            />

            <Input onChange={handleImageChange} type="file" name="file" />
            {isUploading ? (
              <Spinner size="2" />
            ) : (
              image && <img src={image} alt="logo" height="100" width="100" />
            )}

            <Button
              style={{ background: "#152E88" }}
              onClick={updateWebsiteInfo}
              disabled={isSubmitting || isUploading}
            >
              {isSubmitting ? "loading..." : "Save"}
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <div className="">
        <div style={styles.card}>
          {
           isLoading ? <h5 style={{color: "#152E88", fontWeight: 400, padding: "1rem"}}>Loading site header info...</h5> :
           (
            <>
          <div style={styles.cardHeader}>
            <h6 style={styles.cardTitle}>Website information</h6>
            <Button onClick={() => setOpenUpdateWebsite(true)}>Update</Button>
          </div>
          <Divider style={{ color: "#E0E0E0" }} />
          <div style={styles.cardContent}>
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              <p
                style={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#003262",
                }}
              >
                Website title
              </p>
              :
              <p
                style={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#003262",
                }}
              >
                {websiteInfo?.title || ""}
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              <p
                style={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#003262",
                }}
              >
                Logo
              </p>
              {websiteInfo?.logo && (
                <img
                  height="100"
                  width="100"
                  alt="logo"
                  src={websiteInfo.logo}
                />
              )}
            </div>
          </div>
          </>
          )
          }
        </div>

        <Slider />
      </div>
    </div>
  );
};

const styles = {
  container: {
    margin: "20px 30px",
  },
  card: {
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    marginBottom: "12px",
  },
  cardHeader: {
    padding: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardTitle: {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "22px",
    color: "#003262",
  },
  cardContent: {
    padding: "16px 8px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
};

Header.propTypes = {};

export default Header;
