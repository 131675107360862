/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";
import { useAppSelector } from "../store";
import { selectWebsite } from "../store/features/website/websiteSlice";

// core components
const footerLinks = {
  textDecoration: "none",
};

function Footer() {
  const website = useAppSelector(selectWebsite);

  return (
    <>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <img width="55px" height="50px" src={website?.logo} />
                </li>
                <li>
                  <a style={footerLinks} href="/" className="mr-1">
                    Air Warfare Centre
                  </a>
                </li>
                <li>
                  <a
                    style={footerLinks}
                    href="https://airforce.mil.ng"
                    target="_blank"
                    className="mr-1"
                  >
                    NAF Site
                  </a>
                </li>
                <li>
                  <a style={footerLinks} href="/#contact-us">
                    Contact Us
                  </a>
                </li>

                <li style={{ float: "right", marginTop: "1.5rem" }}>
                  <a href="#" style={footerLinks}>
                    © {new Date().getFullYear()}
                    , made for the <i className="fa fa-heart heart" /> love of
                    Air and Country.
                  </a>
                </li>
              </ul>
            </nav>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
