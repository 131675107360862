import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100% - 30px)",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
  },
  main: {
    marginTop: "1.5rem",
    "& .pending": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 200,
        fontSize: "0.9375rem",
        lineHeight: "22px",
        color: colors.black,
      },
      "& .left": {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          marginTop: "1rem",
          flexDirection: "column",
        },
        "&  h6": {
          marginRight: "1.125rem",
          [theme.breakpoints.down("xs")]: {
            marginBottom: ".5rem",
            marginRight: 0,
          },
        },
      },
    },
    "& .MuiButton-root": {
      background: colors.blue,
      border: "1px solid rgba(0, 0, 0, 0.7)",
      borderRadius: "8px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "0.9rem",

      lineHeight: "22px",
      /* identical to box height */

      color: colors.white,
    },
    "& .payList": { margin: "1.5625rem 0" },
    "& .payList > div.item": {
      background: colors.gray,
      margin: "0 -1rem .5rem -1rem",
      // margin: "0 -1rem",
      padding: "1rem 1.4375rem ",
      display: "grid",
      gridTemplateColumns: "3fr 2fr 0.5fr",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "0.9375rem",

        lineHeight: "22px",
        /* identical to box height */

        color: colors.black,
      },
    },
    "& .transactions": {
      marginTop: "2.5rem",
      "& h5": { marginBottom: "1.5625rem" },
    },
    "& .transactionList": {
      background: colors.gray,
      margin: "0 -1rem .5rem -1rem",
      padding: "1px",
    },
    "& .transactionList > div": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",

      padding: "5px",
      background: colors.white,
      margin: ".3rem",
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",

        lineHeight: "22px",
        /* identical to box height */

        color: colors.black,
      },
    },
  },
}));
