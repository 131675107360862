import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    overflowY: "auto",
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    "& a": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2px",
      borderRadius: "5px",
      textDecoration: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "20px",
      /* identical to box height */

      color: colors.blue,
      background: colors.gray,
      "& svg": { marginRight: ".2rem" },
    },
  },
  main: {
    marginTop: "1rem",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 180px)",
      overflowY: "auto",
    },
    "& .video:not(:last-child)": {
      marginBottom: ".5rem",
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "24px",
      /* identical to box height */
      color: colors.black,
    },
    "& .video": {
      display: "flex",
      alignItems: "end",

      "& .videoBanner": {
        position: "relative",
        "& img": {
          maxHeight: "80px",
          maxWidth: "204.33px",
        },
        "& .playIcon": {
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, 50%)",

          "& circle": {
            fill: "rgba(255,255,255,0.4)",
          },
          "& path": {
            fill: "#99CCFF",
          },
        },
      },

      "& .details": {
        marginLeft: ".3rem",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        "& .desc span:not(:last-child)": {
          marginBottom: ".2rem",
        },

        "& .desc span": {
          display: "block",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",

          color: colors.black,
        },
        "& .desc .title": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "1rem",
          lineHeight: "21px",

          color: colors.black,
        },
      },
    },
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modals": {
      margin: "auto",
      width: "31.25rem",
      maxWidth: "100%",
      background: colors.white,
      position: "relative",
      minHeight: "50%",
      transition: ".5s",
      [theme.breakpoints.down("sm")]: { width: "90%" },
    },
    "& .cancelBox": {
      textDecoration: "none",
      position: "absolute",
      padding: "1.4375rem",
      right: 0,
      top: 0,
      color: colors.white,
      background: colors.blue,
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "3.75rem auto",
      // margin: "0 auto",
      width: "70%",
      [theme.breakpoints.down("sm")]: { width: "90%" },
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "30px",
        lineHeight: "45px",
        color: colors.black,
        marginTop: "1.5rem",
        marginBottom: "2.5rem",
      },
      "& form": { width: "100%" },
      "& div": {
        width: "100%",

        marginBottom: ".5rem",
      },
      "& .fileUpload": {
        display: "flex",
        flexDirection: "column",
        gap: "4px",

        "& button": {
          outline: "none",
          border: "none",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          color: colors.blue,
        },
      },
      "& .fileUpload span": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        // lineHeight: "33px",
        color: colors.black,
        padding: ".5rem",
        marginBottom: ".5rem",
      },
      "& .file ": {
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        // lineHeight: "33px",
        color: "#C4C4C4",
        padding: ".5rem",
      },
      "& .MuiInputBase-input ": {
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        // lineHeight: "33px",
        color: "#C4C4C4",
        padding: ".5rem",
      },
      "& .MuiButton-root": {
        background: colors.blue,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "0.9rem",

        lineHeight: "22px",
        /* identical to box height */

        color: colors.white,
      },
    },
  },
  modalPlayContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modals": {
      margin: "auto",
      maxWidth: "884px",
      width: "90%",
      background: colors.white,
      position: "relative",
      maxHeight: "534px",

      transition: ".5s",
      [theme.breakpoints.down("xs")]: { width: "90%", height: "auto" },
    },
    "& .cancelBox": {
      textDecoration: "none",
      position: "absolute",
      padding: "1.4375rem",
      right: 0,
      top: 0,
      color: colors.white,
      background: colors.blue,
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",

      padding: "18px 37px",
      // margin: "0 auto",
      // width: "70%",
      [theme.breakpoints.down("xs")]: {
        padding: "18px 5px",
      },
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "30px",
        color: colors.black,
        [theme.breakpoints.down("xs")]: {
          fontSize: "16px",
          lineHeight: "26px",
        },
      },
      "& .videoPlayer": {
        background: "#f2f2f2",
        marginTop: "25px",
        maxWidth: "810px",
        width: "100%",

        height: "434px",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
          height: "100%",
        },

        "& video": {
          width: "100%",
          height: "100%",
          objectFit: "fill",
          "&::-webkit-media-controls": {
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
        },
        "& .bigControls": {
          position: "absolute",
          right: "42%",
          top: "35%",
          [theme.breakpoints.only("sm")]: { display: "none" },
        },
        "& .controls": {
          paddingLeft: "37px",
          paddingRight: "37px",
          position: "absolute",
          bottom: "16px",
          left: 0,
          right: 0,
          width: "100%",
          [theme.breakpoints.only("sm")]: { display: "none" },

          [theme.breakpoints.down("xs")]: {
            paddingLeft: "5px",
            paddingRight: "5px",
            bottom: "0px",
            opacity: 0.5,
            "&:hover": {
              opacity: 1,
            },
            "&:blur": {
              opacity: 0.5,
            },
          },
          "& .progressBar": {
            width: "100%",
            padding: "0 27px",
            [theme.breakpoints.down("xs")]: {
              padding: "0 5px",
            },
            "& .progress": {
              height: "8px",
              width: "100%",
              backgroundColor: colors.white,
              cursor: "pointer",
            },
            "& .progressStatus": {
              backgroundColor: colors.blue,
              height: "8px",
              display: "block",
              cursor: "pointer",
            },
          },

          "& .playControls": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "11px 27px",
            marginLeft: "40%",
            [theme.breakpoints.down("sm")]: {
              marginLeft: 0,
            },
            [theme.breakpoints.down("xs")]: {
              flexDirection: "column",
              gap: "5px",
            },
            "& .volumeSlider": {},

            "& .volume": {
              appearance: "none",
              width: "100px",
              height: "8px",
              background: colors.white,

              outline: "none",

              transition: "opacity .2s",
              borderRadius: "6px",

              "&::-webkit-slider-thumb": {
                appearance: "none",
                width: "10px",
                height: "10px",
                background: colors.white,
                cursor: "pointer",
                borderRadius: "50%",
                border: "1px solid " + colors.blue,
              },
              "&::-moz-range-thumb": {
                width: "10px",
                height: "10px",
                background: colors.white,
                cursor: "pointer",
                borderRadius: "50%",
                border: "1px solid " + colors.blue,
              },
            },
            "& .right": {
              display: "flex",
              gap: "15px",
              alignItems: "center",
            },
          },
        },
      },
    },
  },
  videoPlayHeader: {
    "& h5.title": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "24px",
      color: colors.black,
      display: "flex",
      alignItems: "center",
      gap: "6px",

      "& .icon": {
        // padding: "9.25px 12.99px",
        background: "#E9E9E9",
        borderRadius: "5px",
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  videoContainer: {
    background: "#f2f2f2",
    "& .videoPlayer": {
      marginTop: "25px",

      width: "100%",

      height: "100%",
      position: "relative",

      "& video": {
        width: "100%",
        height: "100%",
        // objectFit: "fill",
        "&::-webkit-media-controls": {
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
      },
      "& .bigControls": {
        position: "absolute",
        right: "45%",
        top: "35%",
        [theme.breakpoints.only("sm")]: {
          display: "none",
        },
      },
      "& .controls": {
        paddingLeft: "37px",
        paddingRight: "37px",
        position: "absolute",
        bottom: "16px",
        left: 0,
        right: 0,
        width: "100%",
        [theme.breakpoints.only("sm")]: {
          display: "none",
        },
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "5px",
          paddingRight: "5px",
          bottom: "0px",
          opacity: 0.5,
          "&:hover": {
            opacity: 1,
          },
          "&:blur": {
            opacity: 0.5,
          },
        },
        "& .progressBar": {
          width: "100%",
          padding: "0 27px",
          [theme.breakpoints.down("xs")]: {
            padding: "0 5px",
          },
          "& .progress": {
            height: "8px",
            width: "100%",
            backgroundColor: colors.white,
            cursor: "pointer",
          },
          "& .progressStatus": {
            backgroundColor: colors.blue,
            height: "8px",
            display: "block",
            cursor: "pointer",
          },
        },

        "& .playControls": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "11px 27px",
          marginLeft: "30%",
          [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
          },
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            gap: "5px",
          },
          "& .volumeSlider": {},

          "& .volume": {
            appearance: "none",
            width: "100px",
            height: "8px",
            background: colors.white,

            outline: "none",

            transition: "opacity .2s",
            borderRadius: "6px",

            "&::-webkit-slider-thumb": {
              appearance: "none",
              width: "10px",
              height: "10px",
              background: colors.white,
              cursor: "pointer",
              borderRadius: "50%",
              border: "1px solid " + colors.blue,
            },
            "&::-moz-range-thumb": {
              width: "10px",
              height: "10px",
              background: colors.white,
              cursor: "pointer",
              borderRadius: "50%",
              border: "1px solid " + colors.blue,
            },
          },
          "& .right": {
            display: "flex",
            gap: "15px",
            alignItems: "center",
          },
        },
      },
    },
  },
}));
