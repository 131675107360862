export const colors = {
  darkBlue: "#003262",
  lightBlack: "#333333",
  blue: "#152E88",
  light: "#E5E5E5",
  lightBlue: "#8ACBE8",
  dark: "#000000CC",
  black: "#000000",
  gray: "#F2F2F2",
  white: "#fff",
  yellow: "#C4820E",
  grayShade: "#F8F8F8",
};
