import React from "react";
import Live from "./Live";

interface Props {}
interface IFormInputs {
  title: string;
  desc: string;
}

const LiveClasses = () => {
  return (
    <>
      <Live />
    </>
  );
};

LiveClasses.propTypes = {};

export default LiveClasses;
