import { createSlice } from "@reduxjs/toolkit";
import { fetchUser, login } from "../auth/authThunk";

const initialState = {
    isError: false,
    erroMessage: null,
    statusCode: null
}

export const errortSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        clearError: (state) => {
            state.erroMessage = null;
            state.isError = false
            state.statusCode = null

            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.rejected, (state, action) => {
                state.erroMessage = action.error.message as any
                state.statusCode = action.error.code as any
                state.isError = true
            })
            .addCase(fetchUser.rejected, (state, action) => {
                // if (action.error.code as any === "ERR_BAD_REQUEST") {
                //     state.erroMessage = "Your session has expired. Please login again" as any
                // } else {
                //     state.erroMessage = action.error.message as any
                // }
                // state.erroMessage = action.error.message as any
                state.statusCode = action.error.code as any
                state.isError = true
            })
    }
})

export const { clearError } = errortSlice.actions;

export const selectError = (state: any) => state.error.isError
export const selectIsErrorMsg = (state: any) => state.error.erroMessage
export const selectIsErrroCode = (state: any) => state.error.statusCode

export default errortSlice.reducer
