import React, { useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./result.style";
import Layout from "../../../components/Layout";
import { Grid, TextField, Button, Box } from "@material-ui/core";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { Download, DotRed, SvgResult } from "../../../assets/svg";
import { fetchCourses } from "../../../store/features/course/courseThunk";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCourses } from "../../../store/features/course/courseSlice";
import { selectRole } from "../../../store/features/auth/authSlice";
import { ArrowRight } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/common/Loader";

interface IFormInputs {
  email: string;
  session: string;
}
const schema = yup.object().shape({
  email: yup.string().email().required(),
  session: yup.string().required(),
});

interface Props {}

const Result = (props: Props) => {
  const style = useStyles();
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = (
    data: IFormInputs
  ) => {};

  const dispatch = useAppDispatch();
  const courses = useAppSelector(selectCourses);
  const userRole = useAppSelector(selectRole);
  const [isLoading, setIsLoading] = useState(true);

  console.log("here", courses);

  const getStudentCourse = async () => {
    await dispatch(fetchCourses(userRole));
    await setIsLoading(false);
  };

  React.useEffect(() => {
    getStudentCourse();
  }, []);
  return (
    <Layout>
      <div className={style.container}>
        <div className={style.dash}>
          <h5
            style={{
              color: "#152E88",
              fontSize: "1.5rem",
              fontWeight: 100,
              backgroundColor: "#F1EDEC",
              padding: "1rem",
            }}
          >
            Result{" "}
            <span style={{ float: "right" }}>
              <SvgResult />
            </span>
          </h5>
          <div className={style.main}>
            <div className="lists">
              {isLoading && <LoadingSpinner/>}              
              {courses?.map((item: any) => (
                <div
                  key={item.id}
                  className="result"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/dashboard/view-results/${item.id}`, {
                      replace: true,
                      state: {
                        course_id: item.id,
                        course_name: item.name,
                        course_code: item.code,
                        test_score: parseInt(item.cat) || 0,
                        exam_score: parseInt(item.exam) || 0,
                        attendance_score: parseInt(item.attendance) || 0
                      },
                    })
                  }
                >
                  <span>{item.code + " - " + item.name}</span>
                  <div>
                    <ArrowRight />
                  </div>
                </div>
              ))}
              {courses.length == 0 && <h5>No registered course(s) found.</h5>}
            </div>
            {/* <div className="form">
              <span className="info">Search for previous results</span>
              <form onSubmit={handleSubmit(formSubmitHandler)}>
                <div className="form-control row">
                  <div>
                    <Controller
                      name="session"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          // label="Level"
                          SelectProps={{
                            native: true,
                          }}
                          variant="filled"
                          placeholder="Session"
                          size="small"
                          InputProps={{ disableUnderline: true }}
                        >
                          {sessions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      )}
                    />
                  </div>
                  <div>
                    <Button color="primary" variant="contained" type="submit">
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

Result.propTypes = {};

export default Result;
