import React from "react";
import { Routes, Route } from "react-router-dom";
import Unlock from "./sections/unlock";
import Utme from "./sections/utme";
import UtmeExam from "./sections/utmeExam";
import UtmeProfile from "./sections/utmeProfile";
import UtmeResult from "./sections/utmeResult";

const CBT = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/cbt" element={<Unlock />} /> */}
        {/* <Route path="/cbt/utme" element={<Utme />} /> */}
        <Route path="/cbt/profile/:quizId" element={<UtmeProfile />} />
        <Route path="/cbt/exam/:quizId" element={<UtmeExam />} />
        <Route path="/cbt/result" element={<UtmeResult />} />
      </Routes>
    </>
  );
};

export default CBT;
