import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useStyles } from "./website.style";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import LoadingSpinner from "../../../../components/common/Loader";
import { toast, ToastContainer } from "react-toastify";
import Confirm from "../../../../components/common/Confirm";
import { Divider } from "@material-ui/core";
import TextEditor from "../../../../components/common/TextEditor";

interface Props {}

const Contact = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingContact, setIsSavingContact] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [contactInfo, setContactInfo] = useState<any>(null);
  const [inputAddress, setInputAddress] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [inputWorkingHours, setInputWorkingHours] = useState("");

   

  const fetchInformation = async () => {
    try {
      const request = await fetch(`${BASE_URL}/contact/info`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      if (request.ok) {
        setContactInfo(response);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching contact info");
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInformation();
  }, []);

  useEffect(() => {
    setInputAddress(contactInfo?.address);
    setInputEmail(contactInfo?.email);
    setInputPhone(contactInfo?.phone);
    setInputWorkingHours(contactInfo?.working_hours);
  }, [contactInfo]);

  const handleSubmit = async () => {
    try {
      if (!inputAddress || !inputEmail) {
        return toast.error("Please fill all fields");
      }

      const body = {
        address: inputAddress,
        email: inputEmail,
        phone: inputPhone,
        working_hours: inputWorkingHours,
      };
      setIsSavingContact(true);
      const request = await fetch(`${BASE_URL}/contact/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const response = await request.json();
      setIsSavingContact(false);
      console.log(response);
      if (request.ok) {
        toast.success("Contact updated successfully");
        fetchInformation();
        setOpenCreateContact(false);
      }
    } catch (error) {
      setIsSavingContact(false);
      console.log(error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this event?"
        action=""
      />
      <Modal
        isOpen={openCreateContact}
        size="lg"
        toggle={() => setOpenCreateContact(false)}
      >
        <ModalHeader>
          <h6>Update Contact</h6>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <TextEditor
              type="textarea"
              name="address"
              placeholder="enter address"
              id="address"
              onChange={(content: any) => setInputAddress(content)}
              value={inputAddress}
            />

            <Input
              onChange={(e: any) => setInputEmail(e.target.value)}
              value={inputEmail}
              type="text"
              name="email"
              placeholder="user@email.com"
            />
            <Input
              onChange={(e: any) => setInputPhone(e.target.value)}
              value={inputPhone}
              type="text"
              name="phone"
              placeholder="09023184328"
            />
            <Input
              onChange={(e: any) => setInputWorkingHours(e.target.value)}
              value={inputWorkingHours}
              type="text"
              name="title"
              placeholder="18:39 - 19:00"
            />

            <Button
              style={{ background: "#152E88" }}
              onClick={handleSubmit}
              disabled={isSavingContact}
            >
              {isSavingContact ? "saving..." : "Submit"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className="">
      {
          isLoading ? <h5 style={{color: "#152E88", fontWeight: 400, padding: "1rem"}}>Loading contact info...</h5> :
          (
          <>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <Button onClick={() => setOpenCreateContact(true)}>Update</Button>
        </div>

        {contactInfo && (
          <React.Fragment>
            <div style={styles.card}>
              <div style={styles.cardHeader}>
                <h6 style={styles.cardTitle}>Address</h6>
              </div>
              <Divider style={{ color: "#E0E0E0" }} />
              <div
                style={styles.cardContent}
                dangerouslySetInnerHTML={{ __html: contactInfo?.address }}
              />
            </div>
            <div style={styles.card}>
              <div style={styles.cardHeader}>
                <h6 style={styles.cardTitle}>Email</h6>
              </div>
              <Divider style={{ color: "#E0E0E0" }} />
              <div style={styles.cardContent}>{contactInfo?.email}</div>
            </div>
            <div style={styles.card}>
              <div style={styles.cardHeader}>
                <h6 style={styles.cardTitle}>Phone number</h6>
              </div>
              <Divider style={{ color: "#E0E0E0" }} />
              <div style={styles.cardContent}>{contactInfo?.phone}</div>
            </div>
            <div style={styles.card}>
              <div style={styles.cardHeader}>
                <h6 style={styles.cardTitle}>Working hours</h6>
              </div>
              <Divider style={{ color: "#E0E0E0" }} />
              <div style={styles.cardContent}>{contactInfo?.working_hours}</div>
            </div>
          </React.Fragment>
        )}

        </>
        )
      }

      </div>
    </div>
  );
};

const styles = {
  container: {
    margin: "20px 30px",
  },
  card: {
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    marginBottom: "12px",
  },
  cardHeader: {
    padding: "8px",
  },
  cardTitle: {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "22px",
    color: "#003262",
  },
  cardContent: {
    padding: "16px 8px",
  },
};

Contact.propTypes = {};

export default Contact;
