import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import { Button } from "@material-ui/core";

interface Props {
  open: any;
  setOpen: any;
  title: string;
  action: any;
  message: string;
}

const Confirm = (props: Props) => {
  const toggle = () => props.setOpen(!props.open);
  return (
    <Modal isOpen={props.open} size="sm" centered toggle={toggle}>
      <div style={{ padding: "10px" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <span
            onClick={toggle}
            style={{ color: "#054A8C", fontSize: "16px", cursor: "pointer" }}
          >
            x
          </span>
        </div>
        <div style={{ textAlign: "center", color: "red" }}>
          <span
            style={{
              fontFamily: "Open Sans",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "27px",
            }}
          >
            {props.title}
          </span>
        </div>
        <div
          style={{ textAlign: "center", color: "#054A8C", margin: "15px 0" }}
        >
          <span>{props.message}</span>
        </div>
        <div style={{ display: "flex", gap: 4 }}>
          <Button
            onClick={() => {
              props.action();
              toggle();
            }}
            style={{
              background: "#054A8C",
              borderRadius: "9px",
              color: "white",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={toggle}
            style={{
              background: "#EBEEF2",
              border: "1px solid #054A8C",
              borderRadius: "9px",
              cursor: "pointer",
              color: "#054A8C",
            }}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};

Confirm.propTypes = {};

export default Confirm;
