import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { colors } from "../../styles/color";
import facebookIcon from "../../assets/icons/facebook.svg";
import twitterIcon from "../../assets/icons/twitter.svg";
import linkedinIcon from "../../assets/icons/linkedin.svg";
import locationIcon from "../../assets/icons/location.svg";
import callIcon from "../../assets/icons/call.svg";
import emailIcon from "../../assets/icons/email.svg";
import listIcon from "../../assets/icons/list.svg";
import { Link } from "react-router-dom";

interface Props {}

const Footer = (props: Props) => {
  const style = useStyles();
  return (
    <footer className={style.container}>
      {/* Contact Info */}
      <img
        src={require("../../../src/assets/icons/NAFLogo.png")}
        alt="naf logo"
        className={style.Logo}
      />
      <Grid container spacing={2} className={style.gridContainer}>
        {/* <Grid item xs={12} sm={4}>
          <Typography variant="h6" className={style.title}>
            Follow
          </Typography>
          <Divider />
          <ul>
            <li>
              <img src={facebookIcon} alt="facebook" /> <span>Facebook</span>
            </li>
            <li>
              <img src={twitterIcon} alt="twitter" /> <span>Twitter</span>
            </li>
            <li>
              <img src={linkedinIcon} alt="linkedin" /> <span>LinkedIn</span>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" className={style.title}>
            Explore
          </Typography>
          <Divider />
          <div className={style.middleGrid}>
            <ul>
              <li>
                <span>Air Warfare</span>
              </li>
              <li>
                <span>Events</span>
              </li>
              <li>
                <span>Publications</span>
              </li>
              <li>
                <span>Education</span>
              </li>
            </ul>

            <ul>
              <li>
                <span>About</span>
              </li>
              <li>
                <span>News</span>
              </li>
              <li>
                <span>Contact</span>
              </li>
              <li>
                <span>Contribute</span>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" className={style.title}>
            Participate
          </Typography>
          <Divider />
          <div className={style.middleGrid}>
            <ul>
              <li>
                <span>Login</span>
              </li>
              <li>
                <span>Events</span>
              </li>
              <li>
                <span>Publications</span>
              </li>
              <li>
                <span>Education</span>
              </li>
            </ul>
          </div>
        </Grid> */}
      </Grid>
      <span className={style.copyright}>
        &copy;2022 Nigerian Air Force Air Warfare Centre, Abuja
      </span>
    </footer>
  );
};

const useStyles = makeStyles({
  container: {
    bottom: "0",
    marginTop: "50px",
    backgroundColor: "#071923",
    color: "white",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    paddingInline: "50px",

    "& hr": {
      backgroundColor: "#e5e5e563",
      marginBlock: "20px",
    },

    "& ul": {
      listStyle: "none",
      padding: "0",
      marginTop: "10px",

      "& li": {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        "& img": {
          marginRight: "10px",
        },
      },
    },
  },
  Logo: {
    alignSelf: "center",
    height: "100px",
    width: "100px",
    position: "relative",
    top: "-40px",
  },
  gridContainer: {
    marginTop: "0.5rem",
    display: "flex",
    justifyContent: "center",
  },
  middleGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& ul": {
      width: "50%",
    },
  },
  title: {},
  copyright: {
    marginBlock: "20px",
  },
});

Footer.propTypes = {};

export default Footer;
