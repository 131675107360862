import React, { FormEvent } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { useStyles } from "./admission.style";

import { colors } from "../../../styles/color";

import { MenuOutlined } from "@material-ui/icons";
import Header from "../../../components/Header";

interface Props {}

const AdmissionStatus = (props: Props) => {
  const style = useStyles();
  const [jambNumber, setJambNumber] = React.useState("");
  const handleForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(jambNumber);
  };
  return (
    <div className={style.container}>
      <Header />
      <div className="headerTitle">
        <h2>Admission Status</h2>
      </div>
      <div className="wrapper">
        <div className="status">
          <div>
            <h3>Congratulations Mustapha!</h3>
            <p>You are eligible for the Admission Test</p>
            <button>Make payment</button>
          </div>
        </div>
      </div>
    </div>
  );
};

AdmissionStatus.propTypes = {};

export default AdmissionStatus;
