import React, { useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./sendAssignments.style";
import Layout from "../../../components/Layout";
import { Grid, TextField, Button, TextareaAutosize } from "@material-ui/core";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { ChevronLeft, Plus } from "../../../assets/svg/";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCourses } from "../../store/features/course/courseSlice";
import { fetchCourses } from "../../store/features/course/courseThunk";
import { selectRole } from "../../store/features/auth/authSlice";
import { Spinner } from "../../../components/common/Spinner";
import { createAssignment } from "../../../store/features/assignment/assignmentThunk";
import { toast, ToastContainer } from "react-toastify";
import TextEditor from "../../../components/common/TextEditor";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface IFormInputs {
  title: string;
  course_id: number;
  due_date: string;
  obtainablescore: string;

  attachment: any;
  numQ: any;
}

interface Props {}

const SendAssignments = (props: Props) => {
  const style = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const [number, setNumber] = useState(0);
  const [title, setTitle] = useState("");
  const [course, setCourse] = useState(0);
  const [marks, setMarks] = useState(0);
  const [due_date, setDueDate] = useState("");
  const [attachment, setAttachment] = useState("");
  const [time_allowed, setTimeAllowed] = useState(0);

  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectRole);
  const coursess = useAppSelector(selectCourses);

  const [upload, setUpload] = useState(false);
  const [url, setUrl] = React.useState<any | null>(null);
  const [selectedFile, setSelectedFile] = React.useState();

  const navigate = useNavigate();

  const changeHandler = (e: any) => {
    setSelectedFile(e.target.files[0]);
    setUpload(true);
    console.log(selectedFile);
  };

  const handleUpload = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    setIsLoading(true);
    formData.append("file", selectedFile!);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Brearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/upload/add`,
        { file: selectedFile },
        config
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Attachment has been uploaded");
          setIsLoading(false);
          setUpload(false);
          setAttachment(res.data.url);
        } else {
          toast.success(res.data.error);
          setIsLoading(false);
          setUpload(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.success("Something went wrong");
        setIsLoading(false);
        setUpload(false);
      });
  };

  const formSubmitHandler = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const questions: any = [];

    Array.from(e.target.elements).map((el: any) => {
      if (el.name === "questions") {
        questions.push(el.value);
      }
    });

    const data = {
      title: title,
      course_id: course,
      obtainablescore: marks,
      due_date: due_date,
      attachment: attachment,
      questions: questions,
      time_allowed: time_allowed,
    };

    console.log("body", data);
    await dispatch(createAssignment(data))
      .then((res: any) => {
        setIsLoading(false);
        toast.success("Assignment has been created successfully");
        navigate(-1);
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error("Something went wrong please try again");
        console.log(err);
      });
  };

  const handleIncrementQuestions = (e: any) => {
    e.preventDefault();
    setNumber((prev) => prev + 1);
  };

  const courses = [{ value: "0", label: "Course" }];

  coursess.map((course: any) =>
    courses.push({ value: course.id, label: course.name })
  );

  const getCourses = async () => {
    await dispatch(fetchCourses(userRole));
  };

  React.useEffect(() => {
    getCourses();
  }, []);

  return (
    <Layout>
      <ToastContainer />

      {/* Main Container */}
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.header}>
            <h5
              style={{
                color: "#152E88",
                fontSize: "1.5rem",
                fontWeight: 100,
                backgroundColor: "#F1EDEC",
                padding: "1rem",
              }}
            >
              Create Assignment
            </h5>

            <Link to="/dashboard/assignments/">
              <ChevronLeft /> Back
            </Link>
          </div>
          <div className={style.main}>
            <div className={style.form}>
              <form onSubmit={formSubmitHandler}>
                <div className="form-group">
                  <div>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      onChange={(e: any) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div>
                    <select
                      name="course_id"
                      onChange={(e: any) => setCourse(e.target.value)}
                    >
                      {courses.map((course) => (
                        <option key={course.value} value={course.value}>
                          {course.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="obtainablescore"
                      placeholder="Obtainable score"
                      onChange={(e: any) => setMarks(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="due_date"
                      placeholder="Due date"
                      className="date-picker"
                      onChange={(e: any) => setDueDate(e.target.value)}
                      onFocus={(e) => (e.target.type = "datetime-local")}
                    />
                  </div>
                </div>
                <div className="divider"></div>
                <div className="questions">
                  <h5>
                    Questions
                    <button
                      onClick={handleIncrementQuestions}
                      style={{
                        float: "right",
                        border: "none",
                        padding: ".25rem 16px",
                        borderRadius: "5px",
                        marginInline: "4px",
                      }}
                    >
                      <Plus />
                    </button>
                  </h5>
                  <div className="form-group">
                    <TextEditor name="questions" />
                  </div>

                  <div id="addNew">
                    {number > 0 &&
                      [...Array(number)].map((x: any, i: any) => (
                        <div className="form-group">
                          <TextEditor name="questions" />
                        </div>
                      ))}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Attachment url (optional)"
                      name="attachment"
                      value={attachment}
                      onChange={(e: any) => setAttachment(e.target.value)}
                    />
                  </div>
                  <div className="input fileUpload ">
                    <span> Or choose from your device</span>

                    <input
                      type="file"
                      name="file"
                      className="file"
                      accept=".pdf,.doc,.xsl"
                      form="uploadFile"
                      onChange={changeHandler}
                    />
                    {upload && (
                      <button
                        type="submit"
                        form="uploadFile"
                        onClick={handleUpload}
                      >
                        Start Upload
                      </button>
                    )}
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Time allowed (in minutes)"
                    name="time_allowed"
                    onChange={(e: any) => setTimeAllowed(e.target.value)}
                  />
                </div>
                <div className="divider"></div>
                <div className="upload">
                  <Button color="primary" variant="contained" type="submit">
                    {isLoading ? <Spinner size="2" /> : "Create"}
                  </Button>
                </div>
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

SendAssignments.propTypes = {};

export default SendAssignments;
