import React, { useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./createCBT.style";
import Layout from "../../../components/Layout";
import { Grid, TextField, Button, TextareaAutosize } from "@material-ui/core";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { ChevronLeft, Elipses, Plus } from "../../../assets/svg/";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCourses } from "../../store/features/course/courseSlice";
import { fetchCourses } from "../../store/features/course/courseThunk";
import { selectRole } from "../../store/features/auth/authSlice";
import { Spinner } from "../../../components/common/Spinner";
import { createAssignment } from "../../../store/features/assignment/assignmentThunk";
import { toast, ToastContainer } from "react-toastify";
import TextEditor from "../../../components/common/TextEditor";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Input, InputGroup, InputGroupText, Table } from "reactstrap";
import { convertCompilerOptionsFromJson } from "typescript";
import { Editor } from "@tinymce/tinymce-react";
import { getCourseDueDate } from "../../../shared";


interface IFormInputs {
  title: string;
  instruction: string;
  course_id: number;
  due_date: string;
  obtainablescore: string;
  attachment: any;
  numQ: any;
  test_type: string;
}

interface IFormInputsAttendance {
  course_id: number;
  obtainablescore: string;
  attendance_url: any;
}


interface Props {}

const CreateCBT = (props: Props) => {
  const style = useStyles();
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [title, setTitle] = useState("");
  const [course, setCourse] = useState("");
  const [marks, setMarks] = useState(0);
  const [due_date, setDueDate] = useState("");
  const [attachment, setAttachment] = useState("");
  const [time_allowed, setTimeAllowed] = useState(0);
  const [questions, setQuestions] = useState([
    {
      question: "",
      options: [
        { option: "", is_correct: false },
        { option: "", is_correct: false },
        { option: "", is_correct: false },
        { option: "", is_correct: false },
      ],
    },
  ]);
  const [selectedFile, setSelectedFile] = useState("");
  const [test_type, setTestType] = useState("");
  const [sectionType, setSectionType] = useState("Test");
  const [attachmentAttendance, setAttachmentAttendance] = useState("");
  const [completedAttendance, setCompletedAttendance] = useState(false);
  const [attendanceList, setAttendanceList]:any = useState([]);

  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectRole);
  const coursess = useAppSelector(selectCourses);

  const [upload, setUpload] = useState(false);
  
  
  const navigate = useNavigate();

  const formSubmitHandler = async (e: any) => {
    e.preventDefault();

    if(title === "") return toast.error("Title is required");
    if(course === "") return toast.error("Course is required");     
    if(time_allowed === 0) return toast.error("Time is required");
    if(due_date === "") return toast.error("Due date is required");
    if(test_type === "") return toast.error("Assessment Type is required");
     
    
    questions.map((question: { question: string; options: { option: string, is_correct: boolean }[] }, index: number)=>{
      if(question.question === "" && !attachment)return toast.error(`Question ${index+1} is required to be set`);
    
      if((question.options[0].option === "" || question.options[1].option === "" || 
        question.options[2].option === "" || question.options[3].option === "") && !attachment
      ){
        return toast.error(`All options for question ${index+1} are required to be set`);
      } 

      //practice test type may not have answers
      if(test_type !== "practice" && 
        (question.options[0].is_correct === false && question.options[1].is_correct === false &&  
        question.options[2].is_correct === false && question.options[3].is_correct === false) && !attachment){
        return toast.error(`Question ${index+1} must have an answer`);
      }
            
      if(index == questions.length-1){
        const data = {
          name: title,
          course_id: course,
          obtainable_score: marks,
          due_date: due_date,
          questions: questions,
          questions_url: attachment,
          time_allowed: time_allowed,
          instruction, 
          type: test_type
        };
    
        createQuiz(data);
      }
    });
    
  };

  const formSubmitAttendanceHandler = async (e: any) => {
    e.preventDefault();
    
    if(course === "") return toast.error("Course is required");  
    if(attachmentAttendance === "") return toast.error("Attendance file is required");   
    if(marks === 0) return toast.error("Assigned marks is required"); 
       
    const data = {
      course_id: course,
      obtainable_score: marks,
      attendance_url: attachmentAttendance      
    };

    createAttendance(data);   
  };

  const resetAttendanceTable = ()=>{
    setAttendanceList([]);
    setCompletedAttendance(false);
    setSectionType("Attendance");
  }

  const createQuiz = async (data: object) => {
    console.log("data", data); 
    try {            
      setIsLoading(true);
      
      const response = await fetch(`${BASE_URL}/cbt/quiz`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (response.ok) {
        toast.success("quiz created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        navigate("/dashboard/cbt-assignment");
      }
      setIsLoading(false);
      console.log(result);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const createAttendance = async (data: object) => {
    console.log("data", data); 
    try {            
      setIsLoading(true);
      
      const response = await fetch(`${BASE_URL}/record/attendance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      if (response.ok) {
        toast.success("Attendance created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        //navigate("/dashboard/cbt-assignment");
        setCompletedAttendance(true);
        //loop through
        setAttendanceList(result);
      }
      setIsLoading(false);
      console.log(result);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleIncrementQuestions = (e: any) => {
    e.preventDefault();
    setQuestions((prev) => [
      ...prev,
      {
        question: "",
        options: [
          { option: "", is_correct: false },
          { option: "", is_correct: false },
          { option: "", is_correct: false },
          { option: "", is_correct: false },
        ],
      },
    ]);
  };

  const handleDecrementQuestions = (e: any) => {
    e.preventDefault();
    
    setQuestions((prev) =>{
      let previous = [...prev]; previous.pop();
      return previous;
    });
  };

  const getCourses = async () => {
    await dispatch(fetchCourses(userRole));
  };

  React.useEffect(() => {
    getCourses();
  }, []);

  const handleUpload = async (e: any) => {
    e.preventDefault();
    // const formData = new FormData();
    // // setIsLoading(true);
    // formData.append("file", selectedFile!);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Brearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/upload/add`,
        { file: e.target.files[0] },
        config
      )
      .then((res) => {
        if (res.status === 200) {
          // toast.success("Attachment has been uploaded");
          // setIsLoading(false);
          // setUpload(false);
          setAttachment(res.data.url);
          console.log(res)
        } else {
          // toast.success(res.data.error);
          // setIsLoading(false);
          // setUpload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        // toast.success("Something went wrong");
        // setIsLoading(false);
        // setUpload(false);
      });
  };

  const handleUploadAttendance = async (e: any) => {
    e.preventDefault();
    // const formData = new FormData();
    // // setIsLoading(true);
    // formData.append("file", selectedFile!);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/upload/add`,
        { file: e.target.files[0] },
        config
      )
      .then((res) => {
        if (res.status === 200) {
          //toast.success("Attachment has been uploaded");
          // setIsLoading(false);
          // setUpload(false);
          setAttachmentAttendance(res.data.url);
          console.log(res)
        } else {
          // toast.success(res.data.error);
          // setIsLoading(false);
          // setUpload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.warning("Something went wrong. Try uploading again.");
        // setIsLoading(false);
        // setUpload(false);
      });
  };

  const handleQuestionChange = (e: any, question: any) => {
    const { name, id: i, value } = e.target;
    let newQuestion;

    if (name === "question") {
      newQuestion = {
        ...question.question,
        question: value,
      };
    }

    if (name === "option") {
      let options = question.question.options; //create a copy of the options array
      let newOption = options[i]; //get the prev state of target option

      options[i] = { ...newOption, option: value }; // spread the prev state and append the change

      newQuestion = {
        ...question.question,
        options: options,
      };
      //also append the change in the new question object
    }

    if (name === "check") {
      let options = question.question.options;
      let newOption = options[i];
      options[i] = { ...newOption, is_correct: !newOption.is_correct };

      newQuestion = {
        ...question.question,
        options: options,
      };
    }

    let copy = questions; //create a copy of the questions state
    copy[question.questionIndex] = newQuestion; // make changes to the copied array

    setQuestions([...copy]);
  };

  console.log("questions", questions);

  return (
    <Layout>
      <ToastContainer />

      {/* Main Container */}
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.header}>
            {/* <div style={{float:"left", border: "1px solid green"}}>  */}
              {/* <Link to="/dashboard/cbt-assignment/"  >
                <ChevronLeft /> {"  "}                 
              </Link>{" "} */}
            {/* </div> */}

            <h5
              style={{
                color: "#152E88",
                fontSize: "1.5rem",
                fontWeight: 100,
                backgroundColor: "#F1EDEC",
                padding: "1rem",
                width: "100%",
                float: "right"
              }}
            >
              <Link to="/dashboard/cbt-assignment/"   style={{float:"left" }}>
                <ChevronLeft />               
              </Link> 
              <span style={{paddingLeft:"1rem"}}>Create {sectionType === "Attendance"?"Attendance":"Assessment"}</span>                           
              <Link to="/dashboard/cbt-assignment/" style={{float:"right", color:"#152E88"} }>
              <Elipses/>               
              </Link>
                             
            </h5>                                   
          </div>
          <div className={style.main}>
            <div className={style.form}>
              <div className="form-group">
                <div>
                <select 
                    name="section_type"
                    onChange={(e: any) => setSectionType(e.target.value)}
                  >
                    <option key={"Test"} value={"Test"}>
                      {"CBT Assessment"}
                    </option>
                    <option key={"Attendance"} value={"Attendance"}>
                      {"Attendance Assessment"}
                    </option>                       
                  </select>
                </div>
              </div>
              
              { sectionType === "Attendance" && !completedAttendance && (
              <form onSubmit={formSubmitAttendanceHandler}>
                
                <div className="form-row">                                     
                  <div style={{width:"15rem"}}>
                    <select 
                      name="course_id"
                      onChange={(e: any) => setCourse(e.target.value)}
                    >
                      <option value="">
                        select course
                      </option>
                      {coursess.map((course: any) => 
                        getCourseDueDate(course?.start_date,course?.duration_in_days) > new Date() &&                           
                        (
                        <option key={course.id} value={course.id}>
                          {course.name} 
                        </option>
                        )                                   
                      )}
                    </select>
                  </div>
                  <div style={{marginLeft:"4rem",width:"15rem"}}>
                    <input  
                      type="number" min={0}   
                      name="obtainablescore"
                      placeholder="Obtainable score"
                      onChange={(e: any) => setMarks(e.target.value)}
                    /> 
                  </div>                                
                                            
                </div>

                <div className="form-row" style={{marginTop: "1rem"}}>                     
                  <h5>
                  <label htmlFor="upload">Upload Attendance File</label>
                  <input style={{width:"100rem"}}
                      type="file"
                      name="upload"
                      id="upload" accept=".xls,.xlsx"
                      onChange={handleUploadAttendance}
                  />
                  </h5>                                      
                </div>                               

                  <div className="divider"></div>
                  <div className="upload">
                    <Button color="primary" variant="contained" type="submit">
                      {isLoading ? <Spinner size="2" /> : "Create"}
                    </Button>
                  </div>
                <br />
              </form>
              )}

              { sectionType === "Test" && (
              <form onSubmit={formSubmitHandler}>
                <div className="form-group">
                  <div>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      onChange={(e: any) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div>
                    <select
                      name="course_id"
                      onChange={(e: any) => setCourse(e.target.value)}
                    >
                      <option value="">
                        select course
                      </option>
                      {coursess.map((course: any) => 
                        getCourseDueDate(course?.start_date,course?.duration_in_days) > new Date() &&                           
                        (
                        <option key={course.id} value={course.id}>
                          {course.name} 
                        </option>
                        )                                   
                      )}
                    </select>
                  </div>
                  <div>
                    <input
                      type="number" min={0} 
                      name="obtainablescore"
                      placeholder="Obtainable score"
                      onChange={(e: any) => setMarks(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="due_date"
                      placeholder="Due date"
                      className="date-picker"
                      onChange={(e: any) => setDueDate(e.target.value)}
                      onFocus={(e) => (e.target.type = "datetime-local")}
                    />
                  </div>
                </div>
                <div className="form-row" style={{marginTop: "1rem"}}>
                  <input 
                  // style={{marginTop: "1rem"}}
                    type="number" min={0} max={1000} 
                    placeholder="Time (in minutes)"
                    name="time_allowed" 
                    onChange={(e: any) => setTimeAllowed(parseInt(e.target.value))}
                  />
                   
                  <select  
                    name="test_type" style={{width:"auto"}}
                    onChange={(e: any) => setTestType(e.target.value)}
                  >
                    <option value="">
                      Select Assessment Type
                    </option>                        
                    <option key={"test"} value={"test"}>
                      {"Test"}
                    </option>
                    <option key={"exam"} value={"exam"}>
                      {"Examination"}
                    </option>    
                    <option key={"practice"} value={"practice"}>
                      {"Practice"}
                    </option>                    
                  </select>
                </div>                                
                 
                <div className="divider"></div>
                <h5>Instructions (if any)</h5>  
                <div className="form-group" >  
                  <TextEditor name="instruction" id="instruction"  
                    value={instruction} 
                    placeholder="type your instruction here..."
                    onChange={(content:any)=>{
                      setInstruction(content);
                    }}/>                                                  
                </div>
                {/* <div className="divider"></div> */}
                
                <div className="divider"></div>
                <div className="questions">
                  <h5 style={{
                    color: "#152E88",
                    fontSize: "1.5rem",
                    fontWeight: 100,
                    // backgroundColor: "#F1EDEC",
                    padding: "1rem",
                    borderBottom: "1px solid #F1EDEC"
                  }}>
                    Questions
                    <button
                      onClick={handleIncrementQuestions}
                      style={{
                        float: "right",
                        border: "none",
                        padding: ".25rem 16px",
                        borderRadius: "5px",
                        marginInline: "4px",
                      }} 
                      title = "Adds a new question"
                    >
                      <Plus />
                    </button>
                    <button
                      onClick={handleDecrementQuestions}
                      style={{
                        float: "right",
                        border: "none",
                        padding: ".25rem 16px",
                        borderRadius: "5px",
                        marginInline: "4px",
                        color: "#152E88"
                      }}
                      title="Removes the last question"
                    >
                      -
                    </button>
                  </h5>


                  <h5>
                  <label htmlFor="upload">Upload Exam File</label>
                  <input
                      type="file"
                      name="upload"
                      id="upload" 
                      accept=".doc, .docx"
                      onChange={handleUpload}
                  />
                  </h5>


                  {questions?.map((question: any, questionIndex: any) => (
                    <div className="form-group" key={questionIndex}>
                      <h5>Question {questionIndex+1}.</h5>
                      <TextEditor
                        type="textarea"
                        name="question"
                        placeholder="type question here"
                        id={questionIndex}
                        onChange={(content: any) =>
                          handleQuestionChange(
                            {
                              // custom event object
                              target: {
                                name: "question",
                                id: questionIndex,
                                value: content,
                              },
                            },
                            { question, questionIndex }
                          )
                        }
                        value={question?.question}
                      />

                      <div style={{ display: "flex", gap: 4, margin: "5px 0" }}>
                        <h6>Options</h6>
                        <span>(Select answer, multiples where necessary)</span>
                      </div>
                      <Grid container spacing={3}>
                        {question.options.map((option: any, i: any) => (
                          <Grid item xs={12} md={6}>
                            <InputGroup>
                              <Input
                                placeholder="Option"
                                name="option"
                                id={i}
                                value={option.option}
                                onChange={(e) =>
                                  handleQuestionChange(e, {
                                    question,
                                    questionIndex,
                                  })
                                }
                              />
                              <InputGroupText>
                                <Input
                                  addon
                                  aria-label="Checkbox for following text input"
                                  type="checkbox"
                                  id={i}
                                  name="check"
                                  checked={option.is_correct}
                                  onChange={(e) =>
                                    handleQuestionChange(e, {
                                      question,
                                      questionIndex,
                                    })
                                  }
                                />
                              </InputGroupText>
                            </InputGroup>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ))}
                </div>
                
                <div className="divider"></div>
                <div className="upload">
                  <Button color="primary" variant="contained" type="submit">
                    {isLoading ? <Spinner size="2" /> : "Create"}
                  </Button>
                </div>
                <br/>
              </form>
              )}

              {sectionType === "Attendance" && completedAttendance && (
                <>
                  <h4 style={{color: "#152E88", fontWeight: 300}}>
                    Attendance List
                    <span color="primary" title="Reset Attendance List" 
                    style={{float:"right", fontStyle:"italic", cursor:"pointer"}} 
                    onClick={()=>resetAttendanceTable()}>Reset</span>
                  </h4>
                  <Table responsive striped size="sm">
                  <thead>
                    <tr>
                      <th className="scoreTableHeading"  style={{color: "#152E88"}}>
                          Service Number 
                      </th>
                      <th className="scoreTableHeading" style={{color: "#152E88"}}>
                          Participant                        
                      </th>                    
                      <th className="scoreTableHeading" style={{textAlign:"center",color: "#152E88"}}>
                          Score                         
                      </th>                    
                    </tr>
                  </thead>
                  <tbody>                  
                    {attendanceList?.map((item: any) => (
                      <tr key={item.student.id}> 
                        <td>
                          <p className="scoreTableData">
                            {item.student.service_number}
                          </p>
                        </td>
                        <td>
                          <p className="scoreTableData">
                            {item.student.rank} {item.student.name}
                          </p>
                        </td>                      
                        <td>
                          <p className="scoreTableData" style={{textAlign:"center"}}>
                            {item.obtained_score != 0 ?`${item.obtained_score}/${item.obtainable_score}`:'-'}{" "}
                          </p>
                        </td>
                        
                      </tr>
                    ))}
                    {attendanceList?.length == 0 && (<tr><td colSpan={3}><p className="scoreTableData">No submissions recorded.</p></td></tr>)}
                  </tbody>
                  </Table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

CreateCBT.propTypes = {};

export default CreateCBT;
