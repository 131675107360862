import React, { HTMLAttributes, HTMLProps } from "react";
import PropTypes from "prop-types";
import { useStyles } from "../dataTables.style";

import { Arrow, ChevDown, X } from "../../../../assets/svg";

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
interface Props {
  setFilter: string;
  isAddPayment: any;
  managePayments: any;
}
type Payment = {
  [key: string]: any;
};
const ManagePaymentDataTable = (props: Props) => {
  const style = useStyles();

  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [addPaymentModal, setAddPaymentModal] = React.useState(false);

  const handleAddStudent = () => {
    setAddPaymentModal(true);
  };
  const handleCloseModal = () => {
    setAddPaymentModal(false);
  };

  React.useEffect(() => {
    setGlobalFilter(props.setFilter);
  }, [props.setFilter]);
  React.useEffect(() => {
    setAddPaymentModal(props.isAddPayment);
  }, [props.isAddPayment]);

  const columns = React.useMemo<ColumnDef<Payment>[]>(
    () => [
      {
        accessorKey: "paymentDesc",
        header: "Payment description",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "semester",
        header: "Semester",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "level",
        header: "Level",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "session",
        header: "Session",
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  const [data, setData] = React.useState(props.managePayments);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <>
      {addPaymentModal ? (
        <div className={style.addPaymentModal}>
          <div className="modal">
            <div className="header">
              <X className="icon" onClick={handleCloseModal} />
            </div>
            <div className="bodyMod">
              <div className="formGroup">
                <input placeholder="Amount" />
                <select>
                  <option>All Level</option>
                </select>
                <select>
                  <option>All Sessions</option>
                </select>
                <select>
                  <option>All Semesters</option>
                </select>
                <textarea placeholder="Description"></textarea>
              </div>
              <button className="btn">Create Payment</button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <table className={style.table + " payments"}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={style.tableMisc}>
        <div className="nextPrevious">
          <button
            className=""
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className=""
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className=""
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="pageCounter">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className=""
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div></div>
      </div>
    </>
  );
};

ManagePaymentDataTable.propTypes = {};

export default ManagePaymentDataTable;
