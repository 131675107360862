export const formatDateDifference = (newDate: Date) => {
  const months: any = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  };
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const d = new Date(newDate);
  const year = d.getFullYear();
  const date = d.getDate();
  const monthIndex = d.getMonth();
  const monthName = months[d.getMonth()];
  const dayName = days[d.getDay()]; // Thu
  // const formatted = `${dayName}, ${date}, ${monthName} ${year}`
  const formatted = `${date}, ${monthName} ${year}`;
  // return formatted.toString();

  // Get if there are remaining days
  const remainingDays = Math.floor(
    (d.getTime() - new Date().getTime()) / (1000 * 3600 * 24)
  );
  if (remainingDays > 1) {
    return `${remainingDays} days remaining`;
  } else if (Math.abs(remainingDays) === 1) {
    return `Today is due date`;
  } else {
    return `Date passed since ${Math.abs(remainingDays)} day(s) ago`;
  }
};
