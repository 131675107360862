import React, { HTMLAttributes, HTMLProps } from "react";
import PropTypes from "prop-types";
import { useStyles } from "../dataTables.style";
// import { Staff } from "./Columns";
import { ChevDown, X } from "../../../../assets/svg";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Modal as ReactStrapModal, ModalBody } from "reactstrap";

import { Link } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingSpinner from "../../Loader";
import { toast, ToastContainer } from "react-toastify";
import { IFormInputs } from "../../../../shared";
import { Spinner } from "../../Spinner";
import { Box, Grid, Typography } from "@material-ui/core";
import Confirm from "../../Confirm";

import { getYears } from "../../../../shared/utils/date";

interface Props {
  setFilter: string;
  isAddStaff: any;
  staff: any;
  handleCloseAll: any;
  fetchAdminStaff: any;
  isLoadingAdmins: boolean;
}

type Staff = {
  [key: string]: any;
};

const createAdminSchema = yup.object().shape({
  service_number: yup.string().required("Service number is required"),
  rank: yup.string().required("Rank is required"),
  email: yup.string().email().required(),
  marital_status: yup.string().required("Marital status is required"),
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string(),
  last_name: yup.string().required("Last name is required"),
  phone: yup.string().required("Phone is required"),
  gender: yup.string().required("Required"),
  age: yup.string().required("Required"),

  trade: yup.string().required("Specialty/Trade is required"),
  course_intake: yup.string().required("Course/Intake is required"),

  /*highest_qualification: yup.string().required("Highest education is required"),
  proffessional_qualification: yup.string(),
  military_courses_attended: yup.string(),
  other_military_courses_attended: yup.string(),
  course_intake: yup.string().required(),
  service_years: yup.string().required(),
  trade: yup.string(),
  skill_level: yup.string(),
  next_promotion_year: yup.string(),
  course_upgrade_year: yup.string(),
  next_pme_year: yup.string(),
  essay: yup.string(),
  medical_disabilities: yup.string(),
  emergency_contact_name: yup.string(),
  emergency_contact_address: yup.string(),
  emergency_contact_phone: yup.string(),
  emergency_contact_relationship: yup.string(),*/
});

const AdminDataTable = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const NAF_URL = process.env.REACT_APP_NAFURL;
  const token = localStorage.getItem("token");
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [numberSelected, setNumberSelected] = React.useState(0);
  const [staffEditDet, setStaffEditDet] = React.useState<Staff>({});
  const [staffEditModal, setStaffEditModal] = React.useState(false);
  const [modifyStaffModal, setModifyStaffModal] = React.useState(false);
  const [addStaffModal, setAddStaffModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingUserData, setIsLoadingUserData] = React.useState(false);
  const [focusedRow, setFocusedRow] = React.useState<{ [key: string]: any }>(
    {}
  );
  const [openWarning, setOpenWarning] = React.useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(createAdminSchema),
  });
  const onSubmit = (data: IFormInputs) => {
    const body = {
      bio_data: {
        name: `${data.first_name || ""} ${data.middle_name || ""} ${
          data.last_name || ""
        }`,
        rank: data.rank || "",
        service_number: data.service_number || "",
        age: data.age || "",
        gender: data.gender || "",
        email: data.email || "",
        phone: data.phone || "",
        marital_status: data.marital_status || "",
        image: data.image || "",

        specialty: data.trade || "",
        course_intake: data.course_intake || "",
      },
      /*educational_background: {
        highest_qualification: data.highest_qualification || "",
        professional_qualification: data.professional_qualification || "",
        professional_military_qualification:
          data.military_courses_attended || "",
        other_military_course: data.other_military_courses_attended || "",
      },
      other_details: {
        course_intake: data.course_intake || "",
        service_years: data.service_years || "",
        specialty: data.trade || "",
        skill_level: data.skill_level || "",
        projected_promotion_year: data.next_promotion_year || "",
        projected_upgrade_year: data.course_upgrade_year || "",
        projected_pme_year: data.next_pme_year || "",
      },
      medical: data.medical_disabilities || "",
      emergency_contact: {
        emergency_contact_name: data.emergency_contact_name || "",
        emergency_contact_phone: data.emergency_contact_phone || "",
        emergency_contact_relationship:
          data.emergency_contact_relationship || "",
        emergency_contact_address: data.emergency_contact_address || "",
      },*/
    };
    createAdmin(body);
  };

  // form action Functions

  // form action Functions
  const createAdmin = async (data: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/admin/create`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await req.json();
      console.log(res);
      setIsLoading(false);
      if (res.id) {
        toast.success("Admin created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        props.fetchAdminStaff();
        props.handleCloseAll();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const request = await fetch(
        `${BASE_URL}/admin/${focusedRow?.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      console.log(response);
      if (request.ok) {
        toast.success("instructor deleted successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchAdminStaff();
      } else {
        toast.error("Cannot delete instructor", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error("Error  deleting instuctor", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  const getUserData = async () => {
    const service_number = getValues("service_number");
    const url = `${NAF_URL}/${service_number}`;
    try {
      setIsLoadingUserData(true);
      const request = await fetch(url);
      const response = await request.json();
      console.log(response);
      if (response) {
        const bio_data = response?.[0];
        console.log(bio_data);
        setIsLoadingUserData(false);
        setValue("first_name", bio_data.firstname, { shouldValidate: true });
        setValue("last_name", bio_data.surname, { shouldValidate: true });
        setValue("middle_name", bio_data.middlename || "", {
          shouldValidate: true,
        });
        setValue("rank", bio_data.rank || "", { shouldValidate: true });

        let age: string = getYears(bio_data.dob).toLocaleString();
        setValue("age", age || "", { shouldValidate: true });

        setValue("gender", bio_data.sex || "", { shouldValidate: true });
        setValue("marital_status", bio_data.maritalStatus || "", {
          shouldValidate: true,
        });
        setValue("email", bio_data.email || "", { shouldValidate: true });
        setValue("phone", bio_data.phone || "", { shouldValidate: true });

        let cse = bio_data.cse;
        let cseType = bio_data.type_of_commission;
        cse = cse + " " + cseType;
        setValue("course_intake", cse || "", { shouldValidate: true });
        //setValue("course_intake", bio_data.cse || "", { shouldValidate: true });

        setValue("image", bio_data.passport || "", { shouldValidate: true });
        setValue("trade", bio_data.specialty || "", { shouldValidate: true });
      } else {
        toast.error("unable to get user data", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setIsLoadingUserData(false);
    } catch (error) {
      console.log("err", error);
      toast.error("unable to get user data", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoadingUserData(false);
    }
  };

  // modals functions
  const handlestaffEditDet = (row: any) => {
    setStaffEditDet(row);
    setStaffEditModal(true);
    setModifyStaffModal(false);
    setAddStaffModal(false);
  };
  const handleModifyStaff = () => {
    setStaffEditModal(false);
    setModifyStaffModal(true);
    setAddStaffModal(false);
  };

  const handleCloseModal = () => {
    setStaffEditDet({});
    setStaffEditModal(false);
    setModifyStaffModal(false);
    setAddStaffModal(false);
    props.handleCloseAll();
  };

  React.useEffect(() => {
    setGlobalFilter(props.setFilter);
  }, [props.setFilter]);

  React.useEffect(() => {
    setAddStaffModal(props.isAddStaff);
  }, [props.isAddStaff]);

  React.useEffect(() => {
    setNumberSelected(Object.keys(rowSelection).length);
  }, [rowSelection]);

  const columns = React.useMemo<ColumnDef<Staff>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },

      /*{
        accessorKey: "id",
        header: "Serial",

        footer: (props) => props.column.id,
      },*/
      {
        accessorKey: "service_number",
        header: "Service number",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "rank",
        header: "Rank",

        footer: (props) => props.column.id,
      },
      {
        accessorKey: "name",

        header: "Name",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "gender",
        header: "Gender",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "email",
        header: "Email",
        footer: (props) => props.column.id,
      },

      {
        accessorKey: "phone",
        header: "Phone number",
        footer: (props) => props.column.id,
      },
      {
        id: "action",
        header: "",
        cell: ({ row }) => (
          <div className="">
            <span
              onClick={() => {
                setFocusedRow(row.original);
                setOpenWarning(true);
              }}
              style={{ color: "red", fontWeight: 600 }}
            >
              Remove
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const [data, setData] = React.useState(props.staff);
  React.useEffect(() => {
    setData(props.staff);
  }, [props.staff]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <>
      <ToastContainer />
      {staffEditModal ? (
        <div className={style.editStaffModal}>
          <div className="modal">
            <div className="header">
              <button className="btn secondary" onClick={handleModifyStaff}>
                Edit
              </button>
              <X className="icon" onClick={handleCloseModal} />
            </div>
            <div className="bodyMod">
              <h3>Staff’s Information</h3>
              <div className="formGroup">
                <input defaultValue={staffEditDet.firstName} readOnly />
                <input defaultValue={staffEditDet.lastName} readOnly />
                <input defaultValue={staffEditDet.regno} readOnly />
                <input defaultValue={staffEditDet.level} readOnly />
                <input defaultValue={"classich@gmail.com"} readOnly />
                <input defaultValue={staffEditDet.phone} readOnly />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {modifyStaffModal ? (
        <div className={style.modifyStaffModal}>
          <div className="modal">
            <div className="header">
              <h3 className="mTitle">New Staff</h3>
              <X className="icon" onClick={handleCloseModal} />
            </div>
            <div className="bodyMod">
              <div className="formGroup">
                <input defaultValue={"staff"} readOnly />
                <input defaultValue={staffEditDet.firstName} />
                <input defaultValue={staffEditDet.lastName} />
                <select>
                  <option>Male</option>
                </select>
                <input defaultValue={"12th June 2022"} />
                <input defaultValue={"classich@gmail.com"} />
                <input defaultValue={staffEditDet.phone} />
              </div>
              <button className="btn">Save</button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this user?"
        action={handleDelete}
      />

      <ReactStrapModal
        isOpen={addStaffModal}
        toggle={() => handleCloseModal()}
        centered={true}
        scrollable={true}
        size="lg"
      >
        <ModalBody>
          {isLoading && <LoadingSpinner />}
          <div className={style.studentModal}>
            <div className="header">
              <h3 className="mTitle">New Admin</h3>

              <X className="icon" onClick={() => handleCloseModal()} />
            </div>
            <div className="bodyMod">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box style={{ display: "flex", gap: 5 }}>
                      <Typography>Bio Data</Typography>
                      {isLoadingUserData && <Spinner size="1" />}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input
                        {...register("service_number")}
                        placeholder="service number"
                        onBlur={() => getUserData()}
                      />
                      <p>{errors.service_number?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input {...register("rank")} placeholder="rank" />
                      <p>{errors.rank?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input {...register("email")} placeholder="email" />
                      <p>{errors.email?.message}</p>
                    </div>
                  </Grid>
                  {/* end of first row */}
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input
                        {...register("first_name")}
                        placeholder="first_name"
                      />
                      <p>{errors.first_name?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input
                        {...register("middle_name")}
                        placeholder="middle_name"
                      />
                      <p>{errors.middle_name?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input
                        {...register("last_name")}
                        placeholder="last_name"
                      />
                      <p>{errors.last_name?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input {...register("phone")} placeholder="phone" />
                      <p>{errors.phone?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input
                        {...register("marital_status")}
                        placeholder="marital_status"
                      />
                      <p>{errors.marital_status?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input {...register("gender")} placeholder="gender" />
                      <p>{errors.gender?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="formGroup">
                      <input {...register("age")} placeholder="age" />
                      <p>{errors.age?.message}</p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={5}>
                    <div className="formGroup">
                      <input
                        {...register("course_intake")}
                        placeholder="Course on commission/intake"
                      />
                      <p>{errors.course_intake?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="formGroup">
                      <input
                        {...register("trade")}
                        placeholder="Specialty/Trade"
                      />
                      <p>{errors.trade?.message}</p>
                    </div>
                  </Grid>

                  {/* end of bio data */}
                  {/* <Grid item xs={12}>
                    <Box style={{ display: "flex", gap: 5 }}>
                      <Typography>Educational Background</Typography>
                      {isLoadingUserData && <Spinner size="1" />}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <div className="formGroup">
                      <input
                        {...register("highest_qualification")}
                        placeholder="Highest Education qualification"
                      />
                      <p>{errors.highest_qualification?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="formGroup">
                      <input
                        {...register("professional_qualification")}
                        placeholder="Proffessional qualification"
                      />
                      <p>{errors.professional_qualification?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="formGroup">
                      <input
                        {...register("military_courses_attended")}
                        placeholder="military_courses_attended"
                      />
                      <p>{errors.military_courses_attended?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="formGroup">
                      <input
                        {...register("other_military_courses_attended")}
                        placeholder="other_military_courses_attended"
                      />
                      <p>{errors.other_military_courses_attended?.message}</p>
                    </div>
                  </Grid> */}
                  {/* end of eductional data */}
                  {/* <Grid item xs={12}>
                    <Box style={{ display: "flex", gap: 5 }}>
                      <Typography>Other Details</Typography>
                      {isLoadingUserData && <Spinner size="1" />}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="formGroup">
                      <input
                        {...register("course_intake")}
                        placeholder="course_intake"
                      />
                      <p>{errors.course_intake?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="formGroup">
                      <input
                        {...register("service_years")}
                        placeholder="service_years"
                      />
                      <p>{errors.service_years?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="formGroup">
                      <input {...register("trade")} placeholder="trade" />
                      <p>{errors.trade?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="formGroup">
                      <input
                        {...register("skill_level")}
                        placeholder="skill_level"
                      />
                      <p>{errors.skill_level?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="formGroup">
                      <input
                        {...register("next_promotion_year")}
                        placeholder="next_promotion_year"
                      />
                      <p>{errors.next_promotion_year?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <div className="formGroup">
                      <input
                        {...register("course_upgrade_year")}
                        placeholder="course_upgrade_year"
                      />
                      <p>{errors.course_upgrade_year?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className="formGroup">
                      <input
                        {...register("next_pme_year")}
                        placeholder="next_pme_year"
                      />
                      <p>{errors.next_pme_year?.message}</p>
                    </div>
                  </Grid> */}
                  {/* end of additional information */}
                  {/* <Grid item xs={12}>
                    <Typography>Others</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="formGroup">
                      <Typography style={{ fontSize: "14px" }}>
                        Do you suffer from any medical condition or disabilities
                        that may affect your studies? if yes please specify
                      </Typography>
                      <textarea
                        {...register("next_pme_year")}
                        placeholder="Enter text..."
                      />
                      <p>{errors.next_pme_year?.message}</p>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>Emergency contact</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="formGroup">
                      <input
                        {...register("emergency_contact_name")}
                        placeholder="emergency_contact_name"
                      />
                      <p>{errors.emergency_contact_name?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="formGroup">
                      <input
                        {...register("emergency_contact_address")}
                        placeholder="emergency_contact_address"
                      />
                      <p>{errors.emergency_contact_address?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="formGroup">
                      <input
                        {...register("emergency_contact_phone")}
                        placeholder="emergency_contact_phone"
                      />
                      <p>{errors.emergency_contact_phone?.message}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="formGroup">
                      <input
                        {...register("emergency_contact_relationship")}
                        placeholder="emergency_contact_relationship"
                      />
                      <p>{errors.emergency_contact_relationship?.message}</p>
                    </div>
                  </Grid> */}
                </Grid>

                {isLoading ? (
                  <Spinner size="1" />
                ) : (
                  <button className="btn" type="submit">
                    Save
                  </button>
                )}
              </form>
            </div>
          </div>
        </ModalBody>
      </ReactStrapModal>

      <table className={style.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                {...table.getRowModel()}
                // onClick={() => handlestaffEditDet(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.isLoadingAdmins && (<h4 style={{padding:".5rem 1.2rem"}}>Loading Administrators...</h4>)}
      <div className={style.tableMisc}>
        <div className="nextPrevious">
          <button
            className=""
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className=""
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className=""
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="pageCounter">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className=""
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div></div>
      </div>
    </>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}
AdminDataTable.propTypes = {};

export default AdminDataTable;
