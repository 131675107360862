import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./recordAssignment.style";
import Layout from "../../../components/Layout";

import { Link, useParams } from "react-router-dom";
import { Chev, NextPage, Previous, Info, Elipses } from "../../../assets/svg/";

interface Props {}

const RecordAssignment = (props: Props) => {
  const style = useStyles();
  const params = useParams();

  const scores = [
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",
      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },

    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",
      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",
      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",
      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",
      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
    {
      id: 1,
      name: "Fatima Musa",
      studentId: "FM-001",

      score: "06",
      obtainableScore: "10",
      dateSubmitted: "11/07/2021",
      type: "--",
    },
  ];

  const totalScores = scores.length || 0;

  const [currentPage, setCurrentPage] = React.useState(1);
  const [scoresPerPage] = React.useState(6);
  // Get scores
  const indexOfLastScore = currentPage * scoresPerPage;
  const indexOfFirstScore = indexOfLastScore - scoresPerPage;
  const currentScores = scores.slice(indexOfFirstScore, indexOfLastScore);

  // Change page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalScores / scoresPerPage); i++) {
    pageNumbers.push(i);
  }

  //handling menu
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <Layout>
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.header}>
            <h5>
              <Link className="icon" to="/dashboard/markAssignments">
                <Chev />{" "}
              </Link>{" "}
              NRS 101 - Physiology Assignment
            </h5>
            <div>
              <p>
                Status: <span>Shared 20, Sep 2021</span>
              </p>
              <p>
                Submitted: <span>26 students of 80</span>
              </p>
            </div>
            <button
              onClick={() =>
                (window.location.href = "/dashboard/assignments/recorded")
              }
            >
              Record
            </button>
          </div>
          <div className={style.main}>
            <div className="listContainer">
              {/* Assignment submission records */}
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Student ID</th>
                    <th>Score</th>
                    <th>Obtainable Score</th>
                    <th>Date Submitted</th>
                    <th>Assignment Type</th>
                  </tr>
                </thead>
                <tbody>
                  {currentScores.map((score) => (
                    <tr key={score.id}>
                      <td>{score.name}</td>
                      <td>{score.studentId}</td>
                      <td>{score.score}</td>
                      <td>{score.obtainableScore}</td>
                      <td>{score.dateSubmitted}</td>
                      <td>{score.type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* //Pagination */}
            <ul className="pagination">
              <Previous
                className={currentPage === 1 ? "icon active" : "icon "}
                onClick={() => paginate(1)}
              />
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={number === currentPage ? "active" : ""}
                >
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="page-link"
                  >
                    {number}
                  </a>
                </li>
              ))}
              <NextPage
                className={
                  currentPage == pageNumbers.length ? "icon active" : "icon "
                }
                onClick={() => paginate(pageNumbers.length)}
              />
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

RecordAssignment.propTypes = {};

export default RecordAssignment;
