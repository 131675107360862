import React from "react";
import { useStyles } from "./dashboard.style";
import Layout from "../../../components/Layout";
import { Grid } from "@material-ui/core";
import box from "../../../assets/images/box.png";
import {
  Calendar,
  Books,
  Desk,
  Result,
  Assignments,
  Naira,
  Transcript,
  DashVideo,
  Notebook,
  NotebookSvg,
} from "../../../assets/svg/";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { selectRole, selectUser } from "../../../store/features/auth/authSlice";
import { Role } from "../../../shared";

import dashboard from "../../../assets/images/home-icon.svg";
import markers from "../../../assets/images/markers.svg";

const Board = () => {
  const style = useStyles();

  const userRole = useAppSelector(selectRole);
  const user = useAppSelector(selectUser);

  const navigate = useNavigate();
  return (
    <Layout>
      <div className={style.container}>
        {userRole === Role.STUDENT ? (
          <>
            <div className={style.dash}>
              <h3 className={style.dashboardName}>
                Profile ({user.user.reg_no})
                <span style={{ float: "right" }}>
                  {/* <Calendar /> */}
                  <img
                    src={dashboard}
                    alt="dashboard-image"
                    width={"20px"}
                    height={"20px"}
                  />
                </span>
              </h3>

              <Grid container className={style.detailsBox}>
                <Grid item md={5} sm={6} xs={12}>
                  <h6>Service No:</h6>
                  <h6>Name:</h6>
                  {/* <h6>Registered Courses: </h6> */}
                  {/* <p>Department: </p> */}
                  <h6>Specialty</h6>
                </Grid>
                <Grid item md={5} sm={6} xs={12}>
                  <p>{user.user.reg_no}</p>
                  <p>
                    {user.rank} {user.name} {user.last_name}{" "}
                  </p>
                  {/* <p>{4}</p> */}
                  <p>{user.specialty}</p>
                </Grid>
                {/* <Grid item md={2} sm={6} xs={12}>
                  <img src={box} alt="box" />
                </Grid> */}
              </Grid>

              <div className={style.divider}></div>

              <Grid container spacing={1}>
                <Grid item sm={12} md={6} xs={12}>
                  <NavLink to="/dashboard/calendar" className={style.navLink}>
                    <div className={style.cardBox}>
                      <Calendar />
                      <div>
                        <h6>Calendar</h6>
                        <span>View the school calendar</span>
                      </div>
                    </div>
                  </NavLink>
                </Grid>

                <Grid item sm={12} md={6} xs={12}>
                  <NavLink to="/dashboard/courses" className={style.navLink}>
                    <div className={style.cardBox}>
                      <Books />
                      <div>
                        <h6>Courses</h6>
                        <span>View registered courses</span>
                      </div>
                    </div>
                  </NavLink>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={12} md={6} xs={12}>
                  <NavLink to="/dashboard/notes" className={style.navLink}>
                    <div className={style.cardBox}>
                      <img
                        src={markers}
                        style={{ width: "25px", height: "25px" }}
                      />
                      <div>
                        <h6>Notes</h6>
                        <span>View classes notes</span>
                      </div>
                    </div>
                  </NavLink>
                </Grid>
                <Grid item sm={12} md={6} xs={12}>
                  <NavLink to="/dashboard/results" className={style.navLink}>
                    <div className={style.cardBox}>
                      <Result />
                      <div>
                        <h6>Results</h6>
                        <span>View result</span>
                      </div>
                    </div>
                  </NavLink>
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <div className={style.dash}>
              <h3 className={style.dashboardName}>
                Profile ({user.instructor_id})
                <span style={{ float: "right" }}>
                  {/* <Calendar /> */}
                  <img
                    src={dashboard}
                    alt="dashboard-image"
                    width={"20px"}
                    height={"20px"}
                  />
                </span>
              </h3>

              <div className={style.main}>
                <Grid container className={style.detailsBox}>
                  <Grid item md={5} sm={6} xs={12}>
                    <h5>Instructor ID: </h5>
                    <h5>Service Number:</h5>
                    <h5>Name: </h5>
                    <h5>Email: </h5>
                  </Grid>
                  <Grid item md={5} sm={6} xs={12}>
                    <p>{user.instructor_id}</p>
                    <p>{user.service_number || " "}</p>
                    <p>
                      {user.rank} {user.name}
                    </p>
                    <p>{user.email}</p>
                  </Grid>
                  {/* <Grid item md={2} sm={6} xs={12}>
                    <img src={box} alt="box" />
                  </Grid> */}
                </Grid>
                <div className={style.divider}></div>
                <Grid container spacing={1} style={{paddingLeft:"1rem", paddingRight:"1rem"}}>
                  <Grid item sm={12} md={6} xs={12}>
                    <NavLink to="/dashboard/calendar" className={style.navLink}>
                      <div className={style.cardBox}>
                        <Calendar />
                        <div>
                          <h6>Events</h6>
                          <span>View the school events</span>
                        </div>
                      </div>
                    </NavLink>
                  </Grid>
                  <Grid item sm={12} md={6} xs={12}>
                    <NavLink to="/dashboard/courses" className={style.navLink}>
                      <div className={style.cardBox}>
                        <Books />
                        <div>
                          <h6>Courses</h6>
                          <span>View assigned courses</span>
                        </div>
                      </div>
                    </NavLink>
                  </Grid>
                  
                  <Grid container spacing={1} style={{paddingLeft:".2rem", paddingRight:".2rem"}}>
                  <Grid item sm={12} md={6} xs={12}>
                    <NavLink to="/dashboard/notes" className={style.navLink}>
                      <div
                        className={style.cardBox}
                        onClick={() => navigate("/dashboard/notes")}
                      >
                        <Notebook />
                        <div>
                          <h6>Notes</h6>
                          <span>View your notes</span>
                        </div>
                      </div>
                    </NavLink>
                  </Grid>
                  
                
                  <Grid item sm={12} md={6} xs={12}>
                    <NavLink to="/dashboard/videos" className={style.navLink}>
                      <div className={style.cardBox}>
                        <DashVideo />
                        <div>
                          <h6>Videos</h6>
                          <span>View videos</span>
                        </div>
                      </div>
                    </NavLink>
                  </Grid>
                  
                  </Grid>
                  {/* <Grid item sm={12} md={4} xs={12}>
                    <NavLink
                      to="/dashboard/assignments"
                      className={style.navLink}
                    >
                      <div className={style.cardBox}>
                        <Assignments />
                        <div>
                          <h6>Assignments</h6>
                          <span>View assignments</span>
                        </div>
                      </div>
                    </NavLink>
                  </Grid> */}
                  {/* hidden pending implementation */}
                  {/* <Grid item sm={12} md={4} xs={12}>
                    <NavLink to="/dashboard/records" className={style.navLink}>
                      <div className={style.cardBox}>
                        <Transcript />
                        <div>
                          <h6>Records</h6>
                          <span>View records</span>
                        </div>
                      </div>
                    </NavLink>
                  </Grid> */}
                </Grid>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Board;
