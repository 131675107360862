import { createAsyncThunk } from '@reduxjs/toolkit'
import { coursesService } from '../../../services'
import { CourseAction } from './courseType'

export const fetchCourses = createAsyncThunk(CourseAction.FETCHCOURSE, async (user: string) => {
    const response = await coursesService.getAll(user)
    return response.data
})

export const dropCpurse = createAsyncThunk(CourseAction.REMOVECOURSE, async (body: { course_id: number }) => {
    const response = await coursesService.dropCourse(body)
    return response.data
})


