import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./assignments.style";
import Layout from "../../../components/Layout";
import { Grid } from "@material-ui/core";
import box from "../../../assets/images/box.png";
import { Link, useNavigate } from "react-router-dom";
import { Plus, Elipses } from "../../../assets/svg/";
import { useAppDispatch, useAppSelector } from "../../../store";
import { OptionMenuAssignment } from "../../../components";

import {
  selectAssignments,
  selectLecAssignments,
} from "../../../store/features/assignment/assignmentSlice";
import {
  fetchAssignment,
  fetchLecCourseAss,
  fetchLeseAss,
} from "../../../store/features/assignment/assignmentThunk";
import { selectRole } from "../../../store/features/auth/authSlice";
import { formatDate, Role } from "../../../shared";

interface Props {}

const Assignments = (props: Props) => {
  const style = useStyles();
  const [isStudent, setIsStudent] = React.useState(true);

  const dispatch = useAppDispatch();
  const assignments = useAppSelector(selectAssignments);
  // const selectLecAssignemts = useAppSelector(selectLecAssignments);
  const userRole = useAppSelector(selectRole);

  const gettAssignments = async () => {
    if (userRole === Role.STUDENT) {
      await dispatch(fetchAssignment());
    } else {
      await dispatch(fetchLeseAss());
    }
  };

  const draftOptions = [
    { name: "Publish", link: "" },
    { name: "Edit", link: "" },
    { name: "Delete", link: "" },
  ];

  const publishedOptions = [
    { name: "Unpublish", link: "" },
    { name: "Edit", link: "" },
  ];

  React.useEffect(() => {
    gettAssignments();
  }, [assignments]);

  // handling menu for note options
  const [openMenu, setOpenMenu] = React.useState(false);
  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const navigate = useNavigate();

  console.log("assignments", assignments);

  return (
    <Layout>
      <div className={style.container}>
        {userRole === Role.STUDENT ? (
          <>
            <div className={style.dash}>
              <div
                className={style.header}
                style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
              >
                <h5
                  style={{
                    fontWeight: 100,
                    color: "#152E88",
                    fontSize: "1.5rem",
                  }}
                >
                  Assignments
                </h5>
                <span style={{ float: "right" }}>
                  <Link to="/dashboard/assignments/history">History</Link>
                </span>
              </div>
              <div className={style.main}>
                <h5
                  className="drafts"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 100,
                    color: "#152E88",
                  }}
                >
                  Recent
                </h5>
                {/* {assignments.filter((ass: any) => Object.keys(ass.submission)).length !== 0 && `No recent assignment(s) found.`} */}
                {assignments.filter(
                  (ass: any) => Object.keys(ass.submission).length === 0
                ) && (
                  <div className="lists student">
                    {assignments
                      .filter(
                        (ass: any) => Object.keys(ass.submission).length === 0
                      )
                      .map((ass: any, i: number) => (
                        <div
                          className="list"
                          key={i}
                          onClick={() =>
                            navigate(`attempt`, {
                              replace: true,
                              state: {
                                id: ass.id,
                                title: ass.title,
                                questions: ass.questions,
                                time_used: ass.time_used,
                                course: ass.course.name,
                                date: ass.due_date,
                                submission: ass.submission,
                              },
                            })
                          }
                        >
                          <div className="content">
                            <span>{ass.course.lecturers[0].name}</span>
                            <h5>{ass.title}</h5>
                          </div>
                          <div className="detail">
                            <span>{ass.course.name}</span>
                            <span>{formatDate(ass.created_at)}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                <h5
                  className="drafts"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 100,
                    color: "#152E88",
                  }}
                >
                  Submissions
                </h5>
                {assignments.filter((ass: any) => Object.keys(ass.submission))
                  .length === 0 && `No Submission(s) found.`}
                {assignments.filter(
                  (ass: any) => Object.keys(ass.submission).length !== 0
                ) && (
                  <div className="lists student">
                    {assignments
                      .filter(
                        (ass: any) => Object.keys(ass.submission).length !== 0
                      )
                      .map((ass: any, i: number) => (
                        <div
                          className="list"
                          key={i}
                          onClick={() =>
                            navigate(`attempt`, {
                              replace: true,
                              state: {
                                id: ass.id,
                                title: ass.title,
                                questions: ass.questions,
                                course: ass.course.name,
                                date: ass.due_date,
                                submission: ass.submission,
                              },
                            })
                          }
                        >
                          <div className="content">
                            <span>
                              {ass.course.lecturers.map(
                                (lecturer: any, i: number) =>
                                  i === 0 ? lecturer.name : lecturer.name + " ,"
                              )}
                            </span>
                            <h5>{ass.title}</h5>
                          </div>
                          <div className="detail">
                            <span>{ass.course.name}</span>
                            <span>{formatDate(ass.created_at)}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.dash}>
              <div
                className={style.header}
                style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
              >
                <h5
                  style={{
                    fontWeight: 100,
                    color: "#152E88",
                    fontSize: "1.5rem",
                  }}
                >
                  Assignments
                </h5>
                <span style={{ float: "right" }}>
                  <Link to="/dashboard/sendAssignments">
                    <Plus /> Create
                  </Link>
                </span>
              </div>

              <div className={style.main}>
                <h5
                  className="drafts"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 100,
                    color: "#152E88",
                  }}
                >
                  Drafts
                </h5>
                {assignments.filter((ass: any) => !ass.is_published).length ==
                  0 && `No draft(s) found.`}
                {assignments.filter((ass: any) => !ass.is_published).length >
                  0 && (
                  <div className="lists">
                    {assignments
                      .filter((ass: any) => !ass.is_published)
                      .map((obj: any) => ({
                        ...obj,
                        date: new Date(obj.updated_at),
                      }))
                      .sort(
                        (a: any, b: any) => b.date.getTime() - a.date.getTime()
                      )
                      .map((ass: any, i: number) => (
                        <div className="list" key={i}>
                          <div
                            className="content"
                            onClick={() =>
                              navigate(`view`, {
                                replace: true,
                                state: {
                                  id: ass.id,
                                  title: ass.title,
                                  questions: ass.questions,
                                  course: ass.course.name,
                                  course_id: ass.course.id,
                                  due_date: ass.due_date,
                                  attachment: ass.attachment,
                                  date: ass.created_at,
                                  isPublished: false,
                                },
                              })
                            }
                          >
                            <h5>{ass.title}</h5>
                            <h6>{ass.course.name}</h6>
                            <span>{formatDate(ass.updated_at)}</span>
                          </div>
                          <div>
                            <OptionMenuAssignment
                              options={draftOptions}
                              questions={ass}
                              id={ass.id}
                              key={ass.id}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                <h5
                  className="sent"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 100,
                    color: "#152E88",
                  }}
                >
                  Published
                </h5>
                {assignments.filter((ass: any) => ass.is_published).length ==
                  0 && `No published assignment(s) found.`}
                {assignments.filter((ass: any) => ass.is_published).length >
                  0 && (
                  <div className="lists">
                    {assignments
                      .filter((ass: any) => ass.is_published)
                      .map((obj: any) => ({
                        ...obj,
                        date: new Date(obj.updated_at),
                      }))
                      .sort(
                        (a: any, b: any) => b.date.getTime() - a.date.getTime()
                      )
                      .map((ass: any, i: number) => (
                        <div className="list" key={i}>
                          <div
                            className="content"
                            onClick={() =>
                              navigate(`submissions`, {
                                replace: true,
                                state: {
                                  assignment_id: ass.id,
                                  title: ass.title,
                                  questions: ass.questions,
                                  course: ass.course.name,
                                  course_code: ass.course.code,
                                  course_id: ass.course.id,
                                  // due_date: ass.due_date,
                                  // attachment: ass.attachment,
                                  // date: ass.created_at,
                                  // isPublished: false,
                                },
                              })
                            }
                          >
                            <h5>{ass.title}</h5>
                            <h6>{ass.course.name}</h6>
                            <span>{formatDate(ass.updated_at)}</span>
                          </div>
                          <OptionMenuAssignment
                            options={publishedOptions}
                            questions={ass}
                            id={ass.id}
                            key={ass.id}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

Assignments.propTypes = {};

export default Assignments;
