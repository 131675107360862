import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    autoflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      minHeight: "100%",
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
  },
  main: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "75vh",
    // alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      minHeight: "70%",
    },
    justifyContent: "space-between",
    "& .lists": {
      marginTop: "2.875rem",
      overflowY: "auto",
      overflowX: "hidden",
    },

    "& .lists > div.result": {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      padding: "1rem 2rem",
      margin: "0 -1rem .5rem -1rem",
      background: colors.gray,
      "& span": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "1.25rem",
        lineHeight: "22.5px",
        color: colors.black,
      },
      "& .dot": {
        position: "absolute",
        top: "10px",
      },
    },
    "& .form": {
      width: "50%",
      height: "25vh",
      alignSelf: "center",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "25%",
      },
      "& span.info": {
        display: "block",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "1.25rem",
        lineHeight: "22px",
        color: colors.black,
        textAlign: "center",
        marginBottom: "3rem",
        [theme.breakpoints.down("xs")]: { marginBottom: "2rem" },
      },
    },

    "& .form-control.row": {
      display: "flex",
      // gridTemplateColumns: "4fr 1fr",
      // flexDirection:
      width: "100%",
      alignItems: "center",
      "& div:first-child": {
        width: "100%",
      },
      "& .MuiInputBase-input ": {
        borderRight: "0",
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
      },
      "& .MuiButton-root": {
        borderLeft: "0",
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
      },
    },
    "& .form-control": {
      marginBottom: "6px",

      "& .MuiInputBase-input ": {
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        // lineHeight: "33px",
        color: "#C4C4C4",
        padding: ".5rem",
        width: "100%",
      },
      "& .MuiButton-root": {
        float: "right",
        background: colors.blue,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "0.9rem",

        lineHeight: "22px",
        /* identical to box height */

        color: colors.white,
      },
      "& .MuiFilledInput-underline": {
        border: "0",
      },
    },
  },
}));
