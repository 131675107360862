import { makeStyles } from "@material-ui/core";
import { colors } from "../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: "hidden",
    // minHeight: "100vh",
  },
  header: {
    marginTop: "3.125rem",
    padding: "0 1rem",
    display: "flex",

    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  logoTitleContainer: {
    [theme.breakpoints.down("sm")]: {},
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  logoIMG: {
    // width: "80px",
    // height: "60px",
    width: "100px",
    height: "100px",
  },
  appTitle: {
    marginLeft: "10px",
    color: "#152E88",
    // textShadow: "2px 2px 22px rgba(0, 0, 0, 0.25)",
    fontWeight: 700,
    fontSize: "30px",
    marginBottom: "1rem",
  },

  title: {
    fontFamily: "poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "33px",

    color: colors.blue,
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
    },
  },
  headerTitle: {
    // marginTop: "5.1875rem",
    marginTop: "2rem",

    fontFamily: "poppins",
    marginBottom: "2rem",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "45px",

    color: colors.black,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "2rem",
    justifyContent: "center",
    "& div:not(:last-child):not(.formGroup):not(.Mui-error)": {
      marginBottom: "1.875rem",
    },
    "& .MuiFormHelperText-root": {
      margin: "0 -.1rem",
    },
    "& .MuiInputBase-input": {
      width: "399px",
      padding: "0.5rem 1rem",
      background: colors.white,
      // border: "1px solid rgba(0, 0, 0, 0.7)",
      borderRadius: "5px",
      fontFamily: "poppins",
      fontWeight: 400,
      // fontSize: "22px",
      fontSize: "16px",
      lineHeight: "33px",
      // color: "#C4C4C4",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiButton-root": {
      padding: "0.2rem 0",
      width: "200px",
      //   height: "50px",
      background: colors.blue,
      border: "1px solid rgba(0, 0, 0, 0.7)",
      bordeRadius: "8px",
      fontFamily: "poppins",
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "33px",
      color: colors.white,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    marginTop: "1.5rem",
  },
  formGroup: {},

  logo: {},

  // registration form

  regFormContainer: {
    background: "#F6F4F4",
    padding: "2rem",
    marginTop: "2rem",
    "& input": {
      background: "white",
    },
    "& .MuiTypography-body1": {
      fontWeight: 700,
      fontSize: "26px",
      lineHeight: "30px",
    },
  },

  card: {
    padding: "1rem",
    boxShadow: "2px 3px 12px 0 #888888",
  },

  registerLink: {
    float: "right",
    color: "#4373d3",
    fontSize: ".9rem",
    textDecoration: "none",
    paddingBottom: "1rem",
    fontWeight: 500,
  },

  loginBtn: {
    width: "100%",
    marginBottom: "1.5rem",
    textTransform: "capitalize",
    fontWeight: 100,
  },

  labels: {
    marginBottom: ".4rem",
    marginTop: "-1rem",
    fontSize: "1rem",
    fontWeight: 500,
    color: "grey",
  },
}));
