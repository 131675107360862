import React, { HTMLAttributes, HTMLProps, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "../dataTables.style";

import { ChevDown, X } from "../../../../assets/svg";
import { Button, Modal as ReactStrapModal, ModalBody, Table } from "reactstrap";

import { getYears } from "../../../../shared/utils/date";

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { IFormInputs } from "../../../../shared";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Spinner } from "../../Spinner";
import { Box, Grid, Typography } from "@material-ui/core";
import LoadingSpinner from "../../Loader";
import Confirm from "../../Confirm";
import axios from "axios";
interface Props {
  setFilter: string;
  isAddStudent: any;
  students: any;
  handleCloseAll: any;
  fetchStudents: any;
  isLoadingStudents: boolean;
}
type Student = {
  [key: string]: any;
};

const createUserSchema = yup.object().shape({
  service_number: yup.string().required("Service number is required"),
  rank: yup.string().required("Rank is required"),
  email: yup.string().email().required("Email is required"),
  marital_status: yup.string().required("Marital status is required"),
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string(),
  last_name: yup.string().required("Last name is required"),
  phone: yup.string().required("Phone is required"),
  gender: yup.string().required("Gender is required"),
  age: yup.string().required("Age is required"),
  highest_qualification: yup.string().required("Highest education is required"),
  professional_qualification: yup.string(),
  military_courses_attended: yup.string(),
  other_military_courses_attended: yup.string(),
  course_intake: yup.string().required("Course/Intake is required"),
  service_years: yup.string().required("Service Years is required"),
  trade: yup.string().required("Specialty/Trade is required"),
  skill_level: yup.string(),
  next_promotion_year: yup.string().required("Required"),
  course_upgrade_year: yup.string(),
  next_pme_year: yup.string(),
  essay: yup.string(),
  medical_disabilities: yup.string(),
  emergency_contact_name: yup.string().required("Required"),
  emergency_contact_address: yup.string().required("Required"),
  emergency_contact_phone: yup.string().required("Required"),
  emergency_contact_relationship: yup.string().required("Required"),
});
const StudentDataTable = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const NAF_URL = process.env.REACT_APP_NAFURL;
  const token = localStorage.getItem("token");
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [numberSelected, setNumberSelected] = React.useState(0);
  const [studentEditDet, setStudentEditDet] = React.useState<Student>({});
  const [studentEditModal, setStudentEditModal] = React.useState(false);
  const [modifyStudentModal, setModifyStudentModal] = React.useState(false);
  const [addStudentModal, setAddStudentModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingUploadFile, setIsLoadingUploadFile] = React.useState(false);
  const [isLoadingUserData, setIsLoadingUserData] = React.useState(false);
  const [focusedRow, setFocusedRow] = React.useState<{ [key: string]: any }>(
    {}
  );
  const [openWarning, setOpenWarning] = React.useState(false);
  const [openWarningGraduate, setOpenWarningGraduate] = React.useState(false);

  const [bulkRegType, setBulkRegType] = React.useState(true);

  const handleRegType = () => {
    setBulkRegType(!bulkRegType);
    if (!bulkRegType) resetRegistrationTable();
  }

  const getCourses = async () => {

    try {
      //setIsLoading(true);
      const response = await fetch(`${BASE_URL}/course/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });
      const result = await response.json();
      if (response.ok) {
        // toast.success("Registration completed! Check the table below", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        setCourses(result);
      }
      //setIsLoading(false);
      console.log(result);
    } catch (error) {
      //setIsLoading(false);
      console.log(error);
    }
  };

  const getLevels = async () => {

    try {
      //setIsLoading(true);
      const response = await fetch(`${BASE_URL}/level/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });
      const result = await response.json();
      if (response.ok) {
        // toast.success("Registration completed! Check the table below", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
        setLevels(result);

      }
      //setIsLoading(false);
      console.log(result);
    } catch (error) {
      //setIsLoading(false);
      console.log(error);
    }
  };

  const setLevelAndCourses = (level_id: any)=>{
    setLevel(level_id);
    setCoursesPerLevel(courses.filter((course:any)=>course?.level_id== parseInt(level_id)));
  }


  React.useEffect(() => {
    getCourses();
    getLevels();
  }, []);

  const [courses, setCourses] = useState([]);
  const [coursesPerLevel, setCoursesPerLevel] = useState([]);
  const [levels, setLevels] = useState([]);
  const [course, setCourse] = useState(0);
  const [level, setLevel] = useState(0);
  const [attachmentRegistration, setAttachmentRegistration] = useState("");
  const [completedRegistration, setCompletedRegistration] = useState(false);
  const [registrationList, setRegistrationList]: any = useState([]);


  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(createUserSchema),
  });
  const onSubmit = (data: IFormInputs) => {
    const body = {
      bio_data: {
        name: `${data.first_name || ""} ${data.middle_name || ""} ${data.last_name || ""
          }`,
        rank: data.rank || "",
        service_number: data.service_number || "",
        age: data.age || "",
        gender: data.gender || "",
        email: data.email || "",
        phone: data.phone || "",
        marital_status: data.marital_status || "",
        image: data.image || "",
      },
      educational_background: {
        highest_qualification: data.highest_qualification || "",
        professional_qualification: data.professional_qualification || "",
        professional_military_qualification:
          data.military_courses_attended || "",
        //other_military_course: data.other_military_courses_attended || "",
      },
      other_details: {
        course_intake: data.course_intake || "",
        service_years: data.service_years || "",
        specialty: data.trade || "",
        skill_level: data.skill_level || "",
        projected_promotion_year: data.next_promotion_year || "",
        projected_upgrade_year: data.course_upgrade_year || "",
        projected_pme_year: data.next_pme_year || "",
        essay: data.essay || "",
      },
      medical: data.medical_disabilities || "",
      emergency_contact: {
        emergency_contact_name: data.emergency_contact_name || "",
        emergency_contact_phone: data.emergency_contact_phone || "",
        emergency_contact_relationship:
          data.emergency_contact_relationship || "",
        emergency_contact_address: data.emergency_contact_address || "",
      },
    };
    console.log("create Student body", body);
    createStudent(body);
  };

  const createStudent = async (data: any) => {
    console.log("creating student...");
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/admin/student/create`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await req.json();
      console.log(res);
      setIsLoading(false);
      if (res.message === "Applicantion successful") {//if (res.id) { 
        toast.success("Participant created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        reset();
        props.fetchStudents();
        props.handleCloseAll();
        return;
      }

      toast.success(res.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const request = await fetch(
        `${BASE_URL}/admin/student/${focusedRow?.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      console.log(response);
      if (response.status !== "error") {
        toast.success("participant deleted successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchStudents();
      } else {
        toast.error("Cannot delete student", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error("Error deleting student", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  const handleGraduate = async () => {

    try {
      const request = await fetch(
        `${BASE_URL}/admin/student/graduate`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: focusedRow?.id
          })
        }
      );
      const response = await request.json();
      console.log(response);
      if (response.status !== "error") {
        toast.success(response.message || "participant moved to graduate list successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchStudents();
      } else {
        toast.error("Cannot move student to graduate list", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error("Error graduating student", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  const getUserData = async () => {
    const service_number = getValues("service_number");
    const url = `${NAF_URL}/${service_number}`;
    try {
      setIsLoadingUserData(true);
      const request = await fetch(url);
      const response = await request.json();
      console.log(response);
      if (response) {
        setIsLoadingUserData(false);
        setValue("first_name", response[0].firstname, { shouldValidate: true });
        setValue("last_name", response[0].surname, { shouldValidate: true });
        setValue("middle_name", response[0].middlename || "", {
          shouldValidate: true,
        });
        setValue("rank", response[0].rank || "", { shouldValidate: true });

        let dob: string = getYears(response[0].dob).toLocaleString();
        setValue("age", dob || "", { shouldValidate: true });

        setValue("gender", response[0].sex || "", { shouldValidate: true });
        setValue("marital_status", response[0].maritalStatus || "", {
          shouldValidate: true,
        });
        setValue("email", response[0].email || "", { shouldValidate: true });
        setValue("phone", response[0].phone || "", { shouldValidate: true });
        let cse = response[0].cse;
        let cseType = response[0].type_of_commission;
        cse = cse + " " + cseType;
        setValue("course_intake", cse || "", { shouldValidate: true });
        setValue("image", response[0].passport || "", { shouldValidate: true });
        setValue("trade", response[0].specialty || "", {
          shouldValidate: true,
        });

        let serviceYears: string = getYears(
          response[0].date_of_commission
        ).toLocaleString();
        setValue("service_years", serviceYears || "", { shouldValidate: true });

        //qualifications
        let qualification = response[1]
          .map(
            (elem: {
              qualification: string;
              year: string;
              institution: string;
            }) => elem.qualification + " - " + elem.institution
          )
          .join(", ");
        setValue("professional_qualification", qualification, {
          shouldValidate: true,
        });

        //courses
        let courses = response[2]
          .map((elem: { course: string }) => elem.course)
          .join(", ");
        setValue("military_courses_attended", courses, {
          shouldValidate: true,
        });
      } else {
        toast.error("unable to get user data", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setIsLoadingUserData(false);
    } catch (error) {
      console.log("err", error);
      toast.error("unable to get user data", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoadingUserData(false);
    }
  };

  const formSubmitRegistrationHandler = async (e: any) => {
    e.preventDefault(); 

    if (course === 0) return toast.error("Course is required");
    if (attachmentRegistration === "") return toast.error("Registration file is required");
    if (level === 0) return toast.error("Level is required");

    const data = {
      course_id: course,
      level_id: level,
      students_url: attachmentRegistration
    };

    createRegistration(data);
  };

  const resetRegistrationTable = () => {
    setRegistrationList([]);
    setCompletedRegistration(false);
  }

  const createRegistration = async (data: object) => {
    console.log("data", data);
    try {
      setIsLoading(true);

      const response = await fetch(`${BASE_URL}/admin/admission/bulk`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      /*if (response.ok) {
        toast.success("Registration completed! Check the table below", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        //navigate("/dashboard/cbt-assignment");
        setCompletedRegistration(true);
        //loop through
        setRegistrationList(result);
      }*/
      setIsLoading(false);

      result?.message && toast.warning(result?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
      });

      result?.error && toast.error(result?.error, {
          position: toast.POSITION.TOP_RIGHT,
      });

      console.log(result);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleUploadRegistration = async (e: any) => {
    e.preventDefault();
    // const formData = new FormData();
    setIsLoadingUploadFile(true);
    // formData.append("file", selectedFile!);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/upload/add`,
        { file: e.target.files[0] },
        config
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Attachment has been uploaded");
          setIsLoadingUploadFile(false);
          // setUpload(false);
          setAttachmentRegistration(res.data.url);
          console.log(res)
        } else {
          toast.success(res.data.error);
          setIsLoadingUploadFile(false);
          // setUpload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.warning("Something went wrong. Try uploading again.");
        setIsLoadingUploadFile(false);
        // setUpload(false);
      });
  };

  //modals functions
  const handleStudentEditDet = (row: any) => {
    setStudentEditDet(row);
    setStudentEditModal(true);
    setModifyStudentModal(false);
    setAddStudentModal(false);
  };
  const handleModifyStudent = () => {
    setStudentEditModal(false);
    setModifyStudentModal(true);
    setAddStudentModal(false);
  };
  const handleAddStudent = () => {
    setStudentEditModal(false);
    setModifyStudentModal(true);
    setAddStudentModal(true);
  };
  const handleCloseModal = () => {
    console.log("handle close modal");
    setAddStudentModal(false);
    setStudentEditDet({});
    setStudentEditModal(false);
    setModifyStudentModal(false);
    props.handleCloseAll();
  };

  React.useEffect(() => {
    setGlobalFilter(props.setFilter);
  }, [props.setFilter]);
  React.useEffect(() => {
    setAddStudentModal(props.isAddStudent);
  }, [props.isAddStudent]);

  React.useEffect(() => {
    setNumberSelected(Object.keys(rowSelection).length);
  }, [rowSelection]);

  const columns = React.useMemo<ColumnDef<Student>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        accessorKey: "service_number",
        header: "Service Number",

        footer: (props) => props.column.id,
      },
      {
        accessorKey: "rank",
        header: "Rank",

        footer: (props) => props.column.id,
      },
      {
        accessorKey: "name",

        header: "Name",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "gender",
        header: "Gender",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "email",
        header: "Email",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "phone",
        header: "Phone number",
        footer: (props) => props.column.id,
      },
      {
        id: "action",
        header: "",
        cell: ({ row }) => (
          <div className="">
            <span
              onClick={() => {
                setFocusedRow(row.original);
                setOpenWarning(true);
              }}
              title="Remove this participant"
              style={{ color: "red", fontWeight: 600 }}
            >
              Remove
            </span>
            <hr />
            <span
              onClick={() => {
                setFocusedRow(row.original);
                setOpenWarningGraduate(true);
              }}
              title="Graduate this participant"
              style={{ color: "green", fontWeight: 600, marginTop: "1rem" }}
            >
              Graduate
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const [data, setData] = React.useState(props.students);
  React.useEffect(() => {
    setData(props.students);
  }, [props.students]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <>
      <ToastContainer />

      <ReactStrapModal
        isOpen={studentEditModal}
        toggle={() => handleCloseModal()}
        centered={true}
        scrollable={true}
      >
        <ModalBody>
          <div className={style.studentModal}>
            <div className="header">
              <button className="btn secondary" onClick={handleModifyStudent}>
                Edit disabled={true}
              </button>
              <X className="icon" onClick={handleCloseModal} />
            </div>
            <div className="bodyMod">
              <h3>Student’s Information</h3>
              <div className="formGroup">
                <img src={studentEditDet.passport} />
                <input defaultValue={studentEditDet.first_name} readOnly />
                <input defaultValue={studentEditDet.last_name} readOnly />
                <input defaultValue={studentEditDet.regno} readOnly />
                <input defaultValue={studentEditDet.level} readOnly />
                <input defaultValue={studentEditDet.email} readOnly />
                <input defaultValue={studentEditDet.phone} readOnly />
              </div>
            </div>
          </div>
        </ModalBody>
      </ReactStrapModal>

      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this user?"
        action={handleDelete}
      />

      <Confirm
        open={openWarningGraduate}
        setOpen={setOpenWarningGraduate}
        title="warning"
        message="Are u sure you want to move this user to the graduate list?"
        action={handleGraduate}
      />

      <ReactStrapModal
        isOpen={addStudentModal}
        toggle={() => handleCloseModal()}
        centered={true}
        scrollable={true}
        size="lg"
      >
        <ModalBody>
          <div className="header">
            <h3 className="mTitle">Register Participant{bulkRegType && "s"}
              <X style={{ float: 'right' }} className="icon" onClick={() => handleCloseModal()} /></h3>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <div className="formGroup">
                <h4>Bulk Registration <input type="checkbox" checked={bulkRegType} onChange={() => handleRegType()} /></h4>

              </div>
            </Grid>

            
          </Grid>


          {isLoading && <LoadingSpinner />}
          {
            bulkRegType && <>
              <Grid item xs={12} sm={12}>
              <h4>
              <span style={{paddingRight: "2rem"}}>Level</span>
                <select className="formGroup" onChange={(e:any)=>setLevelAndCourses(e.target.value)} style={{padding:".2rem"}}>
                  <option value="0" selected>--</option>
                  {
                    levels?.map((level: any) => (
                      <option value={level?.id}>{level?.name}</option>
                    ))
                  }
                </select>
              </h4>
            </Grid>

            <Grid item xs={12} sm={12}>
              <h4>
                <span style={{paddingRight: "1rem"}}>Course</span>
                <select className="formGroup"  onChange={(e:any)=>setCourse(e.target.value)} style={{padding:".2rem"}}>
                  <option value="0" selected>--</option>
                  {
                    coursesPerLevel?.map((course: any) => (
                      <option value={course?.id}>{course?.code} - {course?.name}</option>
                    ))
                  }
                </select>
              </h4>
            </Grid>

              <Grid item xs={12} sm={12}>
                <form onSubmit={formSubmitRegistrationHandler}>

                  <div className="form-row" style={{ marginTop: "1rem" }}>
                    <h5>
                      <label htmlFor="upload">Upload Registration File</label>
                      <input style={{ width: "100rem" }}
                        type="file"
                        name="upload"
                        id="upload" accept=".xls,.xlsx"
                        onChange={handleUploadRegistration}
                      />
                      {isLoadingUploadFile&&<span color="primary">processing upload...</span>}
                    </h5>
                  </div>

                  <div className="divider"></div>
                  <div className="upload">
                    <Button style={{ backgroundColor: "#152E88", fontWeight: 300 }} variant="contained" type="submit">
                      {isLoading ? "Processing..."/*<Spinner size="2" />*/ : "Register"}
                    </Button>
                  </div>
                  <br /><hr/> 
                </form>
              </Grid>

              {/* 
              <h4 style={{ color: "#152E88", fontWeight: 300 }}>
                Registration List ({registrationList?.length})
                <span color="primary" title="Reset Registration List"
                  style={{ float: "right", fontStyle: "italic", cursor: "pointer" }}
                  onClick={() => resetRegistrationTable()}>Reset</span>
              </h4>
              <Table responsive striped size="sm">
                <thead>
                  <tr>
                    <th className="scoreTableHeading" style={{ color: "#152E88", textAlign: "center" }}>
                      Serial
                    </th>
                    <th className="scoreTableHeading" style={{ color: "#152E88" }}>
                      Service Number
                    </th>
                    <th className="scoreTableHeading" style={{ color: "#152E88" }}>
                      Participant
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {registrationList?.map((item: any, i: number) => (
                    <tr key={item.id}>
                      <td>
                        <p className="scoreTableData" style={{ textAlign: "center" }}>
                          {i + 1}
                        </p>
                      </td>
                      <td>
                        <p className="scoreTableData">
                          {item.service_number}
                        </p>
                      </td>
                      <td>
                        <p className="scoreTableData">
                          {item.rank} {item.name}
                        </p>
                      </td>

                    </tr>
                  ))}
                  {registrationList?.length == 0 && (<tr><td colSpan={3}><p className="scoreTableData">No submissions recorded.</p></td></tr>)}
                </tbody>
              </Table>
               */}
            </>
          }
          {!bulkRegType &&
            <div className={style.studentModal}>
              <div className="bodyMod">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>

                    <Grid item xs={12}>
                      <Box style={{ display: "flex", gap: 5 }}>
                        <Typography>Bio Data</Typography>
                        {isLoadingUserData && <Spinner size="1" />}
                      </Box>
                    </Grid>

                    <>

                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("service_number")}
                            placeholder="Service Number"
                            onBlur={() => getUserData()}
                          />
                          <p>{errors.service_number?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("rank")}
                            placeholder="rank"
                            disabled={true}
                          />
                          <p>{errors.rank?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("email")}
                            placeholder="email"
                            disabled={true}
                          />
                          <p>{errors.email?.message}</p>
                        </div>
                      </Grid>
                      {/* end of first row */}
                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("first_name")}
                            placeholder="First Name"
                            disabled={true}
                          />
                          <p>{errors.first_name?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("middle_name")}
                            placeholder="Middle Name"
                            disabled={true}
                          />
                          <p>{errors.middle_name?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("last_name")}
                            placeholder="Last Name"
                            disabled={true}
                          />
                          <p>{errors.last_name?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("phone")}
                            placeholder="phone"
                            disabled={true}
                          />
                          <p>{errors.phone?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("marital_status")}
                            placeholder="Marital Status"
                            disabled={true}
                          />
                          <p>{errors.marital_status?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("gender")}
                            placeholder="Gender"
                            disabled={true}
                          />
                          <p>{errors.gender?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="formGroup">
                          <input
                            {...register("age")}
                            placeholder="Age"
                            disabled={true}
                          />
                          <p>{errors.age?.message}</p>
                        </div>
                      </Grid>
                      {/* end of bio data */}
                      <Grid item xs={12}>
                        <Box style={{ display: "flex", gap: 5 }}>
                          <Typography>Educational Background</Typography>
                          {isLoadingUserData && <Spinner size="1" />}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div className="formGroup">
                          <input
                            {...register("highest_qualification")}
                            placeholder="Highest Education qualification"
                          />
                          <p>{errors.highest_qualification?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="formGroup">
                          <input
                            {...register("professional_qualification")}
                            placeholder="Professional Qualification"
                            disabled={true}
                          />
                          <p>{errors.professional_qualification?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="formGroup">
                          <input
                            {...register("military_courses_attended")}
                            placeholder="Military Educational Courses attended"
                            disabled={true}
                          />
                          <p>{errors.military_courses_attended?.message}</p>
                        </div>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                    <div className="formGroup">
                      <input
                        {...register("other_military_courses_attended")}
                        placeholder="other_military_courses_attended"
                      />
                      <p>{errors.other_military_courses_attended?.message}</p>
                    </div>
                  </Grid> */}
                      {/* end of eductional data */}
                      <Grid item xs={12}>
                        <Box style={{ display: "flex", gap: 5 }}>
                          <Typography>Other Details</Typography>
                          {isLoadingUserData && <Spinner size="1" />}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className="formGroup">
                          <input
                            {...register("course_intake")}
                            placeholder="Course on commission/intake"
                            disabled={true}
                          />
                          <p>{errors.course_intake?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className="formGroup">
                          <input
                            {...register("service_years")}
                            placeholder="Number of years in service"
                            disabled={true}
                          />
                          <p>{errors.service_years?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className="formGroup">
                          <input
                            {...register("trade")}
                            placeholder="Specialty/Trade"
                            disabled={true}
                          />
                          <p>{errors.trade?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="formGroup">
                          <input
                            {...register("skill_level")}
                            placeholder="Mustering/ Skill level (for Airman/Airwomen)"
                          />
                          <p>{errors.skill_level?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="formGroup">
                          <input
                            {...register("next_promotion_year")}
                            placeholder="Projected year of next promotion"
                          />
                          <p>{errors.next_promotion_year?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="formGroup">
                          <input
                            {...register("course_upgrade_year")}
                            placeholder="Projected year of nomination for upgrading course (NCOs only)"
                          />
                          <p>{errors.course_upgrade_year?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="formGroup">
                          <input
                            {...register("next_pme_year")}
                            placeholder="Projected year for next PME course (Specify Course)"
                          />
                          <p>{errors.next_pme_year?.message}</p>
                        </div>
                      </Grid>
                      {/* end of additional information */}
                      <Grid item xs={12}>
                        <Typography>Others</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="formGroup">
                          <Typography style={{ fontSize: "14px" }}>
                            Do you suffer from any medical condition or disabilities
                            that may affect your studies? if yes please specify
                          </Typography>
                          <textarea
                            {...register("next_pme_year")}
                            placeholder="Enter text..."
                          />
                          <p>{errors.next_pme_year?.message}</p>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography>Emergency contact</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="formGroup">
                          <input
                            {...register("emergency_contact_name")}
                            placeholder="emergency_contact_name"
                          />
                          <p>{errors.emergency_contact_name?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="formGroup">
                          <input
                            {...register("emergency_contact_address")}
                            placeholder="emergency_contact_address"
                          />
                          <p>{errors.emergency_contact_address?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="formGroup">
                          <input
                            {...register("emergency_contact_phone")}
                            placeholder="emergency_contact_phone"
                          />
                          <p>{errors.emergency_contact_phone?.message}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="formGroup">
                          <input
                            {...register("emergency_contact_relationship")}
                            placeholder="emergency_contact_relationship"
                          />
                          <p>{errors.emergency_contact_relationship?.message}</p>
                        </div>
                      </Grid>

                    </>


                  </Grid>

                  {isLoading ? (
                    <Spinner size="1" />
                  ) : (
                    <button className="btn" type="submit">
                      Save
                    </button>
                  )}
                </form>
              </div>
            </div>
          }

        </ModalBody>
      </ReactStrapModal>

      <table className={style.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                {...table.getRowModel()}
              // onClick={() => handleStudentEditDet(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.isLoadingStudents && (<h4 style={{ padding: ".5rem 1.2rem" }}>Loading Students...</h4>)}
      <div className={style.tableMisc}>
        <div className="nextPrevious">
          <button
            className=""
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className=""
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className=""
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="pageCounter">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className=""
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div></div>
      </div>
    </>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}
StudentDataTable.propTypes = {};

export default StudentDataTable;
