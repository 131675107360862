import React, { Key, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel, Button, Grid } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";

import { useStyles } from "./courses.style";
import Layout from "../../../components/Layout";
import { Books, PlusBig, WhiteElipses } from "../../../assets/svg";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  selectCourses,
  selectCourseListAdd,
} from "../../../store/features/course/courseSlice";

import { selectRole, selectUser } from "../../../store/features/auth/authSlice";
import {
  fetchCourses,
  fetchAddCourses,
  addCourses,
} from "../../../store/features/course/courseThunk";
import { getCourseDueDate, Role } from "../../../shared";
import { Spinner } from "../../../components/common/Spinner";
import { CourseOptionMenu } from "../../../components/common/courseOptionMenu";
import LoadingSpinner from "../../../components/common/Loader";

interface Props {}

const Courses = (props: Props) => {
  const style = useStyles();
  const [isLoading, setIsloading] = useState(false);

  const [isAddCourseModal, setIsAddCourseModal] = React.useState(false);
  const handleAddCourseModal = () => {
    isAddCourseModal ? setIsAddCourseModal(false) : setIsAddCourseModal(true);
  };

  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectRole);

  const courses = useAppSelector(selectCourses);
  const listAddCourses = useAppSelector(selectCourseListAdd);
  const user = useAppSelector(selectUser);

  const getCourses = async () => {
    await dispatch(fetchCourses(userRole));
  };

  const getAddCoursesList = async () => {
    await dispatch(fetchAddCourses(user.level_id));
  };

  React.useEffect(() => {
    Role.LECTURER && getCourses();
    Role.STUDENT && getAddCoursesList();
  }, [isLoading]);
  //Fetch list of courses to add

  const options = [{ name: "Drop", link: "" }];
  // React.use
  const handleAddCourse = async (e: any) => {
    e.preventDefault();
    // get values of all the submitted checkboxes
    const values = Array.from(e.target.elements)
      .filter((el: any) => el.checked)
      .map((el: any) => el.value);
    console.log(values);
    setIsloading(true);
    await dispatch(addCourses({ course_ids: values }))
      .then((res: any) => {
        setIsloading(false);
        // Set the message in toaster
        toast.success(res.payload.message);

        // Close the modal
        handleAddCourseModal();
        console.log(res.payload.message);
      })
      .catch((err: any) => {
        console.log(err);
        setIsloading(false);
      });
  };
  const navigate = useNavigate();
  return (
    <Layout>
      <ToastContainer />
      <div className={style.container}>
        {isLoading && <LoadingSpinner />}
        {/* //Add Course Modal */}
        {isAddCourseModal ? (
          <div className={style.modalContainer}>
            <div className="modals">
              <Link to="" className="cancelBox" onClick={handleAddCourseModal}>
                x
              </Link>
              <div className="content">
                <form onSubmit={handleAddCourse}>
                  <div className="courseLists">
                    {listAddCourses
                    .filter((course:any)=> getCourseDueDate(course?.start_date,course?.duration_in_days) > new Date())
                    .map((course: any, i: number) => (
                      <div className="course" key={i}>
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              color="primary"
                              value={course.id}
                              name="course"
                            />
                          }
                          label={course.code + " " + course.name}
                          labelPlacement="end"
                        />
                      </div>
                    ))}

                    <Button color="primary" variant="contained" type="submit">
                      {isLoading ? <Spinner size="2" /> : "Add Courses"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {userRole === Role.STUDENT ? (
          <>
            <div className={style.dash}>
              <h5
                style={{
                  color: "#152E88",
                  fontSize: "1.5rem",
                  fontWeight: 100,
                  backgroundColor: "#F1EDEC",
                  padding: "1rem",
                }}
              >
                Courses{" "}
                <span style={{ float: "right" }}>
                  <Books />
                </span>
              </h5>
              <div className={style.main}>
                <Grid container spacing={1}>
                  {courses.map((course: any, i: number) => (
                    <Grid item md={4} sm={6} xs={12} key={i}>
                      <div className="course-det">
                        {
                        getCourseDueDate(course?.start_date,course?.duration_in_days) > new Date() && (
                        <CourseOptionMenu
                          options={options}
                          className="icon"
                          id={course.id}
                          key={course.id}
                        />
                        )
                        }

                        <div className="details">
                          <h4>{course.code}</h4>
                          <h5>{course.name}</h5>
                        </div>
                      </div>
                    </Grid>
                  ))}
                  <Grid item md={4} sm={6} xs={12}>
                    <div className="course-add">
                      <PlusBig onClick={handleAddCourseModal} />
                      <h6>Add course</h6>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className={style.dash}>
              <h5
                style={{
                  color: "#152E88",
                  fontSize: "1.5rem",
                  fontWeight: 100,
                  backgroundColor: "#F1EDEC",
                  padding: "1rem",
                }}
              >
                Courses{" "}
                <span style={{ float: "right" }}>
                  <Books />
                </span>
              </h5>
              <div className={style.main}>
                <Grid container spacing={1}>
                  {courses.map((course: any, i: number) => (
                    <Grid
                      item
                      md={4}
                      sm={6}
                      xs={12}
                      onClick={() =>
                        navigate(`students`, {
                          replace: true,
                          state: {
                            course_id: course.id,
                            // students: course.students,
                            course_code: course.code,
                            course_name: course.name,
                          },
                        })
                      }
                    >
                      <div className="course-det">
                        <WhiteElipses className="icon" />
                        <div className="details">
                          <h4>{course.code}</h4>
                          <h5>{course.name}</h5>
                          <h5>Level 1</h5>
                        </div>
                      </div>
                    </Grid>
                  ))}
                  {/* <Grid item md={4} sm={6} xs={12}>
                    <div className="course-add">
                      <PlusBig />
                      <h6>Add course</h6>
                    </div>
                  </Grid> */}
                </Grid>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

Courses.propTypes = {};

export default Courses;
