import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAppDispatch, useAppSelector } from '../../store'
import { clearError, selectError, selectIsErrorMsg, selectIsErrroCode } from '../../store/features/error/errorSlice'
import { toast } from 'react-toastify'

export const ErrorBoundry = ({ children }: any) => {
    const isError = useAppSelector(selectError)
    const errorMgs = useAppSelector(selectIsErrorMsg)
    // const statusCode = useAppSelector(selectIsErrroCode)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (isError) {
            // toast.error(errorMgs || "Something Went Wrong");
            dispatch(clearError())
        }
        return () => {
            dispatch(clearError())
        }
    }, [isError])

    return children

}

ErrorBoundry.propTypes = {}
