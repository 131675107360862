import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./payFees.style";
import Layout from "../../../components/Layout";
import { Button, Grid } from "@material-ui/core";

import { Link } from "react-router-dom";

interface Props {}

const PayFees = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const style = useStyles();
  return (
    <Layout>
      {/* Main Container */}
      <div className={style.container}>
        <div className={style.dash}>
          <h5>Pay Fees</h5>

          <div className={style.main}>
            <div className="pending">
              <h6>Pending payment(s)</h6>
              <div className="left">
                <h6>Total &#8358; 6,200</h6>
                <Button color="primary" variant="contained">
                  Pay All
                </Button>
              </div>
            </div>
            <div className="payList">
              <div className="item">
                <h6 className="name">Department Registration</h6>
                <h6 className="amount">&#8358;1,200</h6>
                <Button color="primary" variant="contained">
                  Pay
                </Button>
              </div>
              <div className="item">
                <h6 className="name">Late Registration </h6>
                <h6 className="amount">&#8358;5,200</h6>
                <Button color="primary" variant="contained">
                  Pay
                </Button>
              </div>
            </div>
            <div className="transactions">
              <h5>All Transaction History</h5>
              <div className="transactionList">
                <div className="header">
                  <h6>Invoice ID</h6>
                  <h6>Amount</h6>
                  <h6>Date</h6>
                  <h6>Payment Method</h6>
                </div>
                <div className="item">
                  <h6>#007652</h6>
                  <h6>50,000</h6>
                  <h6>12, Aug 2021</h6>
                  <h6>Card</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

PayFees.propTypes = {};

export default PayFees;
