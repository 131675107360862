import React from "react";
import Main from "./pages";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles/app.css";
import "./styles/fonts.css";

function App() {
  return (
    <React.Fragment>
      <Main />
      {/* <ToastContainer
        limit={1}
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName="toastBody"
        // toastStyle={{ backgroundColor: "#152E88", color: '#FFFFF' }}
        // progressStyle={{ backgroundColor: "#152E88" }}
      /> */}
    </React.Fragment>
  );
}

export default App;
