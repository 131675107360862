import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    background: colors.white,
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",

    margin: "13px 35px 13px 40px",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    // position: "fixed",

    "& .btn": {
      minWidth: "100px",
      padding: "6px 9px",
      height: "35px",
      border: "none",
      background: colors.blue,
      borderRadius: "8px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "22px",
      /* identical to box height */
      color: colors.white,
      "&.secondary": {
        padding: "3px 6px !important",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "21px",
        height: "26px",
        width: "72px",
        background: colors.white,
        color: colors.blue,
        border: "1px solid #E0E0E0",
        borderRadius: "6px",

        "&.disabled": {
          border: "1px solid #E0E0E0",
          color: " #D9D9D9",
        },
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      margin: "1rem",
    },
  },
  header: {
    padding: "38px 177px 0px 38px",
    [theme.breakpoints.down("xs")]: {
      padding: "15px",
    },

    "& .search": {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      gap: "14px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
      "& .searchInput": {
        width: "399px",
        height: "48px",
        padding: "18px 20px",
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "22px",
        lineHeight: "33px",
        /* identical to box height */
        color: colors.black,

        "&:placeholder": {
          color: "#C4C4C4",
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          height: "30px",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "21px",
        },
      },
    },
    "& h4.title": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "45px",
      /* identical to box height */
      color: colors.blue,
    },
    "& .tabs": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
      [theme.breakpoints.down("xs")]: {
        gap: "20px",
        padding: "15px",
        flexDirection: "column",
        alignItems: "start",
      },

      "& .tabLinks": {
        display: "flex",
        // gap: "24px",
        [theme.breakpoints.down("xs")]: {
          justifyContent: "space-between",
          width: "100%",
        },

        "& .link": {
          display: "flex",
          justifyContent: "center",
          width: "112px",

          "&.active": { borderBottom: "3px solid " + colors.blue },
          [theme.breakpoints.down("xs")]: {
            width: "100px",
          },

          "& a": {
            textDecoration: "none",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",

            lineHeight: "25px",
            /* identical to box height */
            color: colors.blue,
          },
        },
      },
    },
  },
  body: {
    "& .table": {
      width: "100%",
      overflowY: "auto",
      [theme.breakpoints.up("sm")]: {
        height: "calc(90vh - 200px)",
        overflow: "auto",
      },
    },
  },
}));
