import { createAsyncThunk } from '@reduxjs/toolkit'
import { recordService } from '../../../services'
import { RecordAction } from './recordType'

export const fetchStCourseResult = createAsyncThunk(RecordAction.GENHSTCOURSERESULT, async (body: any) => {
    const response = await recordService.generateStudentCourseResult(body)
    return response.data
})

export const fetchStSemesterResult = createAsyncThunk(RecordAction.GENHSTSEMESTERRESULT, async (body: any) => {
    const response = await recordService.generateStudentSemesterResult(body)
    return response.data
})

export const ADDSTCOURSERECORD = createAsyncThunk(RecordAction.ADDSTCOURSERECORD, async (body: any) => {
    const response = await recordService.addStudentCouseRecord(body)
    return response.data
})
