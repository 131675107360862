import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useStyles } from "./website.style";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import LoadingSpinner from "../../../../components/common/Loader";
import { toast, ToastContainer } from "react-toastify";
import Confirm from "../../../../components/common/Confirm";
import { Divider } from "@material-ui/core";
import TextEditor from "../../../../components/common/TextEditor";
import { Spinner } from "../../../../components/common/Spinner";
import axios from "axios";
import { DeleteRounded, EditRounded } from "@material-ui/icons";

interface Props {}

const Slider = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openCreateSlider, setOpenCreateSlider] = useState(false);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [slides, setSlides] = useState([]);
  const [focusedSlide, setFocusedSlide] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSlides = async () => {
    try {
      const request = await fetch(`${BASE_URL}/slider/slides`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      console.log(response);
      if (request.ok) {
        setSlides(response);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching info");
      console.log(error);
      setIsLoading(false);
    }
  };

  const createSlide = async (data: any) => {
    if (!title || !imageUrl || !description) {
      return toast.error("All fields are required");
    }
    try {
      setIsSubmitting(true);
      const request = await fetch(`${BASE_URL}/slider/slides`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const response = await request.json();
      setIsSubmitting(false);
      console.log(response);
      if (request.ok) {
        fetchSlides();
        toast.success("Slide created successfully");
        handleClose();
      }
    } catch (error) {
      setIsSubmitting(false);
      toast.error("Error fetching info");
      console.log(error);
    }
  };

  const updateSlide = async (data: any) => {
    if (!title || !imageUrl || !description) {
      return toast.error("All fields are required");
    }
    try {
      setIsSubmitting(true);
      const request = await fetch(
        `${BASE_URL}/slider/slides/${focusedSlide.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
      const response = await request.json();
      setIsSubmitting(false);
      console.log(response);
      if (request.ok) {
        fetchSlides();
        toast.success("Slide updated successfully");
        handleClose();
      }
    } catch (error) {
      setIsSubmitting(false);
      toast.error("Error fetching info");
      console.log(error);
    }
  };
  const deleteSlide = async (data: any) => {
    try {
      const request = await fetch(
        `${BASE_URL}/slider/slides/${focusedSlide.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      console.log(response);
      if (request.ok) {
        fetchSlides();
        toast.success("Slide deleted successfully");
        handleClose();
      }
    } catch (error) {
      toast.error("Error fetching info");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSlides();
  }, []);

  useEffect(() => {
    if (focusedSlide) {
      setTitle(focusedSlide?.title);
      setImage(focusedSlide?.image);
      setImageUrl(focusedSlide?.image);
      setDescription(focusedSlide?.description);
    }
  }, [focusedSlide]);

  const handleSubmit = () => {
    const body = {
      image: imageUrl,
      description,
      title,
    };
    if (focusedSlide) {
      updateSlide(body);
    } else {
      createSlide(body);
    }
  };

  const handleImageChange = async (event: any) => {
    setIsUploading(true);
    try {
      event.preventDefault();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Brearer " + localStorage.getItem("token"),
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}/upload/add`,
          { file: event.target.files[0] },
          config
        )
        .then((res) => {
          setIsUploading(false);
          if (res.status === 200) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setImageUrl(res.data.url);
          }
        })
        .catch((err) => {
          setIsUploading(false);
          console.log(err);
        });
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpenCreateSlider(false);
    if (focusedSlide) {
      setTitle("");
      setImage("");
      setDescription("");
    }
    setFocusedSlide(null);
  };

  return (
    <div>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this slide?"
        action={deleteSlide}
      />

      <Modal isOpen={openCreateSlider} size="lg" toggle={handleClose}>
        <ModalHeader>
          <h6>{focusedSlide ? "Update Slide" : "Create Slider"}</h6>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Input
              onChange={(e: any) => setTitle(e.target.value)}
              value={title}
              type="text"
              name="title"
              placeholder="Title"
            />
            <Input onChange={handleImageChange} type="file" name="file" />
            {isUploading ? (
              <Spinner size="2" />
            ) : (
              image && <img src={image} alt="logo" height="100" width="100" />
            )}

            <TextEditor
              type="textarea"
              name="content"
              placeholder="enter slider content"
              id="content"
              onChange={(content: any) => setDescription(content)}
              value={description}
            />
            <Button style={{ background: "#152E88" }} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className="">
        <div style={styles.card}>
          {
          isLoading ? <h5 style={{color: "#152E88", fontWeight: 400, padding: "1rem"}}>Loading site sliders info...</h5> :
          (
          <>
          <div style={styles.cardHeader}>
            <h6 style={styles.cardTitle}>Slides</h6>
            <Button onClick={() => setOpenCreateSlider(true)}>Add new</Button>
          </div>
          <Divider style={{ color: "#E0E0E0" }} />
          <div style={styles.cardContent}>
            {slides?.map((item: any, i: any) => (
              <React.Fragment key={i}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: 4,
                  }}
                  key={i}
                >
                  <div style={{width: "100%"}}>
                    <h6>{item.title}</h6>
                    <img  style={{height:"20rem", width:"45rem"}} src={item.image} />
                    <div style={{textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>

                  <div style={{marginLeft:".5rem"}}>                  
                  <EditRounded
                    onClick={() => {
                      setFocusedSlide(item);
                      setOpenCreateSlider(true);
                    }}
                    fontSize="small"
                    color="primary" titleAccess="Edit this slide"
                    style={{margin: "1rem .1rem .4rem .1rem", cursor:"pointer"}}
                  />                  
                  <DeleteRounded
                    onClick={() => {
                      setFocusedSlide(item);
                      setOpenWarning(true);
                    }}
                    fontSize="small"
                    color="error" titleAccess="Delete this slide" 
                    style={{cursor:"pointer"}}
                  />
                  </div>

                </div>
                <Divider />
              </React.Fragment>
            ))}
          </div>
          </>
          )
          }
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    margin: "20px 30px",
  },
  card: {
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    marginBottom: "12px",
  },
  cardHeader: {
    padding: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardTitle: {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "22px",
    color: "#003262",
  },
  cardContent: {
    padding: "16px 8px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
};

Slider.propTypes = {};

export default Slider;
