import { Routes, Route } from "react-router-dom";
import { ErrorBoundry } from "../components";
import PageLoader from "../components/common/PageLoader";
import { PublicRoute } from "../components/Routes";
import AdmissionCheck from "./Landing/Admission/admissionCheck";
import AdmissionStatus from "./Landing/Admission/admissionStatus";
import InstructorLogin from "./Auth/instructorsLogin";
import ParticipantLogin from "./Auth/participantsLogin";
import ApplicationPage from "./Auth/registration";
import CBT from "./Cbt";
import Dashboard from "./Dashboard";
import Landing from "./Landing";
import ForgotPassword from "./Auth/forgotPassword";
import ResetPassword from "./Auth/resetPassword";
import AboutUs from "./AboutUs";
import SuccessRegistration from "./Auth/successRegistration";
import SuccessPasswordReset from "./Auth/successPasswordReset";
import VerifyAccount from "./Auth/verifyUser";
import { useAppDispatch } from "../store";
import { fetchWebsite } from "./store/features/website/websiteThunk";
import { useEffect } from "react";
import Updates from "./Updates";

import ReactGA from 'react-ga';
const TRACKING_ID = "G-F0TS0MC1DJ"; 
ReactGA.initialize(TRACKING_ID);

const Main = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchWebsite());
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ErrorBoundry>
      <Dashboard />
      <PageLoader>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/checkAdmission" element={<AdmissionCheck />} />
          <Route path="/admissionStatus" element={<AdmissionStatus />} />
          <Route
            path="/instructors/login"
            element={<PublicRoute roles={[]} component={InstructorLogin} />}
          />
          <Route
            path="/participants/login"
            element={<PublicRoute roles={[]} component={ParticipantLogin} />}
          />
          <Route
            path="/application"
            element={<PublicRoute roles={[]} component={ApplicationPage} />}
          />
          <Route
            path="/verify"
            element={<PublicRoute roles={[]} component={VerifyAccount} />}
          />

          <Route
            path="/forgot-password"
            element={<PublicRoute roles={[]} component={ForgotPassword} />}
          />
          <Route
            path="/reset-password"
            element={<PublicRoute roles={[]} component={ResetPassword} />}
          />
          <Route
            path="/success-registration"
            element={<PublicRoute roles={[]} component={SuccessRegistration} />}
          />
          <Route
            path="/success-password-reset"
            element={
              <PublicRoute roles={[]} component={SuccessPasswordReset} />
            }
          />
        </Routes>
      </PageLoader>
      <CBT />
    </ErrorBoundry>
  );
};

export default Main;
