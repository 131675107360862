import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    autoflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "start",

      gap: "8px",
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "24px",
      color: colors.black,
      display: "flex",
      alignItems: "center",
      gap: "6px",

      "& .icon": {
        // padding: "9.25px 12.99px",
        background: "#E9E9E9",
        borderRadius: "5px",
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& .date": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      color: colors.black,
      "& .remaining": {
        fontWeight: 500 + " !important",
        fontStyle: "italic",
        "& span": {
          color: " #FF0F00",
        },
      },
    },

    "& button": {
      border: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "22px",
      lineHeight: "33x",
      color: colors.white,
      cursor: "pointer",
      // width: "120px",
      height: "40px",
      background: "rgba(75, 211, 123, 0.85)",
      borderRadius: "6px",
    },
  },
  main: {
    marginTop: "1rem",
    position: "relative",
    overflowX: "hidden",  

    "& .questionsContainer": {
      display: "grid",
      gap: "16px",
      height: "70vh",
      overflowY: "auto",      
      gridTemplateColumns: "1fr",  

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
        gap: "16px",
        height: "100%",
        overflowY: "none",
      },
      "& .questions": {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        "& textarea": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: "24px",
          color: colors.black,
          padding: "8px",
        },
        "& > p.question": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: "24px",
          color: colors.black,
        },
        "& >div.options": {
          display: "flex",
          flexDirection: "column",
          gap: "6px",
        },
      },
      "& .border": {
        borderRight: "2px solid #E9E9E9",
        margin: "30px 0px",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
    },
    "& .footBottons": {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        marginTop: "16px",
        flexDirection: "column",
        gap: "16px",
      },
    },
    "& button": {
      border: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "22px",
      lineHeight: "33x",
      color: colors.white,
      cursor: "pointer",

      background: "rgba(75, 211, 123, 0.85)",
      borderRadius: "6px",
      padding: "4px 8px",
      "&:hover": {
        background: colors.blue,
        transition: "all 0.5s",
      },
    },
    "& .pagination": {
      display: "flex",
      listStyleType: "none",
      gap: "6px",
      alignItems: "center",
      paddingTop: "10px",
      //   marginTop: "81px",
      //   position: "absolute",
      //   bottom: "7px",
      //   right: "20px",
      justifyContent: "end",
      "& li": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& a": {
          textDecoration: "none",
          color: colors.white,
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 640,
          fontSize: "1rem",
          lineHeight: "24px",
        },
      },

      "& .icon": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        padding: "4.33px 4.92px",
        cursor: "pointer",
      },
      "& .active": {
        background: " #E5E5E5 !important",
        color: "#fff !important",
      },
    },
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modals": {
      margin: "auto",
      width: "562px;",
      maxWidth: "90%",
      background: colors.white,
      position: "relative",

      minHeight: "50%",
      transition: ".5s",
    },
    "& .cancelBox": {
      textDecoration: "none",
      position: "absolute",
      padding: "1.4375rem",
      right: 0,
      top: 0,
      color: colors.white,
      background: colors.blue,
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "89px 15px",
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "27px",
        color: colors.black,
        marginTop: "22px",
        marginBottom: "8px",
      },
      "& p": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
        color: colors.black,
        textAlign: "center",
      },
      "& .buttons": {
        marginTop: "46px",
        display: "flex",
        gap: "80px",
        [theme.breakpoints.down("xs")]: {
          gap: "8%",
        },

        "& button": {
          width: "120px",
          height: "46px",

          background: colors.white,
          border: "1px solid " + colors.blue,
          borderRadius: "8px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "27px",
          color: colors.blue,

          "&.primary": {
            background: colors.blue,
            color: colors.white,
          },
        },
      },
    },
  },
}));
