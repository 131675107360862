import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    fetchAssignment,
    fetchCourseAssignment,
    submitAssignment,
    createAssignment,
    fetchLecCourseAss,
    markAssignment,
    publishAssignment,
    unPublishAssignment,
    fetchLeseAss
} from './assignmentThunk'

const initialState = {
    assignments: [] as any,
    courseAssignments: [] as any,
    // lecAssignments: [] as any,
    error: '' as string,
    status: '' as string
}

export const assignmentSlice = createSlice({
    name: 'assignment',
    initialState,
    reducers: {
        clearState: (state) => {
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssignment.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetchAssignment.fulfilled, (state, action) => {
                return { ...state, assignments: action.payload, status: "fullfilled" }
            })
            .addCase(fetchAssignment.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })

            .addCase(fetchCourseAssignment.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetchCourseAssignment.fulfilled, (state, action) => {
                return { ...state, courseAssignments: action.payload, status: "fullfilled" }
            })
            .addCase(fetchCourseAssignment.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })

            .addCase(fetchLeseAss.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetchLeseAss.fulfilled, (state, action) => {
                return { ...state, assignments: action.payload, status: "fullfilled" }
            })
            .addCase(fetchLeseAss.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })

    }
})


export const selectAssignments = (state: any) => state.assignments.assignments
export const selectCourseAssignments = (state: any) => state.assignments.courseAssignments
export const selectLecAssignments = (state: any) => state.assignments.lecAssignments
