import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containerDesktop: {
    background: "#F6F4F4",
    height: "100vh",
    width: "100%",
    position: "relative",
    overflowY: "hidden",

    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    },
    // display: "grid",
    // gridTemplateColumns: "1.3fr 5fr",
    // "& .grid": {
    //   display: "grid",
    //   // gridTemplateColumns: "1.5fr 5fr",
    //   "& .grid2": {
    //     display: "grid",
    //     gridTemplateRows: "1fr",
    //     // gridTemplateColumns: "3fr 1fr",
    //     "& .grid3": { display: "grid", gridTemplateColumns: "3fr 1.5fr" },
    //   },
    // },
  },
  containerTablet: {
    background: "#F6F4F4",
    height: "100vh",
    width: "100vw",
    position: "relative",
    overflowY: "scroll",

    [theme.breakpoints.down("xs")]: {
      display: "none !important",
    },
    // [theme.breakpoints.down("sm")]: {
    //   display: "block !important",
    // },
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("xl")]: {
      display: "none !important",
    },

    // display: "grid",
    // gridTemplateColumns: "1.3fr 5fr",
    // "& .grid": {
    //   display: "grid",
    //   // gridTemplateColumns: "1.5fr 5fr",
    //   "& .grid2": {
    //     display: "grid",
    //     gridTemplateRows: "1fr",
    //     // gridTemplateColumns: "3fr 1fr",
    //     "& .grid3": { display: "grid", gridTemplateColumns: "3fr 1.5fr" },
    //   },
    // },
  },
  containerMobile: {
    background: "#F6F4F4",
    height: "100vh",
    width: "100vw",
    position: "relative",
    overflowY: "scroll",

    [theme.breakpoints.up("sm")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("xl")]: {
      display: "none !important",
    },
    // display: "grid",
    // gridTemplateColumns: "1.3fr 5fr",
    // "& .grid": {
    //   display: "grid",
    //   // gridTemplateColumns: "1.5fr 5fr",
    //   "& .grid2": {
    //     display: "grid",
    //     gridTemplateRows: "1fr",
    //     // gridTemplateColumns: "3fr 1fr",
    //     "& .grid3": { display: "grid", gridTemplateColumns: "3fr 1.5fr" },
    //   },
    // },
  },
}));
