import { createAsyncThunk } from "@reduxjs/toolkit";
import { notesService } from "../../../services";
import { NoteAction } from "./noteType";

export const fetchNotes = createAsyncThunk(
  NoteAction.FETCHNOTE,
  async (user?: string) => {
    const response = await notesService.getAll(user);
    return response.data;
  }
);

export const fetcLecthNotes = createAsyncThunk(
  NoteAction.FETCLECHNOTE,
  async () => {
    const response = await notesService.getLecAll();
    return response.data;
  }
);

export const fetchCourseNotes = createAsyncThunk(
  NoteAction.FETCHCOURSENOTE,
  async (courseId: number) => {
    const response = await notesService.getCourseNote(courseId);
    return response.data;
  }
);

export const publishNote = createAsyncThunk(
  NoteAction.PUBLISHNOTE,
  async (note: any) => {
    const response = await notesService.publishNote(note);
    return response.data;
  }
);

export const unPublish = createAsyncThunk(
  NoteAction.UNPUBLISH,
  async (note: any) => {
    const response = await notesService.unPublish(note);
    return response.data;
  }
);

export const deleteNote = createAsyncThunk(
  NoteAction.DELETENOTE,
  async (id: number) => {
    const response = await notesService.deleteNote(id);
    return response.data;
  }
);
export const shareNote = createAsyncThunk(
  NoteAction.SHARENOTE,
  async (note: any) => {
    const response = await notesService.shareNote(note);
    return response.data;
  }
);
