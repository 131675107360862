import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export interface QueryParams {
    [field: string]: any
}

export enum HttpMethods {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    PUT = 'PUT'
}

export class API {
    static async request(method: HttpMethods, url: string, body: any) {
        const BaseURL = `${process.env.REACT_APP_BASEURL}${url}`
        const token = localStorage.getItem("token");
        const headers: any = {}
        headers.Accept = 'application/json'
        headers["Content-Type"] = 'application/json'
        headers["Access-Control-Allow-Origin"] = `${process.env.REACT_APP_BASEURL}`

        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        const BodyRequest = body ? body : undefined;

        const config: AxiosRequestConfig = {
            method: method,
            url: BaseURL,
            headers: headers,
            data: BodyRequest,
        }

        const request: AxiosResponse = await axios(config);

        return request;
    }



    static async get(url: string, body?: any) {
        return await this.request(HttpMethods.GET, url, body);
    }
    static async post(url: string, body?: any) {
        return await this.request(HttpMethods.POST, url, body);
    }
    static async put(url: string, body?: any) {
        return await this.request(HttpMethods.PUT, url, body);
    }

    static async delete(url: string, body?: any) {
        return await this.request(HttpMethods.DELETE, url, body);
    }

    static async patch(url: string, body?: any) {
        return await this.request(HttpMethods.PATCH, url, body);
    }
    static async setToken(token: string) {
        await localStorage.setItem("token", token);
        return true;
    }

    static async unsetToken() {
        await localStorage.removeItem("token");
        return true;
    }
}

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       console.log("is here");
//     }
//     return error;
//   }
// );
