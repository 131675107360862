import React, { Key, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";

import { useStyles } from "./courses.style";
import { colors } from "../../../styles/color";

import Layout from "../../../components/Layout";
import { Books } from "../../../assets/svg";

import LoadingSpinner from "../../../components/common/Loader";

import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchCourseStudents } from "../../../store/features/course/courseThunk";
import { selectCourseStudents } from "../../../store/features/course/courseSlice";

interface Props {}
interface CustomizedState {
  students: any;
  course_name: string;
  course_code: string;
  course_id: number;
}

const CourseStudents = (props: Props) => {
  const style = useStyles();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const [isLoading, setIsloading] = useState(false);

  
  const dispatch = useAppDispatch();
  
  const getCourseStudents = async () => {
    await dispatch(fetchCourseStudents(state.course_id));
  };
  
  const students = useAppSelector(selectCourseStudents);

  useEffect(() => {
    getCourseStudents();
  }, [state.course_id]);

  return (
    <Layout>
      <div className={style.container} style={{height: "60%"}}>
        {isLoading && <LoadingSpinner />}

        <div className={style.dash} style={{overflowX: "hidden", overflowY: "auto"}}>
          <h5
            style={{
              color: "#152E88",
              fontSize: "1.5rem",
              fontWeight: 100,
              backgroundColor: "#F1EDEC",
              padding: "1rem",
            }}
          >
            {state.course_code} 
            <span style={{ float: "right" }}>
              <Books />
            </span>
          </h5>
          <div className={style.main}>
            <Grid container spacing={1}>
            <h5
            style={{
              color: "#152E88",
              fontSize: "1.5rem",
              fontWeight: 100,
              backgroundColor: "#F1EDEC",
              padding: "1rem",
              width: "100%",
              marginTop: "-4rem"
            }}
          >List of Participants
          <span style={{ float: "right" }}>
              Total:{" "}
              {students?.length}              
            </span>
          </h5>
            {students?.map((student: any, index: number) => (
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    backgroundColor: "#F1EDEC",
                    padding: "1rem",
                    borderRadius: "5px",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Grid item md={3} xs={12}>
                    <img
                      src={student.image}
                      alt="student"
                      height={100}
                      width={100}
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <h5 style={{ color: colors.blue }}>Name:</h5>
                      </Grid>
                      <Grid item xs={6}>
                        <h5>{student.name}</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <h5 style={{ color: colors.blue }}>Service Number:</h5>
                      </Grid>
                      <Grid item xs={6}>
                        <h5>{student.service_number}</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <h5 style={{ color: colors.blue }}>Rank:</h5>
                      </Grid>
                      <Grid item xs={6}>
                        <h5>{student.rank}</h5>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            {students?.length == 0 &&  <h5 style={{padding: "1rem"}}>Empty list</h5>}
            </Grid>
          </div>
        </div>
      </div>
    </Layout>
  );
};

CourseStudents.propTypes = {};

export default CourseStudents;
