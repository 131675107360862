import React, { useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import { useStyles } from "../events/events.style";
import { Bullet, X } from "../../../../assets/svg";
import LoadingSpinner from "../../../../components/common/Loader";
import { toast, ToastContainer } from "react-toastify";
import { format } from "date-fns";
import { Modal, ModalBody } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  DeleteForeverOutlined,
  DeleteRounded,
  EditOutlined,
  EditRounded,
} from "@material-ui/icons";
import Confirm from "../../../../components/common/Confirm";

interface Props {}

interface FormInputs {
  title: string;
  content: string;
}

const schema = yup.object().shape({
  title: yup.string().required("Required"),
  content: yup.string().required("Required"),
});

const Notice = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const [openAddNotice, setOpenAddNotice] = useState(false);
  const [allNotice, setAllNotice] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [focusedRow, setFocusedRow] = useState<{ [key: string]: any }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const [isInitLoading, setIsInitLoading] = React.useState(true);

  useEffect(() => {
    fetchALLNotice();
  }, []);

  useEffect(() => {
    // set selected field values of toggle edit
    if (focusedRow) {
      setValue("title", focusedRow?.title);
      setValue("content", focusedRow?.body);
    }
  }, [focusedRow]);

  const fetchALLNotice = async () => {
    try {
      const request = await fetch(`${BASE_URL}/notice/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      console.log(response);
      if (response.length) {
        setAllNotice(response);
      }
      setIsInitLoading(false);
    } catch (error) {
      toast.error("Error fetching instructors");
      console.log(error);
      setIsInitLoading(false);
    }
  };

  // form
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({ resolver: yupResolver(schema) });

  const onSubmit = (data: FormInputs) => {
    const body = {
      title: data.title,
      content: data.content,
    };

    if (openEdit && focusedRow) {
      updateNotice(body);
    } else {
      createNotice(body);
    }
  };

  const handleClose = () => {
    setOpenAddNotice(false);
    setOpenEdit(false);
    setFocusedRow({});
  };

  const createNotice = async (body: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/notice/create`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const res = await req.json();
      console.log(res);
      if (res.id) {
        toast.success("notice posted", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        fetchALLNotice();
        reset();
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const updateNotice = async (body: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/notice/${focusedRow?.id}`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const res = await req.json();
      console.log(res);
      if (res.id) {
        toast.success("notice updated", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        fetchALLNotice();
        reset();
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };
  const deleteNotice = async (body: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/notice/${focusedRow?.id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const res = await req.json();
      console.log(res);
      if (res.message) {
        toast.success("notice deleted", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        fetchALLNotice();
        reset();
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Layout>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this notice?"
        action={deleteNotice}
      />

      <Modal
        isOpen={openAddNotice}
        toggle={() => handleClose()}
        centered={true}
        scrollable={true}
      >
        <ModalBody>
          {isLoading && <LoadingSpinner />}
          <div className={style.addEventModal}>
            <div className="header">
              <X className="icon" onClick={() => handleClose()} />
            </div>
            <div className="bodyMod">
              {openEdit && focusedRow ? (
                <p style={{ fontWeight: "bold" }}>Update </p>
              ) : (
                <p style={{ fontWeight: "bold" }}>New notice </p>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formGroup">
                  <div className="inputGroup">
                    <input
                      className="text"
                      {...register("title")}
                      placeholder="Notice title"
                    />
                    <p>{errors.title?.message}</p>
                    <textarea
                      {...register("content")}
                      placeholder="Enter content..."
                      className="text"
                      style={{ padding: 5 }}
                    />
                    <p>{errors.content?.message}</p>
                  </div>
                  <button className="btn" type="submit">
                    Post Notice
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <div className={style.container}>
        <div className={style.header}>
          <h4 className="title">Notice Board</h4>
        </div>
        <div className={style.tabs}>
          <div className="tabLinks"></div>
          <button className="btn" onClick={() => setOpenAddNotice(true)}>
            Add new
          </button>
        </div>
        <div className={style.body}>
          <div className="events">
              {
              isInitLoading ? <h5 style={{color: "#152E88", fontWeight: 400, padding: "1rem"}}>Loading notifications...</h5> :
              (
                <>
              {allNotice
              ?.map((obj: any) => ({ ...obj, date: new Date(obj.updated_at) }))
              .sort((a, b) => b.date.getTime() - a.date.getTime())
              .map((item: any) => (
                <div
                  className="event"
                  style={{ display: "flex", alignItems: "baseLine", gap: 2 }}
                  key={item.id}
                >
                  <div className="content" style={{ flexGrow: 1 }}>
                    <h4 style={{ marginTop: 0 }}>{item.title}</h4>
                    <p>{item.body}</p>
                    <div className="details">
                      <div className="det">
                        <p>Date updated:</p>
                        <p>
                          {format(
                            new Date(item.updated_at),
                            "EEEE, MMM d, YYY"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: 20 }}>
                    {/* <EditRounded
                      fontSize="small"
                      color="primary"
                      onClick={() => {
                        setFocusedRow(item);
                        setOpenEdit(true);
                        setOpenAddNotice(true);
                      }}
                    /> */}
                    <DeleteRounded
                      fontSize="small"
                      color="error"
                      onClick={() => {
                        setFocusedRow(item);
                        setOpenWarning(true);
                      }}
                    />
                  </div>
                </div>
              ))}

                </>
              )
              }

            {allNotice?.length === 0 && !isInitLoading && 
            <h5 style={{color: "#152E88", fontWeight: 400, padding: "1rem"}}>Empty notification(s) list.</h5> }
          </div>
        </div>
      </div>
    </Layout>
  );
};

Notice.propTypes = {};

export default Notice;
