import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    autoflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      minHeight: "100%",
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
  },
  main: {
    display: "flex",
    width: "100%",
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "100%",
      margin: "3rem auto 3rem auto",
    },
    "& .form": {
      width: "70%",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },

      "& span.info": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: colors.black,
        marginBottom: "15px",
      },
    },

    "& .form-control.row": {
      display: "flex",
      // gridTemplateColumns: "4fr 1fr",
      // flexDirection:
      width: "100%",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "end",
      },
      "& div:first-child": {
        width: "100%",
      },
      "& .MuiInputBase-input ": {
        [theme.breakpoints.up("sm")]: {
          borderRight: "0",
          borderTopRightRadius: "0",
          borderBottomRightRadius: "0",
        },
      },
      "& .MuiButton-root": {
        [theme.breakpoints.up("sm")]: {
          borderLeft: "0",
          borderTopLeftRadius: "0",
          borderBottomLeftRadius: "0",
        },
        [theme.breakpoints.down("xs")]: {
          marginTop: "1rem",
        },
      },
    },
    "& .form-control": {
      marginBottom: "15px",

      "& .MuiInputBase-input ": {
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        // lineHeight: "33px",
        color: "#C4C4C4",
        padding: ".5rem",
        width: "100%",
      },
      "& .MuiButton-root": {
        float: "right",
        background: colors.blue,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "0.9rem",

        lineHeight: "22px",
        /* identical to box height */

        color: colors.white,
      },
      "& .MuiFilledInput-underline": {
        border: "0",
      },
    },
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modal": {
      margin: "auto",
      width: "31.25rem",
      maxWidth: "100%",
      background: colors.white,
      position: "relative",

      minHeight: "50%",
      transition: ".5s",
    },
    "& .cancelBox": {
      textDecoration: "none",
      position: "absolute",
      padding: "1.4375rem",
      right: 0,
      top: 0,
      color: colors.white,
      background: colors.blue,
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "3.75rem 0.625rem",
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "30px",
        lineHeight: "45px",
        color: colors.black,
        marginTop: "1.5rem",
        marginBottom: "2.5rem",
      },
      "& div": {
        marginBottom: ".5rem",
      },
      "& .MuiInputBase-input ": {
        background: colors.white,
        border: "1px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        // lineHeight: "33px",
        color: "#C4C4C4",
        padding: ".5rem",
      },
      "& .MuiButton-root": {
        background: colors.blue,
        border: "1px solid rgba(0, 0, 0, 0.7)",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "0.9rem",

        lineHeight: "22px",
        /* identical to box height */

        color: colors.white,
        width: "100%",
      },
    },
  },
}));
