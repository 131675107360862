import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchEvents, fetchRecentEvents } from "./eventThunk";

const initialState = {
  events: [] as any[],

  recentEvents: [] as any[],
  error: "" as string,
  status: "" as string,
};

export const eventeSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    clearState: (state) => {
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state, action) => {
        return { ...state, status: "loading" };
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        return { ...state, events: action.payload, status: "fullfilled" };
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message as any;
      })
      .addCase(fetchRecentEvents.fulfilled, (state, action) => {
        return { ...state, recentEvents: action.payload, status: "fullfilled" };
      });
  },
});

export const selectEvents = (state: any) => state.events.events;
export const selectRecentEvents = (state: any) => state.events.recentEvents;
