import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useStyles } from "./schoolSystem.style";
import { ChevDown } from "../../../../assets/svg/";
import {
  CourseDataTable,
  LevelDataTable,
  SemesterDataTable,
  SessionDataTable,
} from "../../../../components/common/DataTables/schoolSystem";
import { toast, ToastContainer } from "react-toastify";

interface Props {}

const SchoolSystem = (props: Props) => {
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const [filter, setFilter] = React.useState("");
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [addCourses, setIsAddCourses] = React.useState(false);
  const [addLevels, setIsAddLevels] = React.useState(false);
  const [addSemesters, setIsAddSemester] = React.useState(false);
  const [addSessions, setIsAddSessions] = React.useState(false);
  const [isCoursesTab, setIsCoursesTab] = React.useState(true);
  const [isLevelsTab, setIsLevelsTab] = React.useState(false);
  const [isSemestersTab, setIsSemestersTab] = React.useState(false);
  const [isSessionsTab, setIsSessionsTab] = React.useState(false);
  const [sessions, setSessions] = React.useState([]);
  const [semesters, setSemesters] = React.useState([]);
  const [levels, setLevels] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [staff, setStaff] = React.useState([]);

  const [isLoadingCourses, setIsLoadingCourses] = React.useState(true);
  const [isLoadingLevels, setIsLoadingLevels] = React.useState(true);
  const [isLoadingSemesters, setIsLoadingSemesters] = React.useState(true);
  const [isLoadingSessions, setIsLoadingSessions] = React.useState(true);

  const handleCourseTab = () => {
    setIsCoursesTab(true);
    setIsAddCourses(false);
    setIsLevelsTab(false);
    setIsSemestersTab(false);
    setIsSessionsTab(false);
  };
  const handleLevelsTab = () => {
    setIsLevelsTab(true);
    setIsAddLevels(false);
    setIsCoursesTab(false);
    setIsSemestersTab(false);
    setIsSessionsTab(false);
  };
  const handleSemesterTab = () => {
    setIsSemestersTab(true);
    setIsAddSemester(false);
    setIsLevelsTab(false);
    setIsCoursesTab(false);
    setIsSessionsTab(false);
  };
  const handleSessionsTab = () => {
    setIsSessionsTab(true);
    setIsAddSessions(false);
    setIsLevelsTab(false);
    setIsCoursesTab(false);
    setIsSemestersTab(false);
  };

  const handleCloseAll = () => {
    setIsAddSessions(false);
    setIsAddSemester(false);
    setIsAddLevels(false);
    setIsAddCourses(false);
  };
  //Adding Handl

  const handleAddNew = () => {
    if (isCoursesTab) {
      setIsAddCourses(!addCourses);
    } else if (isLevelsTab) {
      setIsAddLevels(!addLevels);
    } else if (isSemestersTab) {
      setIsAddSemester(!addSemesters);
    } else if (isSessionsTab) {
      setIsAddSessions(!addSessions);
    }
  };

  const fetchSessions = async () => {
    try {
      setIsLoadingSessions(true);
      const request = await fetch(`${BASE_URL}/admin/session/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsLoadingSessions(false);
      if (response.length) {
        setSessions(response);
      }
    } catch (error) {
      setIsLoadingSessions(false);
      toast.error("Error fetching Sessions", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  const fetchSemesters = async () => {
    try {
      setIsLoadingSemesters(true);
      const request = await fetch(`${BASE_URL}/admin/semester/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsLoadingSemesters(false);
      if (response.length) {
        setSemesters(response);
      }
    } catch (error) {
      setIsLoadingSemesters(false);
      toast.error("Error fetching semesters", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  const fetchLevels = async () => {
    try {
      setIsLoadingLevels(true);
      const request = await fetch(`${BASE_URL}/level/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsLoadingLevels(false);
      if (response.length) {
        setLevels(response);
      }
    } catch (error) {
      setIsLoadingLevels(false);
      toast.error("Error fetching levels", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  const fetchCourses = async () => {
    try {
      setIsLoadingCourses(true);
      const request = await fetch(`${BASE_URL}/course/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsLoadingCourses(false);
      console.log("response", response);
      if (response.length) {
        setCourses(response);
      }
    } catch (error) {
      setIsLoadingCourses(false);
      toast.error("Error fetching courses", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };
  const fetchStaff = async () => {
    try {
      const request = await fetch(`${BASE_URL}/admin/lecturer/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      if (response.length) {
        setStaff(response);
      }
    } catch (error) {
      toast.error("Error fetching instructors");
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchCourses();
    fetchSessions();
    fetchSemesters();
    fetchLevels();
    fetchStaff();
  }, []);

  function renderContents() {
    if (isCoursesTab) {
      return (
        <CourseDataTable
          courses={courses}
          setFilter={globalFilter}
          isAddCourse={addCourses}
          fetchCourses={fetchCourses}
          handleCloseAll={handleCloseAll}
          levels={levels}
          semesters={semesters}
          instructors={staff}
          isLoadingCourses={isLoadingCourses}
        />
      );
    } else if (isLevelsTab) {
      return (
        <LevelDataTable
          levels={levels}
          setFilter={globalFilter}
          isAddLevel={addLevels}
          handleCloseAll={handleCloseAll}
          fetchLevels={fetchLevels} 
          isLoadingLevels={isLoadingLevels}
        />
      );
    } else if (isSemestersTab) {
      return (
        <SemesterDataTable
          semesters={semesters}
          setFilter={globalFilter}
          isAddSemester={addSemesters}
          handleCloseAll={handleCloseAll}
          fetchSemesters={fetchSemesters} 
          isLoadingSemesters={isLoadingSemesters}
        />
      );
    } else if (isSessionsTab) {
      return (
        <SessionDataTable
          sessions={sessions}
          setFilter={globalFilter}
          isAddSession={addSessions}
          handleCloseAll={handleCloseAll}
          fetchSessions={fetchSessions} 
          isLoadingSessions={isLoadingSessions}
        />
      );
    }
  }
  return (
    <Layout>
      <ToastContainer />

      <div className={style.container}>
        <div className={style.header}>
          <div className="search">
            <input
              className="searchInput"
              placeholder="Search"
              value={filter}
              onChange={(e) =>
                globalFilter === ""
                  ? setFilter(e.target.value)
                  : setGlobalFilter("")
              }
            />
            <button
              className="btn"
              type="button"
              onClick={() => setGlobalFilter(filter)}
            >
              Search
            </button>
          </div>
          <h4 className="title">Setup</h4>
          <div className="tabs">
            <div className="tabLinks">
              <div className={isCoursesTab ? "active link" : "link"}>
                <Link to="" onClick={handleCourseTab}>
                  Courses
                </Link>
              </div>
              <div className={isLevelsTab ? "active link" : "link"}>
                <Link to="" onClick={handleLevelsTab}>
                  Levels
                </Link>
              </div>
              <div className={isSemestersTab ? "active link" : "link"}>
                <Link to="" onClick={handleSemesterTab}>
                  Semesters
                </Link>
              </div>
              <div className={isSessionsTab ? "active link" : "link"}>
                <Link to="" onClick={handleSessionsTab}>
                  Sessions
                </Link>
              </div>
            </div>
            <button className="btn" onClick={handleAddNew}>
              Add new
            </button>
          </div>
        </div>
        <div className={style.body}>
          <div className="table">{renderContents()}</div>
        </div>
      </div>
    </Layout>
  );
};

SchoolSystem.propTypes = {};

export default SchoolSystem;
