import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  headerIcon: {
    color: '#152E88',fontSize: '1.5rem', fontWeight: 100
  },

  dash: { 
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",

    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)", 
    
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    "& div.header": {  
      display: "flex",
      position: "relative",
      "& div.weeks": {  
        margin: "0 auto",
        display: "flex",
        "& .icon": {   
          cursor: "pointer", 
        },
        "& .weekTitle": { 
          margin: "0 2.8125rem",color: "#152E88", 
        },
      },
    },
    "& h5": { 
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "22px",
      color: colors.black, 
    },
  },
  main: {
    [theme.breakpoints.up("sm")]: { 
      height: "calc(100vh - 120px)",
      overflowY: "auto",
    },

    backgroundColor:'#F1EDEC', padding: '1rem', 
    
  },
  week: {
    // background: "red",
    marginTop: "37px",
    "& div.day:not(:last-child)": {
      marginBottom: "3px",
    },
    "& div.day": {   
      background: "#F6F4F4",
      padding: "27px 23px",
      margin: " 0 -1rem ",
      display: "grid",
      gridTemplateColumns: "8fr 3fr 4fr",
      justifyContent: "space-between",
      cursor: "pointer",
      "&.today": {
        background: "#C0C0C0",
      },

      "& span": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontSize: "15px",
        lineHeight: "22px",
        color: colors.black,
        "&.dayName": {
          fontWeight: "700",
          textTransform: "uppercase",
        },
        "&.number,&.number": {
          fontWeight: "400",
        },
      },
    },
  },

  
}));
