import { API } from '../configure-api'

//Student endpoints
const getAll = (user: string) => API.get(`/${user}/courses`);
const dropCourse = (body: { course_id: number }) => API.delete("/student/drop-course", body);

//Lecturer endpoints
const getLecAll = () => API.get("/lecturer/courses");
const getCourse = (id: number) => API.get(`/lecturer/courses/${id}`);

export const coursesService = {
    getAll,
    dropCourse,
    getLecAll,
    getCourse
};