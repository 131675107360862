import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ADDSTCOURSERECORD } from './recordThunk'

const initialState = {
    data: [] as any[],
    error: '' as string,
    status: '' as string
}

export const recordtSlice = createSlice({
    name: 'records',
    initialState,
    reducers: {
        clearState: (state) => {
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(ADDSTCOURSERECORD.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(ADDSTCOURSERECORD.fulfilled, (state, action) => {
                return { ...state, status: "fullfilled" }
            })
            .addCase(ADDSTCOURSERECORD.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })
    }
})


// export const select = (state: any) => state.notes.notes
