import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./submittedAssignment.style";
import Layout from "../../../components/Layout";

import { Link, useParams } from "react-router-dom";
import { Chev, NextPage, Previous } from "../../../assets/svg/";

interface Props {}

const SubmittedAssignments = (props: Props) => {
  const style = useStyles();
  const params = useParams();

  const questions = [
    {
      id: 1,
      question:
        "Nomenclature is governed by certain universal rules. Which one of the following is contrary to the rules of nomenclature?",
      options: [
        { option1: "Biological names can be written in any language" },
        { option2: "The names are written in Latin and are italicised" },
        { option3: "None of the options" },
        { option4: "All of the options" },
      ],
    },
    {
      id: 1,
      question:
        "Nomenclature is governed by certain universal rules. Which one of the following is contrary to the rules of nomenclature?",
      options: [
        { option1: "Biological names can be written in any language" },
        { option2: "The names are written in Latin and are italicised" },
        { option3: "None of the options" },
        { option4: "All of the options" },
      ],
    },
    {
      id: 1,
      question:
        "Nomenclature is governed by certain universal rules. Which one of the following is contrary to the rules of nomenclature?",
      options: [
        { option1: "Biological names can be written in any language" },
        { option2: "The names are written in Latin and are italicised" },
        { option3: "None of the options" },
        { option4: "All of the options" },
      ],
    },
    {
      id: 1,
      question:
        "Nomenclature is governed by certain universal rules. Which one of the following is contrary to the rules of nomenclature?",
      options: [
        { option1: "Biological names can be written in any language" },
        { option2: "The names are written in Latin and are italicised" },
        { option3: "None of the options" },
        { option4: "All of the options" },
      ],
    },
    {
      id: 1,
      question:
        "Nomenclature is governed by certain universal rules. Which one of the following is contrary to the rules of nomenclature?",
      options: [
        { option1: "Biological names can be written in any language" },
        { option2: "The names are written in Latin and are italicised" },
        { option3: "None of the options" },
        { option4: "All of the options" },
      ],
    },
  ];

  const totalPosts = questions.length || 0;

  const [currentPage, setCurrentPage] = React.useState(1);
  const [questionsPerPage] = React.useState(4);
  // Get current note
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentNote = questions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  // Change page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / questionsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <Layout>
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.header}>
            <h5>
              <Link className="icon" to="/dashboard/notes">
                <Chev />{" "}
              </Link>{" "}
              NRS 113 - Microbiology
            </h5>

            <p className="status">
              Status : <span>Submitted 20, Sep 2021</span>
            </p>
          </div>
          <div className={style.main}>
            <h6 className="assignmentTitle">The Mona Lisa - Assignment</h6>
            <div className="questionsContainer">
              <div className="questionsColumn">
                <div className="questions">
                  <p className="question">How old is “The Mona Lisa” ?</p>
                  <div className="options">
                    <div>
                      18 years. The Mona Lisa is one of the most valuable
                      paintings in the world. It holds the Guinness World Record
                      for the highest-known painting insurance valuation in
                      history at US$100 million in 1962 (equivalent to $870
                      million in 2021).
                    </div>
                  </div>
                </div>
                <div className="questions">
                  <p className="question">How old is “The Mona Lisa” ?</p>
                  <div className="options">
                    <div>
                      18 years. The Mona Lisa is one of the most valuable
                      paintings in the world. It holds the Guinness World Record
                      for the highest-known painting insurance valuation in
                      history at US$100 million in 1962 (equivalent to $870
                      million in 2021).
                    </div>
                  </div>
                </div>
                <div className="questions">
                  <p className="question">How old is “The Mona Lisa” ?</p>
                  <div className="options">
                    <div>
                      18 years. The Mona Lisa is one of the most valuable
                      paintings in the world. It holds the Guinness World Record
                      for the highest-known painting insurance valuation in
                      history at US$100 million in 1962 (equivalent to $870
                      million in 2021).
                    </div>
                  </div>
                </div>
              </div>

              <div className="border"></div>
              <div className="questionsColumn">
                <div className="questions">
                  <p className="question">How old is “The Mona Lisa” ?</p>
                  <div className="options">
                    <div>
                      18 years. The Mona Lisa is one of the most valuable
                      paintings in the world. It holds the Guinness World Record
                      for the highest-known painting insurance valuation in
                      history at US$100 million in 1962 (equivalent to $870
                      million in 2021).
                    </div>
                  </div>
                </div>
                <div className="questions">
                  <p className="question">How old is “The Mona Lisa” ?</p>
                  <div className="options">
                    <div>
                      18 years. The Mona Lisa is one of the most valuable
                      paintings in the world. It holds the Guinness World Record
                      for the highest-known painting insurance valuation in
                      history at US$100 million in 1962 (equivalent to $870
                      million in 2021).
                    </div>
                  </div>
                </div>
                <div className="questions">
                  <p className="question">How old is “The Mona Lisa” ?</p>
                  <div className="options">
                    <div>
                      18 years. The Mona Lisa is one of the most valuable
                      paintings in the world. It holds the Guinness World Record
                      for the highest-known painting insurance valuation in
                      history at US$100 million in 1962 (equivalent to $870
                      million in 2021).
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* //Pagination */}
            <ul className="pagination">
              <Previous
                className={currentPage === 1 ? "icon active" : "icon "}
                onClick={() => paginate(1)}
              />
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={number === currentPage ? "active" : ""}
                >
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="page-link"
                  >
                    {number}
                  </a>
                </li>
              ))}
              <NextPage
                className={
                  currentPage == pageNumbers.length ? "icon active" : "icon "
                }
                onClick={() => paginate(pageNumbers.length)}
              />
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

SubmittedAssignments.propTypes = {};

export default SubmittedAssignments;
