import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./lecturerViewCBT.style";
import Layout from "../../../components/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Chev } from "../../../assets/svg/";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/common/Loader";
import { Badge, Button, Table } from "reactstrap";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Grid } from "@material-ui/core";

import { useReactToPrint } from "react-to-print";

interface Props {}

interface CustomizedState {
  id: number;
}

const LecturerViewCBT = (props: Props) => {
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const dispatch = useDispatch<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);
  const [focusedStudent, setFocusedStudent] = useState<{
    [key: string]: any;
  }>();
  const [quiz, setQuiz] = useState<{ [key: string]: any }>({});

  const focusedStudentAnswers = quiz?.answers?.filter(
    (answer: any) => answer.student.id === focusedStudent?.id
  );

  console.log(focusedStudentAnswers);

  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();
  // console.log(state.course_id, state.assignment_id);
  let parser = new DOMParser();
  console.log(quiz);

  const printRef = React.useRef(null);
  const handlePrintList = useReactToPrint({
    content: () => printRef.current
  });

  const handleErrorFix = async () => {
    setIsLoading(true);
    try {
      const request = await fetch(`${BASE_URL}/cbt/quiz/${state.id}/fix-error`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsLoading(false);
      if (response.id) {
        setQuiz(response);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchQuiz = async () => {
    try {
      const request = await fetch(`${BASE_URL}/cbt/quiz/${state.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsLoading(false);
      if (response.id) {
        setQuiz(response);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchQuiz();
  }, []);

  return (
    <Layout>
      <ToastContainer />
      {isLoading && <LoadingSpinner />}
      {/* //Generate Student Modal */}
      {viewModal && (
        <div className={style.modalContainer}>
          <div className="modals">
            <div
              style={{
                boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
                minHeight: "calc(100vh - 100px)",
                height: "calc(100% - 50px)",
              }}
            >
              <ToastContainer />
              <div
                className={style.header}
                style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
              >
                <h5
                  style={{
                    fontWeight: 100,
                    color: "#152E88",
                    fontSize: "1.5rem",
                  }}
                >
                  <Chev className="icon" onClick={() => setViewModal(false)} />
                  {focusedStudent?.service_number} {focusedStudent?.rank} {focusedStudent?.name}
                </h5>
              </div>

              <div className={style.main}>
                <div className="testBadgesContainer">
                  <Badge className="testBadge" pill>
                    Number of questions: {quiz?.questions?.length}
                  </Badge>
                  <Badge className="testBadge"  color="primary" pill>
                    Attempted:{" "}
                    {
                      quiz?.stats?.find(
                        (item: any) => item.student.id === focusedStudent?.id
                      )?.questions_answered
                    }
                  </Badge>
                  <Badge className="testBadge"  color="success" pill>
                    Correct:{" "}
                    {
                      quiz?.stats?.find(
                        (item: any) => item.student.id === focusedStudent?.id
                      )?.correct_answers
                    }
                  </Badge>
                  <Badge className="testBadge" color="danger" pill>
                    Incorrect:{" "}
                    {
                      quiz?.stats?.find(
                        (item: any) => item.student.id === focusedStudent?.id
                      )?.incorrect_answers
                    }
                  </Badge>
                  <Badge className="testBadge"  color="info" pill>
                    Score:{" "}
                    {
                      quiz?.obtainable_score 
                      != 0 
                      ?`${quiz?.stats?.find(
                        (item: any) => item.student.id === focusedStudent?.id
                      )?.score}/${quiz.obtainable_score}`
                      :'-'                                             
                    }
                  </Badge>
                </div>
                <h4 style={{margin: ".5rem 1rem"}}>Questions:</h4>
                <Grid container spacing={3}>
                  {focusedStudentAnswers?.map((answer: any, index: any) => (
                    <Grid item xs={12} sm={6} key={answer.id}>
                      <div
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                          borderRadius: "8px",
                          margin: "10px 20px",
                          padding: "8px 16px",
                          fontWeight: 400,
                          display: "flex",
                        }}
                      >
                        <p style={{paddingRight:".3rem"}}>{index + 1}.</p>{" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: answer.question.text,
                          }}
                        />
                      </div>
                      <ul style={{ listStyleType: "none" }}>
                        {answer.question.options.map((option: any) => (
                          <React.Fragment key={option.id}>
                            <li
                              style={{
                                background: "#E9E9E9",
                                borderRadius: "8px",
                                margin: "10px 20px",
                                padding: "8px 16px",
                                fontWeight: 400,
                              }}
                            >
                              {option.text}
                              {answer.option.id === option.id &&
                                (answer.is_correct ? (
                                  <CheckIcon style={{ color: "green" }} />
                                ) : (
                                  <ClearIcon style={{ color: "red" }} />
                                ))}
                            </li>
                          </React.Fragment>
                        ))}
                      </ul>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={style.container}>
        <div className={style.dash}>
          <div
            className={style.header}
            style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
          >
            <h5
              style={{
                fontWeight: 100,
                color: "#152E88",
                fontSize: "1.5rem",
              }}
            >
              <Link className="icon" to="/dashboard/cbt-assignment">
                <Chev />
              </Link>
              {isLoading ?'Loading Assessment Stats':`${quiz?.course?.name} - ${quiz?.name}`}
              <Button onClick={()=>handlePrintList()}><i className="fa fa-print"></i></Button> 
              <Button onClick={()=>handleErrorFix()}><i className="fa fa-refresh" style={{color:'greenyellow'}}></i></Button> 
            </h5>
          </div>

          <div className={style.main}>
             {isLoading && (<h5
              style={{
                fontWeight: 400,
                color: "#152E88",
                fontSize: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              Please wait...
              </h5>)}
              <div style={{overflowY: "auto", height:"33rem"}}>                                               
                 
                <Table responsive striped size="sm">
                  <thead>
                    <tr>
                      <th>
                        <p className="scoreTableHeading">
                          Service Number 
                        </p>
                      </th>
                      <th>
                        <p className="scoreTableHeading">
                          Participant
                        </p>
                      </th>
                      <th>
                        <p className="scoreTableHeading">
                          Questions Attempted
                        </p>
                      </th>
                      <th>
                        <p className="scoreTableHeading">
                          Score
                        </p>
                      </th>                    
                    </tr>
                  </thead>
                  <tbody>                  
                    {quiz?.stats?.sort((a: any, b: any) => b.score - a.score)
                    .map((item: any) => (
                      <tr key={item.student.id} 
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setViewModal(true);
                          setFocusedStudent(item.student);
                        }}
                      >
                        <td>
                          <p className="scoreTableData">
                            {item.student.service_number}
                          </p>
                        </td>
                        <td>
                          <p className="scoreTableData">
                            {item.student.rank} {item.student.name}
                          </p>
                        </td>
                        <td>
                          <p className="scoreTableData" style={{textAlign:"center"}}>
                            {item.questions_answered}
                          </p>
                        </td>
                        <td>
                          <p className="scoreTableData" style={{textAlign:"center"}}>
                            {quiz.obtainable_score != 0 ?`${item.score.toFixed(1)}/${quiz.obtainable_score.toFixed(1)}`:'-'}{" "}
                          </p>
                        </td>
                        
                      </tr>
                    ))}
                    {quiz?.stats?.length == 0 && (<tr><td colSpan={4}><p className="scoreTableData">No submissions recorded.</p></td></tr>)}
                  </tbody>
                </Table>             
                 
              </div>

              {printRef && 
              <div ref={printRef} style={{overflowX:"hidden",  margin: "2rem"}}>                                               
                 
                <Table responsive striped size="sm">
                  <thead>
                    <tr>
                      <th colSpan={5}>{quiz?.name}</th>
                    </tr>
                    <tr>
                      <th>
                        <p className="scoreTableHeading" style={{textAlign:"center"}}>
                          Serial 
                        </p>
                      </th>
                      <th>
                        <p className="scoreTableHeading">
                          Service Number 
                        </p>
                      </th>
                      <th>
                        <p className="scoreTableHeading">
                          Participant
                        </p>
                      </th>
                      <th>
                        <p className="scoreTableHeading" style={{textAlign:"center"}}>
                          Questions Attempted
                        </p>
                      </th>
                      <th>
                        <p className="scoreTableHeading" style={{textAlign:"center"}}>
                          Score
                        </p>
                      </th>                    
                    </tr>
                  </thead>
                  <tbody>                  
                    {quiz?.stats?.sort((a: any, b: any) => b.score - a.score)
                    .map((item: any, index: number) => (
                      <tr key={item.student.id} 
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setViewModal(true);
                          setFocusedStudent(item.student);
                        }}
                      >
                        <td>
                          <p className="scoreTableData" style={{textAlign:"center"}}>
                            {index+1}
                          </p>
                        </td>
                        <td>
                          <p className="scoreTableData">
                            {item.student.service_number}
                          </p>
                        </td>
                        <td>
                          <p className="scoreTableData">
                            {item.student.rank} {item.student.name}
                          </p>
                        </td>
                        <td>
                          <p className="scoreTableData" style={{textAlign:"center"}}>
                            {item.questions_answered}
                          </p>
                        </td>
                        <td>
                          <p className="scoreTableData" style={{textAlign:"center"}}>
                            {quiz.obtainable_score != 0 ?`${item.score.toFixed(1)}/${quiz.obtainable_score.toFixed(1)}`:'-'}{" "}
                          </p>
                        </td>
                        
                      </tr>
                    ))}                    
                    {quiz?.stats?.length == 0 && (<tr><td colSpan={5}><p className="scoreTableData">No submissions recorded.</p></td></tr>)}
                  </tbody>
                </Table>             
                 
              </div>
              }
              
          </div>
        </div>
      </div>
    </Layout>
  );
};

LecturerViewCBT.propTypes = {};

export default LecturerViewCBT;
