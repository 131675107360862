import { Routes, Route } from "react-router-dom";
import PageLoader from "../../../components/common/PageLoader";
import { PrivateRoute } from "../../../components/Routes";
import { Role } from "../../../shared";
import Notice from "./notice";
import Users from "./users";
import Events from "./events";
import SchoolSystem from "./schoolSystem";
import Payments from "./payments";
import Applicants from "./applicants";
import Website from "./website";

const Admin = () => {
  return (
    <Routes>
      {/* <Route path="/dashboard/users" element={<PrivateRoute roles={[Role.ADMIN]} component={Users} />} /> */}
      <Route
        path="/dashboard/notice"
        element={<PrivateRoute roles={[Role.ADMIN]} component={Notice} />}
      />
      <Route
        path="/dashboard/events"
        element={<PrivateRoute roles={[Role.ADMIN]} component={Events} />}
      />
      <Route
        path="/dashboard/applicants"
        element={<PrivateRoute roles={[Role.ADMIN]} component={Applicants} />}
      />
      <Route
        path="/dashboard/schoolsystem"
        element={<PrivateRoute roles={[Role.ADMIN]} component={SchoolSystem} />}
      />
      <Route
        path="/dashboard/manage-website"
        element={<PrivateRoute roles={[Role.ADMIN]} component={Website} />}
      />
      <Route
        path="/dashboard/payments"
        element={<PrivateRoute roles={[Role.ADMIN]} component={Payments} />}
      />
    </Routes>
  );
};

export default Admin;
