import { makeStyles } from "@material-ui/core";
import { colors } from "../../styles/color";

export const useStyles = makeStyles({
  container: {
    // border: "2px solid green",
    overflowX: "hidden",
    // padding: "0 29px",
    minHeight: "100vh",
  },
  sectionPadding: {
    padding: "0 100px",
  },
  roundedButton: {
    borderRadius: "20px",
    backgroundColor: "#02b0da",
    color: "#071923",
    fontFamily: "poppins",
    fontWeight: "bold",
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
  },
  latestNews: {
    marginTop: "2.625rem",
  },

  latestNewsHeader: {
    padding: "0.8125rem 1.9375rem",
    background: colors.blue,
    color: colors.white,
    fontFamily: "poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
  },
  latestNewsContent: {
    padding: "0.8125rem 1.1875rem",
    background: colors.light,
    alignItems: "center",

    "& a": {
      color: colors.black,
      textDecoration: "none",
      fontFamily: "poppins",

      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "20px",
      lineHeight: "30px",
    },
    "& a span:not(.badge)": {
      fontFamily: "poppins",

      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      float: "right",
      color: "rgba(0, 0, 0, 0.6)",
    },
    "& a span .badge": {
      fontFamily: "poppins",

      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "26px",
      //   lineHeight: "39px",

      color: "#FF0F00",
      marginLeft: "1rem",
    },
  },

  banner_img: {
    position: "relative",
    backgroundImage: `url(${require("../../assets/images/chopper1.png")})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "90vh",
    width: "100%",

    "& .transparentBox": {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      minHeight: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },

  // header
  header: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    height: "200px",
  },
  headerLeft: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  headerLogo: {
    height: "80px",
    width: "80px",
  },
  headerTitle: {
    color: "#fff",
  },
  headerRight: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "20px",
    color: "#fff",
    "& ul": {
      display: "flex",
      listStyle: "none",
      "& li": {
        marginLeft: "15px",
        fontSize: "0.9rem",
      },
      "& a": {
        color: "#fff",
        textDecoration: "none",
        padding: "10px",

        "&:hover": {
          borderRadius: "20px",
          backgroundColor: "rgba(255,255,255,0.5)",
        },
      },
    },
  },

  activeLink: {
    borderRadius: "20px",
    backgroundColor: "#02b0da",
    color: "white !important",
  },

  headerRightTop: {
    alignSelf: "flex-end",
  },
  headerRightBottom: {
    fontWeight: 700,
  },
  banner_text: {},

  blur: {
    position: "absolute",
    bottom: "0",
    zIndex: 2,
    width: "100%",
    height: "100px",
    backgroundImage: "linear-gradient(to bottom,rgba(7,25,35,0),#071923 63%)",
  },
  // about
  about: {
    backgroundColor: "#071923",
    color: "#fff",
    padding: "30px 0",
  },
  leftPane: {
    display: "flex",
    flexDirection: "column",
    "& p": {
      fontSize: "32px",
      fontWeight: "normal",
      lineHeight: "1.1",
    },
    "& .buttonBox": {
      display: "flex",
      rowGap: "20px",
      columnGap: "20px",
      marginTop: "20px",
    },
  },
  rightPane: {},

  contribution: {
    marginTop: "2.625rem",

    "& p": {
      marginBottom: "1.1875rem",
      fontFamily: "Open Sans, sans-serif",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#071923",
      textAlign: "left",
    },
    "& h3": {
      marginBottom: "1rem",
      fontFamily: "Titillium Web,sans-serif",
      fontWeight: 600,
      color: "#071923",
    },
    "& .rightPane": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "flex-start",

      "& p": {
        width: "60%",
      },
    },
  },
  paragraphContainer: {
    display: "flex",
    flexDirection: "column",
  },

  eventContainer: {
    margin: "4.6875rem 0 2.9375rem 0",
    "& .MuiCard-root": {
      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
      border: "3px solid #C4C4C4",
      borderRadius: "10px",
    },
    "& h2": {
      fontFamily: "poppins",

      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "45px",

      color: colors.blue,
      margin: "2.375rem  0 4.375rem 0",
    },

    "& .MuiButton-label": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "22px",
      lineHeight: "33px",
      color: colors.blue,
      textTransform: "none",
    },
    "& .MuiButton-root span.dash": {
      width: "17px",
      height: "3px",
      background: colors.blue,
      display: "block",
      marginRight: ".5rem",
    },
  },

  marquee: {
    margin: "-25px 0",
  },

  // about us page
  // about us text

  landingPageAbout: {
    height: "120px",
    overflow: "hidden",
    "& p": {
      fontSize: "18px",
      lineHeight: "30px",
      color: "white",
    },
  },

  aboutTextContainer: {
    "& p": {
      fontSize: "18px",
      lineHeight: "30px",
      color: "white",
    },
  },

  carouselTextContainer: {
    "& p": {
      fontSize: "20px",
    },
  },
});
