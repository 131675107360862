import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";
import backgroundIcon from "../../../assets/icons/admissionStar.svg";

export const useStyles = makeStyles((theme) => ({
  container: {
    // width: "100%",
    fontFamily: "'Open Sans', sans-serif",
    minHeight: "100vh",

    "& .headerTitle": {
      padding: "32px 0px",
      backgroundColor: colors.lightBlue,
      backgroundImage: `url(${backgroundIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      "& h2": {
        fontFamily: "'Roboto Slab', serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "1.875rem",
        lineHeight: "30px",
        color: colors.white,
        textAlign: "center",
      },
    },
    "& .form": {
      marginTop: "4.1875rem",
      display: "flex",
      justifyContent: "center",
      "& .form-control": {
        marginBottom: "20px",
        "& input[type='text']": {
          width: "449px",
          height: "56px",
          padding: "13px",
          border: "2px solid #c4c4c4",
          borderRadius: "10px",
          fontFamily: "'Open Sans', sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "1.375rem",
          lineHeight: "30px",
          color: colors.black,
          textAlign: "center",
          "&:placeholder": {
            color: "#c4c4c4",
          },
        },
        "& button": {
          width: "449px",
          height: "56px",
          padding: "13px",
          border: "none",
          borderRadius: "10px",
          fontFamily: "'Open Sans', sans-serif",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "1.375rem",
          lineHeight: "30px",
          color: colors.white,
          textAlign: "center",
          backgroundColor: colors.darkBlue,
          "&:hover": {
            border: "2px solid " + colors.darkBlue,
            backgroundColor: colors.white,
            color: colors.darkBlue,
            cursor: "pointer",
            transition: ".5s",
          },
        },
        [theme.breakpoints.down("xs")]: {
          "& input[type='text'], button": { width: "100%" },
        },
      },
    },
    "& .status": {
      marginTop: "5rem",
      display: "flex",

      justifyContent: "center",

      "& h3": {
        fontFamily: "'Roboto Slab', serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "1.875rem",
        lineHeight: "40px",
        color: colors.blue,
        textAlign: "center",
        marginBottom: "13px",
      },
      "& p": {
        fontFamily: "'Open Sans', sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.5rem",
        lineHeight: "33px",
        color: colors.black,
        textAlign: "center",
        marginBottom: "4rem",
      },
      "& button": {
        width: "449px",
        height: "56px",
        padding: "13px",
        border: "none",
        borderRadius: "5px",
        fontFamily: "'Open Sans', sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "1.375rem",
        lineHeight: "30px",
        color: colors.white,
        textAlign: "center",
        backgroundColor: colors.darkBlue,
        "&:hover": {
          border: "2px solid " + colors.darkBlue,
          backgroundColor: colors.white,
          color: colors.darkBlue,
          cursor: "pointer",
          transition: ".5s",
        },
      },
      [theme.breakpoints.down("xs")]: {
        "&  button": { width: "100%" },
      },
    },
  },
}));
