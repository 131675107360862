import React, { MouseEvent, ChangeEvent, useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./videos.style";
import Layout from "../../../components/Layout";
import { Grid, TextField, Button } from "@material-ui/core";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import {
  Upload,
  Dot,
  PlayS,
  PlayBig,
  PauseS,
  PauseBig,
  NextTrack,
  PrevTrack,
  FullScreen,
  Volume,
  AddVolume,
} from "../../../assets/svg/";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";

import { OptionMenuVideo } from "../../../components";

import videoBanner5 from "../../../assets/images/video5.png";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchCourses } from "../../store/features/course/courseThunk";
import { selectCourses } from "../../../store/features/course/courseSlice";
import { selectVideo } from "../../../store/features/video/videoSlice";
import { selectRole } from "../../../store/features/auth/authSlice";
import {
  fetchVideo,
  createVid,
  uploadVid,
} from "../../../store/features/video/videoThunk";
import { getCourseDueDate, Role } from "../../../shared";
import { formatDate } from "../../shared/utils/date";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/common/Loader";

import video from "../../../assets/images/video.svg";
import { Modal } from "reactstrap";

interface Props {}

interface IFormInputs {
  title: string;
  description: string;
  course_id: number;
  url: string;
  file: any;
}

const Videos = (props: Props) => {
  const style = useStyles();
  const dispatch = useAppDispatch();
  const coursess = useAppSelector(selectCourses);
  const [isAddVideo, setIsAddVideo] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openVideoPlayer, setOpenVideoPlayer] = React.useState(false);
  const [currentVideo, setCurrentVideo] = React.useState<any | null>(null);

  const [upload, setUpload] = useState(false);

  const handleIsAddVideo = () => {
    setIsAddVideo(!isAddVideo);
    setUpload(false);
  };

  const videos = useAppSelector(selectVideo);
  const userRole = useAppSelector(selectRole);

  const schema = yup.object().shape({
    title: yup.string().required(),
    description: yup.string().required(),
    course_id: yup.number().required(),
    url: yup.string().required(),
  });

  const courses = [{ value: "0", label: "Course" }];

  coursess.map((course: any) =>
    courses.push({ value: course.id, label: course.name })
  );
  const draftOptions = [
    { name: "Publish", link: "" },

    { name: "Delete", link: "" },
  ];

  const publishedOptions = [
    { name: "Unpublish", link: "" },
    { name: "Edit", link: "" },
  ];
  const getCourses = async () => {
    await dispatch(fetchCourses(userRole));
  };

  React.useEffect(() => {
    getCourses();
  }, [isLoading]);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setIsLoading(true);

    await dispatch(createVid(data))
      .then((res) => {
        setIsLoading(false);
        setIsAddVideo(false);
        toast.success("Video created successfully");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("Error creating video");
      });
  };

  // console.log("vid", videos);

  const getVideos = async () => {
    await dispatch(fetchVideo(userRole));
  };

  React.useEffect(() => {
    getVideos();
  }, [isLoading]);
  
  const [url, setUrl] = React.useState<any | null>(null);
  const [selectedFile, setSelectedFile] = React.useState();
  const changeHandler = (e: any) => {
    setSelectedFile(e.target.files[0]);
    setUpload(true);
    console.log(selectedFile);
  };

  const handleUpload = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    setIsLoading(true);

    formData.append("file", selectedFile!);
    console.log("aaa", formData.get("file"));
    const abc = formData.get("file");
    console.log("aabc", formData);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Brearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/upload/add`,
        { file: selectedFile },
        config
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Video has been uploaded");
          setIsLoading(false);
          setUpload(false);
          setUrl(res.data.url);
          setValue("url", res.data.url);
        } else {
          toast.success(res.data.error);
          setIsLoading(false);
          setUpload(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.success("Something went wrong");
        setIsLoading(false);
        setUpload(false);
      });
  };

  return (
    <Layout>
      {isLoading && <LoadingSpinner />}
      {/* Upload Modal */}
      {isAddVideo && (
        <div className={style.modalContainer}>
          <div className="modals">
            <Link
              to=""
              className="cancelBox"
              onClick={() => setIsAddVideo(false)}
            >
              x
            </Link>
            <div className="content">
              <h6>Upload video</h6>
              <form
                // onSubmit={handleUpload}
                id="uploadFile"
                encType="multipart/form-data"
              ></form>
              <form
                onSubmit={handleSubmit(formSubmitHandler)}
                // id="videoDetails"
              >
                <div className="input">
                  <Controller
                    name="course_id"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.course_id}
                        select
                        // label="Level"
                        SelectProps={{
                          native: true,
                        }}
                        variant="filled"
                        size="small"
                        InputProps={{ disableUnderline: true }}
                        inputProps={
                          {
                            // form: "videoDetails",
                          }
                        }
                        fullWidth={true}
                        hiddenLabel
                      >
                        {courses.map((option, index)=> 
                            //getCourseDueDate(coursess[index]?.start_date,coursess[index]?.duration_in_days) > new Date() && 
                            (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                            )                                   
                        )}
                      </TextField>
                    )}
                  />
                </div>
                <div className="input">
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        placeholder="Video Title"
                        fullWidth={true}
                        size="small"
                        inputProps={
                          {
                            // form: "videoDetails",
                          }
                        }
                      />
                    )}
                  />
                </div>
                <div className="input">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        placeholder="Video description"
                        fullWidth={true}
                        size="small"
                        inputProps={{
                          form: "videoDetails",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="input">
                  <Controller
                    name="url"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        placeholder="Video URL"
                        fullWidth={true}
                        size="small"
                        value={url}
                        onChange={(e) => {
                          setUrl(field.value);
                          field.onChange(e);
                        }}
                        inputProps={
                          {
                            // form: "videoDetails",
                          }
                        }
                      />
                    )}
                  />
                </div>{" "}
                <div className="input fileUpload ">
                  <span> Or choose from your device</span>

                  <input
                    type="file"
                    name="file"
                    className="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    form="uploadFile"
                    onChange={changeHandler}
                  />
                  {upload && (
                    <button
                      type="submit"
                      form="uploadFile"
                      onClick={handleUpload}
                    >
                      Start Upload
                    </button>
                  )}
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  // form="videoDetails"
                >
                  Upload
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* Play Modal */}

      <Modal
        isOpen={openVideoPlayer}
        toggle={() => setOpenVideoPlayer(!openVideoPlayer)}
        style={{ minWidth: "750px" }}
      >
        <h6>{currentVideo?.title}</h6>

        <video controls id="video1" width="100%">
          <source src={currentVideo?.url} type="video/mp4" />
          Sorry, your browser doesn't support videos.
        </video>
      </Modal>

      {/* Main Container */}
      <div className={style.container}>
        {userRole === Role.STUDENT ? (
          <>
            <div className={style.dash}>
              <div
                className={style.header}
                style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
              >
                <h5
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 100,
                    color: "#152E88",
                  }}
                >
                  Videos
                </h5>
                <span style={{ float: "right" }}>
                  <img
                    src={video}
                    alt="marker-image"
                    width={"25px"}
                    height={"25px"}
                  />{" "}
                </span>
              </div>
              <div className={style.main}>
                {videos.map((vid: any, i: number) => (
                  <div
                    className="video"
                    onClick={() => {
                      setCurrentVideo(vid);
                      setOpenVideoPlayer(true);
                    }}
                  >
                    <div className="videoBanner">
                      <img src={videoBanner5} alt="video" />
                      <PlayS className="playIcon" />
                    </div>
                    <div className="details">
                      <div className="desc">
                        <span className="title">{vid.title}</span>
                        <span>{formatDate(vid.created_at)}</span>
                        <span>{vid.course.lecturers[0].name}</span>

                        <span>
                          {vid.course.code} - {vid.course.name}
                        </span>
                      </div>
                      <Dot />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.dash}>
              {/* <div className={style.header}>
                <h5>Videos</h5>

                <Link to="" onClick={handleIsAddVideo}>
                  <Upload /> Upload
                </Link>
              </div> */}

              <div
                className={style.header}
                style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
              >
                <h5
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 100,
                    color: "#152E88",
                  }}
                >
                  Videos
                </h5>
                <span style={{ float: "right" }}>
                  <Link to="" onClick={handleIsAddVideo}>
                    <Upload /> Upload
                  </Link>{" "}
                </span>
              </div>

              <div className={style.main}>
                <h5
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 100,
                    color: "#152E88",
                  }}
                >
                  Published
                </h5>
                {videos
                  .filter((vid: any) => vid.is_published)
                  .map((vid: any, i: number) => (
                    <div className="video">
                      <div
                        className="videoBanner"
                        onClick={() => {
                          setCurrentVideo(vid);
                          setOpenVideoPlayer(true);
                        }}
                      >
                        <img src={videoBanner5} alt="video" />
                        <PlayS className="playIcon" />
                      </div>
                      <div className="details">
                        <div className="desc">
                          <span className="title">{vid.title}</span>
                          <span>{formatDate(vid.created_at)}</span>
                          <span>{vid.course.lecturers[0].name}</span>
                          <span>
                            {vid.course.code} - {vid.course.name}
                          </span>
                        </div>

                        <OptionMenuVideo
                          options={publishedOptions}
                          id={vid.id}
                          key={vid.id}
                        />
                      </div>
                    </div>
                  ))}
                <h5
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 100,
                    color: "#152E88",
                    paddingTop: "1rem",
                  }}
                >
                  Drafts
                </h5>
                {videos
                  .filter((vid: any) => !vid.is_published)
                  .map((vid: any, i: number) => (
                    <div className="video">
                      <div
                        className="videoBanner"
                        onClick={() => {
                          setCurrentVideo(vid);
                          setOpenVideoPlayer(true);
                        }}
                      >
                        <img src={videoBanner5} alt="video" />
                        <PlayS className="playIcon" />
                      </div>
                      <div className="details">
                        <div className="desc">
                          <span className="title">{vid.title}</span>
                          <span>{formatDate(vid.created_at)}</span>
                          <span>{vid.course.lecturers[0].name}</span>
                          <span>
                            {vid.course.code} - {vid.course.name}
                          </span>
                        </div>

                        <OptionMenuVideo
                          options={draftOptions}
                          id={vid.id}
                          key={vid.id}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

Videos.propTypes = {};

export default Videos;
