import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "../../../services";
import { IFormInputs } from "../../../shared";
import { AuthAction } from "./authTypes";

export const fetchUser = createAsyncThunk(AuthAction.FETCHEDUSER, async () => {
  const response = await authService.authUser();
  return response.data;
});


export const login = createAsyncThunk(
  AuthAction.LOGIN,
  async (formData: IFormInputs) => {
    const lecturer = { email: formData.email, password: formData.password };
    const student = { reg_no: formData.regno, password: formData.password };
    const response = await authService.loguserin(
      formData.regno ? student : lecturer
    );
    localStorage.setItem("token", response.token);
    return response;
  }
);

export const forgotPassword = createAsyncThunk(
  AuthAction.FORGOTPASSWORD,
  async (formData: IFormInputs) => {
    const response = await authService.forgotPassword({email:formData.email});
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  AuthAction.RESETPASSWORD,
  async (formData: IFormInputs) => {
    const response = await authService.resetPassword({
      password: formData.password
    });
    return response;
  }
);

export const logout = createAsyncThunk(AuthAction.LOGOUT, async () => {
  await authService.logout();
  return;
});
