import React from "react";
import { useStyles } from "../Landing/landing.style";

// reactstrap components
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function AboutSection({ page }) {
  const style = useStyles();

  return (
    <>
      <div
        className="section section-team cd-section"
        id="teams"
        style={{ backgroundColor: "#040608" }}
      >
        {/* ********* TEAM 2 ********* */}
        <div
          className="team-2 section-image"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/abujaview.jpg") + ")",
            //backgroundColor: "#273746"
          }}
        >
          <Container>
            <Row>
              <Col md="1" sm1="1"></Col>
              <Col className="ml-auto mr-auto text-center" md="10" sm="10">
                <h2 className="title" style={{ textAlign: "left" }}>
                  {page?.about?.title}
                </h2>
                <h5
                  // className="description text-white"
                  className={style.aboutTextContainer}
                  dangerouslySetInnerHTML={{
                    __html: page?.about?.description,
                  }}
                ></h5>
              </Col>
              <Col md="1" sm="1"></Col>
            </Row>
            <Row>
              <br />
            </Row>

            <Row style={{ marginBottom: "-5rem" }}>
              {/* <Col className="ml-auto mr-auto text-center" md="8"> */}
              <h2 className="title text-center">Leadership</h2>
              {/* <h5 className="description text-white"></h5> */}
              {/* </Col> */}
            </Row>
            <Row>
              {page?.leadership?.map((leadership) => (
                <Col md="6" key={leadership.id}>
                  <Card className="card-profile card-plain">
                    <CardImg top tag="div">
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img
                          style={{
                            width: "210px",
                            height: "250px",
                            boxShadow: "2px 3px 12px 0 #888888",
                          }}
                          alt="..."
                          className="img"
                          src={leadership.image}
                        />
                      </a>
                    </CardImg>
                    <CardBody>
                      <CardTitle tag="h4">{leadership.name}</CardTitle>
                      <h6 className="card-category">{leadership.position}</h6>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        {/* ********* END TEAM 2 ********* */}
      </div>
    </>
  );
}

export default AboutSection;
