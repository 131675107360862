import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Elipses } from "../../assets/svg";
import {
  deleteAssignment,
  publishAssignment,
  unPublishAssignment,
} from "../../pages/store/features/assignment/assignmentThunk";
import { colors } from "../../styles/color";

interface Props {
  options?: any;
  id: number;
  questions?: any;
}

export const OptionMenuAssignment = (props: Props) => {
  const { options, id, questions } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const style = {
    textDecoration: "none",
    color: colors.black,
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
  };

  const dispatch = useDispatch<any>();

  const handlepublishAssignment = async () => {
    await dispatch(publishAssignment({ assignment_id: id }))
      .then((res: any) => {
        toast.success(res.payload.message);

        console.log(res.payload.message);
        // Close the modal

        handleClose();
        // window.location.reload();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleUnpublishAssignment = async () => {
    await dispatch(unPublishAssignment({ assignment_id: id }))
      .then((res: any) => {
        toast.success(res.payload.message);

        console.log(res.payload.message);
        // Close the modal

        handleClose();
        // window.location.reload();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handledeleteAssignment = async () => {
    await dispatch(deleteAssignment(id))
      .then((res: any) => {
        toast.success(res.payload.message);

        console.log(res.payload.message);
        // Close the modal

        handleClose();
        // window.location.reload();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const navigate = useNavigate();
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Elipses />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option: any) => (
          <MenuItem
            key={option.name}
            selected={option === "Pyxis"}
            onClick={handleClose}
          >
            {option.name === "Publish" && (
              <button style={style} onClick={handlepublishAssignment}>
                {option.name}
              </button>
            )}
            {option.name === "Unpublish" && (
              <button style={style} onClick={handleUnpublishAssignment}>
                {option.name}
              </button>
            )}
            {option.name === "Edit" && (
              <button
                style={style}
                onClick={() =>
                  navigate(`edit`, {
                    replace: true,
                    state: {
                      questions: questions,
                    },
                  })
                }
              >
                {option.name}
              </button>
            )}
            {option.name === "Delete" && (
              <button style={style} onClick={handledeleteAssignment}>
                {option.name}
              </button>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
