import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useStyles } from "./payment.style";

import { ChevDown, Arrow, Close } from "../../../../assets/svg/";

import {
  ManagePaymentDataTable,
  PaymentDataTable,
} from "../../../../components/common/DataTables/Payments";
import { format } from "date-fns";
interface Props {}

const Payments = (props: Props) => {
  const style = useStyles();
  const [filter, setFilter] = React.useState("");
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [addPayment, setIsAddPayment] = React.useState(false);
  const [isPaymentsTab, setIsPaymentsTab] = React.useState(true);
  const [isManagePaymentsTab, setIsManagePaymentsTab] = React.useState(false);
  const [isFrom, setIsFrom] = React.useState("06/1/2022");
  const [isTo, setIsTo] = React.useState("06/30/2022");

  const handlePaymentsTab = () => {
    setIsPaymentsTab(true);
    setIsAddPayment(false);
    setIsManagePaymentsTab(false);
  };
  const handleManagePaymentsTab = () => {
    setIsPaymentsTab(false);
    setIsManagePaymentsTab(true);
    setIsAddPayment(false);
  };
  //Adding Handl

  const handleAddNew = () => {
    if (isManagePaymentsTab) {
      setIsAddPayment(!addPayment);
    }
  };
  const managePays = [
    {
      id: 1,
      paymentDesc: "Entry tuition",
      amount: "25,0000",
      semester: "All",
      level: "100",
      session: "2021/2022",
    },
    {
      id: 1,
      paymentDesc: "Departmental dues",
      amount: "500",
      semester: "All",
      level: "100",
      session: "2021/2022",
    },
    {
      id: 1,
      paymentDesc: "Matriculation gowns",
      amount: "1,500",
      semester: "All",
      level: "100",
      session: "2021/2022",
    },
    {
      id: 1,
      paymentDesc: "Social club dues",
      amount: "2,000",
      semester: "All",
      level: "100",
      session: "2021/2022",
    },
    {
      id: 1,
      paymentDesc: "Canteen subscription",
      amount: "25,0000",
      semester: "All",
      level: "100",
      session: "2021/2022",
    },
    {
      id: 1,
      paymentDesc: "Students’ laundary services",
      amount: "25,0000",
      semester: "All",
      level: "100",
      session: "2021/2022",
    },
    {
      id: 1,
      paymentDesc: "Students’ laundary services",
      amount: "25,0000",
      semester: "All",
      level: "100",
      session: "2021/2022",
    },
    {
      id: 1,
      paymentDesc: "Religious dues",
      amount: "25,0000",
      semester: "All",
      level: "100",
      session: "2021/2022",
    },
    {
      id: 1,
      paymentDesc: "Accomodation",
      amount: "25,0000",
      semester: "All",
      level: "100",
      session: "2021/2022",
    },
  ];
  const pays = [
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
    {
      id: 1,
      payer: "Taye Babatunde Bolaji",
      source: "Paystack",
      amount: "N300,000",
      transactionID: "2453758835985GP",
      date: "30 Jun 2022",
      description: "First Semester tuition",
    },
  ];

  function renderContents() {
    if (isPaymentsTab) {
      return <PaymentDataTable payments={pays} setFilter={globalFilter} />;
    } else if (isManagePaymentsTab) {
      return (
        <ManagePaymentDataTable
          managePayments={managePays}
          setFilter={globalFilter}
          isAddPayment={addPayment}
        />
      );
    }
  }
  return (
    <Layout>
      <div className={style.container}>
        {/* Close Icon */}
        {isManagePaymentsTab && (
          <Close className={style.closeIcon} onClick={handlePaymentsTab} />
        )}

        <div className={style.header}>
          <div className="search">
            <input
              className="searchInput"
              placeholder="Search"
              value={filter}
              onChange={(e) =>
                globalFilter === ""
                  ? setFilter(e.target.value)
                  : setGlobalFilter("")
              }
            />
            <button
              className="btn"
              type="button"
              onClick={() => setGlobalFilter(filter)}
            >
              Search
            </button>
          </div>
          <h4 className="title">Payment</h4>
        </div>
        {/* //Header section for payments */}
        {isPaymentsTab ? (
          <div className={style.headerPayment}>
            <div className="fromTo">
              <input
                type="text"
                name="from"
                defaultValue={format(new Date(isFrom), "MMM d, YYY")}
                onFocus={(e) => (e.target.type = "date")}
                onChange={(e) => setIsFrom(e.target.value)}
                onBlur={(e) => {
                  e.target.type = "text";
                  e.target.value = format(new Date(isFrom), "MMM d, YYY");
                  console.log(isFrom);
                }}
              />
              <Arrow />
              <input
                type="text"
                name="to"
                defaultValue={format(new Date(isTo), "MMM d, YYY")}
                onFocus={(e) => (e.target.type = "date")}
                onChange={(e) => setIsTo(e.target.value)}
                onBlur={(e) => {
                  e.target.type = "text";
                  e.target.value = format(new Date(isTo), "MMM d, YYY");
                  console.log(isTo);
                }}
              />
            </div>

            <button className="btn " onClick={handleManagePaymentsTab}>
              Manage payment
            </button>
          </div>
        ) : (
          <div className={style.headerManagePayment}>
            <button className="btn " onClick={handleAddNew}>
              New Payment
            </button>
            <h4 className="">Manage Payment</h4>
            <div className="items">
              <Link to="">
                <ChevDown /> All Semesters
              </Link>
              <Link to="">
                <ChevDown /> All Sessions
              </Link>
            </div>
          </div>
        )}
        <div className={style.body}>
          <div className="table">{renderContents()}</div>
        </div>
      </div>
    </Layout>
  );
};

Payments.propTypes = {};

export default Payments;
