import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchCourses } from './courseThunk'

const initialState = {
    courses: [] as any[],
    error: '' as string,
    status: '' as string
}

export const courseSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        clearState: (state) => {
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourses.pending, (state, action) => {
                return { ...state, status: "loading" }
            })
            .addCase(fetchCourses.fulfilled, (state, action) => {
                return { ...state, courses: action.payload, status: "fullfilled" }
            })
            .addCase(fetchCourses.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.error.message as any
            })
    }
})


export const selectCourses = (state: any) => state.courses.courses
