import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    autoflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "8px",
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "24px",
      color: colors.black,
      display: "flex",
      alignItems: "center",
      gap: "6px",

      "& .icon": {
        // padding: "9.25px 12.99px",
        background: "#E9E9E9",
        borderRadius: "5px",
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& .status": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21px",
      color: colors.black,
      "& span": {
        fontWeight: 300,

        fontStyle: "italic",
      },
    },
  },
  main: {
    marginTop: "1rem",
    position: "relative",
    background: "#f6f4f4",
    padding: "14px 19px",
    "& .assignmentTitle": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "22px",
      lineHeight: "33px",
      color: colors.black,
      marginBottom: "16px",
    },

    "& .questionsContainer": {
      display: "flex",
      gap: "74px",
      height: "70vh",
      overflowY: "auto",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "8px",
        height: "100%",
        overflowY: "none",
      },
      "& .questions": {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        "& > p.question": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
          color: colors.black,
          margin: "4px 0",
        },
        "& >div.options": {
          "& > div": {
            padding: "14px 22px",
            borderRadius: "4px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "27px",
            background: colors.white + " !important",
            color: colors.black + " !important",
          },
        },
      },
      "& .border": {
        borderRight: "2px solid " + colors.white,
        margin: "30px 0px",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
    },
    "& .pagination": {
      display: "flex",
      listStyleType: "none",
      gap: "6px",
      alignItems: "center",
      paddingTop: "10px",
      //   marginTop: "81px",
      //   position: "absolute",
      //   bottom: "7px",
      //   right: "20px",
      justifyContent: "end",
      "& li": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& a": {
          textDecoration: "none",
          color: colors.white,
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 640,
          fontSize: "1rem",
          lineHeight: "24px",
        },
      },

      "& .icon": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        padding: "4.33px 4.92px",
        cursor: "pointer",
      },
      "& .active": {
        background: " #E5E5E5 !important",
        color: "#fff !important",
      },
    },
  },
}));
