import React, { HTMLAttributes, HTMLProps, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "../dataTables.style";
import { ChevDown, X } from "../../../../assets/svg";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingSpinner from "../../../../components/common/Loader";
import { toast, ToastContainer } from "react-toastify";
import { DeleteRounded, EditRounded } from "@material-ui/icons";
import Confirm from "../../Confirm";
import { format } from "date-fns";

interface Props {
  setFilter: string;
  isAddSession: any;
  sessions: any;
  fetchSessions: any;
  handleCloseAll: any;
  isLoadingSessions: boolean;
}

interface CreateSessionInputs {
  name: string;
  start_date: string;
  end_date: string;
}

const createSessionSchema = yup.object().shape({
  name: yup.string().required("Required"),
  start_date: yup.string().required("Required"),
  end_date: yup.string().required("Required"),
});

type Session = {
  [key: string]: any;
};
const SessionDataTable = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");

  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [addSessionModal, setAddSessionModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [focusedRow, setFocusedRow] = React.useState<any>(null);
  const [openWarning, setOpenWarning] = React.useState(false);

  // form
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateSessionInputs>({
    resolver: yupResolver(createSessionSchema),
  });
  const onSubmit = (data: CreateSessionInputs) => {
    const body = {
      name: data.name,
      start_date: data.start_date+"T10:00:00.0",
      end_date: data.end_date+"T10:00:00.0",
    };

    if (focusedRow.name) {
      updateSession(body);
    } else {
      createSession(body);
    }
  };

  useEffect(() => {
    setValue("name", focusedRow?.name);
    setValue("start_date", focusedRow?.start_date);
    setValue("end_date", focusedRow?.end_date);
  }, [focusedRow]);

  console.log(focusedRow);

  const createSession = async (data: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/admin/session/create`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await req.json();
      if (res.id) {
        toast.success("Session created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        reset();
        handleCloseModal();
        props.fetchSessions();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const updateSession = async (data: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/admin/session/${focusedRow?.id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await req.json();
      if (res.id) {
        toast.success("Session updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        reset();
        handleCloseModal();
        props.fetchSessions();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const deleteSession = async () => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/admin/session/${focusedRow.id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await req.json();
      console.log(res);
      if (res.message) {
        toast.success(res.messsage || "Session deleted", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchSessions();
        reset();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    props.handleCloseAll();
    setAddSessionModal(false);
    setFocusedRow(null);
  };

  React.useEffect(() => {
    setGlobalFilter(props.setFilter);
  }, [props.setFilter]);
  React.useEffect(() => {
    setAddSessionModal(props.isAddSession);
  }, [props.isAddSession]);

  const columns = React.useMemo<ColumnDef<Session>[]>(
    () => [
      {
        accessorKey: "name",

        header: "Session",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "start_date",
        header: "Session Opens",

        footer: (props) => props.column.id,
      },
      {
        accessorKey: "end_date",
        header: "Session Closes",

        footer: (props) => props.column.id,
      },
      {
        id: "action",
        header: "Actions",
        cell: ({ row }) => (
          <div style={{ display: "flex", gap: 20 }}>
            <EditRounded
              fontSize="small"
              color="primary"
              onClick={() => {
                setFocusedRow(row.original);
                setAddSessionModal(true);
              }}
            />
            <DeleteRounded
              fontSize="small"
              color="error"
              onClick={() => {
                setFocusedRow(row.original);
                setOpenWarning(true);
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const [data, setData] = React.useState(props.sessions);

  useEffect(() => {
    let formatted_data = props.sessions.map((item: any) => ({
      ...item,
      start_date: item.start_date?.split("T")[0],//format(new Date(item.start_date), "dd-MM-yyyy"),
      end_date: item.end_date?.split("T")[0],//format(new Date(item.end_date), "dd-MM-yyyy"),
    }));
    setData(formatted_data);
  }, [props.sessions]); //reload data

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this session?"
        action={deleteSession}
      />

      <Modal
        isOpen={addSessionModal}
        toggle={handleCloseModal}
        centered={true}
        scrollable={true}
      >
        <ModalBody>
          {isLoading && <LoadingSpinner />}
          <div className={style.addSessionModal}>
            <div className="header">
              <h3 className="mTitle">
                {focusedRow ? "Update session" : "New session"}
              </h3>
              <X className="icon" onClick={() => handleCloseModal()} />
            </div>
            <div className="bodyMod">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formGroup">
                  <input {...register("name")} placeholder="Title" />
                  <p>{errors.name?.message}</p>
                  <label htmlFor="start_date">Opening Date</label>
                  <input {...register("start_date")} type="date" />
                  <p>{errors.start_date?.message}</p>
                  <label htmlFor="end_date">Closing Date</label>
                  <input {...register("end_date")} type="date" />
                  <p>{errors.end_date?.message}</p>
                </div>
                <button className="btn" type="submit">
                  Save
                </button>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <div className={style.displayAll}>
        <div className="items">
          <Link to="">
            All Sessions <ChevDown />
          </Link>
        </div>
        {/* <button
          className={
            numberSelected === 0 ? "btn secondary disabled" : "btn secondary"
          }
          disabled={numberSelected === 0}
          onClick={() =>
            console.info(
              "Selected Rows",
              table.getSelectedRowModel().flatRows.map((row) => row.original)
            )
          }
        >
          Delete
        </button> */}
      </div>
      <table className={style.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} {...table.getRowModel()}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.isLoadingSessions && (
        <h4 style={{ padding: ".5rem 1.2rem" }}>Loading Sessions data...</h4>
      )}
      <div className={style.tableMisc}>
        <div className="nextPrevious">
          <button
            className=""
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className=""
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className=""
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="pageCounter">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className=""
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div></div>
      </div>
    </>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}
SessionDataTable.propTypes = {};

export default SessionDataTable;
