import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../store";
import {
  selectAuth,
  selectRole,
  selectStatus,
} from "../../store/features/auth/authSlice";
interface Props {
  component: React.ComponentType;
  path?: string;
  roles: Array<any>;
}

export const PrivateRoute: any = ({
  component: RouteComponent,
  roles,
  ...props
}: any) => {
  const isAuth = useAppSelector(selectAuth);
  const status = useAppSelector(selectStatus);
  const userRole = useAppSelector(selectRole);

  const userHasRequiredRole = isAuth && roles.includes(userRole) ? true : false;

  if (isAuth && status === "fullfilled" && userHasRequiredRole) {
    return <RouteComponent {...props} />;
  }

  if (isAuth && !userHasRequiredRole) {
    toast.error("You don't have permission to access this page");
    return <Navigate to="/dashboard" />;
  }
  return <Navigate to="/" />;
};

export const PublicRoute: React.FC<Props> = ({
  component: RouteComponent,
  roles,
  ...props
}: any) => {
  const isAuth = useAppSelector(selectAuth);
  // const status = useSelector(selectStatus)

  if (isAuth) {
    return <Navigate to="/dashboard" />;
  }

  return <RouteComponent {...props} />;
};
