import React from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./notes.style";
import Layout from "../../../components/Layout";
import {
  Grid,
  TextField,
  Button,
  TextareaAutosize,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import box from "../../../assets/images/box.png";
import { Link, useNavigate } from "react-router-dom";
import { Plus, Elipses, Dot } from "../../../assets/svg/";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectNotes } from "../../../store/features/note/noteSlice";
import { selectRole } from "../../../store/features/auth/authSlice";
import { formatDate, getCourseDueDate, Role } from "../../../shared";
import {
  fetchNotes,
  fetcLecthNotes,
} from "../../../store/features/note/noteThunk";
import { OptionMenu } from "../../../components";
import { notesService } from "../../../services";
import { selectCourses } from "../../../store/features/course/courseSlice";
import { fetchCourses } from "../../../store/features/course/courseThunk";
import LoadingSpinner from "../../../components/common/Loader";
import TextEditor from "../../../components/common/TextEditor";

import marker from "../../../assets/images/markers.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { colors } from "../../../styles/color";

interface Props { }
interface IFormInputs {
  title: string;
  course_id: any;
  content: string;

  attachment: any;
}
const schema = yup.object().shape({
  title: yup.string().required(),
  course_id: yup.number().required(),
  content: yup.string().required(),
  attachment: yup.string()
});

const Notes = (props: Props) => {
  const style = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);

  const [isAddNote, setIsAddNote] = React.useState(false);
  const handleIsAddNote = () => {
    isAddNote ? setIsAddNote(false) : setIsAddNote(true);
  };

  const dispatch = useAppDispatch();
  const notes = useAppSelector(selectNotes);
  const userRole = useAppSelector(selectRole);
  const coursess = useAppSelector(selectCourses);

  const [upload, setUpload] = React.useState(false);
  const [url, setUrl] = React.useState<any | null>(null);
  const [selectedFile, setSelectedFile] = React.useState();
  const [attachment, setAttachment] = React.useState("");

  const changeHandler = (e: any) => {
    setSelectedFile(e.target.files[0]);
    setUpload(true);
    console.log(selectedFile);
  };

  const handleUpload = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    setIsLoading(true);
    formData.append("file", selectedFile!);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Brearer " + localStorage.getItem("token"),
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/upload/add`,
        { file: selectedFile },
        config
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Attachment has been uploaded");
          setIsLoading(false);
          setUpload(false);
          setAttachment(res.data.url);
        } else {
          toast.success(res.data.error);
          setIsLoading(false);
          setUpload(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.success("Something went wrong");
        setIsLoading(false);
        setUpload(false);
      });
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    const response = await notesService.createNote(data);
    console.log("response", response);
    setIsAddNote(false);
  };

  const createNote = async (e: any) => {
    e.preventDefault();
    // iterate the inputs with their name
    // get all the values from the form
    const values = Object.fromEntries(new FormData(e.target).entries());
    console.log(values);
    const response = await notesService.createNote({
      title: values.title,
      course_id: values.course_id,
      content: values.content, 
      attachment: values.attachment
    });

    // console.log("response", response);
    setIsAddNote(false);
  };

  const courses = [{ value: "0", label: "Course" }];

  coursess.map((course: any) =>
    courses.push({ value: course.id, label: course.name })
  );

  const draftOptions = [
    { name: "publish", link: "" },
    { name: "edit", link: "" },
    { name: "delete", link: "" },
  ];

  const publishedOptions = [
    { name: "unpublish", link: "" },
    { name: "edit", link: "" },
  ];

  console.log("notes", notes);

  const getNotes = async () => {
    if (userRole === Role.STUDENT) {
      await dispatch(fetchNotes()).then(() => {
        setIsLoading(false);
      });
    } else {
      await dispatch(fetcLecthNotes()).then(() => {
        setIsLoading(false);
      });
      await dispatch(fetchCourses(userRole));
    }
  };

  React.useEffect(() => {
    getNotes();
  }, []);

  let navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <Layout>
      <div className={style.container}>
        {isLoading && <LoadingSpinner />}
        {/* Add note modal */}
        {isAddNote ? ( 
          <div className={style.modalContainer}>
            <div className="modals">
              <Link to="" className="cancelBox" onClick={handleIsAddNote}>
                x
              </Link>
              <div className="content">
                <h6>Create Note</h6>
                <div className={style.form}>
                  <form onSubmit={createNote}>
                    <div className="form-row">
                      <div>
                        <Controller
                          name="title"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              error={!!errors.title}
                              variant="outlined"
                              placeholder="Title"
                              //   fullWidth={true}
                              size="small"
                              helperText={
                                errors.title ? (
                                  <span>Title is required</span>
                                ) : (
                                  ""
                                )
                              }
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Controller
                          name="course_id"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              error={!!errors.course_id}
                              select
                              // label="Level"
                              SelectProps={{
                                native: true,
                              }}
                              variant="filled"
                              placeholder="Level"
                              size="small"
                              InputProps={{ disableUnderline: true }}
                            >
                              {courses.map((option, index)=> 
                                  //getCourseDueDate(coursess[index]?.start_date,coursess[index]?.duration_in_days) > new Date() && 
                                  (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                  )                                   
                              )}
                            </TextField>
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <TextEditor height="300px" name="content" />
                    </div>


                    <div className="">                                            
                      <div>
                        <input
                          type="text"
                          placeholder="Attachment url (optional)"
                          name="attachment"
                          value={attachment}
                          onChange={(e: any) => setAttachment(e.target.value)} 
                          style={{
                            background: "#fff",
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                            borderRadius: "5px",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "1rem",
                            // lineHeight: "33px",
                            color: "#C4C4C4",
                            padding: ".5rem",
                            width: "100%"
                          }}
                        />
                      </div>
                      <div className="input fileUpload ">
                        <span> Or choose from your device</span>

                        <input
                          type="file"
                          name="file"
                          className="file"
                          accept=".pdf,.doc,.xsl"
                          form="uploadFile"
                          onChange={changeHandler} 
                          style={{
                            background: "#fff",
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                            borderRadius: "5px",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "1rem",
                            // lineHeight: "33px",
                            color: "#C4C4C4",
                            padding: ".5rem",
                            width: "100%"
                          }}
                        />
                        {upload && (
                          <button
                            type="submit"
                            form="uploadFile"
                            onClick={handleUpload} 
                            style={{
                              background: "#fff",
                              border: "1px solid rgba(0, 0, 0, 0.3)",
                              borderRadius: "5px",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "1rem",
                              // lineHeight: "33px",
                              color: colors.blue,
                              padding: ".5rem",
                              width: "50%",
                              marginTop: ".5rem"
                            }}
                          >
                            Start Upload
                          </button>
                        )}
                      </div>
                    </div>








                    <div className="form-row2">
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          CREATE
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {userRole === Role.STUDENT ? (
          <>
            <div className={style.dash}>
              <div className={style.header} style={{ backgroundColor: '#F1EDEC', padding: '1rem' }}>
                <h5 style={{ fontSize: '1.5rem', fontWeight: 100, color: '#152E88' }}>
                  Notes
                </h5>
                <span style={{ float: 'right' }}><img src={marker} alt="marker-image" width={'25px'} height={'25px'} /> </span>
              </div>
              <div className={style.main}>
              {/* <h5 className="drafts" style={{fontSize: '1.5rem', fontWeight: 100, color: '#152E88'}}>Drafts</h5> */}
                {notes.length === 0 && `No notes(s) found.`}
                {notes.length !== 0 && 
                (
                <div className="lists student">
                  {notes.map((note: any, i: number) => 
                   
                    getCourseDueDate(note.course?.start_date, note.course.duration_in_days) >= new Date() && 
                    (
                    <div
                      key={i}
                      className="list"
                      onClick={() =>
                        navigate(`read`, {
                          // replace: true,
                          state: {
                            id: note.id,
                            title: note.title,
                            body: note.content,
                            course: note.course.name,
                            date: note.created_at,
                            attachment: note.attachment,
                          },
                        })
                      }
                    >
                      <div className="content">
                        <span>{note.title}</span>
                        <span>
                          {note.course.lecturers.map(
                            (lecturer: any, i: number) =>
                              i === 0 ? lecturer.name : lecturer.name + " ,"
                          )}
                        </span>

                        <h5>{note.course.name}</h5>
                        <span>{formatDate(note.created_at)}</span>
                      </div>
                      <div className="icon">
                        <Dot className="dot" />
                      </div>
                    </div>
                    )
                   
                  )}
                </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={style.dash}>
              <div className={style.header} style={{  }}>

                <h5 style={{fontSize: '1.5rem', fontWeight: 100, color: '#152E88'}}>
                  Notes
                </h5>
                <span style={{ float: 'right' }}>
                    <Link to="" onClick={handleIsAddNote}>
                      <Plus /> Create
                    </Link>
                  </span>

              </div>
              <div className={style.main}>
                <h5 className="drafts">Drafts</h5>
                {notes.filter((note: any) => !note.is_published).length === 0 && `No draft(s) found.`}
                {notes.filter((note: any) => !note.is_published).length !== 0 && 
                (
                <div className="lists">
                  {notes
                    .filter((note: any) => !note.is_published)
                    .map((note: any, idx: number) => (
                      <div className="list" key={note.id}>
                        <div
                          className="content"
                          key={`-${note.id}`}
                          onClick={() =>
                            navigate(`read`, {
                              // replace: true,
                              state: {
                                id: note.id,
                                title: note.title,
                                body: note.content,
                                course: note.course.name,
                                date: note.created_at,
                                attachment: note.attachment,
                              },
                            })
                          }
                        >
                          <h5>{note.title}</h5>
                          <h6>{note.course.name}</h6>
                          <span>{formatDate(note.created_at)}</span>
                        </div>
                        <OptionMenu
                          options={draftOptions}
                          id={note.id}
                          key={note.id}
                        />
                      </div>
                    ))}
                </div>
                )}

                <h5 className="drafts">Published</h5>
                {notes.filter((note: any) => note.is_published).length === 0 && `No published notes(s) found.`}
                {notes.filter((note: any) => note.is_published).length !== 0 && 
                (
                <div className="lists">
                  {notes
                    .filter((note: any) => note.is_published)
                    .map((note: any, idx: number) => (
                      <div className="list" key={note.id}>
                        <div
                          className="content"
                          key={`-${note.id}`}
                          onClick={() =>
                            navigate(`read`, {
                              // replace: true,
                              state: {
                                id: note.id,
                                title: note.title,
                                body: note.content,
                                course: note.course.name,
                                date: note.created_at,
                                attachment: note.attachment,
                              },
                            })
                          }
                        >
                          <h5>{note.title}</h5>
                          <h6>{note.course.name}</h6>
                          <span>{formatDate(note.created_at)}</span>
                        </div>
                        <OptionMenu
                          options={publishedOptions}
                          id={note.id}
                          key={note.id}
                        />
                      </div>
                    ))}
                </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

Notes.propTypes = {};

export default Notes;
