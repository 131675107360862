import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./viewAssignment.style";
import Layout from "../../../components/Layout";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Chev, NextPage, Previous, Info, Elipses } from "../../../assets/svg/";
import { formatDate } from "../../../shared";
import { formatDateDifference } from "../../shared/utils/dateDifference";
import {
  fetchSubmissions,
  markAssignment,
} from "../../../store/features/assignment/assignmentThunk";
import { getSubmissions } from "../../../store/features/assignment/assignmentSlice";
import { selectRole } from "../../../store/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/common/Loader";
import { id } from "date-fns/locale";
import { Card, CardBody, FormGroup, Label, Input } from "reactstrap";
import { Box, Grid } from "@material-ui/core";
import { fetchCourseNotes } from "../../store/features/payments/paymentThunk";
import useFetch from "../../../components/common/useFetch";

interface Props {}

interface CustomizedState {
  myState: any;
  assignment_id: number;
  title: string;
  course: string;
  course_id: number;
  course_code: string;
  createdDate: any;
}

const ViewAssignmentLecturer = (props: Props) => {
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const dispatch = useDispatch<any>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [studentSubmissions, setStudentSubmissions] = React.useState<any>([]);
  const [viewModal, setViewModal] = React.useState(false);
  const [submissions, setSubmissions] = React.useState<any>([]);
  const [submissionID, setSubmisionID] = React.useState<any>(null);
  const [studentSubmission, setStudentSumission] = React.useState<any>([]);
  const [inputScore, setInputScore] = React.useState<number>(0);
  const [inputComment, setInputComment] = React.useState<string>("");

  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();
  // console.log(state.course_id, state.assignment_id);
  let parser = new DOMParser();

  const submission = useAppSelector(getSubmissions);

  const appDispatch = useAppDispatch();
  const getSubmissionsList = async () => {
    await appDispatch(
      fetchSubmissions({
        course_id: state.course_id,
        ass_id: state.assignment_id,
      })
    );
  };

  React.useEffect(() => {
    getSubmissionsList();
    setSubmissions(submission);
    setIsLoading(false);
  }, [submissions]);
  // console.log(submissions);

  const handleSubmission = (e: any) => {
    e.preventDefault();
    console.log("submitted");
    //  get values of all submitted textarea using e target .elements
    const form = e.target;
    const formData = new FormData(form);
    const data: any = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    // console.log(data);
  };
  const handleViewModal = (id: any) => {
    setViewModal(!viewModal);
    if (id !== null) {
      setSubmisionID(id);
      // console.log(submissions.submissions.id == 15);
      submissions.submissions.filter((submission: any) => {
        setStudentSubmissions(submission);
        console.log(studentSubmissions);
        if (submission.id == id) {
          submission.answers.map((answer: any) => {
            for (let i = 0; i < submission.questions.length; i++) {
              if (submission.questions[i].id == answer.question_id) {
                console.log(submission.questions[i].text);
              }
            }
            console.log(answer.text + "answers");
          });
        }
      });
    }
  };

  const handleMarkAssignment = async () => {
    try {
      const body = {
        submission_id: studentSubmissions.id,
        score: inputScore,
        comment: inputComment,
      };
      // await appDispatch(markAssignment(body));
      const request = await fetch(`${BASE_URL}/assignment/mark`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      const response = await request.json();
      console.log("mark assignment body", body);
      console.log("request", request);
      console.log("response", response);
      if (request.ok) {
        console.log("assignment marked");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error marking assignment", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  console.log("student submissions", studentSubmissions);
  console.log("submissions", submissions);

  return (
    <Layout>
      <ToastContainer />
      {isLoading && <LoadingSpinner />}
      {/* //Generate Student Modal */}
      {viewModal && (
        <div className={style.modalContainer}>
          <div className="modals">
            <div
              style={{
                boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
                minHeight: "calc(100vh - 100px)",
                height: "calc(100% - 50px)",
              }}
            >
              <ToastContainer />
              <div
                className={style.header}
                style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
              >
                <h5
                  style={{
                    fontWeight: 100,
                    color: "#152E88",
                    fontSize: "1.5rem",
                  }}
                >
                  <Chev className="icon" onClick={handleViewModal} />
                  {studentSubmissions?.student?.name}
                </h5>
              </div>

              <div className={style.main}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={8}>
                    {studentSubmissions?.questions?.map(
                      (question: any, index: any) => (
                        <div key={question.id} style={{ color: "black" }}>
                          <p
                            style={{
                              margin: "0 20px",
                            }}
                          >
                            Question {index + 1}.
                          </p>
                          <div
                            style={{
                              background: "#FFFFFF",
                              border: "1px solid rgba(0, 0, 0, 0.3)",
                              borderRadius: "8px",
                              margin: "10px 20px",
                              padding: "8px 16px",
                              fontWeight: 400,
                            }}
                            dangerouslySetInnerHTML={{ __html: question.text }}
                          ></div>
                          {studentSubmissions?.answers
                            ?.filter(
                              (answer: any) =>
                                answer.question_id === question.id
                            )
                            ?.map((ans: any) => (
                              <div
                                className={style.answerCard}
                                dangerouslySetInnerHTML={{ __html: ans.text }}
                              ></div>
                            ))}
                        </div>
                      )
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div style={{ margin: "10px 20px" }}>
                      <p style={{ fontWeight: 600 }}>
                        Obtainable Score: {submissions?.obtainable_score || 0}
                      </p>
                      <Input
                        value={inputScore}
                        onChange={(e: any) => setInputScore(e.target.value)}
                        className="mb-3"
                        type="number"
                      />
                      <Input
                        value={inputComment}
                        onChange={(e: any) => setInputComment(e.target.value)}
                        type="textarea"
                      />
                      <button
                        style={{
                          width: "fit-content",
                          padding: "10px",
                          margin: "10px 0",
                        }}
                        onClick={handleMarkAssignment}
                      >
                        Submit
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={style.container}>
        <div className={style.dash}>
          <div
            className={style.header}
            style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
          >
            <h5
              style={{
                fontWeight: 100,
                color: "#152E88",
                fontSize: "1.5rem",
              }}
            >
              <Link className="icon" to="/dashboard/assignments">
                <Chev />
              </Link>
              {state.course_code} {state.course} - {state.title}
            </h5>
          </div>

          <div className={style.main}>
            <h5
              style={{
                fontWeight: 400,
                color: "#152E88",
                fontSize: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              Submissions Lists
            </h5>
            {!isLoading &&
              (Object.keys(submissions).length > 0 ? (
                <>
                  <ul>
                    {submissions.submissions.map((submission: any) => (
                      <li
                        key={submission.id}
                        style={{
                          fontWeight: 400,
                          color: "000000",
                          fontSize: "1.2rem",
                          marginBottom: "1rem",
                          cursor: "pointer",
                          display: "flex",
                        }}
                        className={style.submissionList}
                        onClick={handleViewModal.bind(this, submission.id)}
                      >
                        {submission.student.name}
                        {submission.score === null ? (
                          <span style={{ marginLeft: "auto", color: "red" }}>
                            Not Graded
                          </span>
                        ) : (
                          <span style={{ marginLeft: "auto", color: "green" }}>
                            Graded <i className="fa fa-check"></i>
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                  <h6
                    style={{
                      fontWeight: 400,
                      color: "#152E88",
                      fontSize: "1.2rem",
                    }}
                  >
                    Total Submissions: {submissions.submissions.length}
                  </h6>
                </>
              ) : (
                <h6
                  style={{
                    fontWeight: 400,
                    color: "#152E88",
                    fontSize: "1.2rem",
                  }}
                >
                  No Submissions Yet
                </h6>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

ViewAssignmentLecturer.propTypes = {};

export default ViewAssignmentLecturer;
