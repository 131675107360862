import { ArrowForward, HelpOutline } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import { useAppSelector } from "../../store";
import { selectRole } from "../../../store/features/auth/authSlice";
import { Role } from "../../../shared";
import { useStyles } from "./help.style";
import { Modal, ModalBody } from "reactstrap";
import { Grid } from "@material-ui/core";
import { PlayBig, PlayS } from "../../../assets/svg";

interface Props {}

const Help = () => {
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const userRole = useAppSelector(selectRole);
  const [videos, setVideos] = useState([]);
  const [openVideo, setOpenVideo] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  const fetchVideos = async () => {
    try {
      const response = await fetch(`${BASE_URL}/video/lecturer/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (response.ok) {
        setVideos(result);
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <Layout>
      {/* video player modal */}
      <Modal
        isOpen={openVideo}
        toggle={() => setOpenVideo(!openVideo)}
        style={{ minWidth: "750px" }}
      >
        <video controls width="100%">
          <source src={currentVideo} type="video/mp4" />
          Sorry, your browser doesn't support videos.
        </video>
      </Modal>
      <div className={style.container}>
        <div className={style.dash}>
          <div
            className={style.header}
            style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
          >
            <h5
              style={{
                fontWeight: 100,
                color: "#152E88",
                fontSize: "1.5rem",
              }}
            >
              Help
            </h5>
            <span style={{ float: "right" }}>
              <HelpOutline className="icon" style={{color: "#152E88"}}/>
            </span>
          </div>

          <div className={style.main}>
            <h5>Short instructive videos on how to use this platform</h5>
          <Grid container spacing={3}>

              {videos?.map((video: any) => (
                <Grid item xs={12} sm={6} md={4}
                  key={video.id}
                  >
                    <div   onClick={() => {
                    setCurrentVideo(video.url);
                    setOpenVideo(true);
                  }} style={{backgroundColor:"#D9D9D9",display:"flex", justifyContent:"center", alignItems:"center",padding:"30px 0", cursor:"pointer"}}>
                    <PlayS />
                    </div>

                  <h6>{video.title}</h6>
                </Grid>
              ))}
              </Grid>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Help.propTypes = {};

export default Help;
