import { ArrowForward, StayPrimaryLandscapeRounded } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Books, Notebook } from "../../../assets/svg";
import Layout from "../../../components/Layout";
import { useAppSelector, useAppDispatch } from "../../store";
import { selectCourses } from "../../store/features/course/courseSlice";
import { selectRole, selectUser } from "../../../store/features/auth/authSlice";
import { Role } from "../../../shared";
import { fetchCourses } from "../../../store/features/course/courseThunk";
import { useStyles } from "./essays.style";
import LoadingSpinner from "../../../components/common/Loader";

interface Props {}

const Essays = () => {
  const style = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectRole);
  const user = useAppSelector(selectUser);
  const courses = useAppSelector(selectCourses);
  console.log("courses", courses);
  console.log("user", user);

  const getCourses = async () => {
    await dispatch(fetchCourses(userRole));
    setIsLoading(false);
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <Layout>
      <div className={style.container}>
        <div className={style.dash}>
          <div
            className={style.header}
            style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
          >
            <h5
              style={{
                fontWeight: 100,
                color: "#152E88",
                fontSize: "1.5rem",
              }}
            >
              Essays
            </h5>
            <span style={{ float: "right" }}>
              <Notebook />
            </span>
          </div>

          <div className={style.main}>
            <h5>Courses Reviews</h5>

            <div className="lists">
              {isLoading && <LoadingSpinner />}
              {userRole === Role.STUDENT && (
                <div
                  className="list"
                  onClick={() =>
                    navigate("/dashboard/essays/read-essay", {
                      state: {
                        course: { name: "Air Mindedness" },
                      },
                    })
                  }
                >
                  <div className="content">
                    <h6>Air Mindedness</h6>
                  </div>

                  <div style={{ cursor: "pointer" }}>
                    <ArrowForward />
                  </div>
                </div>
              )}
              {courses?.map((course: any) => (
                <div
                  className="list"
                  key={course.id}
                  onClick={() =>
                    userRole === Role.STUDENT
                      ? navigate("/dashboard/essays/read-essay", {
                          state: {
                            course: course,
                          },
                        })
                      : navigate(`students-list`, {
                          //  replace: true,
                          state: {
                            id: course.id,
                            name: course.name,
                            code: course.code,
                          },
                        })
                  }
                >
                  <div className="content" key={`-${course.id}`}>
                    <span>{course.code}</span>
                    <h6>{course.name}</h6>
                  </div>

                  <div>
                    <ArrowForward />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Essays.propTypes = {};

export default Essays;
