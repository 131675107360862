import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useStyles } from "./applicants.style";
import {
  AdmittedApplicantsDataTable,
  NewApplicantsDataTable,
} from "../../../../components/common/DataTables/applicants";
import { toast, ToastContainer } from "react-toastify";
import RejectedApplicantsDataTable from "../../../../components/common/DataTables/applicants/RejectedApplicantsDataTable";
import GraduatedApplicantsDataTable from "../../../../components/common/DataTables/applicants/GraduatedApplicantsDataTable";

interface Props {}

const Applicants = (props: Props) => {
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const [activeTab, setActiveTab] = React.useState("new");
  const [levels, setLevels] = React.useState([]);
  const [pendingApplicants, setPendingApplicants] = React.useState([]);
  const [admittedApplicants, setAdmittedApplicants] = React.useState([]);
  const [rejectedApplicants, setRejectedApplicants] = React.useState([]);
  const [graduatedApplicants, setGraduatedApplicants] = React.useState([]);

  const [isLoadingPending, setIsLoadingPending] = React.useState(true);
  const [isLoadingAdmitted, setIsLoadingAdmitted] = React.useState(true);
  const [isLoadingRejected, setIsLoadingRejected] = React.useState(true);
  const [isLoadingGraduated, setIsLoadingGraduated] = React.useState(true);

  const [filter, setFilter] = React.useState("");
  const [globalFilter, setGlobalFilter] = React.useState("");

  useEffect(() => {
    fetchApplicants();
    fetchLevels();
  }, []);

  const fetchApplicants = async () => {
    try {
      setIsLoadingPending(true);
      setIsLoadingAdmitted(true);
      setIsLoadingRejected(true);
      setIsLoadingGraduated(true);
      const request = await fetch(`${BASE_URL}/admin/applicant/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      setIsLoadingPending(false);
      setIsLoadingAdmitted(false);
      setIsLoadingRejected(false);
      setIsLoadingGraduated(false);
      if (response.length) {
        setPendingApplicants(
          response.filter((item: any) => item.status === "pending")
        );
      }
      if (response.length) {
        setAdmittedApplicants(
          response.filter((item: any) => item.status === "admitted")
        );
      }
      if (response.length) {
        setRejectedApplicants(
          response.filter((item: any) => item.status === "rejected")
        );
      }
      if (response.length) {
        setGraduatedApplicants(
          response.filter((item: any) => item.status === "graduated")
        );
      }
    } catch (error) {
      setIsLoadingPending(false);
      setIsLoadingAdmitted(false);
      setIsLoadingRejected(false);
      setIsLoadingGraduated(false);
      toast.error("Error fetching applicants", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  const fetchLevels = async () => {
    try {
      const request = await fetch(`${BASE_URL}/level/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      if (response.length) {
        setLevels(response);
      }
    } catch (error) {
      toast.error("Error fetching levels", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  return (
    <Layout>
      <ToastContainer />
      <div className={style.container}>
        <div className={style.header}>
        <div className="search">
            <input
              className="searchInput"
              placeholder="Search"
              value={filter}
              onChange={(e) =>
                globalFilter === ""
                  ? setFilter(e.target.value)
                  : setGlobalFilter("")
              }
            />
            <button
              className="btn"
              type="button"
              onClick={() => setGlobalFilter(filter)}
            >
              Search
            </button>
          </div>

          <h4 className="title">Applications</h4>
          <div className="tabs">
            <div className="tabLinks">
              <div className={activeTab === "new" ? "active link" : "link"}>
                <Link to="" onClick={() => setActiveTab("new")}>
                  New
                </Link>
              </div>
              <div
                className={activeTab === "admitted" ? "active link" : "link"}
              >
                <Link to="" onClick={() => setActiveTab("admitted")}>
                  Admitted
                </Link>
              </div>
              <div
                className={activeTab === "rejected" ? "active link" : "link"}
              >
                <Link to="" onClick={() => setActiveTab("rejected")}>
                  Rejected
                </Link>
              </div>

              <div
                className={activeTab === "graduated" ? "active link" : "link"}
              >
                <Link to="" onClick={() => setActiveTab("graduated")}>
                  Graduated
                </Link>
              </div>

            </div>
          </div>
        </div>
        <div className={style.body}>
          <div className="table">
            {activeTab === "new" && (
              <NewApplicantsDataTable
                fetchApplicants={fetchApplicants}
                rows={pendingApplicants}
                levels={levels} 
                isLoadingPending={isLoadingPending} 
                setFilter={globalFilter}
              />
            )}
            {activeTab === "admitted" && (
              <AdmittedApplicantsDataTable
                fetchApplicants={fetchApplicants} 
                rows={admittedApplicants} 
                isLoadingAdmitted={isLoadingAdmitted} 
                setFilter={globalFilter} 
              />
            )}
            {activeTab === "rejected" && (
              <RejectedApplicantsDataTable
                fetchApplicants={fetchApplicants}
                rows={rejectedApplicants}
                levels={levels}
                isLoadingRejected={isLoadingRejected} 
                setFilter = {globalFilter}
              />
            )}

            {activeTab === "graduated" && (
              <GraduatedApplicantsDataTable
                fetchApplicants={fetchApplicants}
                rows={graduatedApplicants}
                levels={levels}
                isLoadingGraduated={isLoadingGraduated} 
                setFilter={globalFilter} 
              />
            )}

          </div>
        </div>
      </div>
    </Layout>
  );
};

Applicants.propTypes = {};

export default Applicants;
