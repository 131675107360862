import {
  ArrowBack,
  ArrowForward,
  StayPrimaryLandscapeRounded,
} from "@material-ui/icons";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Books } from "../../../assets/svg";
import Layout from "../../../components/Layout";
import { useAppSelector } from "../../store";
import { selectCourses } from "../../store/features/course/courseSlice";
import { useStyles } from "./essays.style";
import { Role } from "../../../shared";
import { selectRole } from "../../../store/features/auth/authSlice";
import { Button, Input } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";

interface Props {
  course: any;
  getStudentEssays: any;
}

const CreateEssay = (props: Props) => {
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const title =
    "Explain in not more than 100 words how this course would improve your capacity and work as a NAF personnel";

  const handleSubmit = async () => {
    try {
      const body = {
        course_id: props.course.id,
        title: title,
        content: content,
      };
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/essay/create`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const res = await req.json();
      if (req.ok) {
        toast.success("Essay submitted successfully");
        props.getStudentEssays();
      }
      console.log(res);
      if (res) {
        toast.success(res.messsage, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={style.container}>
      <ToastContainer />
      <div className={style.dash}>
        <div className={style.main}>
          <div>
            <h6>{title}</h6>
            <Input
              id="exampleText"
              name="text"
              type="textarea"
              rows="12"
              value={content}
              onChange={(e: any) => setContent(e.target.value)}
            />
            <Button
              style={{
                backgroundColor: "#152E88",
                outline: "none",
                margin: "8px 0",
                float: "right",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateEssay.propTypes = {};

export default CreateEssay;
