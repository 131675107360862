import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import PropTypes from "prop-types";

import { useStyles } from "../login.style";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  MenuItem,
  Select,
} from "@material-ui/core";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";

import { Link, useLocation } from "react-router-dom";
import Footer from "../../../components/Footer";
import { useAppDispatch } from "../../../store";
import { Spinner } from "../../../components/common/Spinner";
import { clearState } from "../../../store/features/auth/authSlice";
import { IFormInputs } from "../../../shared/interfaces/user";
import { getYears } from "../../../shared/utils/date";
import { toast, ToastContainer } from "react-toastify";
import { Sync } from "@material-ui/icons";
import useNetworkStatus from "../../shared/utils/network";
import { useAppSelector } from "../../store";
import { selectWebsite } from "../../store/features/website/websiteSlice";

interface Props {}

interface CustomizedState {
  serviceNo: any;
}

const schema = yup.object().shape({
  service_number: yup.string().required("Service number is required"),
  rank: yup.string().required("Rank is required"),
  email: yup.string().email().required("Email is required"),
  marital_status: yup.string().required("Marital status is required"),
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string(),
  last_name: yup.string().required("Last name is required"),
  phone: yup.string().required("Phone is required"),
  gender: yup.string().required("Gender is required"),
  age: yup.string().required("Age is required"),
  highest_qualification: yup.string().required("Highest education is required"),
  professional_qualification: yup.string(),
  military_courses_attended: yup.string(),
  other_military_courses_attended: yup.string(),
  course_intake: yup.string().required("Course/Intake is required"),
  service_years: yup.string().required("Service Years is required"),
  trade: yup.string().required("Specialty/Trade is required"),
  skill_level: yup.string(),
  next_promotion_year: yup.string().required("Required"),
  course_upgrade_year: yup.string(),
  next_pme_year: yup.string(),
  essay: yup.string().required("Essay is required"),
  medical_disabilities: yup.string(),
  emergency_contact_name: yup.string().required("Required"),
  emergency_contact_address: yup.string().required("Required"),
  emergency_contact_phone: yup.string().required("Required"),
  emergency_contact_relationship: yup.string().required("Required"),
});

const ApplicationPage = (props: Props) => {
  const style = useStyles();
  const [isSubmitting, setIsloading] = useState(false);
  const [isLoadingUserData, setIsLoadingUserData] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as CustomizedState;

  const BASE_URL = process.env.REACT_APP_BASEURL;
  const NAF_URL = process.env.REACT_APP_NAFURL;

  const { isOnline } = useNetworkStatus();
  !isOnline &&
    toast.warning("Check your network connectivity to be sure you're online.");

  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    const body = {
      bio_data: {
        name: `${data.first_name || ""} ${data.middle_name || ""} ${
          data.last_name || ""
        }`,
        rank: data.rank || "",
        service_number: data.service_number || "",
        age: data.age || "",
        gender: data.gender || "",
        email: data.email || "",
        phone: data.phone || "",
        marital_status: data.marital_status || "",
        image: data.image || "",
      },
      educational_background: {
        highest_qualification: data.highest_qualification || "",
        professional_qualification: data.professional_qualification || "",
        professional_military_qualification:
          data.military_courses_attended || "",
        //other_military_course: data.other_military_courses_attended || "",
      },
      other_details: {
        course_intake: data.course_intake || "",
        service_years: data.service_years || "",
        specialty: data.trade || "",
        skill_level: data.skill_level || "",
        projected_promotion_year: data.next_promotion_year || "",
        projected_upgrade_year: data.course_upgrade_year || "",
        projected_pme_year: data.next_pme_year || "",
        essay: data.essay || "",
      },
      medical: data.medical_disabilities || "",
      emergency_contact: {
        emergency_contact_name: data.emergency_contact_name || "",
        emergency_contact_phone: data.emergency_contact_phone || "",
        emergency_contact_relationship:
          data.emergency_contact_relationship || "",
        emergency_contact_address: data.emergency_contact_address || "",
      },
    };

    try {
      setIsloading(true);
      const response = await fetch(`${BASE_URL}/admission/applicant/apply`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const result = await response.json();
      console.log("result", result);
      if (result.message) {
        toast.success(result.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setIsloading(false);
      window.location.href = "/success-registration";
    } catch (error) {
      console.log(error);
      toast.error("Check the details and try again", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsloading(false);
    }
  };

  useEffect(() => {
    setValue("service_number", state?.serviceNo || "");
    getUserData();
  }, [state?.serviceNo]);

  const getUserData = async () => {
    const service_number = getValues("service_number");
    const url = `${NAF_URL}/${service_number}`;
    try {
      setIsLoadingUserData(true);
      const request = await fetch(url);
      const response = await request.json();
      if (response) {
        setIsLoadingUserData(false);
        setValue("first_name", response[0].firstname, { shouldValidate: true });
        setValue("last_name", response[0].surname, { shouldValidate: true });
        setValue("middle_name", response[0].middlename || "", {
          shouldValidate: true,
        });
        setValue("rank", response[0].rank || "", { shouldValidate: true });

        let dob: string = getYears(response[0].dob).toLocaleString();
        setValue("age", dob || "", { shouldValidate: true });

        setValue("gender", response[0].sex || "", { shouldValidate: true });
        setValue("marital_status", response[0].maritalStatus || "", {
          shouldValidate: true,
        });
        setValue("email", response[0].email || "", { shouldValidate: true });
        setValue("phone", response[0].phone || "", { shouldValidate: true });
        let cse = response[0].cse;
        let cseType = response[0].type_of_commission;
        cse = cse + " " + cseType;
        setValue("course_intake", cse || "", { shouldValidate: true });
        setValue("image", response[0].passport || "", { shouldValidate: true });
        setValue("trade", response[0].specialty || "", {
          shouldValidate: true,
        });

        let serviceYears: string = getYears(
          response[0].date_of_commission
        ).toLocaleString();
        setValue("service_years", serviceYears || "", { shouldValidate: true });

        //qualifications
        let qualification = response[1]
          .map(
            (elem: {
              qualification: string;
              year: string;
              institution: string;
            }) => elem.qualification + " - " + elem.institution
          )
          .join(", ");
        setValue("professional_qualification", qualification, {
          shouldValidate: true,
        });

        //courses
        let courses = response[2]
          .map((elem: { course: string }) => elem.course)
          .join(", ");
        setValue("military_courses_attended", courses, {
          shouldValidate: true,
        });
      } else {
        toast.error("unable to get user data", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setIsLoadingUserData(false);
    } catch (error) {
      console.log("err", error);
      toast.error("unable to get user data", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoadingUserData(false);
    }
  };

  useEffect(() => {
    dispatch(clearState());
  }, []);

  const website = useAppSelector(selectWebsite);

  return (
    <Container maxWidth="lg" className={style.container}>
      <ToastContainer />
      <div className={style.header}>
        <div className={style.logoTitleContainer}>
          <Link to="/">
            <img src={website?.logo} alt="logo" className={style.logoIMG} />
          </Link>
          <Typography variant="h1" className={style.appTitle}>
            Air Warfare Centre
          </Typography>
        </div>
        <h1 className={style.title}>Application form</h1>
      </div>
      <Paper elevation={3} className={style.regFormContainer}>
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box style={{ display: "flex", gap: 5 }}>
                <Typography>Bio Data</Typography>
                {isLoadingUserData && <Spinner size="1" />}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div style={{ display: "flex" }}>
                <Controller
                  name="service_number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onBlur={getUserData}
                      error={!!errors.service_number}
                      variant="outlined"
                      placeholder="Service Number"
                      helperText={errors.service_number?.message}
                      fullWidth
                      disabled
                    />
                  )}
                />
                <Sync
                  style={{
                    color: "#152E88",
                    height: "1.1876em",
                    cursor: "pointer",
                  }}
                  onClick={() => getUserData()}
                  titleAccess="Refetch user data"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div>
                <Controller
                  name="rank"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.rank}
                      variant="outlined"
                      placeholder="rank"
                      disabled={true}
                      helperText={errors.rank?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.email}
                      variant="outlined"
                      placeholder="email"
                      disabled={true}
                      helperText={errors.email?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.first_name}
                    variant="outlined"
                    placeholder="Firstname"
                    disabled={true}
                    helperText={errors.first_name?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div>
                <Controller
                  name="middle_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.middle_name}
                      variant="outlined"
                      placeholder="Middle Name"
                      disabled={true}
                      helperText={errors.middle_name?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.last_name}
                      variant="outlined"
                      placeholder="Last Name"
                      disabled={true}
                      helperText={errors.last_name?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.phone}
                      variant="outlined"
                      placeholder="phone"
                      disabled={true}
                      helperText={errors.phone?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div>
                <Controller
                  name="marital_status"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.marital_status}
                      variant="outlined"
                      placeholder="Marital Status"
                      disabled={true}
                      helperText={errors.marital_status?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.gender}
                      variant="outlined"
                      placeholder="Gender"
                      disabled={true}
                      helperText={errors.gender?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div>
                <Controller
                  name="age"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.age}
                      variant="outlined"
                      placeholder="Age"
                      disabled={true}
                      helperText={errors.age?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            {/* end of bio data */}
            <Grid item xs={12}>
              <Box style={{ display: "flex", gap: 5 }}>
                <Typography>Educational Background</Typography>
                {isLoadingUserData && <Spinner size="1" />}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="highest_qualification"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.highest_qualification}
                    variant="outlined"
                    placeholder="Highest Educational Qualification"
                    helperText={errors.highest_qualification?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div>
                <Controller
                  name="professional_qualification"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.professional_qualification}
                      variant="outlined"
                      placeholder="Professional Qualifications"
                      disabled={true}
                      helperText={errors.professional_qualification?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div>
                <Controller
                  name="military_courses_attended"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.military_courses_attended}
                      variant="outlined"
                      placeholder="Military Educational courses"
                      disabled={true}
                      helperText={errors.military_courses_attended?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <div>
                <Controller
                  name="other_military_courses_attended"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.other_military_courses_attended}
                      variant="outlined"
                      placeholder="other military courses attended"  disabled 
                      helperText={
                        errors.other_military_courses_attended?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid> */}
            <Grid item xs={12}>
              <Box style={{ display: "flex", gap: 5 }}>
                <Typography>Other Details</Typography>
                {isLoadingUserData && <Spinner size="1" />}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="course_intake"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.course_intake}
                    variant="outlined"
                    placeholder="Course on commission/intake"
                    disabled={true}
                    helperText={errors.course_intake?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div>
                <Controller
                  name="service_years"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.service_years}
                      variant="outlined"
                      placeholder="Number of years in service"
                      helperText={errors.service_years?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div>
                <Controller
                  name="trade"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.trade}
                      variant="outlined"
                      placeholder="Specialty/Trade"
                      disabled={true}
                      helperText={errors.trade?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div>
                <Controller
                  name="skill_level"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.skill_level}
                      variant="outlined"
                      placeholder="Mustering/ Skill level (for Airman/Airwomen)"
                      helperText={errors.skill_level?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Controller
                name="next_promotion_year"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.next_promotion_year}
                    variant="outlined"
                    placeholder="Projected year of next promotion"
                    helperText={errors.next_promotion_year?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <div>
                <Controller
                  name="course_upgrade_year"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.course_upgrade_year}
                      variant="outlined"
                      placeholder="Projected year of nomination for upgrading course (NCOs only)"
                      helperText={errors.course_upgrade_year?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div>
                <Controller
                  name="next_pme_year"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.next_pme_year}
                      variant="outlined"
                      placeholder="Projected year for next PME course (Specify Course)"
                      helperText={errors.next_pme_year?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography>Essay</Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography style={{ fontSize: "14px" }}>
                  In not more than 150 words explain what you understand by air
                  mindedness of a NAF personnel
                </Typography>
                <Controller
                  name="essay"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.essay}
                      variant="outlined"
                      placeholder="type here..."
                      helperText={errors.essay?.message}
                      fullWidth
                      multiline
                      minRows={4}
                      style={{ width: "100%", backgroundColor: "white" }}
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography>Others</Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography style={{ fontSize: "14px" }}>
                  Do you suffer from any medical condition or disabilities that
                  may affect your studies? if yes please specify
                </Typography>
                <Controller
                  name="medical_disabilities"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.medical_disabilities}
                      variant="outlined"
                      placeholder="type here..."
                      helperText={errors.medical_disabilities?.message}
                      fullWidth
                      multiline
                      maxRows={4}
                      style={{ width: "100%", backgroundColor: "white" }}
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography>Emergency contact</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="emergency_contact_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.emergency_contact_name}
                    variant="outlined"
                    placeholder="name"
                    helperText={errors.emergency_contact_name?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Controller
                  name="emergency_contact_address"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.emergency_contact_address}
                      variant="outlined"
                      placeholder="address"
                      helperText={errors.emergency_contact_address?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Controller
                  name="emergency_contact_phone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.emergency_contact_phone}
                      variant="outlined"
                      placeholder="phone number"
                      helperText={errors.emergency_contact_phone?.message}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Controller
                  name="emergency_contact_relationship"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.emergency_contact_relationship}
                      variant="outlined"
                      placeholder="relation"
                      helperText={
                        errors.emergency_contact_relationship?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end">
              <Button variant="contained" type="submit">
                submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <br />
      <br />

      {/* <Footer /> */}
    </Container>
  );
};

ApplicationPage.propTypes = {};

export default ApplicationPage;
