import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useStyles } from "./website.style";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import LoadingSpinner from "../../../../components/common/Loader";
import { toast, ToastContainer } from "react-toastify";
import Confirm from "../../../../components/common/Confirm";
import { Divider } from "@material-ui/core";
import TextEditor from "../../../../components/common/TextEditor";
import axios from "axios";
import { Spinner } from "../../../../components/common/Spinner";

interface Props {}

const AboutUs = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isCreatingPage, setIsCreatingPage] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isAddingLeadership, setIsAddingLeadership] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openCreatePage, setOpenCreatePage] = useState(false);
  const [openAddLeadership, setOpenAddLeadership] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [leadershipName, setLeadershipName] = useState("");
  const [leadershipPosition, setLeadershipPosition] = useState("");
  const [leadershipImageUrl, setLeadershipImageUrl] = useState("");
  const [focusedLeader, setFocusedLeader] = useState<any>(null);
  const [page, setPage] = useState<any>(null);

  const [isInitLoading, setIsInitLoading] = useState(true);

  const fetchPage = async () => {
    try {
      const request = await fetch(`${BASE_URL}/about/page`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      if (request.ok) {
        setPage(response);
      }
      setIsInitLoading(false);
    } catch (error) {
      toast.error("Error fetching page");
      console.log(error);
      setIsInitLoading(false);
    }
  };

  const createPage = async () => {
    try {
      if (!title || !description) {
        return toast.error("Please fill all fields");
      }
      const body = {
        title,
        description,
      };
      setIsCreatingPage(true);
      const request = await fetch(`${BASE_URL}/about/page`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const response = await request.json();
      setIsCreatingPage(false);
      console.log(response);
      if (request.ok) {
        toast.success(" Updated successfully");
        fetchPage();
        setIsCreatingPage(false);
        handleClose();
      }
    } catch (error) {
      setIsCreatingPage(false);
      console.log(error);
    }
  };

  const handleAddLeadership = async () => {
    try {
      if (!leadershipName || !leadershipPosition || !leadershipImageUrl) {
        return toast.error("Please fill all fields");
      }
      const body = {
        name: leadershipName,
        position: leadershipPosition,
        image: leadershipImageUrl,
      };
      setIsAddingLeadership(true);
      const request = await fetch(`${BASE_URL}/about/leadership`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const response = await request.json();
      setIsAddingLeadership(false);
      if (request.ok) {
        toast.success("Leadership created successfully");
        fetchPage();
        setIsAddingLeadership(false);
        handleClose();
      }
    } catch (error) {
      setIsAddingLeadership(false);
      console.log(error);
    }
  };

  const handleUpdateLeaderShip = async () => {
    try {
      if (!leadershipName || !leadershipPosition || !leadershipImageUrl) {
        return toast.error("Please fill all fields");
      }
      const body = {
        name: leadershipName,
        position: leadershipPosition,
        image: leadershipImageUrl,
      };

      setIsAddingLeadership(true);
      const request = await fetch(
        `${BASE_URL}/about/leadership/${focusedLeader?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );
      const response = await request.json();
      setIsAddingLeadership(false);
      console.log(response);
      if (request.ok) {
        toast.success("Leadership updated successfully");
        handleClose();
        fetchPage();
        setIsAddingLeadership(false);
      }
    } catch (error) {
      setIsAddingLeadership(false);
      console.log(error);
    }
  };

  const handleDeleteLeadership = async () => {
    try {
      setIsCreatingPage(true);
      const request = await fetch(
        `${BASE_URL}/about/leadership/${focusedLeader.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await request.json();
      if (request.ok) {
        toast.success(response.message || "delete success");
        fetchPage();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPage();
  }, []);

  useEffect(() => {
    setLeadershipName(focusedLeader?.name || "");
    setLeadershipPosition(focusedLeader?.position || "");
    setLeadershipImageUrl(focusedLeader?.image || "");
    setImage(focusedLeader?.image || "");
  }, [focusedLeader]);

  useEffect(() => {
    setTitle(page?.about?.title);
    setDescription(page?.about?.description);
  }, [page]);

  const handleSubmit = () => {
    if (openCreatePage) {
      createPage();
    } else if (openAddLeadership) {
      if (focusedLeader) {
        handleUpdateLeaderShip();
      } else {
        handleAddLeadership();
      }
    }
  };

  const handleImageChange = async (event: any) => {
    setIsUploading(true);
    try {
      event.preventDefault();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Brearer " + localStorage.getItem("token"),
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}/upload/add`,
          { file: event.target.files[0] },
          config
        )
        .then((res) => {
          setIsUploading(false);
          if (res.status === 200) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setLeadershipImageUrl(res.data.url);
          }
        })
        .catch((err) => {
          setIsUploading(false);
          console.log(err);
        });
    } catch (error) {
      setIsUploading(false);
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpenCreatePage(false);
    setOpenAddLeadership(false);
    setFocusedLeader(null);
  };

  console.log(page);

  return (
    <div>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning!!"
        message="Are u sure you want to delete?"
        action={handleDeleteLeadership}
      />
      <Modal isOpen={openCreatePage} size="lg" toggle={handleClose}>
        <ModalHeader>
          <h6>About us content</h6>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Input
              onChange={(e: any) => setTitle(e.target.value)}
              value={title}
              type="text"
              name="title"
              placeholder="Title"
            />

            <TextEditor
              type="textarea"
              name="content"
              placeholder="enter slider content"
              id="content"
              onChange={(content: any) => setDescription(content)}
              value={description}
            />
            <Button
              style={{ background: "#152E88" }}
              onClick={handleSubmit}
              disabled={isCreatingPage}
            >
              {isCreatingPage ? "loading..." : "Submit"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={openAddLeadership} size="lg" toggle={handleClose}>
        <ModalHeader>
          <h6>{focusedLeader ? "Update" : "Create"} Leadership</h6>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Input
              onChange={(e: any) => setLeadershipName(e.target.value)}
              value={leadershipName}
              type="text"
              name="name"
              placeholder="Name"
            />
            <Input
              onChange={(e: any) => setLeadershipPosition(e.target.value)}
              value={leadershipPosition}
              type="text"
              name="position"
              placeholder="Position"
            />
            <Input onChange={handleImageChange} type="file" name="file" />
            {isUploading ? (
              <Spinner size="2" />
            ) : (
              image && <img src={image} alt="leader" height="100" width="100" />
            )}

            <Button
              style={{ background: "#152E88" }}
              onClick={handleSubmit}
              disabled={isAddingLeadership || isUploading}
            >
              {isAddingLeadership ? "loading..." : "Submit"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className="">

        {
          isInitLoading ? <h5 style={{color: "#152E88", fontWeight: 400, padding: "1rem"}}>Loading about centre info...</h5> :
          (
          <>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <Button onClick={() => setOpenCreatePage(true)}>Update</Button>
        </div>

        {page && (
          <div style={styles.card}>
            <div style={styles.cardHeader}>
              <h6 style={styles.cardTitle}>{page?.about?.title}</h6>
            </div>
            <Divider style={{ color: "#E0E0E0" }} />
            <div
              style={styles.cardContent}
              dangerouslySetInnerHTML={{ __html: page?.about?.description }}
            />
          </div>
        )}
        <h4 style={{color: "#152E88", fontWeight: 400, paddingTop:"1rem"}}>LEADERSHIP</h4>
        <Divider/>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: "10px",
            marginTop: ".5rem"
          }}
        >
          <Button onClick={() => setOpenAddLeadership(true)}>Add </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {page?.leadership?.map((item: any) => (
            <div
              key={item.id}
              style={{
                display: "flex",
                flexDirection: "column", 
                border: "1px solid #E0E0E0",
                width: "15rem",
              }}
            >
              <div style={{display:"flex", justifyContent: "center"}}>
              <img 
                height="250"
                width="150"
                src={item.image}
                alt="leader"
                style={{ flex: 1, justifySelf: "center" }}
              />
              </div>
              <h6 style={{ margin: "0 4px", textAlign:"center" }}>{item.name}</h6>
              <p style={{ margin: "0 4px", textAlign:"center"  }}>{item.position}</p>
              <button
                style={{
                  border: "none",
                  outline: "none",
                  color: "white",
                  backgroundColor: "#152E88",
                  margin: ".2rem",
                  marginBottom: ".3rem",
                  padding: ".2rem"
                }}
                onClick={() => {
                  setFocusedLeader(item);
                  setOpenAddLeadership(true);
                }}
              >
                Update
              </button>
              <button
                style={{
                  border: "none",
                  outline: "none",
                  color: "white",
                  backgroundColor: "red",
                  margin: ".2rem",
                  padding: ".2rem"
                }}
                onClick={() => {
                  setOpenWarning(true);
                  setFocusedLeader(item);
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>

         </>
          )
        }

      </div>
    </div>
  );
};

const styles = {
  container: {
    margin: "20px 30px",
  },
  card: {
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    marginBottom: "12px",
  },
  cardHeader: {
    padding: "8px",
  },
  cardTitle: {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "22px",
    color: "#003262",
  },
  cardContent: {
    padding: "16px 8px",
    color: "black",
  },
};

AboutUs.propTypes = {};

export default AboutUs;
