import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";
// select dropdown
import dropdown from "../../../assets/icons/select.svg";
export const useStyles = makeStyles((theme) => ({
  displayAll: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px",
    padding: "16px 189px 12px 58px",
    background: colors.grayShade,
    "& .items": {
      display: "flex",
      gap: "58px",
      "& a": {
        display: "flex",
        alignItems: "center",
        gap: "2px",
        textDecoration: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        /* identical to box height */
        color: colors.lightBlack,
        "&:hover": {
          color: colors.black,
        },
      },
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    " & th": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "19px",
      /* identical to box height */
      color: colors.blue,
      textTransform: "capitalize",
    },
    " & td": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      /* identical to box height */
      color: colors.lightBlack,
      cursor: "pointer",
    },
    "& th, td": {
      padding: "15px",
    },
    "& th": { background: colors.white, textAlign: "left" },
    // Border for payment pages
    "&.payments th": {
      borderTop: "1px solid #EBEEF2",
    },
    "& tr:nth-child(odd)": {
      backgroundColor: colors.grayShade,
    },
    "& tr:nth-child(even)": {
      backgroundColor: colors.white,
    },
  },
  tableMisc: {
    "& .nextPrevious": {
      marginTop: "1rem",
      padding: "15px",
      display: "flex",
      background: colors.grayShade,
      gap: "5px",
      justifyContent: "space-between",
      "&>button": {
        height: "100%",
        background: colors.blue,
        color: colors.white,
        border: "none",
        padding: "0 2px",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "16px",
        cursor: "pointer",
        "&:disabled": {
          background: colors.white,
          color: colors.lightBlack,
          border: "0.0001px solid " + colors.lightBlack,
          cursor: "not-allowed",
        },
      },
    },
    "& .pageCount": {
      display: "block",
    },
  },
  editStudentModal: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modal": {
      border: "1px solid #DADADA",
      borderRadius: "8px",
      margin: "auto",
      width: "90%",
      maxWidth: "310px",
      background: colors.white,
      position: "relative",
      height: "90%",
      maxHeight: "509px",
      transition: ".5s",
      "& .header": {
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        margin: "33px 35px 0px 41px",
        "& .icon": {
          cursor: "pointer",
        },
      },

      "& .btn.secondary": {
        width: "56px",
        height: "25px",
        background: colors.white,
        border: "1px solid #054A8C",
        borderRadius: "15px",
        cursor: "pointer",
      },
      "& .bodyMod": {
        padding: "35px",
        "& h3": {
          fontFamily: "'Open Sans', sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "18.2px",
          /* identical to box height */
          color: colors.blue,
          marginBottom: "10px",
        },
        "& .formGroup": {
          display: "flex",
          flexDirection: "column",
          gap: "10px",

          "&>input": {
            fontFamily: "'Open Sans', sans-serif",
            width: "100%",
            height: "31px",
            background: "#F5F5F5",
            color: "#A3A3A3",
            borderRadius: "6px",
            border: "none",
            padding: "6px 17px 7px 17px",
          },
        },
      },
    },
  },
  modifyStudentModal: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modal": {
      border: "1px solid #DADADA",
      borderRadius: "8px",
      margin: "auto",
      width: "90%",
      maxWidth: "310px",
      background: colors.white,
      position: "relative",
      height: "90%",
      maxHeight: "514px",
      transition: ".5s",
      "& .header": {
        display: "flex",
        alignItems: "end",
        justifyContent: "space-between",
        margin: "33px 35px 0px 41px",
        "& .icon": {
          cursor: "pointer",
        },
      },

      "& .btn.secondary": {
        width: "56px",
        height: "25px",
        background: colors.white,
        border: "1px solid #054A8C",
        borderRadius: "15px",
        cursor: "pointer",
      },
      "& .btn": {
        width: "100%",
        height: " 38.6px",
        background: colors.blue,
        borderRadius: "6px",
        marginTop: "21px",
      },
      "& h3.mTitle": {
        fontFamily: "'Open Sans', sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "102%",
        /* identical to box height */
        color: colors.blue,
      },
      "& .bodyMod": {
        padding: "35px",

        "& .formGroup": {
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          "& select": {
            appearance: "none",
          },
          "& >select": {
            backgroundImage: `url(${dropdown}) !important`,
            backgroundSize: "24px !important",
            backgroundRepeat: "no-repeat !important",
            backgroundPosition: " calc(100% - 35px) !important",
          },

          "&>input, select": {
            width: "100%",
            height: "31px",
            background: "#F5F5F5",
            color: "#A3A3A3",
            borderRadius: "6px",
            border: "none",
            padding: "6px 17px 7px 17px",
          },
        },
      },
    },
  },
  studentModal: {
    // position: "fixed",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // zIndex: 20290,
    // background: "rgba(0,0,0,0.4)",
    // display: "flex",
    // overflow: "auto",

    // "& .modal": {
    // border: "1px solid #DADADA",
    // borderRadius: "8px",
    // margin: "auto",
    // width: "90%",
    // maxWidth: "310px",
    // background: colors.white,
    // position: "relative",
    // height: "90%",
    // maxHeight: "511px",
    // transition: ".5s",

    "& .header": {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      margin: "33px 35px 0px 41px",
      "& .icon": {
        cursor: "pointer",
      },
    },

    "& .btn.secondary": {
      width: "56px",
      height: "25px",
      background: colors.white,
      border: "1px solid #054A8C",
      borderRadius: "15px",
      cursor: "pointer",
    },
    "& .btn": {
      width: "100%",
      height: " 38.6px",
      background: colors.blue,
      borderRadius: "6px",
      marginTop: "35px",
    },
    "& h3.mTitle": {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "102%",
      /* identical to box height */
      color: colors.blue,
    },
    "& .bodyMod": {
      padding: "35px",

      "& .formGroup": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        "& select": {
          appearance: "none",
        },
        "& >select": {
          backgroundImage: `url(${dropdown}) !important`,
          backgroundSize: "24px !important",
          backgroundRepeat: "no-repeat !important",
          backgroundPosition: " calc(100% - 35px) !important",
        },
        "&>input, select": {
          width: "100%",
          height: "31px",
          background: "#F5F5F5",
          color: "#A3A3A3",
          borderRadius: "6px",
          border: "none",
          padding: "6px 17px 7px 17px",
        },
      },
    },
  },
  editStaffModal: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modal": {
      border: "1px solid #DADADA",
      borderRadius: "8px",
      margin: "auto",
      width: "90%",
      maxWidth: "310px",
      background: colors.white,
      position: "relative",
      height: "90%",
      maxHeight: "509px",
      transition: ".5s",
      "& .header": {
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        margin: "33px 35px 0px 41px",
        "& .icon": {
          cursor: "pointer",
        },
      },

      "& .btn.secondary": {
        width: "56px",
        height: "25px",
        background: colors.white,
        border: "1px solid #054A8C",
        borderRadius: "15px",
        cursor: "pointer",
      },
      "& .bodyMod": {
        padding: "35px",
        "& h3": {
          fontFamily: "'Open Sans', sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "18.2px",
          /* identical to box height */
          color: colors.blue,
          marginBottom: "10px",
        },
        "& .formGroup": {
          display: "flex",
          flexDirection: "column",
          gap: "10px",

          "&>input": {
            fontFamily: "'Open Sans', sans-serif",
            width: "100%",
            height: "31px",
            background: "#F5F5F5",
            color: "#A3A3A3",
            borderRadius: "6px",
            border: "none",
            padding: "6px 17px 7px 17px",
          },
        },
      },
    },
  },
  modifyStaffModal: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modal": {
      border: "1px solid #DADADA",
      borderRadius: "8px",
      margin: "auto",
      width: "90%",
      maxWidth: "310px",
      background: colors.white,
      position: "relative",
      height: "90%",
      maxHeight: "514px",
      transition: ".5s",
      "& .header": {
        display: "flex",
        alignItems: "end",
        justifyContent: "space-between",
        margin: "33px 35px 0px 41px",
        "& .icon": {
          cursor: "pointer",
        },
      },

      "& .btn.secondary": {
        width: "56px",
        height: "25px",
        background: colors.white,
        border: "1px solid #054A8C",
        borderRadius: "15px",
        cursor: "pointer",
      },
      "& .btn": {
        width: "100%",
        height: " 38.6px",
        background: colors.blue,
        borderRadius: "6px",
        marginTop: "21px",
      },
      "& h3.mTitle": {
        fontFamily: "'Open Sans', sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "102%",
        /* identical to box height */
        color: colors.blue,
      },
      "& .bodyMod": {
        padding: "35px",

        "& .formGroup": {
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          "& select": {
            appearance: "none",
          },
          "& >select": {
            backgroundImage: `url(${dropdown}) !important`,
            backgroundSize: "24px !important",
            backgroundRepeat: "no-repeat !important",
            backgroundPosition: " calc(100% - 35px) !important",
          },

          "&>input, >select": {
            width: "100%",
            height: "31px",
            background: "#F5F5F5",
            color: "#A3A3A3",
            borderRadius: "6px",
            border: "none",
            padding: "6px 17px 7px 17px",
          },
        },
      },
    },
  },
  addStaffModal: {
    // position: "fixed",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // display: "flex",
    // overflow: "auto",

    position: "absolute",
    maxWidth: 700,
    backgroundColor: "white",
    border: "2px solid #000",
    padding: 2,

    // "& .modal": {
    //   border: "1px solid #DADADA",
    //   borderRadius: "8px",
    //   margin: "auto",
    //   width: "90%",
    //   maxWidth: "310px",
    //   background: colors.white,
    //   position: "relative",
    //   height: "90%",
    //   maxHeight: "511px",
    //   transition: ".5s",
    // position: "absolute",
    // maxWidth: 700,
    // backgroundColor: "white",
    // border: "2px solid #000",
    // padding: 2,
    "& .header": {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      margin: "33px 35px 0px 41px",
      "& .icon": {
        cursor: "pointer",
      },
    },

    "& .btn.secondary": {
      width: "56px",
      height: "25px",
      background: colors.white,
      border: "1px solid #054A8C",
      borderRadius: "15px",
      cursor: "pointer",
    },
    "& .btn": {
      width: "100%",
      height: " 38.6px",
      background: colors.blue,
      borderRadius: "6px",
      marginTop: "35px",
    },
    "& h3.mTitle": {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "102%",
      /* identical to box height */
      color: colors.blue,
    },
    "& .bodyMod": {
      padding: "35px",

      "& .formGroup": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        "& select": {
          appearance: "none",
        },
        "& >select": {
          backgroundImage: `url(${dropdown}) !important`,
          backgroundSize: "24px !important",
          backgroundRepeat: "no-repeat !important",
          backgroundPosition: " calc(100% - 35px) !important",
        },

        "&>input, select": {
          width: "100%",
          height: "31px",
          background: "#F5F5F5",
          color: "#A3A3A3",
          borderRadius: "6px",
          border: "none",
          padding: "6px 17px 7px 17px",
        },
      },
      // },
    },
  },
  /*     School System Page   */
  //Courses
  addCourseModal: {
    // position: "fixed",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // zIndex: 20290,
    // background: "rgba(0,0,0,0.4)",
    // display: "flex",
    // overflow: "auto",

    // "& .modal": {
    //   border: "1px solid #DADADA",
    //   borderRadius: "8px",
    //   margin: "auto",
    //   width: "90%",
    //   maxWidth: "310px",
    //   background: colors.white,
    //   position: "relative",
    //   height: "90%",
    //   maxHeight: "511px",
    //   transition: ".5s",
    "& .header": {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      margin: "33px 35px 0px 41px",
      "& .icon": {
        cursor: "pointer",
      },
    },

    "& .btn.secondary": {
      width: "56px",
      height: "25px",
      background: colors.white,
      border: "1px solid #054A8C",
      borderRadius: "15px",
      cursor: "pointer",
    },
    "& .btn": {
      width: "100%",
      height: " 38.6px",
      background: colors.blue,
      borderRadius: "6px",
      marginTop: "100px",
    },
    "& h3.mTitle": {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "102%",
      /* identical to box height */
      color: colors.blue,
    },
    "& .bodyMod": {
      padding: "35px",

      "& .formGroup": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",

        "&>input, select, option": {
          width: "100%",
          height: "31px",
          background: "#F5F5F5",
          color: "#A3A3A3",
          borderRadius: "6px",
          border: "none",
          padding: "6px 17px 7px 17px",
        },
      },
    },
    // },
  },
  //Levels
  addLevelModal: {
    // position: "fixed",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // zIndex: 20290,
    // background: "rgba(0,0,0,0.4)",
    // display: "flex",
    // overflow: "auto",

    // "& .modal": {
    //   border: "1px solid #DADADA",
    //   borderRadius: "8px",
    //   margin: "auto",
    //   width: "90%",
    //   maxWidth: "310px",
    //   background: colors.white,
    //   position: "relative",
    //   height: "90%",
    //   maxHeight: "259px",
    //   transition: ".5s",
    "& .header": {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      margin: "33px 35px 0px 41px",
      "& .icon": {
        cursor: "pointer",
      },
    },

    "& .btn.secondary": {
      width: "56px",
      height: "25px",
      background: colors.white,
      border: "1px solid #054A8C",
      borderRadius: "15px",
      cursor: "pointer",
    },
    "& .btn": {
      width: "100%",
      height: " 38.6px",
      background: colors.blue,
      borderRadius: "6px",
      marginTop: "23px",
    },
    "& h3.mTitle": {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "102%",
      /* identical to box height */
      color: colors.blue,
    },
    "& .bodyMod": {
      padding: "35px",

      "& .formGroup": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",

        "&>input, select, option": {
          width: "100%",
          height: "31px",
          background: "#F5F5F5",
          color: "#A3A3A3",
          borderRadius: "6px",
          border: "none",
          padding: "6px 17px 7px 17px",
        },
      },
      // },
    },
  },
  //Semesters
  addSemesterModal: {
    // position: "fixed",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // zIndex: 20290,
    // background: "rgba(0,0,0,0.4)",
    // display: "flex",
    // overflow: "auto",

    // "& .modal": {
    //   border: "1px solid #DADADA",
    //   borderRadius: "8px",
    //   margin: "auto",
    //   width: "90%",
    //   maxWidth: "310px",
    //   background: colors.white,
    //   position: "relative",
    //   height: "90%",
    //   maxHeight: "511px",
    //   transition: ".5s",
    "& .header": {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      margin: "33px 35px 0px 41px",
      "& .icon": {
        cursor: "pointer",
      },
    },

    "& .btn.secondary": {
      width: "56px",
      height: "25px",
      background: colors.white,
      border: "1px solid #054A8C",
      borderRadius: "15px",
      cursor: "pointer",
    },
    "& .btn": {
      width: "100%",
      height: " 38.6px",
      background: colors.blue,
      borderRadius: "6px",
      marginTop: "192px",
    },
    "& h3.mTitle": {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "102%",
      /* identical to box height */
      color: colors.blue,
    },
    "& .bodyMod": {
      padding: "35px",

      "& .formGroup": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",

        "&>input, select, option": {
          width: "100%",
          height: "31px",
          background: "#F5F5F5",
          color: "#A3A3A3",
          borderRadius: "6px",
          border: "none",
          padding: "6px 17px 7px 17px",
        },
      },
      // },
    },
  },
  //Sessions
  addSessionModal: {
    // position: "fixed",
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
    // zIndex: 20290,
    // background: "rgba(0,0,0,0.4)",
    // display: "flex",
    // overflow: "auto",

    // "& .modal": {
    //   border: "1px solid #DADADA",
    //   borderRadius: "8px",
    //   margin: "auto",
    //   width: "90%",
    //   maxWidth: "310px",
    //   background: colors.white,
    //   position: "relative",
    //   height: "90%",
    //   maxHeight: "511px",
    //   transition: ".5s",
    "& .header": {
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      margin: "33px 35px 0px 41px",
      "& .icon": {
        cursor: "pointer",
      },
    },

    "& .btn.secondary": {
      width: "56px",
      height: "25px",
      background: colors.white,
      border: "1px solid #054A8C",
      borderRadius: "15px",
      cursor: "pointer",
    },
    "& .btn": {
      width: "100%",
      height: " 38.6px",
      background: colors.blue,
      borderRadius: "6px",
      marginTop: "192px",
    },
    "& h3.mTitle": {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "102%",
      /* identical to box height */
      color: colors.blue,
    },
    "& .bodyMod": {
      padding: "35px",

      "& .formGroup": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",

        "&>input, select, option": {
          width: "100%",
          height: "31px",
          background: "#F5F5F5",
          color: "#A3A3A3",
          borderRadius: "6px",
          border: "none",
          padding: "6px 17px 7px 17px",
        },
      },
      // },
    },
  },
  /* Payment Page */
  // Add Payment

  addPaymentModal: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modal": {
      border: "1px solid #DADADA",
      borderRadius: "8px",
      margin: "auto",
      width: "90%",
      maxWidth: "645px",
      background: colors.white,
      position: "relative",
      height: "90%",
      maxHeight: "469px",
      transition: ".5s",
      // padding:''
      "& .header": {
        display: "flex",
        alignItems: "start",
        justifyContent: "end",
        margin: "71px 103px 0px 41px",
        [theme.breakpoints.down("xs")]: {
          margin: "15px",
        },
        "& .icon": {
          cursor: "pointer",
        },
      },

      "& .btn.secondary": {
        width: "56px",
        height: "25px",
        background: colors.white,
        border: "1px solid #054A8C",
        borderRadius: "15px",
        cursor: "pointer",
      },
      "& .btn": {
        width: "120px",
        height: " 34px",
        background: colors.blue,
        borderRadius: "5px",
        margin: "35px auto 0 auto",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "16px",
        display: "block",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
      "& h3.mTitle": {
        fontFamily: "'Open Sans', sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "102%",
        /* identical to box height */
        color: colors.blue,
      },
      "& .bodyMod": {
        padding: "30px 63px 63px 63px",
        [theme.breakpoints.down("xs")]: {
          padding: "15px",
        },

        "& .formGroup": {
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          "& select": {
            appearance: "none",
          },
          "& >select": {
            backgroundImage: `url(${dropdown}) !important`,
            backgroundSize: "24px !important",
            backgroundRepeat: "no-repeat !important",
            backgroundPosition: "48px center !important",
            paddingLeft: "72px !important",
            [theme.breakpoints.down("xs")]: {
              backgroundPosition: "10px center !important",
              paddingLeft: "30px !important",
            },
          },

          "&>input, >select,textarea": {
            width: "100%",
            height: "31px",
            background: "#F5F5F5",
            color: "#A3A3A3",
            borderRadius: "6px",
            border: "none",
            padding: "6px 18px 7px 53px",
            [theme.breakpoints.down("xs")]: {
              padding: "0px 15px !important",
            },
          },
          "& textarea": {
            fontFamily: "Poppins",
            height: "85px",
          },
        },
      },
    },
  },
}));
