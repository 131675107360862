import { createAsyncThunk } from "@reduxjs/toolkit";
import { notesService } from "../../../services";
import { NoteAction } from "./noteType";

export const fetchNotes = createAsyncThunk(
  NoteAction.FETCHNOTE,
  async (user?: string) => {
    const response = await notesService.getAll(user);
    return response.data;
  }
);

export const fetcLecthNotes = createAsyncThunk(
  NoteAction.FETCLECHNOTE,
  async () => {
    const response = await notesService.getLecAll();
    return response.data;
  }
);

export const fetchCourseNotes = createAsyncThunk(
  NoteAction.FETCHCOURSENOTE,
  async (courseId: number) => {
    const response = await notesService.getCourseNote(courseId);
    return response.data;
  }
);
