import React from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { colors } from "../../../styles/color";
import { Settings, ChevronDown, Logout } from "../../../assets/svg/";
import { useAppDispatch, useAppSelector } from "../../../store";
import { logout } from "../../../store/features/auth/authThunk";
import { toast } from "react-toastify";
import { clearState, selectRole } from "../../../store/features/auth/authSlice";
import { Role } from "../../../shared";
import useNetworkStatus from "../../../pages/shared/utils/network";
import useTokenStatus from "../../../pages/shared/utils/tokenCheck";
import { selectWebsite } from "../../../pages/store/features/website/websiteSlice";

interface Props {}

const TopBar = (props: Props) => {
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const website = useAppSelector(selectWebsite);

  const role = useAppSelector(selectRole);

  const style = useStyles();

  const loginOut = async () => {
    await dispatch(logout());
    dispatch(clearState());
    // toast.error("Successfully logout");
    if (role === Role.LECTURER) Navigate("/instructors/login");
    if (role === Role.ADMIN) Navigate("/instructors/login");
    if (role === Role.STUDENT) Navigate("/participants/login");
  };

  const { isOnline } = useNetworkStatus();
  !isOnline &&
    toast.warning("Check your network connectivity to be sure you're online.");

  const { isValid } = useTokenStatus();
  !isValid && toast.warning("Your session has expired.");

  return (
    <div className={style.container}>
      <Link to="/">
        <img width="55px" height="50px" src={website?.logo} alt="" />
      </Link>

      <Typography variant="h5" style={{ color: "gold" }}>
        Training Centre
      </Typography>

      <div className={style.rightIcons}>
        <div onClick={loginOut} className={style.logout}>
          <Logout /> <span>Log Out</span>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    background: colors.white,
    height: "70px",
    padding: "1.4375rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",

      // position: "fixed",
      // left: "100%",
      // top: 0,

      // marginBottom: "190px",
    },

    backgroundColor: "#152E88",
    boxShadow: "2px 3px 12px 0 #888888",
  },
  rightIcons: {
    position: "absolute",
    display: "flex",
    // background: colors.white,
    // border: "1px solid #000000",
    // borderRadius: "8px",
    padding: "7px",
    right: "16px",
  },
  logout: {
    // background: "#F6F4F4",
    textDecoration: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1.1rem",
    lineHeight: "22px",
    cursor: "pointer",
    /* identical to box height */

    // color: colors.black,
    display: "flex",
    alignItems: "center",
    justifyItems: "space-between",

    color: colors.white,
  },
}));

TopBar.propTypes = {};

export default TopBar;
