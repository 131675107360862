import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Divider,
} from "@material-ui/core";
import { Link, NavLink, useLocation } from "react-router-dom";

import { useStyles } from "../Landing/landing.style";

import SecondNavbar from "../Landing/Navbar/SecondNavbar";
import UpdatesSection from "./UpdatesSection";
import JumpToSectionNav from "../Landing/Navbar/JumpToSectionNav";
import Footer from "../Landing/Footer";

interface Props {}

interface CustomizedState {
  page: any;
}

function Updates(props: Props) {
  const style = useStyles();
  const location = useLocation();
  const state = location.state as CustomizedState;

  return (
    <div>
      {/* banner */}
      <SecondNavbar />
      <UpdatesSection /*page={state?.page}*/ />
      <JumpToSectionNav />

      {/* Footer */}
      <Footer />
    </div>
  );
}

Updates.propTypes = {};

export default Updates;
