import { API } from '../configure-api'

//Student endpoints

//Lecturer endpoints
const generateStudentCourseResult = (body: any) => API.post("/record/generate-student-course-result", body);
const generateStudentSemesterResult = (body: any) => API.post("/record/generate-student-semester-result", body);
const addStudentCouseRecord = (body: any) => API.post("/record/add", body);

export const recordService = {
    generateStudentCourseResult,
    generateStudentSemesterResult,
    addStudentCouseRecord
};