import React, { HTMLAttributes, HTMLProps, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "../dataTables.style";
import { ChevDown, X } from "../../../../assets/svg";
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingSpinner from "../../../../components/common/Loader";
import { toast, ToastContainer } from "react-toastify";
import Confirm from "../../Confirm";
import { DeleteRounded, EditRounded } from "@material-ui/icons";

interface Props {
  setFilter: string;
  isAddLevel: any;
  levels: any;
  handleCloseAll: any;
  fetchLevels: any;
  isLoadingLevels: boolean;
}
type Level = {
  [key: string]: any;
};

interface CreateLevelInputs {
  name: string;
  code: string;
  coordinator: string;
}

const createLevelSchema = yup.object().shape({
  name: yup.string().required("Required"),
  code: yup.string().required("Required"),
  coordinator: yup.string().required("Required"),
});

const LevelDataTable = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");

  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [addLevelModal, setAddLevelModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [staff, setStaff] = React.useState([]);
  const [focusedRow, setFocusedRow] = React.useState<any>(null);
  const [openWarning, setOpenWarning] = React.useState(false);

  console.log(props.levels);

  // form
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateLevelInputs>({
    resolver: yupResolver(createLevelSchema),
  });
  const onSubmit = (data: CreateLevelInputs) => {
    const body = {
      name: data.name,
      code: data.code,
      coordinator_id: data.coordinator,
    };

    if (focusedRow) {
      updateLevel(body);
    } else {
      createLevel(body);
    }
  };

  useEffect(() => {
    //fetch data to be used in the form
    const fetchStaff = async () => {
      try {
        const request = await fetch(`${BASE_URL}/admin/lecturer/all`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const response = await request.json();
        if (response.length) {
          setStaff(response);
        }
      } catch (error) {
        toast.error("Error fetching instructors", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log(error);
      }
    };

    fetchStaff();
  }, []);

  useEffect(() => {
    setValue("code", focusedRow?.code);
    setValue("coordinator", focusedRow?.coordinator);
    setValue("name", focusedRow?.name);
  }, [focusedRow]);

  const createLevel = async (data: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/level/add`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await req.json();
      console.log(res);
      console.log(res.ok);
      if (res.id) {
        toast.success("Level created successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchLevels();
        reset();
        handleCloseModal();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const updateLevel = async (data: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/level/update/${focusedRow?.id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await req.json();
      if (req.ok) {
        toast.success("Level updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchLevels();
        reset();
        handleCloseModal();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const deleteLevel = async () => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/level/delete/${focusedRow.id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const res = await req.json();
      if (!req.ok) {
        toast.error("could not delete level", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.success("level deleted successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchLevels();
        reset();
      }

      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setAddLevelModal(false);
    setFocusedRow(null);
    props.handleCloseAll();
  };

  React.useEffect(() => {
    setGlobalFilter(props.setFilter);
  }, [props.setFilter]);

  React.useEffect(() => {
    setAddLevelModal(props.isAddLevel);
  }, [props.isAddLevel]);

  const columns = React.useMemo<ColumnDef<Level>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Level Name",

        footer: (props) => props.column.id,
      },
      {
        accessorKey: "code",

        header: "Level Code",
        footer: (props) => props.column.id,
      },
      {
        id: "action",
        header: "Actions",
        cell: ({ row }) => (
          <div style={{ display: "flex", gap: 20 }}>
            <EditRounded
              fontSize="small"
              color="primary"
              onClick={() => {
                setFocusedRow(row.original);
                setAddLevelModal(true);
              }}
            />
            <DeleteRounded
              fontSize="small"
              color="error"
              onClick={() => {
                setFocusedRow(row.original);
                setOpenWarning(true);
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const [data, setData] = React.useState(props.levels);

  useEffect(() => {
    setData(props.levels);
  }, [props.levels]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this level?"
        action={deleteLevel}
      />
      <Modal
        isOpen={addLevelModal}
        toggle={() => handleCloseModal()}
        centered={true}
        scrollable={true}
      >
        <ModalBody>
          {isLoading && <LoadingSpinner />}
          <div className={style.addLevelModal}>
            <div className="header">
              <h3 className="mTitle">
                {focusedRow ? "Update level" : "New level"}
              </h3>
              <X className="icon" onClick={handleCloseModal} />
            </div>
            <div className="bodyMod">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formGroup">
                  <input {...register("code")} placeholder="Level code" />
                  <p>{errors.code?.message}</p>
                  <input {...register("name")} placeholder="level name" />
                  <p>{errors.name?.message}</p>
                  <select {...register("coordinator")}>
                    <option value="" disabled>
                      Select coordinator
                    </option>
                    {staff?.map((item: any) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <button className="btn">Save</button>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <div className={style.displayAll}>
        <div className="items">
          <Link to="">
            All Levels <ChevDown />
          </Link>
        </div>
        {/* <button
          className={
            numberSelected === 0 ? "btn secondary disabled" : "btn secondary"
          }
          disabled={numberSelected === 0}
          onClick={() =>
            console.info(
              "Selected Rows",
              table.getSelectedRowModel().flatRows.map((row) => row.original)
            )
          }
        >
          Delete
        </button> */}
      </div>

      <table className={style.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                {...table.getRowModel()}
                // onClick={() => handleCourseEditDet(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.isLoadingLevels && (
        <h4 style={{ padding: ".5rem 1.2rem" }}>Loading Levels data...</h4>
      )}
      <div className={style.tableMisc}>
        <div className="nextPrevious">
          <button
            className=""
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className=""
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className=""
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="pageCounter">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className=""
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div></div>
      </div>
    </>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}
LevelDataTable.propTypes = {};

export default LevelDataTable;
