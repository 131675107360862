import { API } from '../configure-api'

function loguserin(formData: { email?: string, password: string, reg_no?: string }) {
    const requestOptions = {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
    };

    return fetch(`${process.env.REACT_APP_BASEURL}/user/login`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        });
}

function authUser() {
    return API.get("/user/profile", "");
}

function getAll() {
    return API.get("/users/get-all-users", "");
}

function getById(id: string) {
    return API.get(`/users/get-user/${id}`, "")
}
function enableUser(id: string, body: any) {
    return API.patch(`/users/enable-user/${id}`, body);
}
function disableUser(id: string, body: any) {
    return API.patch(`/users/disable-user/${id}`, body);
}

function createUser(user: any) {
    return API.post(`/users/create-user`, user)
}

function updateUser(id: string, user: any) {
    return API.patch(`/users/edit-user/${id}/`, user)
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("token");
}



function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export const authService = {
    loguserin,
    authUser,
    logout,
    createUser,
    getAll,
    getById,
    enableUser,
    disableUser,
    updateUser,
};