import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import PropTypes from "prop-types";

import { useStyles } from "../login.style";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
} from "@material-ui/core";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { clearState } from "../../../store/features/auth/authSlice";
import { login } from "../../../store/features/auth/authThunk";
import { IFormInputs } from "../../../shared";
import { Spinner } from "../../../components/common/Spinner";
import { toast, ToastContainer } from "react-toastify";
import useNetworkStatus from "../../shared/utils/network";
import { useAppSelector } from "../../store";
import { selectWebsite } from "../../store/features/website/websiteSlice";

interface Props {}

const schema = yup.object().shape({
  regno: yup.string().required(),
  password: yup.string().required(),
});

const ParticipantLogin = (props: Props) => {
  const style = useStyles();
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useAppDispatch();

  const { isOnline } = useNetworkStatus();
  !isOnline &&
    toast.warning("Check your network connectivity to be sure you're online.");

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver: yupResolver(schema) });

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setIsloading(true);
    let res = await dispatch(login(data)); //console.log(Object.keys(res)); console.log(res.type);
    if (res.type === "[auth/login]/rejected") toast.error("Invalid login");
    setIsloading(false);
  };

  useEffect(() => {
    dispatch(clearState());
  }, []);
  const website = useAppSelector(selectWebsite);

  return (
    <Container maxWidth="xl" className={style.container}>
      <ToastContainer />

      <Grid container className={style.form}>
        <Link to="/">
          <img src={website?.logo} className={style.logoIMG} alt="Site Logo" />
        </Link>

        <Typography variant="h3" className={style.appTitle}>
          Air Warfare Centre
        </Typography>

        <Card variant="outlined" className={style.card}>
          <Container>
            <h3 className={style.headerTitle}>
              Participant
              <span>
                <a className={style.registerLink} href="/verify">
                  Not Registered? Click here.
                </a>
              </span>
            </h3>

            <form onSubmit={handleSubmit(formSubmitHandler)}>
              <Grid item sm={12} className={style.formGroup}>
                <p className={style.labels}>Service Number</p>
                <div>
                  <Controller
                    name="regno"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.regno}
                        variant="outlined"
                        placeholder="Service***"
                        helperText={
                          errors.regno ? (
                            <span>Service Number is required</span>
                          ) : (
                            ""
                          )
                        }
                      />
                    )}
                  />
                </div>
                <p className={style.labels}>Password</p>
                <div>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.password}
                        variant="outlined"
                        placeholder="Password"
                        type="password"
                        helperText={
                          errors.password ? (
                            <span>Password is required</span>
                          ) : (
                            ""
                          )
                        }
                      />
                    )}
                  />
                </div>
                <a className={style.registerLink} href="/forgot-password">
                  Forgot Password?
                </a>
                <div>
                  {/* <Grid container justify="center"> */}
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={style.loginBtn}
                    style={{ width: "100%", textTransform: "capitalize" }}
                  >
                    {isLoading ? <Spinner size="2" /> : "Login"}
                  </Button>
                  {/* </Grid> */}
                </div>
              </Grid>
            </form>
          </Container>
        </Card>
      </Grid>
      {/* <Footer /> */}
    </Container>
  );
};

ParticipantLogin.propTypes = {};

export default ParticipantLogin;
