import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";
import { Plus, Elipses } from "../../../assets/svg";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectRecentEvents } from "../../../pages/store/features/event/eventSlice";
import { fetchRecentEvents } from "../../../pages/store/features/event/eventThunk";
import { selectRole } from "../../../store/features/auth/authSlice";
import { fetchNotice } from "../../../store/features/notice/noticeThunk";
import { selectNotice } from "../../../store/features/notice/noticeSlice";
import { formatDate } from "../../../shared";

interface Props {}

const RightBar = (props: Props) => {
  const style = useStyles();

  const dispatch = useAppDispatch();
  const events = useAppSelector(selectRecentEvents);
  const notice = useAppSelector(selectNotice);
  const userRole = useAppSelector(selectRole);

  const getEvents = async () => {
    await dispatch(fetchRecentEvents(userRole));
  };

  const getNotice = async () => {
    await dispatch(fetchNotice());
  };

  React.useEffect(() => {
    getEvents();
    getNotice();
  }, []);

  return (
    // loading === false && (
    <div className={style.container}>
      <div className={style.header}>
        <h3 style={{fontWeight: 500, color: '#152E88', padding: '.4rem'}}>Events</h3>
        {/* <Link to="">
          Add <Plus />
        </Link> */}
      </div>
      <h5 style={{color: '#152E88', padding: '.4rem'}}>{events.length == 0 && 'No new event(s).'}</h5>
      {events.length > 0 &&(
      <div className={style.events}>
        {events.map((event: any, i: number) => (
          <div key={i}>
            <div className="details">
              <h6>{event.title}</h6>
              <span>{formatDate(event.starts_at)}</span>
            </div>
            <Elipses className="eventOptions" onClick={() => {}} />
          </div>
        ))}
      </div>
      )}

      <div className={style.noticeBoard}>
        <div className={style.header}>
          <h3 style={{fontWeight: 500, color: '#152E88', padding: '.4rem'}}>Notice Board</h3>
        </div>
        <h5 style={{color: '#152E88', padding: '.4rem'}}>{notice.length == 0 && 'Nothing posted yet.'}</h5>
        <div className={style.notices}>
          {notice.map((notice: any, i: number) => (
            <div key={i}>
              <h6>{notice.body}</h6>
              <span>{formatDate(notice.created_at)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
    // )
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    background: colors.white,
    height: "100%",
    padding: "1.625rem 0",
    // overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      height: "100%",
      alignSelf: "center",
      // height: "auto",
      margin: "0.5rem 1rem ",
      background: "white",
      boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
      borderRadius: "5px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  header: {
    margin: "0 0.25rem 0.2rem 0.2rem",
    display: "flex",
    "& h4": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      color: colors.black,
    },

    "& a": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "absolute",
      right: "0.5rem",
      textDecoration: "none",
      background: "#F7F4F4",
      borderRadius: "5px",
      padding: "1px 2px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "19px",
      /* identical to box height */

      color: colors.blue,
    },
  },
  events: {
    overflowY: "auto",
    height: "25vh",
    [theme.breakpoints.down("sm")]: { height: "auto" },
    "& div:not(.details)": {
      marginBottom: "7px",
      display: "flex",

      padding: "4px 20px",
      justifyContent: "space-between",
      alignItems: "center",
      background: colors.gray,
      borderLeft: "3px solid #152E88;",
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        /* identical to box height */

        color: colors.black,
      },
      "& span": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "9px",
        lineHeight: "14px",
        /* identical to box height */

        color: colors.black,
      },
      "& .eventOptions": {
        cursor: "pointer",
      },
    },
  },
  noticeBoard: {
    height: "75vh",
    [theme.breakpoints.down("sm")]: { height: "auto" },
    marginTop: "1.3125rem",
  },
  notices: {
    overflowY: "auto",
    "& div": {
      marginBottom: "14px",

      padding: "4px 20px",
      justifyContent: "space-between",
      alignItems: "center",
      background: colors.gray,
      borderLeft: "3px solid #152E88;",
      "& h6": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "11px",
        lineHeight: "16px",
        /* identical to box height */

        color: colors.black,
      },
      "& span": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "9px",
        lineHeight: "12px",
        /* identical to box height */

        color: colors.black,
      },
    },
  },
}));

RightBar.propTypes = {};

export default RightBar;
