import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Divider,
} from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";

import { useStyles } from "./landing.style";
import headerImage from "../../assets/images/HeaderImage.png";
import professorImage from "../../assets/images/ProfessorImage.png";
import eventImage from "../../assets/images/event.jpg";
import locationIcon from "../../assets/icons/location.svg";
import callIcon from "../../assets/icons/call.svg";
import emailIcon from "../../assets/icons/email.svg";
import listIcon from "../../assets/icons/list.svg";
// import LandingTabs from "../../components/Menu/LandingTabs";

import WhiteNavbar from "./Navbar/WhiteNavbar";
import Header from "./header/Header";
import UpdatesSection from "./UpdatesSection";
import TeamSection from "./TeamSection";
import ContactUsSection from "./ContactUsSection";
import JumpToSectionNav from "./Navbar/JumpToSectionNav";
import Footer from "./Footer";
import { toast } from "react-toastify";

interface Props {}
function Landing(props: Props) {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const [about, setAbout] = useState<any>(null);
  const [contactInfo, setContactInfo] = useState<any>(null);
  const [slides, setSlides] = useState<any>([]);

  const fetchSlides = async () => {
    try {
      const request = await fetch(`${BASE_URL}/slider/slides`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer `,
        },
      });
      const response = await request.json();
      console.log(response);
      if (request.ok) {
        setSlides(response);
      }
    } catch (error) {
      toast.error("Error fetching info");
      console.log(error);
    }
  };

  const fetchAbout = async () => {
    console.log("fetching about...");
    try {
      const request = await fetch(`${BASE_URL}/about/page`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "bearer ",
        },
      });
      const response = await request.json();
      if (request.ok) {
        setAbout(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContact = async () => {
    console.log("fetching contact");
    try {
      const request = await fetch(`${BASE_URL}/contact/info`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await request.json();
      if (request.ok) {
        setContactInfo(response);
      }
    } catch (error) {
      toast.error("Error fetching contact info");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAbout();
    fetchContact();
    fetchSlides();
  }, []);

  console.log("about", about);

  return (
    <div className={style.container}>
      {/* banner */}
      <WhiteNavbar />
      <Header slideItems={slides} />
      <UpdatesSection />
      <TeamSection page={about} />
      <ContactUsSection contact={contactInfo} />
      <JumpToSectionNav />

      {/* Footer */}
      <Footer />
    </div>
  );
}

Landing.propTypes = {};

export default Landing;
