import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    autoflowY: "auto",
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
    "& .header": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "start",
      "& .btn": {
        position: "relative",
      },
      "& button": {
        border: "none",
        display: "flex",
        gap: "4px",
        padding: "9px",
        alignItems: "center",
        borderRadius: "8px",
        background: colors.blue,
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "15px",
        lineHeight: "22px",
        color: colors.white,
        cursor: "pointer",
      },
      "& .dropItems": {
        width: "123px",
        background: "#F6F4F4",
        boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.25)",
        borderRadius: "8px",
        padding: "12px 17px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "6px",
        position: "absolute",
        top: "50px",
        right: 0,
        zIndex: 1,
        "&>a": {
          textDecoration: "none",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
          color: colors.black,
          paddingBottom: "6px",
        },
        "& a:hover": {
          color: colors.blue,
        },
        "& a:not(:last-child)": {
          borderBottom: "1px solid #FFFFFF",
        },
      },
    },
  },
  main: {
    marginTop: "1rem",

    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 180px)",
      overflow: "auto",
    },
  },
  form: {
    // display: "flex",

    margin: "1.875rem 10%  4rem 10%",
    // justifyContent: "center",
    [theme.breakpoints.down("sm")]: { width: "100%", margin: "1rem 0" },
    "& div:not(.form-row):not(.Mui-error)": { marginBottom: ".5rem" },

    "& .MuiInputBase-input": {
      background: colors.white,
      border: "1px solid rgba(0, 0, 0, 0.7)",
      borderRadius: "5px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1.375rem",
      lineHeight: "33px",
      color: "#C4C4C4",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "30px",
      },
    },
    "& .form-row": {
      display: "grid",
      width: "100%",
      gap: "7px",

      "&.row-1": {
        gridTemplateColumns: "4fr 6fr",
      },
      "&.row-2": {
        gridTemplateColumns: "4fr 4fr 2fr",
        "& .MuiInputBase-input": {
          fontSize: "18px !important",
          lineHeight: "27px !important",
        },
      },
      [theme.breakpoints.down("xs")]: {
        "&.row-2,&.row-1": {
          gridTemplateColumns: "1fr",
        },
      },
    },
    "& .MuiFormHelperText-root": {
      margin: "0",
    },

    // "& .form-row": {},
    "& .MuiButton-root": {
      float: "right",
      background: colors.blue,
      border: "1px solid rgba(0, 0, 0, 0.7)",
      borderRadius: "8px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "0.9375rem",
      lineHeight: "22px",
      /* identical to box height */

      color: colors.white,
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        float: "none",
        width: "100%",
      },
    },
  },
  lists: {
    marginTop: "1rem",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: { width: "100%", marginTop: "4rem" },

    "& .list:not(:last-child)": { marginBottom: ".5rem" },
    "& .list": {
      background: colors.gray,
      padding: ".5rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .title": {
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        /* identical to box height */

        color: colors.black,
      },
      "& .desc": {
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "1rem",
        lineHeight: "21px",
        /* identical to box height */

        color: colors.black,
      },
      "& .right": {
        textAlign: "right",
        "& span": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "12px",
          lineHeight: "15px",
          /* identical to box height */

          color: colors.black,
        },
      },
    },
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modals": {
      margin: "auto",
      width: "90%",
      maxWidth: "31.25rem",
      background: colors.white,
      position: "relative",
      minHeight: "90%",
      transition: ".5s",
    },
    "& .cancelBox": {
      textDecoration: "none",
      position: "absolute",
      padding: "1.4375rem",
      right: 0,
      top: 0,
      color: colors.white,
      background: colors.blue,
    },
    "& .content": {
      margin: "113px 52px",
      [theme.breakpoints.down("xs")]: {
        margin: "100px 10px",
      },
    },
    "& form div.input:not(:last-child)": {
      marginBottom: "17px",
    },

    "& .MuiInputBase-input": {
      background: colors.white,
      border: "1px solid rgba(0, 0, 0, 0.7)",
      borderRadius: "5px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1.375rem",
      lineHeight: "33px",
      color: "#C4C4C4",
    },
    "& button": {
      width: "50%",
      background: colors.blue,
      border: "none",
      boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",

      borderRadius: "8px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "20px",

      lineHeight: "30px",
      /* identical to box height */

      color: colors.white,
      padding: "10px 52px",
      marginTop: "50px",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
}));
