import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";
import not from "../../../assets/icons/not.svg";
import answer from "../../../assets/icons/answer.svg";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    autoflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",

    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "24px",
      color: colors.black,
      display: "flex",
      alignItems: "center",
      gap: "6px",

      "& .icon": {
        // padding: "9.25px 12.99px",
        background: "#E9E9E9",
        borderRadius: "5px",
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    "& .iconElipses": {
      width: "10px",

      "&:hover": {
        cursor: "pointer",
      },
    },
    //handling menu options
    "& .dropItems": {
      width: "100px",
      background: colors.white,
      boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.25)",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      padding: "4px",
      right: "8px",
      marginTop: "100px",
      zIndex: 1,

      "& >button": {
        textDecoration: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
        /* identical to box height */
        color: colors.black,
        textAlign: "center",
        padding: "4px 0",
        border: "none",
        outline: "none",
        background: "white",
        "&:hover": {
          color: "rgba(0,0,0,0.6)",
        },
      },
      "& a:not(:last-child)": {
        borderBottom: "1px solid #F6F4F4",
      },
    },
  },
  main: {
    marginTop: "1rem",
    position: "relative",

    "& .questionsContainer": {
      display: "grid",
      gap: "16px",
      height: "70vh",
      overflowY: "auto",
      gridTemplateColumns: "1fr",

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "8px",
        height: "100%",
        overflowY: "none",
      },
      "& .questions": {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        "& textarea": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: "24px",
          color: colors.black,
          padding: "8px",
          background: "rgba(0, 0, 0, 0.2)",
        },
        "& > p.question": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: "24px",
          color: colors.black,
        },
        "& >div.options": {
          display: "flex",
          flexDirection: "column",
          gap: "6px",
          "& > div.questionText": {
            padding: "11px",
            background: "#E9E9E9;",
            borderRadius: "8px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "28px",
            color: colors.black,
          },
        },
      },
      "& .border": {
        borderRight: "2px solid #E9E9E9",
        margin: "30px 0px",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
    },
    "& .pagination": {
      display: "flex",
      listStyleType: "none",
      gap: "6px",
      alignItems: "center",
      paddingTop: "10px",
      //   marginTop: "81px",
      //   position: "absolute",
      //   bottom: "7px",
      //   right: "20px",
      justifyContent: "end",
      "& li": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& a": {
          textDecoration: "none",
          color: colors.white,
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 640,
          fontSize: "1rem",
          lineHeight: "24px",
        },
      },

      "& .icon": {
        background: colors.blue,
        width: "26px",
        height: "26px",
        padding: "4.33px 4.92px",
        cursor: "pointer",
      },
      "& .active": {
        background: " #E5E5E5 !important",
        color: "#fff !important",
      },
    },
  },
  submissionList: {
    "&:hover": {
      background: "#f1f1f1",
      padding: "8px",
      borderRadius: "8px",
    },
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20290,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    overflow: "auto",

    "& .modals": {
      margin: "auto",
      width: "90%",
      // maxWidth: "31.25rem",
      background: colors.white,
      position: "relative",
      minHeight: "90%",
      transition: ".5s",
    },
    "& .cancelBox": {
      textDecoration: "none",
      position: "absolute",
      padding: "1.4375rem",
      right: 0,
      top: 0,
      color: colors.white,
      background: colors.blue,
    },
    "& .content": {
      margin: "113px 52px",
      [theme.breakpoints.down("xs")]: {
        margin: "100px 10px",
      },
    },
    "& form div.input:not(:last-child)": {
      marginBottom: "17px",
    },

    "& .MuiInputBase-input": {
      background: colors.white,
      border: "1px solid rgba(0, 0, 0, 0.7)",
      borderRadius: "5px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1.375rem",
      lineHeight: "33px",
      color: "#C4C4C4",
    },
    "& button": {
      width: "50%",
      background: colors.blue,
      border: "none",
      boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",

      borderRadius: "8px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "20px",

      lineHeight: "30px",
      /* identical to box height */

      color: colors.white,
      padding: "10px 52px",
      marginTop: "50px",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },

  answerCard: {
    background: "#E9E9E9",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
    margin: "10px 20px",
    padding: "8px 16px",
    "& p": {
      fontWeight: 400,
    },
  },
}));
