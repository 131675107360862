import { API } from "../configure-api";

//Students endpoints
const getAll = (user: string) => API.get(`/video/${user}/all`, "");
const getCourseVideo = (user: string, id: number) =>
  API.get(`/video/${user}/courses/${id}`, "");

//Lecturer endpoints
const createVid = (body: any) => API.post("/video/create", body);
const publish = (body: { video_id: number }) =>
  API.patch("/video/publish", body);
const unPublish = (body: { video_id: number }) =>
  API.patch("/video/unpublish", body);
const uploadVid = (body: { file: any }) =>
  API.post("/upload/add", {
    headers: {
      "Content-Type": "multipart/form",
    },
    body,
  });

export const videoService = {
  getAll,
  getCourseVideo,
  createVid,
  uploadVid,
  publish,
  unPublish,
};
