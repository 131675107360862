import React from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./notes.style";
import { toast } from "react-toastify";
import Layout from "../../../components/Layout";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Chev, NextPage, Previous, Elipses } from "../../../assets/svg";
import { useAppSelector } from "../../../store";
import { selectRole } from "../../../store/features/auth/authSlice";

import { Role } from "../../../shared";
import { formatDate } from "../../../shared/utils/date";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/common/Loader";
import { shareNote } from "../../store/features/note/noteThunk";
import { Button } from "@material-ui/core";

interface Props {
  // state: any;
}
interface CustomizedState {
  myState: any;
  id: number;
  title: string;
  body: string;
  course: string;
  date: any;
  attachment: any;
}

const ReadNotes = () => {
  const style = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();
  React.useEffect(() => {
    if (location.state === null) {
      navigate("/dashboard/notes");
    }
  }, []);
  // const { myState } = state;

  const userRole = useAppSelector(selectRole);

  // Redirect if the note is not found using useEffect hook

  // const note = posts.find((element: any) => element.id == params.id);
  const note = state?.body;
  const totalPosts = note?.length || 0;

  const [currentPage, setCurrentPage] = React.useState(1);
  const [noteLengthPerPage] = React.useState(800);
  // Get current note
  const indexOfEndNote = currentPage * noteLengthPerPage;
  const indexOfBeginNote = indexOfEndNote - noteLengthPerPage;
  const currentNote = note?.slice(indexOfBeginNote, indexOfEndNote);

  // Change page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / noteLengthPerPage); i++) {
    pageNumbers.push(i);
  }
  //handling menu
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const dispatch = useDispatch<any>();
  const handleShareNote = async () => {
    setIsLoading(true);
    await dispatch(shareNote({ note_id: state?.id }))
      .then((res: any) => {
        // if (res.status === 200) {
        setIsLoading(false);
        toast.success("Note has been shared successfully");
        // } else {
        //   toast.error(res.payload.message);
        //   setIsLoading(false);
        // }
      })
      .catch((err: any) => {
        setIsLoading(false);

        console.log(err);
      });
  };
  return (
    <Layout>
      {isLoading && <LoadingSpinner />}
      {userRole === Role.STUDENT ? (
        <div className={style.container}>
          <div className={style.readNote}>
            <h5 className="title">
              <Link className="icon" to="/dashboard/notes">
                <Chev />{" "}
              </Link>{" "}
              {state?.course}

              {/* <Button className="icon" style={{color: "#152E88"}}><span className="fa fa-arrows-h"></span></Button> */}
            </h5>{" "}
            <div className="content">
              <div className="header">
                <h5 className="title">{state?.title}</h5>
                <h6 className="date">{formatDate(state?.date)}</h6>
              </div>
              <div className="body">
                <p className="">
                  {/* <div dangerouslySetInnerHTML={{ __html: currentNote }}></div> */}                  
                  {/* {} */}
                </p>

                <div dangerouslySetInnerHTML={{ __html: note }} className="reading"></div>
                {/* //Pagination */}
                <ul className="pagination">
                  { state?.attachment && (
                    <a href={state?.attachment || "#"} target="_blank"  className="attachment" >
                    <i className="fa fa-download"></i>
                    <span> Attachment</span> 
                  </a>
                  )}
                  
                  {/* <Previous
                    className={currentPage === 1 ? "icon active" : "icon "}
                    onClick={() => paginate(1)}
                  />
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      className={number === currentPage ? "active" : ""}
                    >
                      <a
                        onClick={() => paginate(number)}
                        href="#"
                        className="page-link"
                      >
                        {number}
                      </a>
                    </li>
                  ))}
                  <NextPage
                    className={
                      currentPage == pageNumbers.length
                        ? "icon active"
                        : "icon "
                    }
                    onClick={() => paginate(pageNumbers.length)}
                  /> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={style.container}>
          <div className={style.readNote}>
            <div className="title">
              <h5>
                <Link className="icon" to="/dashboard/notes">
                  <Chev />{" "}
                </Link>{" "}
                {state?.course}
              </h5>
              <Elipses onClick={handleMenuOpen} />
              {isMenuOpen && (
                <div className="dropItems">
                  <button onClick={handleShareNote}>Share</button>
                  <button>Edit</button>
                  <button>Delete</button>
                </div>
              )}
            </div>
            <div className="content">
              <div className="header" style={{}}>
                <h5 className="title">{state?.title}</h5>
                <h6 className="date">{formatDate(state?.date)}</h6>
              </div>
              <div className="body"> 
                <p className="">
                  {/* <div dangerouslySetInnerHTML={{ __html: currentNote }}></div> */}                  
                  {/* {} */}
                </p>

                <div dangerouslySetInnerHTML={{ __html: note }} className="reading"></div>

                {/* //Pagination */}
                <ul className="pagination">
                                      
                  { state?.attachment && (
                    <a href={state?.attachment || "#"} target="_blank"  className="attachment" >
                    <i className="fa fa-download"></i>
                    <span> Attachment</span> 
                  </a>
                  )}
                  
                  {/* <Previous
                    className={currentPage === 1 ? "icon active" : "icon "}
                    onClick={() => paginate(1)}
                  />
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      className={number === currentPage ? "active" : ""}
                    >
                      <a
                        onClick={() => paginate(number)}
                        href="#"
                        className="page-link"
                      >
                        {number}
                      </a>
                    </li>
                  ))}
                  <NextPage
                    className={
                      currentPage == pageNumbers.length
                        ? "icon active"
                        : "icon "
                    }
                    onClick={() => paginate(pageNumbers.length)}
                  /> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

ReadNotes.propTypes = {};

export default ReadNotes;
