import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import { useStyles } from "./website.style";
import { ToastContainer } from "react-toastify";
import Header from "./Header";
import Posts from "./Posts";
import Contact from "./Contact";
import AboutUs from "./AboutUs";

interface Props {}

const Website = (props: Props) => {
  const style = useStyles();
  const [activeTab, setActiveTab] = useState("header");

  return (
    <Layout>
      <ToastContainer />
      <div className={style.container}>
        <div className={style.header}>
          <h4 className="title"> Manage Website</h4>
        </div>
        <div className={style.tabs}>
          <div className="tabLinks">
            <div className={activeTab === "header" ? "active link" : "link"}>
              <Link to="" onClick={() => setActiveTab("header")}>
                Header
              </Link>
            </div>
            <div className={activeTab === "posts" ? "active link" : "link"}>
              <Link to="" onClick={() => setActiveTab("posts")}>
                Posts
              </Link>
            </div>
            <div className={activeTab === "contact" ? "active link" : "link"}>
              <Link to="" onClick={() => setActiveTab("contact")}>
                Contact
              </Link>
            </div>
            <div className={activeTab === "about" ? "active link" : "link"}>
              <Link to="" onClick={() => setActiveTab("about")}>
                About us
              </Link>
            </div>
          </div>
        </div>

        <div className={style.body}>
          {activeTab === "header" && <Header />}
          {activeTab === "posts" && <Posts />}
          {activeTab === "contact" && <Contact />}
          {activeTab === "about" && <AboutUs />}
        </div>
      </div>
    </Layout>
  );
};

Website.propTypes = {};

export default Website;
