import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import PropTypes from "prop-types";

import { useStyles } from "../login.style";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
} from "@material-ui/core";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo.png";
import Footer from "../../../components/Footer";
import { useAppDispatch } from "../../../store";
import { clearState } from "../../../store/features/auth/authSlice";
import { login } from "../../../store/features/auth/authThunk";
import { IFormInputs } from "../../../shared";
import { Spinner } from "../../../components/common/Spinner";

interface Props { }

 

const SuccessRegistration = (props: Props) => {
  const style = useStyles();
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useAppDispatch();

   

  useEffect(() => {
    dispatch(clearState());
  }, []);
  return (
    <Container maxWidth="xl" className={style.container}>

      <Grid container className={style.form}>

        <Link to="/">
          <img
            src={require("../../../assets/icons/NAFLogo.png")}
            className={style.logoIMG} alt="Site Logo"
          />
        </Link>

        <Typography variant="h3" className={style.appTitle}>
          Air Warfare Centre
        </Typography>

        <Card variant="outlined" className={style.card} >
          <Container>
            <h3 className={style.headerTitle}>
              Success
              <span >
                  <a className={style.registerLink} href="/">Go back to Home</a> 
                  <i className="fa fa-info-circle" style={{color: '#152E88'}}></i>
                </span>
              </h3> 

              <Typography variant="h4" className="text-center"style={{color: '#152E88'}}>Registration Successful!</Typography>
              <br/>
             <h5 style={{fontWeight: 500}}>You will be contacted via email once your registration is approved.</h5>
          </Container>
        </Card>
      </Grid>
      {/* <Footer /> */}
    </Container>
  );
};

SuccessRegistration.propTypes = {};

export default SuccessRegistration;
