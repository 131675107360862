import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { colors } from "../../../styles/color";
import { Search } from "../../../assets/svg/";
import logo from "../../../assets/images/logo.png";

interface Props {}

const TopMenuItems = (props: Props) => {
  const style = useStyles();
  return (
    <div className={style.container}>
      <ul className={style.topLinks}>
        <li>
          <Link to="">Home</Link>
        </li>
        <li>
          <Link to="">Courses</Link>
        </li>
        <li>
          <Link to="">Contact Us</Link>
        </li>
        <li>
          <Link to="">News &amp; Events</Link>
        </li>
        <li>
          <Link to="">NAFCONS e-learning</Link>
        </li>
        <li>
          <Link to="/">
            <Search />
          </Link>
        </li>
      </ul>
      <img src={logo} alt="logo" className="logo" />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    // border: "2px solid green",
    "& .logo": {
      height: "111px",
    },
  },
  topLinks: {
    marginTop: "17px",
    display: "flex",
    listStyle: "none",
    justifyContent: "end",

    "& li:not(:last-child)": { marginRight: "18px" },
    "& li a": {
      textDecoration: "none",
      fontFamily: "poppins",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "1rem",
      lineHeight: "24px",
      color: colors.black,
    },
    "& li a:hover": {
      color: colors.blue,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

TopMenuItems.propTypes = {};

export default TopMenuItems;
