import React from "react";

function JumpToSectionNav() {
  return (
    <nav id="cd-vertical-nav">
      <ul>
        <li>
          <a
            data-number="1"
            href="#headers"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("headers").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }}
          >
            <span className="cd-dot" />
            <span className="cd-label">Home</span>
          </a>
        </li>
        <li>
          <a
            data-number="3"
            href="#blogs"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("blogs").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }}
          >
            <span className="cd-dot" />
            <span className="cd-label">Updates</span>
          </a>
        </li>
        <li>
          <a
            data-number="4"
            href="#teams"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("teams").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }}
          >
            <span className="cd-dot" />
            <span className="cd-label">Our Team</span>
          </a>
        </li>

        <li>
          <a
            data-number="8"
            href="#contact-us"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("contact-us").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }}
          >
            <span className="cd-dot" />
            <span className="cd-label">Contact Us</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default JumpToSectionNav;
