import { API } from "../configure-api";

//Students endpoints
const getAll = () => API.get("/video/student/all", "");
const getCourseVideo = (id: number) =>
  API.get(`/video/student/courses/${id}`, "");

//Lecturer endpoints
const createVid = (body: any) => API.post("/video/create", body);
const publish = (body: { video_id: number }) =>
  API.patch("/video/publish", body);
const unPublish = (body: { video_id: number }) =>
  API.patch("/video/unpublish", body);
const deleteVideo = (id: number) => API.delete(`/video/${id}`);

export const videoService = {
  getAll,
  getCourseVideo,
  createVid,
  publish,
  unPublish,
  deleteVideo,
};
