import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./viewAssignment.style";
import Layout from "../../../components/Layout";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Chev, NextPage, Previous, Info, Elipses } from "../../../assets/svg/";
import { formatDate } from "../../../shared";
import { formatDateDifference } from "../../shared/utils/dateDifference";
import {
  deleteAssignment,
  publishAssignment,
  unPublishAssignment,
} from "../../../pages/store/features/assignment/assignmentThunk";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

interface Props {}

interface CustomizedState {
  myState: any;
  id: number;
  title: string;
  questions: any;
  course: string;
  course_id: number;
  createdDate: any;
  due_date: any;
  attachment: any;
  isPublished: boolean;
}
const ViewAssignment = (props: Props) => {
  const style = useStyles();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const handleIsSubmit = () => {
    setIsSubmitted(!isSubmitted);
  };

  const questions = state.questions;
  console.log(typeof questions);
  console.log("questions", questions);
  React.useEffect(() => {
    typeof questions === "undefined" && navigate("/dashboard/assignments");
  }, []);
  const totalQuestions = state.questions.length || 0;

  // // Let divide the array questions into two arrays but if the arrays are greater than 2 then divide the array into two arrays
  // const [questions1, questions2] = React.useMemo(() => {
  //   if (totalQuestions > 2) {
  //     const questions1 = questions.slice(0, Math.floor(questions.length / 2));
  //     const questions2 = questions.slice(
  //       Math.floor(questions.length / 2),
  //       questions.length
  //     );
  //     return [questions1, questions2];
  //   } else {
  //     return [questions, []];
  //   }
  // }, [questions]);
  // const [currentQuestion1, setCurrentQuestion1] = React.useState(questions1);
  // const [currentQuestion2, setCurrentQuestion2] = React.useState(questions2);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [questionsPerPage] = React.useState(4);
  // Get current note
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = questions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  // Change page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalQuestions / questionsPerPage); i++) {
    pageNumbers.push(i);
  }

  const [isPublished, setIsPublished] = React.useState(state.isPublished);

  const dispatch = useDispatch<any>();
  const handlepublishAssignment = async () => {
    await dispatch(publishAssignment({ assignment_id: state.id }))
      .then((res: any) => {
        toast.success("Assignment Published");

        console.log(res.payload.message);
        // Close the modal

        // window.location.reload();
        setIsPublished(true);
      })
      .catch((err: any) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  const handleUnpublishAssignment = async () => {
    await dispatch(unPublishAssignment({ assignment_id: state.id }))
      .then((res: any) => {
        toast.success("Assignment unpublished");

        console.log(res);
        // Close the modal
        setIsPublished(false);

        // handleClose();
        // window.location.reload();
      })
      .catch((err: any) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };

  const handledeleteAssignment = async () => {
    await dispatch(deleteAssignment(state.id))
      .then((res: any) => {
        toast.success("Assignment Deleted");
        console.log(res.payload.message);
        // Close the modal

        navigate("/dashboard/assignments");
      })
      .catch((err: any) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  };
  const handleSubmission = (e: any) => {
    e.preventDefault();
    console.log("submitted");
    //  get values of all submitted textarea using e target .elements
    const form = e.target;
    const formData = new FormData(form);
    const data: any = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log(data);
  };
  console.log(state.questions);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const handleMenuOpen = () => setIsMenuOpen(!isMenuOpen);
  return (
    <Layout>
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.header}>
            <h5>
              <Link className="icon" to="/dashboard/assignments">
                <Chev />{" "}
              </Link>{" "}
              {state.course} - {state.title}
            </h5>

            <Elipses onClick={handleMenuOpen} className="iconElipses" />
            {isMenuOpen ? (
              !isPublished ? (
                <div className="dropItems" onMouseLeave={handleMenuOpen}>
                  <button onClick={handlepublishAssignment}>Publish</button>
                  {/* <button
                    onClick={() =>
                      navigate(`/dashboard/assignments/edit`, {
                        replace: true,
                        state: {
                          questions: {
                            id: state.course_id,
                            title: state.title,
                            questions: state.questions,
                            course: state.course_id,
                            attachment: state.attachment,
                            due_date: state.due_date,
                          },
                        },
                      })
                    }
                  >
                    Edit
                  </button> */}
                  <button onClick={handledeleteAssignment}>Delete</button>
                </div>
              ) : (
                <div className="dropItems">
                  <button onClick={handleUnpublishAssignment}>
                    Unpublished
                  </button>
                  <button>Edit</button>
                  <button onClick={handledeleteAssignment}>Delete</button>
                </div>
              )
            ) : (
              ""
            )}
          </div>
          <div className={style.main}>
            <div className="questionsContainer">
              {/* {currentQuestions.map((question: any, i: any) => ( */}
              {questions.map((question: any, i: any) => (
                <div className="questions" key={i}>
                  <p className="question">
                    <strong>Q{i + 1}.</strong>
                  </p>
                  <div className="options">
                    <div
                      className="questionText"
                      dangerouslySetInnerHTML={{ __html: question.text }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>

            {/* //Pagination */}
            {/* <ul className="pagination">
              <Previous
                className={currentPage === 1 ? "icon active" : "icon "}
                onClick={() => paginate(1)}
              />
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={number === currentPage ? "active" : ""}
                >
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="page-link"
                  >
                    {number}
                  </a>
                </li>
              ))}
              <NextPage
                className={
                  currentPage == pageNumbers.length ? "icon active" : "icon "
                }
                onClick={() => paginate(pageNumbers.length)}
              />
            </ul> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};
<script></script>;
ViewAssignment.propTypes = {};

export default ViewAssignment;
