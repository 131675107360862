import { createAsyncThunk } from "@reduxjs/toolkit";
import { assignmentService } from "../../../services";
import { IASSIGNMENT } from "../../../shared";
import { AssignemtAction } from "./assignmentType";

export const fetchAssignment = createAsyncThunk(
  AssignemtAction.FETCHASSIGNMENT,
  async () => {
    const response = await assignmentService.getAll();
    return response.data;
  }
);

export const fetchCourseAssignment = createAsyncThunk(
  AssignemtAction.FETCHCOURSEASSIGNMENT,
  async (courseId: number) => {
    const response = await assignmentService.getCourseAss(courseId);
    return response.data;
  }
);

export const createSubmission = createAsyncThunk(
  AssignemtAction.CREATESUBMISSION,
  async (body: any) => {
    const response = await assignmentService.createSubmission(body);
    return response.data;
  }
);

export const submitAssignment = createAsyncThunk(
  AssignemtAction.ADDTSUBMISSION,
  async (body: any) => {
    const response = await assignmentService.submitAssignemt(body);
    return response.data;
  }
);

export const fetchLeseAss = createAsyncThunk(
  AssignemtAction.FETCHLECCOURSEASS,
  async () => {
    const response = await assignmentService.getLectAss();
    return response.data;
  }
);

export const fetchLecCourseAss = createAsyncThunk(
  AssignemtAction.FETCHLECCOURSEASS,
  async (course_id: number) => {
    const response = await assignmentService.getLecCourseAss(course_id);
    return response.data;
  }
);

export const publishAssignment = createAsyncThunk(
  AssignemtAction.PUBLISH,
  async (body: any) => {
    const response = await assignmentService.publishAss(body);
    return response.data;
  }
);

export const unPublishAssignment = createAsyncThunk(
  AssignemtAction.UNPUBLISH,
  async (body: any) => {
    const response = await assignmentService.unPublishAss(body);
    return response.data;
  }
);

export const deleteAssignment = createAsyncThunk(
  AssignemtAction.DELETEASSIGNMENT,
  async (id: number) => {
    const response = await assignmentService.deleteAssignment(id);
    return response.data;
  }
);

export const markAssignment = createAsyncThunk(
  AssignemtAction.MARKASSIGNMENT,
  async (body: any) => {
    const response = await assignmentService.markAss(body);
    return response.data;
  }
);

export const createAssignment = createAsyncThunk(
  AssignemtAction.CREATEASSIGNMENT,
  async (body: any) => {
    const response = await assignmentService.createAss(body);
    return response.data;
  }
);
