import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./markAssigments.style";
import Layout from "../../../components/Layout";
import { Grid } from "@material-ui/core";
import box from "../../../assets/images/box.png";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeft, Elipses, Marked } from "../../../assets/svg/";
import {
  fetchAssignment,
  fetchLecCourseAss,
  fetchLeseAss,
} from "../../../store/features/assignment/assignmentThunk";
import {
  selectAssignments,
  selectLecAssignments,
} from "../../../store/features/assignment/assignmentSlice";
import { selectRole } from "../../../store/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  OptionMenuAssignment,
  OptionMenuMarkAssignment,
} from "../../../components";
import { formatDate, Role } from "../../../shared";
import LoadingSpinner from "../../../components/common/Loader";

interface Props {}

const MarkAssignments = (props: Props) => {
  const style = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useAppDispatch();
  const assignments = useAppSelector(selectAssignments);
  // const selectLecAssignemts = useAppSelector(selectLecAssignments);
  const userRole = useAppSelector(selectRole);

  const gettAssignments = async () => {
    if (userRole === Role.STUDENT) {
      await dispatch(fetchAssignment());
    } else {
      await dispatch(fetchLeseAss());
    }
  };
  const options = [
    { name: "Record", link: "" },
    { name: "Delete", link: "" },
  ];

  React.useEffect(() => {
    gettAssignments();
    setIsLoading(false);
  }, [assignments]);
  const navigate = useNavigate();
  return (
    <Layout>
      {isLoading && <LoadingSpinner />}
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.header}>
            <h5 style={{color: '#152E88',fontSize: '1.5rem', fontWeight: 100, backgroundColor:'#F1EDEC', padding: '1rem' }}>Mark Assignments</h5>

            <Link to="/dashboard/assignments">
              <ChevronLeft /> Back
            </Link>
          </div>
          <div className={style.main}>
            <div className="lists">
              {assignments
                .filter((ass: any) => ass.is_published)
                .map((ass: any, i: any) => (
                  <div className="list" key={i}>
                    <div className="content">
                      <div
                        className="details"
                        key={i}
                        onClick={() =>
                          navigate(`submissions`, {
                            replace: true,
                            state: {
                              assignmentId: ass.id,
                              courseId: ass.course.id,
                            },
                          })
                        }
                      >
                        <h5>
                          {ass.course.code} - {ass.course.name}
                        </h5>
                        <h6>Level 1</h6>
                        {/* <span>56 students of 80</span>
                  <span>15, Sep 2021</span> */}
                      </div>

                      <OptionMenuMarkAssignment
                        id={ass.id}
                        key={ass.id}
                        questions={ass}
                        options={options}
                      />
                    </div>
                  </div>
                ))}
            </div>

            <div className="recorded lists">
              <div className="list">
                <div className="content">
                  <h5>NRS 104 -Anatomy</h5>
                  <h6>Level 1</h6>
                  <span>20 students of 60</span>
                  <span>19, Sep 2021</span>
                </div>
                <span className="recorded">
                  <Marked /> Recorded
                </span>
              </div>
              <div className="list">
                <div className="content">
                  <h5>NRS 113 - Microbiology</h5>
                  <h6>Level 1</h6>
                  <span>60 students of 60</span>
                  <span>03, Sep 2021</span>
                </div>
                <span className="recorded">
                  <Marked /> Recorded
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

MarkAssignments.propTypes = {};

export default MarkAssignments;
