import { createAsyncThunk } from '@reduxjs/toolkit'
import { payFeesService } from '../../../services'
import { PaymentAction } from './paymentTypes'

export const fetchPayments = createAsyncThunk(PaymentAction.FETCHPAYMENTS, async () => {
    // const response = await payFeesService.getAll()
    // return response.data
})

export const fetchCourseNotes = createAsyncThunk(PaymentAction.PAYFEES, async (body: any) => {
    const response = await payFeesService.payFees(body)
    return response.data
})
