import React, { useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./editAssigments.style";
import Layout from "../../../components/Layout";
import { Grid, TextField, Button, TextareaAutosize } from "@material-ui/core";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft, Plus } from "../../../assets/svg/";

import { useAppDispatch, useAppSelector } from "../../../store";
import { selectCourses } from "../../store/features/course/courseSlice";
import { fetchCourses } from "../../store/features/course/courseThunk";
import { selectRole } from "../../store/features/auth/authSlice";
import { Spinner } from "../../../components/common/Spinner";
import { updateAssignment } from "../../../store/features/assignment/assignmentThunk";
import { toast } from "react-toastify";
import TextEditor from "../../../components/common/TextEditor";

interface Props {}
interface CustomizedState {
  myState: any;

  questions: any;
}

const EditAssignment = (props: Props) => {
  const style = useStyles();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const [number, setNumber] = useState(0);
  const [title, setTitle] = useState(state.questions.title);
  const [course, setCourse] = useState(state.questions.course.id);
  const [marks, setMarks] = useState(state.questions.obtainablescore);
  const [due_date, setDueDate] = useState(state.questions.due_date);
  const [attachment, setAttachment] = useState(state.questions.attachment);
  console.log(state.questions);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectRole);
  const coursess = useAppSelector(selectCourses);

  const formSubmitHandler = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const questions: any = [];

    Array.from(e.target.elements).map((el: any) => {
      if (el.name === "questions") {
        el.value !== "" && questions.push(el.value);
      }
    });
    const data = {
      id: state.questions.id,
      title: title,
      course_id: course,

      due_date: due_date,
      attachment: attachment,
      questions: questions,
    };

    console.log("From ", data);
    await dispatch(updateAssignment(data))
      .then((res: any) => {
        setIsLoading(false);
        toast.success("Assignment has been created successfully");
      })
      .catch((err: any) => {
        setIsLoading(false);
        toast.error("Something went wrong please try again");
        console.log(err);
      });
  };

  const courses = [{ value: "0", label: "Course" }];

  coursess.map((course: any) =>
    courses.push({ value: course.id, label: course.name })
  );

  const getCourses = async () => {
    await dispatch(fetchCourses(userRole));
  };

  React.useEffect(() => {
    getCourses();
  }, []);

  return (
    <Layout>
      {isAddNew && (
        <div className={style.modalContainer}>
          <div className="modals">
            <i className="cancelBox" onClick={() => setIsAddNew(false)}>
              x
            </i>
            <div className="content">
              <div className="input">
                <input
                  type="number"
                  value={number}
                  onChange={(e: any) => {
                    setNumber(parseInt(e.target.value) || 0);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Main Container */}
      <div className={style.container}>
        <div className={style.dash}>
          <div className={style.header}>
            <h5>Update Assignment</h5>

            <Link to="/dashboard/assignments/">
              <ChevronLeft /> Back
            </Link>
          </div>
          <div className={style.main}>
            <div className={style.form}>
              <form onSubmit={formSubmitHandler}>
                <div className="form-group">
                  <div>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      value={title}
                      onChange={(e: any) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div>
                    <select
                      name="course_id"
                      value={course}
                      onChange={(e: any) => setCourse(e.target.value)}
                    >
                      {courses.map((course) => (
                        <option key={course.value} value={course.value}>
                          {course.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="obtainablescore"
                      placeholder="Obtainable score"
                      value={marks}
                      onChange={(e: any) => setMarks(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="due_date"
                      placeholder="Due date"
                      className="date-picker"
                      value={due_date}
                      onChange={(e: any) => setDueDate(e.target.value)}
                      onFocus={(e) => (e.target.type = "date")}
                    />
                  </div>
                </div>
                <div className="divider"></div>

                <div className="questions">
                  <h5>Questions</h5>
                  {state.questions.questions.map((question: any) => (
                    <div className="form-group">
                      <TextEditor
                        name="questions"
                        initalValue={question.text}
                      />
                    </div>
                  ))}
                  <div id="addNew">
                    {number > 0 &&
                      [...Array(number)].map((x: any, i: any) => (
                        <div className="form-group">
                          <TextEditor name="questions" />
                        </div>
                      ))}
                  </div>
                  <p onClick={() => setIsAddNew(true)}>
                    <Plus /> Add New
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Attachment url (optional)"
                      name="attachment"
                      value={attachment}
                      onChange={(e: any) => setAttachment(e.target.value)}
                    />
                  </div>
                </div>
                <div className="divider"></div>
                <div className="upload">
                  <Button color="primary" variant="contained" type="submit">
                    {isLoading ? <Spinner size="2" /> : "Update"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

EditAssignment.propTypes = {};

export default EditAssignment;
