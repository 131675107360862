import React, { HTMLAttributes, HTMLProps, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "../dataTables.style";
import { ChevDown, X } from "../../../../assets/svg";
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingSpinner from "../../../../components/common/Loader";
import { toast, ToastContainer } from "react-toastify";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import GraduateRoundedIcon from "@material-ui/icons/School";

import Confirm from "../../Confirm";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface Props {
  setFilter: string;
  isAddCourse: any;
  courses: any;
  handleCloseAll: any;
  fetchCourses: any;
  levels: any;
  semesters: any;
  instructors: any;
  isLoadingCourses: boolean;
}
type Course = {
  [key: string]: any;
};

interface CreateCourseInputs {
  name: string;
  code: string;
  description: string;
  level_id: string;
  semester_id: string;
  start_date: string;
  duration_in_days: number;
  attendance: number;
  cat: number;
  exam: number;
}

const createCourseSchema = yup.object().shape({
  name: yup.string().required("Required"),
  code: yup.string().required("Required"),
  description: yup.string().required("Required"),
  level_id: yup.string().required("Required"),
  semester_id: yup.string().required("Required"),
});

const CourseDataTable = (props: Props) => {
  const style = useStyles();
  const BASE_URL = process.env.REACT_APP_BASEURL;
  const token = localStorage.getItem("token");
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [addCourseModal, setAddCourseModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [focusedRow, setFocusedRow] = React.useState<{ [key: string]: any }>(
    {}
  );
  const [openWarning, setOpenWarning] = React.useState(false);
  const [openGraduateWarning, setOpenGraduateWarning] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  
  // form
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateCourseInputs>({
    resolver: yupResolver(createCourseSchema),
  });

  useEffect(() => {
    // set selected field values of toggle edit
    if (focusedRow) {
      setValue("name", focusedRow?.name);
      setValue("code", focusedRow?.code);
      setValue("description", focusedRow?.description);
      setValue("level_id", focusedRow?.level_id);
      setValue("semester_id", focusedRow?.semester_id);
      setValue("start_date", focusedRow?.start_date);
      setValue("duration_in_days", focusedRow?.duration_in_days);
      setValue("attendance", focusedRow?.attendance);
      setValue("cat", focusedRow?.cat);
      setValue("exam", focusedRow?.exam);
    }
  }, [focusedRow]);


  const onSubmit = (data: CreateCourseInputs) => {
    console.log(`Semester: ${data.semester_id}`);
    const body = {
      name: data.name,
      code: data.code,
      description: data.description,
      semester_id: data.semester_id,
      level_id: data.level_id,
      start_date: data.start_date+"T10:00:00.0",
      duration_in_days: data.duration_in_days,
      attendance: data.attendance || 0,
      cat: data.cat || 0,
      exam: data.exam || 0,
    };
    if (focusedRow.code) {
      updateCourse(body);
    } else {
      createCourse(body);
    }
  };
  
  const createCourse = async (data: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/course/add`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await req.json();
      console.log(res);
      if (res.message) {
        toast.success(res.messsage || "Course created", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchCourses();
        reset();
        handleCloseModal();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const updateCourse = async (data: any) => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/course/update/${focusedRow?.id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const res = await req.json();
      console.log(res);
      if (req.ok) {
        toast.success(res.messsage || "Course updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchCourses();
        reset();
        handleCloseModal();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const graduateCourse = async () => {
    try {
      setIsLoading(true);

      const body = {
        course_id : focusedRow.id
      }
      //toast.success(body.course_id);
      
      const req = await fetch(`${BASE_URL}/admin/course/graduate`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      const res = await req.json();
      console.log(res);
      if (req.ok) {
        toast.success(res.messsage || "Course graduated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchCourses();
        reset();
        handleCloseModal();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleDeleteCourse = async () => {
    try {
      setIsLoading(true);
      const req = await fetch(`${BASE_URL}/course/${focusedRow.id}/delete`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await req.json();
      console.log(res);
      if (res.message) {
        toast.success(res.messsage || "course deleted", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchCourses();
        reset();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSelectInstructor = async (e: any) => {
    const body = { lecturer_id: e.target.value };
    try {
      setIsLoading(true);
      const req = await fetch(
        `${BASE_URL}/course/${focusedRow.id}/lecturer/add`,
        {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );
      const res = await req.json();
      console.log(res);
      if (res.message) {
        toast.success(res.messsage, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchCourses();
        reset();
        // handleCloseModal();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleRemoveInstructor = async (item: any) => {
    const body = { lecturer_id: item.id };
    try {
      setIsLoading(true);
      const req = await fetch(
        `${BASE_URL}/course/${focusedRow.id}/lecturer/remove`,
        {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );
      const res = await req.json();
      console.log(res);
      if (res.message) {
        toast.success(res.messsage, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        props.fetchCourses();
        reset();
        // handleCloseModal();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    props.handleCloseAll();
    setOpenEdit(false);
  };

  React.useEffect(() => {
    setGlobalFilter(props.setFilter);
  }, [props.setFilter]);
  React.useEffect(() => {
    setAddCourseModal(props.isAddCourse);
  }, [props.isAddCourse]);

  const columns = React.useMemo<ColumnDef<Course>[]>(
    () => [
      {
        accessorKey: "name",

        header: "Course",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "code",
        header: "Course Code.",

        footer: (props) => props.column.id,
      },
      {
        accessorKey: "description",
        header: "Description",
        footer: (props) => props.column.id,
      },
      {
        id: "action",
        header: "Actions",
        cell: ({ row }) => (
          <div style={{ display: "flex", gap: 20 }}>
            <GraduateRoundedIcon
              fontSize="small" 
              style={{color: "#597955"}}
              // color="primary" 
              onClick={() => {
                setFocusedRow(row.original);
                setOpenGraduateWarning(true);
              }}
            />
            
            <EditRoundedIcon
              fontSize="small"
              color="primary"
              onClick={() => {
                setFocusedRow(row.original);
                setOpenEdit(true);
              }}
            />
            <DeleteRoundedIcon
              fontSize="small"
              color="error"
              onClick={() => {
                setFocusedRow(row.original);
                setOpenWarning(true);
              }}
            />

            
          </div>
        ),
      },
    ],
    []
  );

  const [data, setData] = React.useState(props.courses);

  useEffect(() => {  
    props.courses.map((course:any)=>{
      course.start_date = course.start_date?.split("T")[0];      
    });
    setData(props.courses);
  }, [props.courses]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="Warning"
        message="Are you sure you want to delete this course?"
        action={handleDeleteCourse}
      />

      <Confirm
        open={openGraduateWarning}
        setOpen={setOpenGraduateWarning}
        title="Graduate Course"
        message="Are you sure you want to graduate students from this course?"
        action={graduateCourse}
      />

      <Modal
        isOpen={addCourseModal}
        toggle={() => handleCloseModal()}
        centered={true}
        scrollable={true}
      >
        <ModalBody>
          {/* add course modal */}
          {isLoading && <LoadingSpinner />}
          <div className={style.addCourseModal}>
            <div className="header">
              <h3 className="mTitle">New Course</h3>
              <X className="icon" onClick={handleCloseModal} />
            </div>
            <div className="bodyMod">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formGroup">
                  <input {...register("code")} placeholder="course code" />
                  <p>{errors.code?.message}</p>
                  <input {...register("name")} placeholder="course name" />
                  <p>{errors.name?.message}</p>
                  <input
                    {...register("description")}
                    placeholder="course description"
                  />
                  <p>{errors.description?.message}</p>
                  <input
                    type="date"
                    {...register("start_date")}
                    placeholder="course start date"
                  />
                  <p>{errors.start_date?.message}</p>
                  <input
                    type="number"
                    {...register("duration_in_days")}
                    placeholder="Course duration(days)"
                  />
                  <p>{errors.duration_in_days?.message}</p>
                  <select {...register("level_id")}>
                    <option value="">
                      Select level
                    </option>
                    {props.levels?.map((item: any) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                  <select {...register("semester_id")}>
                    <option value="">
                      Select semester
                    </option>
                    {props.semesters?.map((item: any) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                  <input
                    type="number"
                    {...register("attendance")}
                    placeholder="Attendance Score/100"
                  />
                  <input
                    type="number"
                    {...register("cat")}
                    placeholder="CA Test Score/100"
                  />
                  <input
                    type="number"
                    {...register("exam")}
                    placeholder="Exam Score/100"
                  />
                </div>
                <button className="btn">Save</button>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={openEdit}
        toggle={() => handleCloseModal()}
        centered={true}
        scrollable={true}
      >
        {isLoading && <LoadingSpinner />}
        {/* edit course modal */}
        <ModalBody>
          <div className={style.addCourseModal}>
            <div className="header">
              <h3 className="mTitle">Edit Course</h3>
              <X className="icon" onClick={handleCloseModal} />
            </div>
            <div className="bodyMod">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formGroup">
                  <input {...register("code")} placeholder="course code" />
                  <p>{errors.code?.message}</p>
                  <input {...register("name")} placeholder="course name" />
                  <p>{errors.name?.message}</p>
                  <input
                    {...register("description")}
                    placeholder="course description"
                  />
                  <p>{errors.description?.message}</p>
                  <input
                    type="date"
                    {...register("start_date")}
                    placeholder="course start date"
                  />
                  <p>{errors.start_date?.message}</p>
                  <input
                    type="number"
                    {...register("duration_in_days")}
                    placeholder="Course duration(days)"
                  />
                  <p>{errors.duration_in_days?.message}</p>
                  <select {...register("level_id")}>
                    <option value="">
                      Select level
                    </option>
                    {props.levels?.map((item: any) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                  <select {...register("semester_id")}>
                    <option value="">
                      Select semester
                    </option>
                    {props.semesters?.map((item: any) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                  <input
                    type="number"
                    {...register("attendance")}
                    placeholder="Attendance Score/100"
                  />
                  <input
                    type="number"
                    {...register("cat")}
                    placeholder="CA Test Score/100"
                  />
                  <input
                    type="number"
                    {...register("exam")}
                    placeholder="Exam Score/100"
                  />
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id="panel1a-header"
                    >
                      <Typography>Instructors</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formGroup" style={{ width: "100%" }}>
                        <select
                          defaultValue=""
                          onChange={handleSelectInstructor}
                        >
                          <option value="" disabled>
                            Add New
                          </option>
                          {props.instructors?.map((item: any) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {focusedRow?.lecturers?.map((item: any) => (
                          <div style={{ display: "flex" }}>
                            <input
                              style={{ flex: 1 }}
                              placeholder={item.name}
                              disabled
                            />
                            <DeleteRoundedIcon
                              color="error"
                              onClick={() => handleRemoveInstructor(item)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <button className="btn">Save</button>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <div className={style.displayAll}>
        <div className="items">
          <Link to="">
            All Courses <ChevDown />
          </Link>
        </div>
        {/* <button
          className={
            numberSelected === 0 ? "btn secondary disabled" : "btn secondary"
          }
          disabled={numberSelected === 0}
          onClick={() =>
            console.info(
              "Selected Rows",
              table.getSelectedRowModel().flatRows.map((row) => row.original)
            )
          }
        >
          Delete
        </button> */}
      </div>

      <table className={style.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                {...table.getRowModel()}
                // onClick={() => handleCourseEditDet(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.isLoadingCourses && (
        <h4 style={{ padding: ".5rem 1.2rem" }}>Loading Courses...</h4>
      )}
      <div className={style.tableMisc}>
        <div className="nextPrevious">
          <button
            className=""
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className=""
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className=""
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className=""
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="pageCounter">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className=""
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div></div>
      </div>
    </>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}
CourseDataTable.propTypes = {};

export default CourseDataTable;
