import { createAsyncThunk } from "@reduxjs/toolkit";
import { coursesService } from "../../../services";
import { CourseAction } from "./courseType";

export const fetchCourses = createAsyncThunk(
  CourseAction.FETCHCOURSE,
  async (user: string) => {
    const response = await coursesService.getAll(user);
    return response.data;
  }
);

export const fetchCourseStudents = createAsyncThunk(
  CourseAction.FETCHCOURSESTUDENTS,
  async (courseId: number) => {
    const response = await coursesService.getAllStudents(courseId);
    return response.data;
  }
);

export const fetchAddCourses = createAsyncThunk(
  CourseAction.FETCHADDCOURSE,
  async (level_id: number) => {
    const response = await coursesService.getAddCourses(level_id);
    return response.data;
  }
);

export const addCourses = createAsyncThunk(
  CourseAction.ADDCOURSES,
  async (body: { course_ids: Array<{}> }) => {
    const response = await coursesService.addCourses(body);
    console.log(response.data);
    return response.data;
  }
);

export const dropCourse = createAsyncThunk(
  CourseAction.REMOVECOURSE,
  async (body: { course_id: number }) => {
    const response = await coursesService.dropCourse(body);
    return response.data;
  }
);
