import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import { useStyles } from "../cbt.style";
import { Clock, SuccessGreen, ExclamationWhite } from "../../../assets/svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../store";
import { selectUser } from "../../store/features/auth/authSlice";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from "../../../components/common/Loader";
import Countdown from "react-countdown";
import useNetworkStatus from "../../shared/utils/network";

interface Props {}

const UtmeExam = (props: Props) => {
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const style = useStyles();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const { quizId } = useParams();
  const [test, setTest] = useState<{ [key: string]: any }>({});
  const [isTestLoaded, setIsTestLoaded] = useState(false);
  const [isTestCompleted, setIsTestCompleted] = useState(false);
  const [answers, setAnswers] = useState<object[]>([]);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [openWarning, setOpenWarning] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(100000000);
  const countdown = localStorage.getItem(`countdown_${quizId}`);
  const dateRef = React.useRef()

  useEffect(() => {
    let status = localStorage.getItem(`status_${quizId}`)
    if(status !== undefined && status === "finished")return navigate("/dashboard/cbt-assignment")

    fetchQuiz();
     
    if(window.localStorage === undefined){ 
      toast.warning("Your browser is Outdated.");
      toast.warning("Update your browser or switch to another up to date browser to proceed with the test.");
      alert("Your browser is Outdated. Update your browser or switch to another up to date browser to proceed with the test.");
      navigate("/dashboard/cbt-assignment");
      return;
    }
  }, [quizId]);

  const dateMemo = React.useMemo(() => {
    return Date.now() + timeLeft;
  }, []);

  /*useEffect(()=>{
    Array.from(Array(test?.questions?.length)).map((item, index) => 
                  (  
    answers?.filter(
      (ans: any) => {     
        // //console.log(`${index}. ${test?.questions[index]?.id === ans?.question_id}`); 
        //console.log(`Questions: ${test?.questions[index]?.text}`);
        //console.log(`Answers: ${test?.questions[index]?.id} - ${ans?.question_id}`);                
        return test?.questions[index]?.id === ans?.question_id; 
      }
    )
                  )
  }, [answers]);*/

  // counte
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      toast.success("Your time is up");
      setIsTestCompleted(true);
      handleSubmit();
    } else {
      // Render a countdown
      return parseInt(hours) === 0 &&
        parseInt(minutes) <= 1 &&
        parseInt(seconds) ? (
        <span style={{ backgroundColor: "#F40D34", color: "#fff" }}>
          {hours}H {minutes}M {seconds}S
        </span>
      ) : (
        <span style={{ color: "#152E88" }}>
          {hours}H {minutes}M {seconds}S
        </span>
      );
    }
  };

  //https://www.webmound.com/shuffle-javascript-array/
  //Fisher-Yates algorithm implementation
  const shuffle = (array: any) => {
    const newArray = [...array];

    newArray.reverse().forEach((item, index) => {
      const j = Math.floor(Math.random() * (index + 1));
      [newArray[index], newArray[j]] = [newArray[j], newArray[index]];
    });

    return newArray;
  };

  const fetchQuiz = async () => {
    try {
      const request = await fetch(`${BASE_URL}/cbt/quiz/${quizId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      if (request.ok) {
        //response.questions = response.questions.shuffle();
        console.log("fetch quiz ran");
        response.questions = shuffle(response.questions);
        response.questions.map((question: any)=>{
          question.options = shuffle(question.options);
        });
        setTest(response);
        
        setTimeLeft(
          countdown ? parseInt(countdown) : response.time_allowed * 60 * 1000
        );

        //if still default time
        if(!countdown)setTimeLeft(response.time_allowed * 60 * 1000)
        
        setIsTestLoaded(true);
        localStorage.setItem(`started_status_${quizId}`, "started");
      }
    } catch (error) {
      console.log(error);
            
      //if still default time, go back to cbt page
      //if(timeLeft === 100000000)navigate("/dashboard/cbt-assignment"); 
      setTimeout(()=>{ 

        if(window.localStorage === undefined){ 
          toast.warning("Your browser is Outdated.");
          toast.warning("Update your browser or switch to another up to date browser to proceed with the test.");
          navigate("/dashboard/cbt-assignment");
          return;
        }
        
        fetchQuiz();
      }, 5000);
    }
  };

  const handleClickNext = () => {
    setcurrentIndex((prev) => prev + 1);
  };

  const handleClickPrev = () => {
    setcurrentIndex((prev) => prev - 1);
  };

  const handleSelectAnswer = (answer: any) => {
    let newAnswers: object[] = answers?.filter(
        (item: any) => item.question_id !== answer.question_id
      ); 
    newAnswers.push(answer);
    setAnswers([...newAnswers]);   
    let updated_answers = {
        answers: newAnswers
    };
    localStorage.setItem(`answers_${quizId}`, JSON.stringify(updated_answers));
    console.log(`Answers: ${JSON.stringify(updated_answers)}`);
    
    handleSubmitQuestion(answer.question_id, answer.option_id);

  };

  const handleAttemptedSection = (index:number, answer:any)=>{
    answers?.length > 0 && answers?.filter(
      (ans: any) => {                      
        //console.log(`${index}. ${test?.questions[index]?.id === ans?.question_id}`); 
        return test?.questions[index]?.id === ans?.question_id; 
      }
    )
  }

  const isOptionSelected = (option: any) => {
    //for styled purpose
    const selected = answers.find(
      (question: any) =>
        question.question_id === option.question_id &&
        question.option_id === option.option_id
    );
    if (selected) {
      return true;
    } else {
      return false;
    }
  };

  const { isOnline } = useNetworkStatus();
  //don't display for now to avoid distracting the student
  //!isOnline && toast.warning("Check your network connectivity to be sure you're online.");
  //window.location.reload(false); 

  const handleSubmit = async () => {

    
    try {
      /*const body = {
        answers: answers,
      };*/

      //check connectivity before submission
      let countInterval = 0;
      const intervalCheck = setInterval(()=>{
        countInterval++;
        if(!isOnline){
          setOpenWarning(false); //close modal
          localStorage.setItem(`time_submitted_${quizId}`, new Date().toLocaleString());
          //localStorage.setItem(`answers_${quizId}`, JSON.stringify(body));

          if(countInterval == 1){
            setTimeout(()=>toast.warning("Check your network connectivity to be sure you're online."), 1500);
            setTimeout(()=>toast.warning("No need to refresh the browser. Just check your network connectivity."), 1500);
          }      
                               
        }
        isOnline && clearInterval(intervalCheck);
         
      }, 2000);
       
      //proceed
      if(isOnline){    

        clearInterval(intervalCheck);
        setIsTestCompleted(true);
        
        type storedAnswer = {
          question_id?: number;
          option_id?: number;
        };
         
        let storedAnswers: storedAnswer[] = JSON.parse(localStorage.getItem(`answers_${quizId}`) || '{}').answers; 
        console.log(`Stored Answers: ${storedAnswers.length}`);     

        const request = await fetch(`${BASE_URL}/cbt/quiz/${quizId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          //body: !localStorage.getItem(`time_submitted_${quizId}`) ? JSON.stringify(body):localStorage.getItem(`answers_${quizId}`),
          body: JSON.stringify({answers: storedAnswers}),//JSON.stringify(localStorage.getItem(`answers_${quizId}`)),
        });
        const response = await request.json();
        console.log(response);  
        if (request.status === 400) {
          toast.error(response.message || "error");
          setOpenWarning(false);
        }
        if (request.ok) {
          setOpenSuccess(true);
          setOpenWarning(false);
          localStorage.removeItem(`countdown_${quizId}`);
          console.log(`Before: ${localStorage.getItem(`time_submitted_${quizId}`)}`);
          //remove offline storage values          
          localStorage.removeItem(`time_submitted_${quizId}`);
          localStorage.removeItem(`answers_${quizId}`); 
          clearInterval(intervalCheck);         
          
          toast.warning("Redirecting. Please wait...");
          localStorage.setItem(`status_${quizId}`, "finished");
          setTimeout(() => {
            navigate("/cbt/result", {
              state: {
                score: response.score,
                student: response.student, //id, name, rank, service_number
                obtainable_score: response.obtainable
              }
               
            });
          }, 2000);
        }
        
      }       
      
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleSubmitQuestion = async (questionId:number, optionId:number) => {

    try {

      const body = {
        quiz_id: quizId,
        option_id: optionId,
      };             
                  
      const request = await fetch(`${BASE_URL}/cbt/question/${questionId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body)
      });           
      
    } catch (error) {
      console.log(error); 
    }

  };

  return (
    <div className={style.exam}>
      <ToastContainer />
      {openWarning && (
        <div className="confirmModal">
          <div
            style={{
              backgroundColor: "white",
              margin: "auto",
              maxWidth: "35.125rem",
              position: "relative",
              minHeight: "50%",
              transition: ".5s",
            }}
          >
            <Link
              to=""
              className="cancelBox"
              onClick={() => setOpenWarning(!openWarning)}
            >
              x
            </Link>

            <div
              className="content"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ExclamationWhite className="icon" />
              <h6>Submit Exams?</h6>
              <div>
                <p>Are you sure you want to submit your exam?</p>
                <p>You cannot undo this action.</p>
              </div>
              <div
                className="buttons"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  size="medium"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  size="medium"
                  className="cancel"
                  onClick={() => setOpenWarning(!openWarning)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {openSuccess && (
        <div className="successModal">
          <div
            style={{
              backgroundColor: "white",
              margin: "auto",
              maxWidth: "35.125rem",
              position: "relative",
              minHeight: "50%",
              transition: ".5s",
            }}
          >
            <Link
              to=""
              className="cancelBox"
              onClick={() => setOpenSuccess(false)}
            >
              x
            </Link>
            <div className="content">
              <SuccessGreen className="icon" />

              <div>
                <p>Your Exam has been submitted.</p>
                <p>you can view your result in your dashboard</p>
              </div>
              <Button
                color="primary"
                variant="contained"
                type="button"
                size="medium"
                onClick={() => navigate("/cbt/result")}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className="header">
        <img
          src={require("../../../assets/icons/NAFLogo.png")}
          alt="img"
          className="logo"
        />

        <div className="time">
          <Clock className="clock" />
          <h6>
            Time Left:{" "}
            {isTestLoaded ?
            <span>
              <Countdown

                onTick={(e: any) => {
                  localStorage.setItem(`countdown_${quizId}`, e.total);
                  setTimeLeft(e.total);
                }}
                date={Date.now() + timeLeft}

                renderer={renderer}
              />
            </span>
            : '-'}
          </h6>
        </div>
        <div className="details">
          <div className="name">
            <h6>{user?.name}</h6>
            <h6 className="reg">{user?.service_number}</h6>
          </div>
          <img src={user?.image} alt="pic" />
        </div>
      </div>
      <div style={{padding:"1rem", paddingTop: "2rem"}}>
        {countdown  === "1000" && !isOnline && 
        <h4 style={{ textAlign: "center" }}>
          Waiting for network connection to complete the submission.<br/>
          Please stand by...
        <LoadingSpinner />
        </h4>}
        {(!isTestCompleted && isTestLoaded) && <div dangerouslySetInnerHTML={{ __html: test?.instruction }} className="reading"></div>}
      </div>
      
      { !isTestCompleted && (

      <div className="cont" style={{paddingTop: '1rem'}}>        
        
        {isTestLoaded ? (
          <Grid container className="body">
            <Grid item md={6} sm={12} xs={12} className="question">
              <h6>
                Question:{" "}
                <span>{currentIndex + 1 + "/" + test?.questions?.length}</span>
              </h6>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    test?.questions?.[currentIndex]?.question ||
                    test?.questions?.[currentIndex]?.text,
                }}
              ></div>
              <div className="buttons">
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  size="medium"
                  className="prev"
                  disabled={currentIndex === 0}
                  onClick={handleClickPrev}
                >
                  Previous
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  size="medium"
                  disabled={currentIndex === test?.questions?.length - 1}
                  onClick={handleClickNext}
                >
                  Next
                </Button>
              </div>
            </Grid>
            <Grid item md={3} sm={12} xs={12} className="options">
              {test?.questions?.[currentIndex]?.options?.map(
                (option: any, i: any) => (
                  <Link
                    to=""
                    className="option"
                    key={i}
                    style={{
                      backgroundColor: isOptionSelected({
                        question_id: test?.questions?.[currentIndex]?.id,
                        option_id: option.id,
                      })
                        ? "#152E88"
                        : "",
                      color: isOptionSelected({
                        question_id: test?.questions?.[currentIndex]?.id,
                        option_id: option.id,
                      })
                        ? "white"
                        : "",
                    }}
                    onClick={() =>
                      handleSelectAnswer({
                        question_id: test?.questions?.[currentIndex]?.id,
                        option_id: option.id,
                      })
                    }
                  >
                    {option.option || option.text}
                  </Link>
                )
              )}
            </Grid>
            
            <Grid item md={3} sm={12} xs={12} 
            style={{
              // border: "1px solid green",
              // background: "blue",
              color: "black",
              padding: "1rem"
            }}>
              <h4 style={{marginTop: "-1rem", background:"#152E88", color:"#fff", padding:".3rem",textAlign:"center"}}>
                Questions Attempted: <br/>{answers?.length} out of {test?.questions?.length}</h4>
               
                {                    
                Array.from(Array(test?.questions?.length)).map((item, index) => 
                  (                                         
                    /*(<Button 
                      style={{padding:".25rem",  margin: '.15rem', color:"#fff",
                      background: answers[index] != undefined ?"green":"orange"}}  
                      onClick={()=>setcurrentIndex(index)} key={index}>
                      {index+1}
                    </Button>)  */ 
                    (<Button 
                      style={{padding:".25rem",  margin: '.15rem', color:"#fff",
                      background: //test?.questions[index]
                      answers?.filter(
                        (ans: any) => {     
                          return test?.questions[index]?.id === ans?.question_id; 
                        }
                      ).length > 0 
                       ?"green":"orange"}}  
                      onClick={()=>setcurrentIndex(index)} key={index}>
                      {index+1}
                    </Button>) 
                    
                  )                  
                )
                }
                <br/>
                <p style={{background:"#152E88", color: "#fff", padding: ".2rem"}}>Green - Answered<br/> Amber - Unanswered</p>                               
               
            </Grid>
          </Grid>
        ) : (
          <>
            <h3 style={{ textAlign: "center" }}>
              Setting up questions. Please wait...
            </h3>
            <LoadingSpinner />
          </>
        )}

        {currentIndex === test?.questions?.length - 1 && (
          <Button
            color="primary"
            variant="contained"
            type="button"
            size="medium"
            className="submitExams"
            onClick={() => setOpenWarning(true)}
          >
            Submit Exams
          </Button>
        )}

      </div>

      )}
    </div>
  );
};

UtmeExam.propTypes = {};

export default UtmeExam;
