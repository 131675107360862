import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWebsite } from "./websiteThunk";

const initialState = {
  website: {},
  status: "idle",
  isLoading: true,
};

export const websiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    clearState: (state) => {
      localStorage.removeItem("token");
      state.website = {};
      state.isLoading = false;
      state.status = "idle";
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsite.pending, (state, action) => {
        return { ...state, status: "loading", isLoading: true };
      })
      .addCase(fetchWebsite.fulfilled, (state, action) => {
        return {
          ...state,
          website: action.payload,
          status: "fullfilled",
          isLoading: false,
        };
      })
      .addCase(fetchWebsite.rejected, (state, action) => {
        state.status = "rejected";
        state.isLoading = false;
      });
  },
});

export const { clearState } = websiteSlice.actions;

export const selectWebsite = (state: any) => state.website.website;
export const selectStatus = (state: any) => state.website.status;
export const selectIsLoading = (state: any) => state.website.isLoading;

export default websiteSlice.reducer;
