import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./CBTAssignments.style";
import Layout from "../../../components/Layout";
import { NextPage, Previous } from "../../../assets/svg/";
import {
  Button,
  // Checkbox,
  colors,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
} from "@material-ui/core";
import box from "../../../assets/images/box.png";
import { Link, useNavigate } from "react-router-dom";
import { Plus, Elipses } from "../../../assets/svg/";
import { useAppDispatch, useAppSelector } from "../../../store";

import { selectRole } from "../../../store/features/auth/authSlice";
import { formatDate, Role } from "../../../shared";
import { toast, ToastContainer } from "react-toastify";
import CBTAssignmentsList from "./CBTAssignmentListStudent";
import LoadingSpinner from "../../../components/common/Loader";
import { More, MoreHoriz, MoreVert } from "@material-ui/icons";
import Confirm from "../../../components/common/Confirm";
import { Spinner } from "../../../components/common/Spinner";

import {
  CheckboxGroup,
  AllCheckerCheckbox,
  Checkbox
} from "@createnl/grouped-checkboxes";

interface Props {}

const CBTAssignments = (props: Props) => {
  const style = useStyles();
  const BASE_URL = `${process.env.REACT_APP_BASEURL}`;
  const token = localStorage.getItem("token");
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectRole);
  const navigate = useNavigate();
  const [quizes, setQuizes] = useState([]);
  const [quizesLoaded, isQuizesLoaded] = useState(true);
  const [openWarning, setOpenWarning] = useState(false);
  const [focusedItem, setFocusedItem] = useState<{ [key: string]: any }>({});
  const [course, setCourse] = useState<{ [key: string]: any }>({});
  const [isPublish, setIsPublish] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const [pageNumbers, setPageNumbers] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const handlePublishModal = () => {
    isPublish ? setIsPublish(false) : setIsPublish(true);
  };

  const handleIsPublish = async (courseId: number) => {
    // to get the course so we can get the students that offer
    try {
      const request = await fetch(`${BASE_URL}/lecturer/courses/${courseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      if (response.id) {
        setIsPublish(true);
        setCourse(response);
      }
    } catch (error) {
      console.log(error);
    }
  };   

  const [onSelectParticipant, setOnSelectParticipant] = React.useState({}); 

  const fetchAllQuizes = async (page: number = 1) => {
    try {
      const request = await fetch(`${BASE_URL}/cbt/quiz?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await request.json();
      isQuizesLoaded(false);
      if (response.quizzes) {
        setQuizes(response.quizzes);
        // let pages = Array.from(Array(response.total).keys());
        setPageNumbers(response.pages);
      }
      isQuizesLoaded(false);
    } catch (error) {
      isQuizesLoaded(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllQuizes(currentPage);
  }, [currentPage]);

  // console.log(quizes);

  const handleDelete = async () => {
    setOpenWarning(false);
    // console.log(focusedItem.id);
    try {
      const req = await fetch(`${BASE_URL}/cbt/quiz/${focusedItem?.id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await req.json();
      // console.log(res);
      if (req.status === 400) {
        toast.error("Error! Test cannot be deleted");
      }
      if (req.ok) {
        toast.success("Test successfully deleted", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        fetchAllQuizes();
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // console.log(error);
    }
  };

  const handlePublishCBT = async (e: any) => {
    e.preventDefault();
    handleClose();
    setIsPublishLoading(true);
    // get values of all the submitted checkboxes
    const values = Array.from(e.target.elements)
      .filter((el: any) => el.checked)
      .map((el: any) => el.value);

    if (values?.length === 0) {
      setIsPublishLoading(false);
      return toast.error("Select atleast one student to continue");
    }
    try {
      const req = await fetch(
        `${BASE_URL}/cbt/quiz/publish/${focusedItem?.id}`,
        {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            quiz_id: focusedItem?.id,
            participants: values,
          }),
        }
      );
      const res = await req.json();
      // console.log(res);
      setIsPublishLoading(false);
      if (req.status === 400) {
        toast.error("Error! Test cannot be published");
      }
      if (req.ok) {
        setIsPublish(false);
        toast.success("Test successfully published", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        fetchAllQuizes();
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // console.log(error);
    }
  };

  const handleUnpublishCBT = async () => {
    try {
      const req = await fetch(
        `${BASE_URL}/cbt/quiz/unpublish/${focusedItem?.id}`,
        {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await req.json();
      console.log(res);
      if (req.status === 400) {
        toast.error("Error! Test cannot be unpublished");
      }
      if (req.ok) {
        toast.success("Test successfully unpublished", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        fetchAllQuizes();
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };

  // beggining of options menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // end of options menu

  return (
    <Layout>
      <ToastContainer />
      <Confirm
        open={openWarning}
        setOpen={setOpenWarning}
        title="warning"
        message="Are u sure you want to delete this test?"
        action={handleDelete}
      />
      {isPublish ? (
        <div className={style.modalContainer}>
          <div className="modals">
            <Link to="" className="cancelBox" onClick={handlePublishModal}>
              x
            </Link>
            <div className="content">
              <CheckboxGroup onChange={setOnSelectParticipant}>
                <div className="courseLists" key={"selectAll"} style={{marginBottom:"-5rem"}}>
                  <FormControlLabel 
                    value="start"
                    control={
                        <AllCheckerCheckbox style={{margin:"1rem"}} />
                    }
                    label="Select All Participants"
                    labelPlacement="end"
                  />
                </div>
                <form onSubmit={handlePublishCBT}>
                  <div className="courseLists">
                    {/* {course?.students?.map((student: any, i: number) => (
                      <div className="course" key={i}>
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              color="primary"
                              value={student.id}
                              name="course"
                            />
                          }
                          label={student.name}
                          labelPlacement="end"
                        />
                      </div>
                    ))} */}
                    
                    {course?.students?.map((student: any, i: number) => (
                      <div className="course" key={i}>
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox style={{margin:"1rem"}} 
                              color="primary"
                              value={student.id}
                              name="course"
                            />
                          }
                          label={student.name}
                          labelPlacement="end"
                        />
                      </div>
                    ))}                      

                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      fullWidth
                    >
                      {isPublishLoading ? <Spinner size="2" /> : "Send"}
                    </Button>
                  </div>
                </form>
              </CheckboxGroup>  
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={style.container}>
        <div className={style.dash}>
          <div
            className={style.header}
            style={{ backgroundColor: "#F1EDEC", padding: "1rem" }}
          >
            <h5
              style={{
                fontWeight: 100,
                color: "#152E88",
                fontSize: "1.5rem",
              }}
            >
              CBT
            </h5>

            {userRole != Role.STUDENT && (
              <span style={{ float: "right" }}>
                <Link to="/dashboard/create-cbt">
                  <Plus /> Create
                </Link>
              </span>
            )}
          </div>
          <div className={style.main}>
            {/* <h5
              className="drafts"
              style={{
                fontSize: "1.5rem",
                fontWeight: 100,
                color: "#152E88",
              }}
            >
              CBT Tests
            </h5> */}
            {quizesLoaded && <LoadingSpinner />}
            {userRole !== Role.STUDENT ? (
              <div className="lists">
                {quizes
                  ?.map((obj: any) => ({
                    ...obj,
                    date: new Date(obj.updated_at),
                  }))
                  .sort((a: any, b: any) => b.date.getTime() - a.date.getTime())
                  .map((ass: any, i: number) => (
                    <div className="list" key={i}>
                      <div
                        className="content"
                        onClick={() =>
                          navigate(`/dashboard/view-cbt`, {
                            replace: true,
                            state: {
                              id: ass.id,
                            },
                          })
                        }
                      >
                        <h5>
                          {ass.name}
                          {ass.is_published && (
                            <span
                              style={{
                                float: "right",
                                fontStyle: "italic",
                                color: "green",
                              }}
                            >
                              Published
                            </span>
                          )}
                        </h5>
                        <h6>{ass.course.name}</h6>
                        <span>Due Date {formatDate(ass.due_date).replace(",","")} {" "}</span>
                        {new Date(ass.due_date).getHours() >= 10 && new Date(ass.due_date).getMinutes() >= 10 &&
                         (<span>{new Date(ass.due_date).getHours().toLocaleString()+new Date(ass.due_date).getMinutes().toLocaleString()}hours</span>)
                        }
                        {new Date(ass.due_date).getHours() >= 10 && new Date(ass.due_date).getMinutes() < 10 &&
                         (<span>{new Date(ass.due_date).getHours().toLocaleString()+"0"+new Date(ass.due_date).getMinutes().toLocaleString()}hours</span>)
                        }
                        {new Date(ass.due_date).getHours() < 10 && new Date(ass.due_date).getMinutes() < 10 &&
                         (<span>0{new Date(ass.due_date).getHours().toLocaleString()+"0"+new Date(ass.due_date).getMinutes().toLocaleString()}hours</span>)
                        }
                        {new Date(ass.due_date).getHours() < 10 && new Date(ass.due_date).getMinutes() >= 10 &&
                         (<span>0{new Date(ass.due_date).getHours().toLocaleString()+new Date(ass.due_date).getMinutes().toLocaleString()}hours</span>)
                        }
                      </div>
                      <div>
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleClick(e);
                            setFocusedItem(ass);
                          }}
                        >
                          <MoreVert />
                        </Button>
                        
                          <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{ boxShadow: "none", border: "none" }}
                          >
                            {new Date(focusedItem.due_date) > new Date() && (
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                navigate("/dashboard/update-cbt", {
                                  state: {
                                    assignment: focusedItem,
                                  },
                                });
                              }}
                            >
                              Edit
                            </MenuItem>
                            )}

                            <MenuItem
                              onClick={() => {
                                handleClose();
                                setOpenWarning(true);
                              }}
                            >
                              Delete
                            </MenuItem>

                            
                            {!focusedItem.is_published &&
                              new Date(focusedItem.due_date) > new Date() && (
                                <MenuItem
                                  onClick={() => {
                                    handleIsPublish(focusedItem.course.id);
                                  }}
                                >
                                  Publish
                                </MenuItem>
                              )}

                            {focusedItem.is_published &&
                              new Date(focusedItem.due_date) > new Date() && (
                                <MenuItem
                                  onClick={() => {
                                    handleUnpublishCBT();
                                    handleClose();
                                  }}
                                >
                                  Unpublish
                                </MenuItem>
                              )}
                          </Menu>
                         
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <CBTAssignmentsList quizes={quizes} />
            )}
          </div>
          <ul className="pagination">
          <Previous
                    className={currentPage === 1 ? "icon active" : "icon "}
                    onClick={() => paginate(1)}
                  />
                  {Array.from(Array(pageNumbers).keys()).map((number) => (
                    <li
                      key={number+1}
                      className={number+1 === currentPage ? "active" : ""}
                    >
                      <a
                        onClick={() => paginate(number+1)}
                        href="#"
                        className="page-link"
                      >
                        {number+1}
                      </a>
                    </li>
                  ))}
                  <NextPage
                    className={
                      currentPage == pageNumbers
                        ? "icon active"
                        : "icon "
                    }
                    onClick={() => paginate(pageNumbers)}
                  />
                  </ul>
        </div>
      </div>
    </Layout>
  );
};

CBTAssignments.propTypes = {};

export default CBTAssignments;
