import { createAsyncThunk } from "@reduxjs/toolkit";
import { videoService } from "../../../services";
import { VideoAction } from "./videoType";

export const fetchVideo = createAsyncThunk(
  VideoAction.FETCHVIDEOS,
  async (user: string) => {
    const response = await videoService.getAll(user);
    return response.data;
  }
);

export const fetchCourseVideo = createAsyncThunk(
  VideoAction.FETCHCOURSEVIDEOS,
  async (body: any) => {
    const response = await videoService.getCourseVideo(body.user, body.id);
    return response.data;
  }
);
export const createVid = createAsyncThunk(
  VideoAction.CREATECOURSEVIDEOS,
  async (body: any) => {
    const response = await videoService.createVid(body);
    return response.data;
  }
);
export const uploadVid = createAsyncThunk(
  VideoAction.UPLOADCOURSEVIDEOS,
  async (body: any) => {
    const response = await videoService.uploadVid({ file: body });
    return response.data;
  }
);
