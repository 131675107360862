import { makeStyles } from "@material-ui/core";
import { colors } from "../../../styles/color";

export const useStyles = makeStyles((theme) => ({
  container: {},

  dash: {
    background: "white",
    boxShadow: "2px 2px 9px rgba(0, 0, 0, 0.25);",
    borderRadius: "5px",
    margin: "0.5rem 1rem ",
    padding: "0.50rem 1rem 1.60rem 1.0rem",
    minHeight: "calc(100vh - 100px)",
    height: "calc(100% - 50px)",
    autoflow: "hidden",

    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "22px",
      color: colors.black,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    "& a": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2px",
      borderRadius: "5px",
      textDecoration: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "22px",
      /* identical to box height */

      color: colors.blue,
      background: colors.gray,
      "& svg": { marginRight: ".2rem" },
    },
  },
  main: { 
    overflowX: "hidden",
    marginTop: "1rem",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 200px)",
      overflowY: "auto",
    },
    "& .lists.student": { marginTop: "3rem"},
    "& .lists": {
      overflow: "auto",      
      margin: "0 -1rem",

      "& div.list:not(:last-child)": {
        marginBottom: "0.5rem",
      },
      "& div.list": {
        position: "relative",
        "& .content:hover": {
          cursor: "pointer",
        },
        "& svg path": { fill: colors.blue },
        background: colors.gray,
        padding: "0.2rem 1.875rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .detail": {
          "& span": { display: "block", textAlign: "right" },
        },
        "& h5": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "1rem",
          lineHeight: "22px",
          /* identical to box height */
          marginBottom: "4px",
          color: colors.black,
        },
        "& h6": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "21px",
          /* identical to box height */
          marginBottom: "4px",

          color: colors.black,
        },
        "& span": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "15px",
          /* identical to box height */

          color: colors.black,
        },
      },
    },
    "& .draft": {
      margin: "1rem 0",
    },
    "& .sent": {
      margin: "1rem 0",
    },
    //handling menu options
    "& .dropItems": {
      width: "81px",
      background: colors.white,
      boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      gap: "6px",
      right: "20px",
      marginTop: "120px",
      zIndex: 1,
      "& >a": {
        textDecoration: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
        /* identical to box height */
        color: colors.black,
        textAlign: "center",
        padding: "4px 0",
      },
      "& a:not(:last-child)": {
        borderBottom: "1px solid #F6F4F4",
      },
    },
  },
}));
